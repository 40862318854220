import { ReactNode } from 'react';

import { DatetimeShow } from '@fhs/ui';

import {
  frenchDipCAEndDate,
  frenchDipCAStartDate,
  hotOnesEndDate,
  hotOnesStartDate,
} from '../constants';

export type LtoContentCAProps = {
  default?: ReactNode;
  frenchDip: ReactNode;
  ltoContent: ReactNode;
};

export const LtoContentCA = ({
  ltoContent,
  default: defaultContent,
  frenchDip,
}: LtoContentCAProps) => {
  return (
    <DatetimeShow
      after={hotOnesStartDate}
      before={hotOnesEndDate}
      default={
        <DatetimeShow
          after={frenchDipCAStartDate}
          before={frenchDipCAEndDate}
          default={defaultContent}
        >
          {frenchDip}
        </DatetimeShow>
      }
    >
      {ltoContent}
    </DatetimeShow>
  );
};
