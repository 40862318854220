import { useEffect, useRef, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

import type { Schema } from '@fhs/client';
import { Text, tokens } from '@fhs/ui';

export function OrderStatus({ status }: { status: Schema['CartState']['type'] | undefined }) {
  const [x, setStartNode] = useState(0);
  const [endNodeLeft, setEndNode] = useState(0);
  const width = Math.floor(endNodeLeft - x);

  return (
    <View style={styles.container}>
      <Text.Ui size="md" weight="semibold">
        Your Order is Being Processed! 🗳️
        {status}
      </Text.Ui>

      <View style={styles.lineContainer}>
        <View style={[styles.line, { left: x, width }]} />
        <View
          style={styles.node}
          onLayout={node =>
            setStartNode(Math.floor(node.nativeEvent.layout.x + node.nativeEvent.layout.width / 2))
          }
        >
          <Node focused={status && ['PRICE_REQUESTED', 'PRICE_SUCCESSFUL'].includes(status)} />
          <Text.Ui size="sm" style={styles.nodeText}>
            {'Order\nProcessed'}
          </Text.Ui>
        </View>
        <View style={styles.node}>
          <Node focused={status === 'INSERT_REQUESTED'} />
          <Text.Ui
            size="sm"
            style={styles.nodeText}
            weight={status === 'INSERT_REQUESTED' ? 'semibold' : 'normal'}
          >
            {'Order\nReceived'}
          </Text.Ui>
        </View>

        <View style={styles.node}>
          <Node focused={status === 'INSERT_SUCCESSFUL'} />
          <Text.Ui size="sm" style={styles.nodeText}>
            Preparing
          </Text.Ui>
        </View>
        <View
          style={styles.node}
          onLayout={node =>
            setEndNode(Math.floor(node.nativeEvent.layout.x + node.nativeEvent.layout.width / 2))
          }
        >
          <Node />
          <Text.Ui size="sm" style={styles.nodeText}>
            {'Ready\nNow'}
          </Text.Ui>
        </View>
      </View>
    </View>
  );
}

function Node(props: { focused?: boolean }) {
  const scale = useRef(new Animated.Value(props.focused ? 1 : 0));

  useEffect(() => {
    Animated.spring(scale.current, {
      toValue: props.focused ? 1 : 0,
      //   tension: 2,
      bounciness: 2,
      useNativeDriver: true,
    }).start();
  }, [props.focused]);

  return (
    <View style={[styles.focusedNode]}>
      <Animated.View style={[styles.borderNode, { transform: [{ scale: scale.current }] }]} />
      <View style={[styles.focusedInner]}>
        <Animated.View
          style={[styles.focusedInnerNode, { transform: [{ scale: scale.current }] }]}
          testID="foo"
        />
        <View style={styles.ball} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  hiddenNode: {
    backgroundColor: 'transparent',
  },
  lineContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  focusedInner: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    width: 24,
    borderRadius: 24,
  },
  focusedInnerNode: {
    transform: [{ scale: 0 }],
    position: 'absolute',
    height: 24,
    width: 24,
    borderRadius: 24,
    backgroundColor: tokens.colors.$black,
  },
  focusedNode: {
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    top: -7,
  },
  borderNode: {
    transform: [{ scale: 0 }],
    position: 'absolute',
    height: 32,
    width: 32,
    borderRadius: 32,
    backgroundColor: tokens.colors.$houseLight,
  },
  nodeText: {
    height: 32,
    alignContent: 'center',
    textAlign: 'center',
  },
  node: {
    alignItems: 'center',
    flex: 1,
  },
  ball: {
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: tokens.colors.$houseLight,
  },
  line: {
    position: 'absolute',
    top: 3,
    height: 4,
    width: 264,
    backgroundColor: tokens.colors.$houseLight,
  },
  container: {
    alignItems: 'center',
    height: 200,
    width: 343,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderColor: tokens.colors.$black10,
    backgroundColor: tokens.colors.$white,
    borderWidth: 1,
    borderRadius: 8,
  },
});
