import { ApolloProvider } from '@apollo/client';
import { useApolloClientDevTools } from '@dev-plugins/apollo-client';
import React from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';

import { client } from './client';
import { useHeadersSync } from './hooks/use-headers-sync';

export const GraphQLProvider = ({ children }: IBaseProps) => {
  useHeadersSync();
  useApolloClientDevTools(client);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { useLazySanityQuery, useSanityQuery } from './hooks/use-sanity-query';
