import { cloneDeep } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { IBackendCartEntries, ICartEntry, IItem, IOfferDiscount } from '@rbi-ctg/menu';
import { ICartEntryFragment, OfferType } from 'generated/rbi-graphql';
import useAuth from 'hooks/auth/use-auth';
import { usePriceOrder } from 'hooks/price-order';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { useOnBeforeUnload } from 'hooks/use-on-before-unload';
import { useToast } from 'hooks/use-toast';
import { DeliverDetailsErrors } from 'pages/cart/types';
import { IAddToCartSelectionAttributes } from 'state/amplitude/types';
import { mapEntryTypeToOfferType } from 'state/cart/helpers';
import { useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { isLoyaltyOfferV2 } from 'state/loyalty/types';
import { isDiscountOffer } from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { OrderStatus, ServiceMode, useOrderContext } from 'state/order';
import useAlertOrderCateringMinimum from 'state/order/hooks/use-alert-order-catering-min';
import { findEntriesByCmsId, replaceEntry } from 'state/order/utils';
import { useStoreContext } from 'state/store';
import { createCartEntry } from 'utils/cart';
import { computeCartTotal } from 'utils/cart/helper';
import { redeemReward } from 'utils/cart/redeem-reward';
import { isTest } from 'utils/environment';
import { isCatering, isDelivery } from 'utils/service-mode';
import { TOAST_ITEM_ADDED_TO_CART } from 'utils/test-ids';

import { useSanitizedRewards } from './hooks/use-sanitized-rewards';
import { TempCartPriceMismatchLogger } from './temp-cart-price-mismatch-logger';
import type { ICartContext, ICartContextArgs, ISwappableItem, ITryPricingOverrides } from './types';

const CartContext = createContext<ICartContext>({} as ICartContext);

export const CartProvider: React.FC<React.PropsWithChildren<ICartContextArgs>> = ({ children }) => {
  const [cardSuccessfullyAdded, setCardSuccessfullyAdded] = useState(false);

  const [shouldSkipPriceOrder, setShouldSkipPriceOrder] = useState(false);
  const [shouldSkipPriceOrderStatusPolling, setShouldSkipPriceOrderStatusPolling] = useState(false);

  const [isSyncEntriesPending, setIsEntriesSyncPending] = useState(false);

  const syncEntries = useCallback(() => setIsEntriesSyncPending(true), []);

  const {
    cartEntries: orderContextCartEntries,
    cartVersion,
    calculateCartTotalWithDiscount: orderCalculateCartTotalWithDiscount,
    deliveryAddress,
    deliveryInstructions,
    emptyCart: orderEmptyCart,
    orderPhoneNumber,
    serviceMode: orderServiceMode,
    selectServiceMode: selectOrderServiceMode,
    unavailableCartEntries,
    setUnavailableCartEntries,
    setCartEntries: setOrderCartEntries,
  } = useOrderContext();

  const { logAddOrRemoveFromCart, logChangeServiceMode } = useCRMEventsContext();

  const [serviceMode, setServiceMode] = useState(orderServiceMode);
  // Keep local ref of the serviceMode to properly update OrderContext on unmount
  // The ref update is synchronous, while the state update will only occur after re-render
  const contextServiceModeRef = useRef<ServiceMode>(orderServiceMode);

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);

  const [contextCartEntries, setContextCartEntries] =
    useState<ICartEntry[]>(orderContextCartEntries);
  const toast = useToast();

  const selectServiceMode = useCallback(
    (selectedServiceMode: ServiceMode) => {
      logChangeServiceMode({
        newServiceMode: selectedServiceMode,
        oldServiceMode: contextServiceModeRef.current,
      });
      //
      setServiceMode(selectedServiceMode);
      contextServiceModeRef.current = selectedServiceMode;
    },
    [setServiceMode, logChangeServiceMode]
  );

  const offers = useOffersContext();
  const {
    selectedOffer: selectedCBAOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
    clearSelectedOffer,
  } = offers;

  const { user, refreshCurrentUser } = useAuth();
  const { isStoreAvailable, store, serviceModeStatus } = useStoreContext();
  const { formatMessage } = useIntl();
  const { pricingFunction } = useMenuContext();

  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const incentiveSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);
  const loyaltyAppliedDiscountOffer = useAppSelector(
    selectors.loyalty.selectDiscountAppliedCmsOffer
  );
  const selectedOfferSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);
  const isPricingRewardApplication = useAppSelector(
    selectors.loyalty.selectIsPricingRewardApplication
  );

  const { locale: customerLocale } = useLocale();
  const dispatch = useAppDispatch();

  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();

  useEffect(() => {
    // TODO: having a deps array will trigger this cleanup function every time that the deps changes
    // Check if this is intentional or this should only be called on unmount
    return () => {
      if (deliveryAddress?.shouldSave) {
        refreshCurrentUser();
      }
    };
  }, [deliveryAddress, refreshCurrentUser]);

  useEffect(() => {
    // Update OrderContext on unmount since Cart components should use serviceMode from CartContext
    // This significantly improves perceived performance when switching between serviceMode options
    return () => {
      if (contextServiceModeRef.current !== orderServiceMode) {
        selectOrderServiceMode(contextServiceModeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Ensure the service mode is being reset if it's Delivery
    // This happens in React Nav when changing stores from the Cart between Pick Up/Delivery that may result in a stale state
    const isDeliveryOrder =
      isDelivery(orderServiceMode) || isDelivery(contextServiceModeRef.current);
    if (isDeliveryOrder && contextServiceModeRef.current !== orderServiceMode) {
      selectServiceMode(orderServiceMode);
    }
  }, [orderServiceMode, selectServiceMode]);

  const appliedOffersMap = useMemo(
    () =>
      appliedOffers.reduce((acc, offer) => {
        acc[offer.cartId || ''] = offer;
        return acc;
      }, {}),
    [appliedOffers]
  );

  const cartEntries = useMemo(() => {
    // Create the cart entry from the selected offer cart entry
    const offerMenuCartEntry = selectedOfferCartEntry
      ? [
          {
            ...selectedOfferCartEntry,
            price: selectedOfferPrice,
            offerVendorConfigs: selectedCBAOffer && selectedCBAOffer.vendorConfigs,
            type: mapEntryTypeToOfferType(selectedOfferCartEntry.type),
          },
        ]
      : [];
    // Create the cart entry from the selected discount offer
    // This offer will be available if the cart is not empty
    const selectedOffer = loyaltyAppliedDiscountOffer || selectedCBAOffer;

    // @ts-expect-error TS(2322) FIXME: ICartEnty does not support empty vendorConfigs
    const offerDiscountCartEntry: ICartEntry[] =
      contextCartEntries.length &&
      selectedOffer &&
      (isDiscountOffer(selectedOffer) || isLoyaltyOfferV2(selectedOffer?.__typename!))
        ? [
            {
              ...createCartEntry({ item: selectedOffer }),
              cartId: 'discount-offer',
              vendorConfigs: undefined,
              offerVendorConfigs: selectedOffer.vendorConfigs,
            },
          ]
        : [];

    // Updating entry type if its part of an applied offer
    const updatedCartEntries = (contextCartEntries || []).map((entry: ICartEntry) =>
      appliedOffersMap[entry.cartId]
        ? { ...entry, type: mapEntryTypeToOfferType(entry.type) }
        : entry
    );

    // Cart entries with offers
    return updatedCartEntries.concat(offerMenuCartEntry, offerDiscountCartEntry);
  }, [
    appliedOffersMap,
    loyaltyAppliedDiscountOffer,
    contextCartEntries,
    selectedCBAOffer,
    selectedOfferCartEntry,
    selectedOfferPrice,
  ]);

  const rewardsApplied = useSanitizedRewards({ cartEntries });

  const swapItems = useCallback(
    (to: IItem, swapOffer: ISwappableItem) => {
      const { from, ...offer } = swapOffer;
      setContextCartEntries(prevEntries => {
        // Root cart entry is needed for appliedOffers
        // Child cart entry is needed for swaps objects
        const { rootEntry } = findEntriesByCmsId(prevEntries, from);
        let entriesToReturn = prevEntries;

        if (rootEntry) {
          const oldEntry = rootEntry;
          // Define price for item
          const oldEntryUnitaryPrice = (oldEntry?.price ?? 0) / oldEntry.quantity;
          const { type: swapType, offerId, offerType, cmsId } = offer;

          const entryToSwap = {
            ...createCartEntry({
              item: to,
              price: oldEntry.children.length ? 0 : oldEntryUnitaryPrice,
              quantity: 1,
            }),
            sanityId: to._id,
          };

          const offerToApply = {
            id: offerId,
            cartId: oldEntry.children.length ? oldEntry.cartId : entryToSwap.cartId,
            type: OfferType[offerType],
            swap: {
              cartId: entryToSwap.cartId,
              from,
              to: to._id,
              swapType,
            },
            cmsId,
          };

          // This covers the case when a cartEntry has more than one item and swap is selected
          // updating price and quantity to the cartEntry that was swapped
          if (oldEntry.quantity > 1) {
            oldEntry.quantity--;
            oldEntry.price = oldEntryUnitaryPrice * oldEntry.quantity;

            // If cartEntry has children, we keep a copy of the entry without
            // the swap applied to concat to the entries array after applying the swap
            if (oldEntry.children.length) {
              const splittedItem = cloneDeep(oldEntry);
              splittedItem.cartId = uuidv4();
              offerToApply.cartId = oldEntry.cartId;
              // Creating new cart entry with swap applied to replace at root level
              // having quantity and price correct for a swapped item
              const newRoot = {
                ...oldEntry,
                children: replaceEntry(oldEntry.children, from, { ...entryToSwap, price: 0 }),
                price: oldEntryUnitaryPrice,
                quantity: 1,
              };

              entriesToReturn = replaceEntry(prevEntries, oldEntry._id, newRoot).concat(
                splittedItem
              );
            } else {
              entriesToReturn = prevEntries.concat(entryToSwap);
            }
          } else {
            entriesToReturn = replaceEntry(prevEntries, from, entryToSwap);
          }

          dispatch(actions.loyalty.applyOffer(offerToApply));
        }

        return entriesToReturn;
      });
    },
    [setContextCartEntries, dispatch]
  );
  const {
    priceOrder,
    orderStatus,
    priceOrderFailure,
    priceOrderSuccess,
    priceResult: { error: priceMutationError },
    serverOrder,
  } = usePriceOrder({
    cartEntries,
    skipOrderStatusPolling: shouldSkipPriceOrderStatusPolling,
  });

  // To access the Cart we should have OrderCtx cartEntries
  // If those are empty is because it was cleared out by an external cart logic (reset cart time / order commit)
  const orderContextCartEntriesWasCleared = !orderContextCartEntries?.length;

  // Curbside will not have INSERT_SUCCESSFUL status if we close the cart before clicking I'm Here button
  const orderWasPlaced =
    orderStatus === OrderStatus.INSERT_SUCCESSFUL || orderStatus === OrderStatus.INSERT_REQUESTED;

  /**
   * CartContext has its own local state, which saves to OrderContext on unmount.
   * Storing the deps on refs so we don't have stale values on the useEffect cleanup function.
   */
  const cartEntriesRef = useRef<ICartEntry[]>();
  cartEntriesRef.current = contextCartEntries;

  const skipOrderContextSyncRef = useRef<boolean>();
  skipOrderContextSyncRef.current = orderContextCartEntriesWasCleared || orderWasPlaced;

  const syncEntriesToOrderCtx = useCallback(() => {
    // Update order context setting all local state changes (edit, update, remove..)
    if (!cartEntriesRef.current?.length) {
      orderEmptyCart();
    } else {
      setOrderCartEntries(cartEntriesRef.current || []);
    }
    // Removed orderEmptyCart from the dependecies because it was causing too many extra renders sometimes.
    // This shouldn't cause problems since the idea is for this method to run just on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOrderCartEntries]);

  useEffectOnUnmount(() => {
    if (skipOrderContextSyncRef.current) {
      return;
    }
    syncEntriesToOrderCtx();
  });

  useOnBeforeUnload(syncEntriesToOrderCtx);

  useEffect(() => {
    if (isSyncEntriesPending) {
      syncEntriesToOrderCtx();
      setIsEntriesSyncPending(false);
    }
  }, [contextCartEntries, syncEntriesToOrderCtx, isSyncEntriesPending]);

  const addItemToCart = useCallback(
    (
      cartEntry: ICartEntry | IBackendCartEntries | ICartEntryFragment | null,
      eventAttrs: IAddToCartSelectionAttributes | undefined,
      showMessage = true
    ) => {
      setContextCartEntries((prevCartEntries: ICartEntry[]) => {
        logAddOrRemoveFromCart({
          action: 'add',
          cartEntry: cartEntry as ICartEntry,
          previousCartEntries: prevCartEntries,
          selectionAttrs: eventAttrs,
        });

        return prevCartEntries.concat([cartEntry as ICartEntry]);
      });

      if (cartEntry?.isDonation) {
        return;
      }

      if (showMessage) {
        toast.show({
          testID: TOAST_ITEM_ADDED_TO_CART,
          duration: isTest ? 9999999 : undefined,
          text: formatMessage({ id: 'addToCartSuccess' }, { itemName: cartEntry?.name }),
          variant: 'positive',
        });
      }

      setIsEntriesSyncPending(true);
    },
    [formatMessage, logAddOrRemoveFromCart, toast]
  );

  const removeRewardIfNeeded = useCallback(
    (cartEntry: ICartEntry) => {
      const { cartId } = cartEntry;
      const cartEntryReward = getAvailableRewardFromCartEntry(cartEntry);

      const isRewardApplied = !!appliedLoyaltyRewards?.[cartId]?.timesApplied;
      if (loyaltyEnabled && isRewardApplied && cartEntryReward) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: cartEntryReward.rewardBenefitId,
            cartId,
          })
        );
      }
    },
    [appliedLoyaltyRewards, getAvailableRewardFromCartEntry, loyaltyEnabled, dispatch]
  );

  const shouldOfferBeRemoved = useCallback(
    (item: ICartEntry) => {
      const cartEntriesWithRemovedItem = contextCartEntries.filter(
        (entry: ICartEntry) => entry._id !== item._id
      );
      const offerDetails = {
        selectedOffer: offers.selectedOffer,
        selectedOfferCartEntry: offers.selectedOfferCartEntry,
        selectedOfferPrice: offers.selectedOfferPrice,
      };

      let total = computeCartTotal(offerDetails, cartEntriesWithRemovedItem, {
        loyaltyEnabled,
        appliedLoyaltyRewards,
        appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0] as IOfferDiscount,
      });
      total = total < 0 ? 0 : total;

      const offerPrice = (offers?.selectedOffer?.option as IOfferDiscount)?.discountValue || 0;
      // TODO: this line does not account for percentage type discounts
      return total < offerPrice * 100;
    },
    [appliedLoyaltyRewards, contextCartEntries, loyaltyEnabled, offers, discountAppliedCmsOffers]
  );

  const shouldOfferV2BeRemoved = useCallback(
    (item: ICartEntry) => {
      const isASelectionFromOffer = selectedOfferSelections?.find(
        selection => selection.lineId === item.cartId
      );
      return isASelectionFromOffer;
    },
    [selectedOfferSelections]
  );

  const logCartEntryRemovedFromCart = useCallback(
    (cartEntry: ICartEntry, previousCartEntries: ICartEntry[]) => {
      logAddOrRemoveFromCart({ action: 'remove', cartEntry, previousCartEntries });
    },
    [logAddOrRemoveFromCart]
  );
  const removeOfferFromCart = useCallback(() => {
    return selectedCBAOffer && clearSelectedOffer();
  }, [clearSelectedOffer, selectedCBAOffer]);

  const removeFromCart = useCallback(
    ({ cartId }: { cartId: string }) => {
      // Entry to remove can be an offer cart entry
      const isOffer = !!appliedOffers.find(offer => offer.cartId === cartId);
      const cartEntryToRemove = contextCartEntries.find(
        (entry: ICartEntry) => entry.cartId === cartId
      );

      if (!cartEntryToRemove) {
        return;
      }
      logCartEntryRemovedFromCart(cartEntryToRemove, contextCartEntries);
      setContextCartEntries((prevCartEntries: ICartEntry[]) =>
        prevCartEntries.filter(entry => cartEntryToRemove.cartId !== entry.cartId)
      );

      if (shouldOfferV2BeRemoved(cartEntryToRemove)) {
        dispatch(actions.loyalty.resetAppliedOffers());
      }

      if (isOffer || shouldOfferBeRemoved(cartEntryToRemove)) {
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntryToRemove));
        removeOfferFromCart();
      }
      // removes applied rewards associated to cart entry on removal
      removeRewardIfNeeded(cartEntryToRemove);

      // Resets the availability of Surprise so it wont show in cart page
      dispatch(actions.loyalty.resetSurpriseAvailability());

      setIsEntriesSyncPending(true);
    },
    [
      appliedOffers,
      contextCartEntries,
      logCartEntryRemovedFromCart,
      shouldOfferBeRemoved,
      removeRewardIfNeeded,
      dispatch,
      removeOfferFromCart,
      shouldOfferV2BeRemoved,
    ]
  );

  const emptyCart = () => {
    setContextCartEntries([]);
    cartEntriesRef.current = [];
  };

  const removeAllFromCart = useCallback(
    (cartEntriesToRemove: ICartEntry[] = []): void => {
      cartEntriesToRemove.forEach(entry => removeFromCart(entry));
    },
    [removeFromCart]
  );

  const onConfirmRemoveItemDialog = useCallback(
    (entry: any) => {
      return removeFromCart(entry);
    },
    [removeFromCart]
  );

  const [RemoveItemDialog, openRemoveItemDialog, itemToRm] = useDialogModal({
    onConfirm: onConfirmRemoveItemDialog,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Remove item from cart',
  });

  // for confirming item removal
  const confirmRemoveItemFromCart = useCallback(
    (cartId: string) => {
      const entryToRemove = contextCartEntries.find((item: ICartEntry) => cartId === item.cartId);
      openRemoveItemDialog(entryToRemove);
    },
    [contextCartEntries, openRemoveItemDialog]
  );

  const updateItemsQuantity = useCallback(
    (cartId: string, quantity: number) => {
      if (quantity < 1) {
        return removeFromCart({ cartId });
      }

      setContextCartEntries((prevCartEntries: ICartEntry[]) =>
        prevCartEntries.map(entry => {
          const entryCopy = { ...entry };
          if (entryCopy.cartId === cartId) {
            entryCopy.quantity = quantity;
            entryCopy.price = pricingFunction(entry, entryCopy.quantity);
          }

          return entryCopy;
        })
      );
      setIsEntriesSyncPending(true);
    },
    [pricingFunction, removeFromCart]
  );

  const calculateCartTotalWithDiscount = useCallback(
    () => orderCalculateCartTotalWithDiscount(contextCartEntries),
    [contextCartEntries, orderCalculateCartTotalWithDiscount]
  );

  const tryPricing = useCallback(
    (overrideOrderParams?: ITryPricingOverrides) => {
      /**
       * Sample barcode composition looks like:
       * 046300...|F78B55B3...|13FF07A7DC0C...|MA|DT:TO|PT:TC
       * where MA means mobile app, DT is the dining type and PT is the payment type
       * https://rbictg.atlassian.net/browse/CBA-12104
       */
      const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount();

      if (!isStoreAvailable || !user || redeemReward || isCartTotalNegative) {
        return;
      }

      const validAppliedOffers = appliedOffers.map(({ id, cartId, swap, type, cmsId }) => ({
        id,
        cartId,
        swap,
        type,
        sanityId: cmsId,
      }));

      const customerName = null;
      const orderParams = {
        rewardsApplied,
        cartVersion,
        customerLocale,
        customerName,
        deliveryAddress,
        orderPhoneNumber,
        deliveryInstructions,
        appliedOffers: validAppliedOffers,
        requestedAmountCents: cartTotal,
        serviceMode,
        store,
        isStoreAvailable,
        incentiveSelections,
        ...overrideOrderParams,
      };

      void priceOrder(orderParams, user);
      if (isPricingRewardApplication) {
        dispatch(actions.loyalty.setIsPricingRewardApplication(false));
      }
    },
    [
      rewardsApplied,
      appliedOffers,
      calculateCartTotalWithDiscount,
      cartVersion,
      customerLocale,
      deliveryAddress,
      deliveryInstructions,
      dispatch,
      isPricingRewardApplication,
      isStoreAvailable,
      orderPhoneNumber,
      priceOrder,
      serviceMode,
      store,
      user,
    ]
  );
  const getOfferText = (item: ICartEntry) =>
    shouldOfferBeRemoved(item) || shouldOfferV2BeRemoved(item)
      ? formatMessage({ id: 'removeItemFromCartOfferText' })
      : '';

  const removeItemMessage = (item: ICartEntry) => {
    return item
      ? formatMessage(
          { id: 'removeItemFromCart' },
          {
            item: item.name,
            offerText: getOfferText(item),
          }
        )
      : '';
  };
  const pricedAndAvailable = useMemo(() => {
    return priceOrderSuccess && !unavailableCartEntries.length && !priceMutationError;
  }, [priceMutationError, priceOrderSuccess, unavailableCartEntries.length]);

  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);
  const checkoutCateringPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM);

  const selectedDonationCartEntry = useMemo(
    () => cartEntries.find((cartEntry: ICartEntry) => cartEntry.isDonation),
    [cartEntries]
  );

  // This is important to let the reset cart time out works as we expect when the user is on the cart screen.
  // as the empty cart method callback is fired on the order provider and doesn't has effect on it, since we are using a ref to keep the cart items.
  // In order to empty the cart in that case we need to fire the emptyCart from here.
  useEffect(() => {
    if (!orderContextCartEntries.length) {
      emptyCart();
    }
  }, [orderContextCartEntries]);

  const [areDeliveryDetailsValid, setAreDeliveryDetailsValid] = useState(
    serviceMode === ServiceMode.DELIVERY ? Boolean(orderPhoneNumber) : true
  );
  const [hasInvalidDeliveryOptions, setHasInvalidDeliveryOptions] = useState(false);
  const [deliveryDetailsErrors, setDeliveryDetailsErrors] = useState<DeliverDetailsErrors>({});

  const [cartCateringPriceTooLow, setCartCateringPriceTooLow] = useState(false);

  const calculateCartTotal = useCallback(() => {
    const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount();
    return isCartTotalNegative ? 0 : cartTotal;
  }, [calculateCartTotalWithDiscount]);

  useAlertOrderCateringMinimum({
    calculateCartTotal,
    checkoutCateringPriceMinimum,
    isCatering: isCatering(orderServiceMode),
    setCartCateringPriceTooLow,
    cartEntries,
  });

  const hasPriceError = !!priceMutationError || priceOrderFailure;
  const { isCartTotalNegative = false } = calculateCartTotalWithDiscount() || {};
  const showCartErrorModal = useMemo(
    () =>
      hasPriceError &&
      !unavailableCartEntries.length &&
      !isCartTotalNegative &&
      !shouldSkipPriceOrder,
    [hasPriceError, isCartTotalNegative, shouldSkipPriceOrder, unavailableCartEntries]
  );

  return (
    <CartContext.Provider
      value={{
        cartEntries,
        addItemToCart,
        confirmRemoveItemFromCart,
        removeFromCart,
        removeAllFromCart,
        emptyCart,
        updateItemsQuantity,
        serverOrder,
        tryPricing,
        cardSuccessfullyAdded,
        setCardSuccessfullyAdded,
        orderStatus,
        priceOrderFailure,
        priceOrderSuccess,
        priceMutationError,
        pricedAndAvailable,
        unavailableCartEntries,
        setUnavailableCartEntries,
        serviceMode,
        selectServiceMode,
        serviceModeStatus,
        shouldSkipPriceOrder,
        setShouldSkipPriceOrder,
        shouldSkipPriceOrderStatusPolling,
        setShouldSkipPriceOrderStatusPolling,
        enableFutureOrdering,
        selectedDonationCartEntry,
        swapItems,
        areDeliveryDetailsValid,
        setAreDeliveryDetailsValid,
        hasInvalidDeliveryOptions,
        setHasInvalidDeliveryOptions,
        deliveryDetailsErrors,
        setDeliveryDetailsErrors,
        calculateCartTotalWithDiscount,
        showCartErrorModal,
        cartCateringPriceTooLow,
        syncEntries,
      }}
    >
      {children}
      <TempCartPriceMismatchLogger serverOrder={serverOrder} />

      <RemoveItemDialog
        body={removeItemMessage(itemToRm)}
        heading={formatMessage({ id: 'removeItem' })}
      />
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);
