import delve from 'dlv';
import { router } from 'expo-router';
import { pick } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { IPaymentCardType } from '@rbi-ctg/offers';
import { CurrentSelectedMethodType } from 'components/payment-method/types';
import StoreConfirmationModal from 'components/store-confirmation-modal';
import { CartPaymentCardType } from 'generated/rbi-graphql';
import { ICommitOrderInput, useCommitOrder } from 'hooks/commit-order';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { usePickupTips } from 'pages/cart/your-cart/totals/use-pickup-tips';
import { getNonce } from 'remote/api/first-data';
import { IPayment } from 'remote/queries/order';
import useApplePay from 'state/apple-pay/hooks/use-apple-pay';
import { useAuthContext } from 'state/auth';
import { useCartContext } from 'state/cart';
import {
  TransactionType,
  getGooglePaymentsConfigs,
} from 'state/google-pay/get-google-payments-configs';
import useGooglePay from 'state/google-pay/hooks/use-google-pay';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import {
  PaymentFieldVariations,
  SupportedCardType,
  defaultPaymentFieldVariation,
  defaultSupportedCardTypes,
} from 'state/launchdarkly/variations';
import { useOffersContext } from 'state/offers';
import { OrderStatus, useOrderContext } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { DeliveryStatus } from 'state/order/types';
import { usePaymentContext } from 'state/payment';
import {
  CASH_ACCOUNT_IDENTIFIER,
  PAYPAL_PAYMENT_METHOD_PLACEHOLDER,
} from 'state/payment/constants';
import usePrepaidMethodReload from 'state/payment/hooks/use-prepaid-method-reload';
import { CardType, CardTypes, IApplePayDetails, IGooglePayDetails } from 'state/payment/types';
import { ServiceMode } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { setUserAttributes } from 'utils/braze';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import { HapticsNotificationType, hapticNotification } from 'utils/haptic';
import logger from 'utils/logger';
import { GraphQLError } from 'utils/network';
import { RuleType, findFirstRuleOfTypeThatApplies } from 'utils/offers';
import {
  IFraudPreventionValues,
  IPaymentState,
  checkIfValidTip,
  extractPaymentTypeForLD,
  getCCFormErrors,
  getFraudPreventionValues,
  initialErrorState,
  initialPaymentState,
  mapDeliveryToCCFormAddress,
  onCCFormChange,
  splitExpiry,
  testCardPaymentState,
} from 'utils/payment';
import { mapPaymentMethodCardTypes } from 'utils/payment/map-payment-method-card-type';
import { isApplePay, isGooglePay } from 'utils/payment/native-payment';
import { routes } from 'utils/routing';
import { getLocalizedServiceMode, isCatering } from 'utils/service-mode';

import { useHandleCommitError } from '../../hooks/use-handle-commit-error';
import { getOrderFailureMessageKey } from '../get-error-dialog-options';

import { UseOrderPayment } from './types';

export const useOrderPayment = ({ serverOrder }: UseOrderPayment) => {
  const payment = usePaymentContext();
  const isOneTimeCardPayment =
    payment.paymentDetails.state &&
    !payment.paymentDetails.state.saveCard &&
    !!payment.paymentDetails.state.cardNumber;
  const {
    checkoutPaymentMethodId,
    setCheckoutPaymentMethodId,
    checkoutPaymentMethod,
    paymentProcessor,
  } = payment;

  const auth = useAuthContext();
  const order = useOrderContext();
  const offersCtx = useOffersContext();
  const googleConfig = useConfigValue({ key: 'google', defaultValue: {} });
  const migrationEnabled = useFlag(LaunchDarklyFlag.ENABLE_DIGITAL_WALLET_MIGRATION);
  const urlsConfig = useConfigValue({ key: 'urls', defaultValue: {} });
  const fdUrl = useMemo(
    () => urlsConfig.firstData || urlsConfig.firstDataTh,
    [urlsConfig.firstData, urlsConfig.firstDataTh]
  );

  const { formatCurrencyForLocale, confirmStoreOnCheckout, shouldConfirmStoreOnCheckout } =
    useUIContext();
  const { serviceMode, setShouldSkipPriceOrderStatusPolling } = useCartContext();
  const { pickUpTipsCents, pickUpTipsEnabled } = usePickupTips(serviceMode);
  const paymentFieldVariations =
    useFlag<PaymentFieldVariations>(LaunchDarklyFlag.PAYMENT_FIELD_VARIATIONS) ||
    defaultPaymentFieldVariation;

  const rawSupportedCardTypes: SupportedCardType[] =
    useFlag(LaunchDarklyFlag.SUPPORTED_CARD_BRANDS_VARIATIONS) || defaultSupportedCardTypes;
  const supportedCardTypes = rawSupportedCardTypes
    .map(cardType => mapPaymentMethodCardTypes(cardType))
    .filter(Boolean) as CardType[];

  const oneTimePaymentFlowEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_VAULT_AFTER_MAKING_ONE_TIME_PAYMENT
  );

  const [ErrorDialog, openErrorDialog, dismissErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Order Commit Error',
  });

  const { deliveryAddress, emptyCart } = useOrderContext();
  const { selectedOffer } = useOffersContext();
  const { email, isRestaurantAvailable, openOrderingUnavailableDialog, store, isStoreAvailable } =
    useStoreContext();
  const { formatMessage } = useIntl();
  const { updateCheckoutSelections } = useLDContext();
  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const isCurbside = serviceMode === ServiceMode.CURBSIDE;
  const isCateringOrder = isCatering(serviceMode);
  const isDeliveryQuoted =
    isDelivery && serverOrder?.delivery?.status === DeliveryStatus.QUOTE_SUCCESSFUL;

  const billingCountry = delve(auth, 'user.details.isoCountryCode') || ISOs.USA;
  const userDetailsName = auth.user?.details.name;
  const [paymentValues, setPaymentValues] = useState(
    initialPaymentState({ billingCountry, userDetailsName })
  );
  const [errors, setErrors] = useState(initialErrorState);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [isAddGiftCardMethodSelected, setIsAddGiftCardMethodSelected] = useState(false);
  const [isCommittingOrder, setCommittingOrder] = useState(false);
  const [isHandlingOrderCommit, setIsHandlingOrderCommit] = useState(false);
  const [commitOrderAfterPricing, setCommitOrderAfterPricing] = useState(false);
  const [refetchingOrderStatus, setRefetchingOrderStatus] = useState(false);

  const autoFill = useFlag(LaunchDarklyFlag.AUTO_FILL_TEST_CARD);
  const enableDelivery = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);
  const fdAccessTokenFirstRequest = useRef<string | null>(null);
  const paymentNonceFirstRequest = useRef<string | null>(null);
  const accountIdentifierFirstRequest = useRef<string | null>(null);

  const [StoreConfirmationDialog, openStoreConfirmationModal, , dismissStoreConfirmationDialog] =
    useDialogModal({
      showCancel: true,
      Component: StoreConfirmationModal,
      modalAppearanceEventMessage: 'Confirmation Modal: Store Location',
    });

  const {
    commitOrder,
    commitResult: { called: commitOrderCalled, error: commitError, loading: isCommitOrderLoading },
    forceCommitOrder,
    success: commitOrderSuccess,
    failure: commitOrderFailure,
    setSkipPolling,
    refetchOrderStatus,
  } = useCommitOrder({
    rbiOrderId: serverOrder && serverOrder.rbiOrderId,
  });

  // hold on to the original orderId
  const prevOrderId = useRef<string | null>(null);
  useEffect(() => {
    if (serverOrder && !prevOrderId.current) {
      prevOrderId.current = serverOrder.rbiOrderId;
    }
  }, [serverOrder]);

  const [orderStatusRefetched, setOrderStatusRefetched] = useState(false);

  /*
    Sometimes commit mutation returns time out error, but the payment/commit is successfully processed.
    To avoid inconsistencies, we have this workaround:
    - Instead of showing the error, we try to refetch the order status once.
    - If the order status is passed "PRICE_SUCCESSFUL", we continue the polling.
    - If it isn't, we stop the polling and call setOrderStatusRefetched(true) to retrigger the handleCommitError
  */
  const handleRefetchOrderStatusStart = useCallback(() => {
    setRefetchingOrderStatus(true);

    refetchOrderStatus().then(response => {
      const orderStatus = response?.data?.order?.status;
      if (orderStatus && !OrderSuccessFailureStatuses.orderSubmitted.includes(orderStatus)) {
        setSkipPolling(true); // Status is not in orderSubmitted, stop polling
        setOrderStatusRefetched(true);
        setRefetchingOrderStatus(false);
        setShouldSkipPriceOrderStatusPolling(true);
      }
    });
  }, [refetchOrderStatus, setSkipPolling, setShouldSkipPriceOrderStatusPolling]);

  const { handleCommitError } = useHandleCommitError({
    errorMsgId: getOrderFailureMessageKey(payment.checkoutPaymentMethodId, commitError),
    openErrorDialog,
    dismissErrorDialog,
    onCoolingPeriodConfirm: forceCommitOrder,
    commitError,
    refetchOrderStatus: handleRefetchOrderStatusStart,
    refetchingOrderStatus,
    orderStatusRefetched,
  });

  const isOrderStatusFailureHandledRef = useRef(false);
  useEffect(() => {
    if (!isOrderStatusFailureHandledRef.current && commitOrderFailure) {
      isOrderStatusFailureHandledRef.current = true;
      // present the generic error modal.
      handleCommitError(new GraphQLError([]));
    }
  }, [isOrderStatusFailureHandledRef, commitOrderFailure, handleCommitError]);

  const isPlacingOrder =
    payment.loading ||
    isCommittingOrder ||
    isCommitOrderLoading ||
    refetchingOrderStatus ||
    (commitOrderCalled && !commitOrderSuccess && !commitError);

  const enableImCloseDisplay = useFlag(LaunchDarklyFlag.ENABLE_IM_CLOSE_DISPLAY);
  const onlySendPostalCode = useFlag(LaunchDarklyFlag.SEND_POSTAL_CODE_ONLY_FOR_FIRST_DATA_PAYMENT);

  const shouldCommitOrder = useMemo(
    () =>
      serviceMode === ServiceMode.DELIVERY ||
      serviceMode === ServiceMode.DRIVE_THRU ||
      serviceMode === ServiceMode.TAKEOUT ||
      serviceMode === ServiceMode.EAT_IN ||
      serviceMode === ServiceMode.CATERING_PICKUP ||
      serviceMode === ServiceMode.PICKUP_WINDOW,
    [serviceMode]
  );

  const nonSelectedPaymentMethods = payment.paymentMethods.filter(
    method =>
      method.accountIdentifier !== payment.checkoutPaymentMethodId ||
      method.fdAccountId !== payment.checkoutPaymentMethodId
  );
  const selectedPaymentMethod = payment.paymentMethods.find(
    method =>
      method.accountIdentifier === payment.checkoutPaymentMethodId ||
      method.fdAccountId === payment.checkoutPaymentMethodId
  );

  const paymentMethods = selectedPaymentMethod
    ? [selectedPaymentMethod, ...nonSelectedPaymentMethods]
    : nonSelectedPaymentMethods;
  const showPaymentMethodSelection = !isPlacingOrder;

  const {
    reloadAddPaymentMethod,
    reloadAmount,
    reloadPaymentValues,
    reloadGiftCardAndCheckout,
    setReloadPaymentValues,
    showReloadPaymentMethods,
    needReloadAgain,
  } = usePrepaidMethodReload({
    commitOrder,
    handleCommitError,
    order,
    payment,
    paymentMethods,
    user: auth.user,
    serverOrder,
    selectedPaymentMethod,
    shouldCommitOrder: !!shouldCommitOrder,
    storeEmail: email,
  });

  const showPrepaidTransferBalance = !!selectedPaymentMethod?.prepaid;

  useEffect(() => {
    const ldPaymentType = extractPaymentTypeForLD(checkoutPaymentMethod);
    updateCheckoutSelections(ldPaymentType);
  }, [checkoutPaymentMethod, selectedPaymentMethod, updateCheckoutSelections]);

  /**
   * Persist payment values in payment context state
   */
  const persistPaymentValuesToState = useCallback(
    (data: {
      accountIdentifier?: string;
      fdAccessToken?: string;
      fdNonce?: string;
      creditType: string;
      fraudPreventionValues: IFraudPreventionValues;
      googlePayDetails?: IGooglePayDetails;
      applePayDetails?: IApplePayDetails;
      saveCard?: boolean;
      state: IPaymentState;
    }) => {
      payment.storePaymentDetails(data);
    },
    [payment]
  );

  const handlePaymentMethodSelected = (newFdAccountId?: string) => {
    if (!newFdAccountId) {
      payment.setCheckoutPaymentMethodId('');
      return;
    }

    setShowAddPaymentMethod(false);
    setIsAddGiftCardMethodSelected(false);
    payment.setCheckoutPaymentMethodId(newFdAccountId);
  };

  // when checkout payment method have been updated from payment context
  // the flags to show add new credit card or add gift card
  // have to be updated.
  useEffect(() => {
    if (checkoutPaymentMethodId) {
      const isAddNewCardSelected =
        checkoutPaymentMethodId === CurrentSelectedMethodType.ADD_NEW_CARD;
      if (isAddNewCardSelected) {
        setCheckoutPaymentMethodId('');
      }
      setShowAddPaymentMethod(isAddNewCardSelected);
      setIsAddGiftCardMethodSelected(false);
    }
  }, [setCheckoutPaymentMethodId, checkoutPaymentMethodId]);

  // adds First Data test card to improve QA experience
  useEffectOnce(() => {
    if (autoFill) {
      setPaymentValues(testCardPaymentState({ isDelivery, isWorldpay: payment.isWorldpay }));
    }
  });

  useEffect(() => {
    // For one time payments, we already have payment values that have been stored in context.
    // If it is one time & we have card info present we need to update the payment details to use them
    if (payment.paymentDetails.state && isOneTimeCardPayment) {
      setPaymentValues(payment.paymentDetails.state);
    }
  }, [isOneTimeCardPayment, payment.paymentDetails.state]);

  useEffect(() => {
    const testBillingAddress = pick(
      testCardPaymentState({
        isDelivery: paymentValues.billingAddressSameAsDelivery,
        isWorldpay: payment.isWorldpay,
        paymentFieldVariations,
      }),
      ['billingStreetAddress', 'billingApt', 'billingCity', 'billingState', 'billingZip']
    );

    if (paymentValues.billingAddressSameAsDelivery) {
      setPaymentValues(prevState => ({
        ...prevState,
        ...mapDeliveryToCCFormAddress(deliveryAddress),
      }));
    } else {
      setPaymentValues(prevState => ({
        ...prevState,
        ...(autoFill
          ? testBillingAddress
          : mapDeliveryToCCFormAddress({
              zip: payment.paymentDetails.state?.billingZip ?? '',
            })),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentValues.billingAddressSameAsDelivery,
    deliveryAddress,
    autoFill,
    // paymentFieldVariations -> removed from the dependency array to prevent unnecessary re-renders and form resets caused by LD flag changes.
    payment.paymentDetails.state?.billingZip,
    payment.isWorldpay,
  ]);

  const navigateToOrderConfirmation = useCallback(() => {
    if (serverOrder) {
      while (router.canGoBack()) {
        router.back();
      }

      router.navigate({
        pathname: `${routes.orderConfirmation}/${serverOrder.rbiOrderId}`,
        params: { showToast: 'true' },
      });
    }
  }, [serverOrder]);

  useEffectOnUpdates(() => {
    if (!serverOrder) {
      return;
    }

    if (commitOrderSuccess) {
      emptyCart();

      hapticNotification({ type: HapticsNotificationType.SUCCESS });
      setUserAttributes({
        'Last Purchase Store ID': String(store._id),
      });
      navigateToOrderConfirmation();
      return;
    }
    if (serverOrder.status === OrderStatus.PRICE_SUCCESSFUL && commitOrderAfterPricing) {
      // If the orderId hasn't changed, it has not finished pricing as we get a new orderId when its done
      if (prevOrderId.current === serverOrder?.rbiOrderId || (isDelivery && !isDeliveryQuoted)) {
        return;
      }
      void handleOrderCommit();
    }
  }, [
    commitOrderSuccess,
    serverOrder,
    prevOrderId,
    isDelivery,
    isDeliveryQuoted,
    commitOrderAfterPricing,
  ]);

  const onChange = useCallback(
    (name: string, value: string) => {
      const { state, formErrors } = onCCFormChange(
        name,
        value,
        paymentValues,
        errors,
        formatMessage,
        supportedCardTypes
      );
      setErrors(prevState => ({ ...prevState, ...formErrors }));
      setPaymentValues(prevState => ({ ...prevState, ...state }));
    },
    [paymentValues, errors, formatMessage, supportedCardTypes]
  );

  const focusElementWithError = () => {
    // TODO: Remove queryselector and replace with ref's
    // TODO: RN - Remove usage of document
    // const element: HTMLElement | null = document.querySelector(`[name=${name}]`);
    const element: HTMLElement | null = null;
    if (element) {
      // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
      element.focus();
    }
  };

  const validateCreditCardForm = useCallback(() => {
    const { formErrors, hasErrors } = getCCFormErrors(
      paymentValues,
      errors,
      formatMessage,
      paymentFieldVariations,
      billingCountry
    );

    if (hasErrors) {
      const nameOfFirstError = [
        'nameOnCard',
        'cardNumber',
        'cvv',
        'expiry',
        'billingStreetAddress',
        'billingApt',
        'billingCity',
        'billingState',
        'billingZip',
        'billingCountry',
      ].find(name => formErrors[name]);

      if (nameOfFirstError) {
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        focusElementWithError(nameOfFirstError);
      }

      setErrors(prevState => ({ ...prevState, ...formErrors }));
      return false;
    }
    return true;
  }, [paymentValues, errors, formatMessage, paymentFieldVariations, billingCountry]);

  const placeOrder = useCallback(async () => {
    if (!serverOrder) {
      return Promise.reject('Required parameter is not defined.');
    }

    const shouldValidateCreditCard =
      !reloadAddPaymentMethod || (reloadAddPaymentMethod && showReloadPaymentMethods);
    if (shouldValidateCreditCard) {
      const ccFormIsValid = validateCreditCardForm();
      if (!ccFormIsValid) {
        return;
      }
    }

    const isRestaurantAvailableForOrdering = await isRestaurantAvailable(store);

    if (!isRestaurantAvailableForOrdering && !isStoreAvailable) {
      return openOrderingUnavailableDialog();
    }

    if (!showAddPaymentMethod && selectedPaymentMethod?.prepaid) {
      return reloadGiftCardAndCheckout();
    }

    const reshapedPaymentValues = payment.transformPaymentValues({
      paymentValues,
      paymentFieldVariations,
    });
    const fraudPreventionValues = getFraudPreventionValues(paymentValues);

    let paymentNonce;
    let fdAccessToken;
    let accountIdentifier: string | undefined;

    if (payment.isFirstData) {
      if (fdAccessTokenFirstRequest.current && paymentNonceFirstRequest.current) {
        fdAccessToken = fdAccessTokenFirstRequest.current;
        paymentNonce = paymentNonceFirstRequest.current;
      } else {
        try {
          const response = await payment.getEncryptionDetails();

          fdAccessToken = fdAccessTokenFirstRequest.current = response.fdAccessToken;
          const nonceResponse = await getNonce(
            reshapedPaymentValues,
            response.fdPublicKey,
            response.fdApiKey,
            fdAccessToken,
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null | undefined' is no... Remove this comment to see the full error message
            response.fdCustomerId,
            fdUrl,
            onlySendPostalCode,
            response.algorithm
          );
          const nonce = await nonceResponse.json();
          // TODO: Share paymentNonce for when we save a users payment/card below? [payment.addPaymentMethod]
          paymentNonce = paymentNonceFirstRequest.current = nonce.token.tokenId;
        } catch (error) {
          openErrorDialog({
            message: formatMessage({ id: 'paymentAddingError' }),
            modalAppearanceEventMessage: 'Error: Fetching Encryption Details Failure',
            // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
            error,
          });

          return;
        }
      }
    }

    if (auth.user && paymentValues.saveCard && payment.isFirstData) {
      if (accountIdentifierFirstRequest.current) {
        accountIdentifier = accountIdentifierFirstRequest.current;
      } else {
        try {
          accountIdentifier = accountIdentifierFirstRequest.current =
            await payment.addPaymentMethod(reshapedPaymentValues);
        } catch (error) {
          logger.error({ error, message: 'Error adding payment method during checkout' });
          return;
        }
      }
    }

    if (shouldCommitOrder) {
      let commitInput: ICommitOrderInput;
      if (payment.isOrbital) {
        const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');

        // Vaulted Payment
        if (selectedPaymentMethod?.accountIdentifier) {
          commitInput = {
            creditType: paymentValues.cardType,
            order,
            ...(pickUpTipsEnabled && { pickUpTipsCents }),
            payment: {
              billingAddress: fraudPreventionValues.billingAddress,
              ccMetadata: {
                ccBin: fraudPreventionValues.ccBin,
                ccExpiryMonth: expiryMonth,
                ccExpiryYear: expiryYear,
                ccLast4: fraudPreventionValues.ccLast4,
              },
              orbitalInput: {
                accountIdentifier: selectedPaymentMethod?.accountIdentifier,
                savePaymentMethod: false,
              },
              fullName: fraudPreventionValues.fullName,
            },
            skipCoolingPeriod: false,
            storeEmail: email,
          };
          // One time payment
        } else {
          if (!payment.encryptionResult || !('cryptCard' in payment.encryptionResult)) {
            throw new Error('no orbital encryption results');
          }
          const {
            cryptCard,
            cryptCvv,
            integrityCheck: pieIntegrityCheck,
            pieFormat,
            subscriberId: pieSubscriberId,
            mode: pieMode,
            keyId: pieKeyID,
            phase: piePhaseId,
          } = payment.encryptionResult;

          commitInput = {
            creditType: paymentValues.cardType,
            order,
            ...(pickUpTipsEnabled && { pickUpTipsCents }),
            payment: {
              billingAddress: fraudPreventionValues.billingAddress,
              ccMetadata: {
                ccBin: fraudPreventionValues.ccBin,
                ccExpiryMonth: expiryMonth,
                ccExpiryYear: expiryYear,
                ccLast4: fraudPreventionValues.ccLast4,
              },
              orbitalInput: {
                encryptedPayload: {
                  encryptedCardNum: cryptCard,
                  encryptedCvv: cryptCvv,
                  expiryMonth: reshapedPaymentValues.expiryDate?.month!,
                  expiryYear: reshapedPaymentValues.expiryDate?.year!,
                  pieFormat,
                  pieMode,
                  piePhaseId: `${piePhaseId}`,
                  pieIntegrityCheck,
                  pieSubscriberId,
                  pieKeyID,
                  cardBrand: paymentValues.cardType.toLowerCase(),
                  bin: reshapedPaymentValues.cardNumber.slice(0, 6),
                },
                savePaymentMethod: oneTimePaymentFlowEnabled ? paymentValues.saveCard : false,
              },
              fullName: fraudPreventionValues.fullName,
            },
            skipCoolingPeriod: false,
            storeEmail: email,
          };
        }

        return commitOrder(commitInput);
      } else if (payment.isWorldpay) {
        const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');

        // Vaulted Payment
        if (selectedPaymentMethod?.accountIdentifier) {
          commitInput = {
            creditType: paymentValues.cardType,
            order,
            ...(pickUpTipsEnabled && { pickUpTipsCents }),
            payment: {
              billingAddress: fraudPreventionValues.billingAddress,
              ccMetadata: {
                ccBin: fraudPreventionValues.ccBin,
                ccExpiryMonth: expiryMonth,
                ccExpiryYear: expiryYear,
                ccLast4: fraudPreventionValues.ccLast4,
              },
              worldpayInput: {
                storedPaymentMethodId: selectedPaymentMethod?.accountIdentifier,
              },
              fullName: fraudPreventionValues.fullName,
            },
            skipCoolingPeriod: false,
            storeEmail: email,
          };
          // One time payment
        } else {
          if (!payment.encryptionResult || !('lowValueToken' in payment.encryptionResult)) {
            throw new Error('no worldpay encryption results');
          }
          const { lowValueToken } = payment.encryptionResult;

          commitInput = {
            creditType: paymentValues.cardType,
            order,
            ...(pickUpTipsEnabled && { pickUpTipsCents }),
            payment: {
              billingAddress: fraudPreventionValues.billingAddress,
              ccMetadata: {
                ccBin: fraudPreventionValues.ccBin,
                ccExpiryMonth: expiryMonth,
                ccExpiryYear: expiryYear,
                ccLast4: fraudPreventionValues.ccLast4,
              },
              worldpayInput: {
                lowValueToken,
                storePaymentMethod: false,
              },
              fullName: fraudPreventionValues.fullName,
            },
            skipCoolingPeriod: false,
            storeEmail: email,
          };
        }

        return commitOrder(commitInput);
      }
      const { expiryMonth, expiryYear } = splitExpiry(fraudPreventionValues.expiry ?? '');
      commitInput = {
        creditType: paymentValues.cardType,
        order,
        ...(pickUpTipsEnabled && { pickUpTipsCents }),
        payment: {
          billingAddress: fraudPreventionValues.billingAddress,
          ccMetadata: {
            ccBin: fraudPreventionValues.ccBin,
            ccExpiryMonth: expiryMonth,
            ccExpiryYear: expiryYear,
            ccLast4: fraudPreventionValues.ccLast4,
          },
          firstDataInput: {
            accountIdentifier: paymentValues.saveCard ? accountIdentifier : undefined,
            fdAccessToken: paymentValues.saveCard ? undefined : fdAccessToken,
            fdNonce: paymentValues.saveCard ? undefined : paymentNonce,
          },
          fullName: fraudPreventionValues.fullName,
        },
        skipCoolingPeriod: true,
        storeEmail: email,
      };
    }

    persistPaymentValuesToState({
      fdAccessToken,
      fdNonce: paymentNonce,
      creditType: paymentValues.cardType,
      fraudPreventionValues,
      saveCard: paymentValues.saveCard,
      state: paymentValues,
    });

    hapticNotification({ type: HapticsNotificationType.SUCCESS });
    return navigateToOrderConfirmation();
  }, [
    serverOrder,
    reloadAddPaymentMethod,
    showReloadPaymentMethods,
    isRestaurantAvailable,
    store,
    isStoreAvailable,
    showAddPaymentMethod,
    selectedPaymentMethod?.prepaid,
    selectedPaymentMethod?.accountIdentifier,
    payment,
    paymentValues,
    paymentFieldVariations,
    auth.user,
    shouldCommitOrder,
    persistPaymentValuesToState,
    navigateToOrderConfirmation,
    validateCreditCardForm,
    openOrderingUnavailableDialog,
    reloadGiftCardAndCheckout,
    fdUrl,
    onlySendPostalCode,
    openErrorDialog,
    formatMessage,
    order,
    email,
    commitOrder,
  ]);

  const { requestingPayment: isGooglePayLoading, requestGooglePayPayment } = useGooglePay({
    onError: error => {
      logger.warn({ message: 'requestGooglePayPayment error', error });
    },
    // @ts-expect-error TS(2322) FIXME: Type '(data: IGooglePayDetails) => void' is not as... Remove this comment to see the full error message
    onCompleted: (data: IGooglePayDetails) => {
      if (!data) {
        logger.warn('User left the Google Pay Flow');
        return;
      }
      processOrderWithAccount({ googlePayDetails: data });
    },
    rbiOrderId: serverOrder?.rbiOrderId,
  });

  const handleApplePay = ({ error, data }: { error?: string; data?: IApplePayDetails }): void => {
    if (data) {
      processOrderWithAccount({ applePayDetails: data });
      return;
    }
    if (error) {
      logger.warn(error);
    }
  };

  // FD apple pay reload
  const { requestApplePayPayment: prepaidRequestApplePayPayment } = useApplePay({
    handleApplePay,
    order,
    prepaid: true,
    serverOrder,
  });

  const processOrderWithAccount = useCallback(
    async (data?: { applePayDetails?: IApplePayDetails; googlePayDetails?: IGooglePayDetails }) => {
      if (selectedPaymentMethod?.prepaid) {
        const reloadPaymentDetails = data?.applePayDetails ?? data?.googlePayDetails;

        if (!reloadPaymentDetails && payment.prepaidReloadPaymentMethodId && reloadAmount > 0) {
          const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
          if (payment.prepaidReloadPaymentMethodId === CardTypes.GOOGLE_PAY) {
            // Get the checkout gateway configs for google pay
            const { gateway, gatewayMerchantId } = getGooglePaymentsConfigs({
              transactionType: TransactionType.PREPAID,
              countryCode,
              googleConfig,
              migrationEnabled,
            });

            return requestGooglePayPayment({
              countryCode,
              currencyCode,
              total: reloadAmount,
              gatewayMerchantId,
              gateway,
            });
          } else if (payment.prepaidReloadPaymentMethodId === CardTypes.APPLE_PAY) {
            const applePayParams = {
              countryCode,
              currencyCode,
              total: reloadAmount,
              paymentProcessor,
            };

            return prepaidRequestApplePayPayment(applePayParams);
          }
        }

        const result = await reloadGiftCardAndCheckout(reloadPaymentDetails);
        // Do NOT return for service modes that should not commit
        // (e.g Curbside)
        if (shouldCommitOrder) {
          return result;
        }
      }
      const paymentAccount = payment.paymentMethods.find(method => {
        const accountId = method.accountIdentifier ?? method.fdAccountId ?? '';
        return accountId === payment.checkoutPaymentMethodId;
      });
      if (!paymentAccount || !serverOrder) {
        return Promise.reject('Required parameters are not defined.');
      }

      const creditType = paymentAccount.credit
        ? CartPaymentCardType[paymentAccount.credit.cardType.toUpperCase()]
        : null;

      const fraudPreventionValues = getFraudPreventionValues(paymentValues);

      const accountIdentifier =
        paymentAccount.accountIdentifier ?? paymentAccount.fdAccountId ?? '';
      const commitInput = {
        creditType,
        rbiOrderId: serverOrder.rbiOrderId,
        storeEmail: email,
        order,
        ...(pickUpTipsEnabled && { pickUpTipsCents }),
      };
      const fullName = fraudPreventionValues.fullName;

      const applePayDetails = data?.applePayDetails;
      const googlePayDetails = data?.googlePayDetails;

      // The reason this is here is because we want to avoid committing the order EXCEPT for in these situations...
      // In other cases, we want to navigate to a separate route where the user must confirm being close by, before committing... hence the sort of complicated logic here.
      // TODO - this whole section deserves a proper refactor due to how important it is to conversions as a whole.

      if (shouldCommitOrder) {
        // Digital pay
        if (applePayDetails || googlePayDetails) {
          const digitalPayDetails = {
            applePayDetails,
            googlePayDetails,
            fullName,
          };

          if (payment.isWorldpay) {
            return commitOrder({
              ...commitInput,
              payment: {
                worldpayInput: {
                  lowValueToken: googlePayDetails?.paymentData ?? applePayDetails?.paymentData,
                },
                ...digitalPayDetails,
              },
            });
          }

          return commitOrder({
            ...commitInput,
            payment: {
              ...digitalPayDetails,
            },
          });
        }

        if (payment.checkoutPaymentMethodId === CASH_ACCOUNT_IDENTIFIER) {
          const paymentData: IPayment = {
            cashPayment: true,
            fullName,
          };
          return commitOrder({
            ...commitInput,
            creditType: CASH_ACCOUNT_IDENTIFIER,
            payment: paymentData,
          });
        }

        if (payment.isOrbital) {
          return commitOrder({
            ...commitInput,
            payment: {
              orbitalInput: {
                accountIdentifier,
              },
              fullName,
            },
          });
        }

        if (payment.isWorldpay) {
          return commitOrder({
            ...commitInput,
            payment: {
              worldpayInput: {
                storedPaymentMethodId: accountIdentifier,
              },
              fullName,
            },
          });
        }
        return commitOrder({
          ...commitInput,
          payment: {
            firstDataInput: {
              accountIdentifier,
            },
            fullName,
          },
        });
      }

      // This is a temporary hack. This will go away when we actually do a pseudo-injection here
      persistPaymentValuesToState({
        accountIdentifier,
        creditType: creditType!,
        fraudPreventionValues,
        googlePayDetails,
        applePayDetails,
        state: paymentValues,
      });

      // This is actually NEEDED to handle for dine-in and take-out orders
      return navigateToOrderConfirmation();
    },
    [
      selectedPaymentMethod?.prepaid,
      payment.paymentMethods,
      payment.prepaidReloadPaymentMethodId,
      payment.checkoutPaymentMethodId,
      payment.isOrbital,
      payment.isWorldpay,
      serverOrder,
      paymentValues,
      email,
      order,
      pickUpTipsCents,
      shouldCommitOrder,
      persistPaymentValuesToState,
      navigateToOrderConfirmation,
      reloadAmount,
      reloadGiftCardAndCheckout,
      billingCountry,
      googleConfig,
      migrationEnabled,
      requestGooglePayPayment,
      paymentProcessor,
      prepaidRequestApplePayPayment,
      commitOrder,
    ]
  );

  const {
    requestingPayment: isApplePayLoading,
    formatApplePayCartPayload,
    requestApplePayPayment,
  } = useApplePay({
    order,
    serverOrder,
    handleApplePay,
  });

  const placeOrderWithAccount = useCallback(async () => {
    const accountIdentifier = payment.paymentMethods.find(
      method =>
        method.accountIdentifier === payment.checkoutPaymentMethodId ||
        method.fdAccountId === payment.checkoutPaymentMethodId
    );
    if (!accountIdentifier || !serverOrder) {
      return Promise.reject('Required parameters are not defined.');
    }

    if (isApplePay(payment.checkoutPaymentMethodId)) {
      const applePayPayload = await formatApplePayCartPayload();
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ countryCode: PaymentISOs; curr... Remove this comment to see the full error message
      return requestApplePayPayment({ ...applePayPayload, paymentProcessor });
    }

    // This check has to happen after request apple pay merchant validation.
    // We add restaurant validation within apple pay method
    // Details: https://github.com/rbilabs/fhs-app/pull/965
    const isRestaurantAvailableForOrdering = await isRestaurantAvailable(store);

    if (!isRestaurantAvailableForOrdering && !isStoreAvailable) {
      return openOrderingUnavailableDialog();
    }
    if (isGooglePay(payment.checkoutPaymentMethodId)) {
      const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
      // Get the checkout gateway configs for google pay
      const { gateway, gatewayMerchantId } = getGooglePaymentsConfigs({
        transactionType: TransactionType.PAYMENT,
        countryCode,
        googleConfig,
        migrationEnabled,
      });
      return requestGooglePayPayment({
        countryCode,
        currencyCode,
        total: serverOrder.cart.totalCents + (order?.tipAmount ?? 0),
        gatewayMerchantId,
        gateway,
      });
    }

    return processOrderWithAccount();
  }, [
    payment.paymentMethods,
    payment.checkoutPaymentMethodId,
    serverOrder,
    isRestaurantAvailable,
    store,
    isStoreAvailable,
    processOrderWithAccount,
    openOrderingUnavailableDialog,
    formatApplePayCartPayload,
    requestApplePayPayment,
    paymentProcessor,
    billingCountry,
    googleConfig,
    migrationEnabled,
    requestGooglePayPayment,
    order?.tipAmount,
  ]);

  const isOrderPriceLoading = !serverOrder || serverOrder.status === OrderStatus.PRICE_REQUESTED;

  const isOfferPaymentCardValid = useMemo(() => {
    if (!selectedOffer?.ruleSet) {
      return true;
    }

    const offerCardRule = findFirstRuleOfTypeThatApplies<IPaymentCardType>(
      RuleType.PaymentCardType,
      selectedOffer?.ruleSet
    );

    // paymentValues come from the form when adding a new payment
    // where selectedPaymentMethod is the payment method selected asssuming
    // not a new card is being added on checkout
    const cardType = paymentValues?.cardType || selectedPaymentMethod?.credit?.cardType;

    return offerCardRule ? offerCardRule.cardType === cardType : true;
  }, [selectedOffer, selectedPaymentMethod, paymentValues]);

  const cardIsValid =
    !!selectedPaymentMethod ||
    showAddPaymentMethod ||
    isAddGiftCardMethodSelected ||
    payment.paymentMethods.length === 0;
  const isValidTip = checkIfValidTip({
    tipAmount: order?.tipAmount,
  });

  const isValidReloadPaymentMethodWithCard =
    !reloadAddPaymentMethod || (reloadAddPaymentMethod && reloadPaymentValues.saveCard);

  const offerIsValid = selectedOffer ? !offersCtx.offerValidationErrors.length : true;

  const handleOrderCommit = useCallback(async () => {
    setCommittingOrder(true);
    isOrderStatusFailureHandledRef.current = false;
    try {
      if (!serverOrder) {
        throw new Error('Unable to get the rbiOrderId from serverOrder.');
      }

      if (isCurbside) {
        order.setCurbsidePickupOrderId(serverOrder.rbiOrderId);
        order.setCurbsidePickupOrderTimePlaced(new Date().toString());
      }

      const isPayPalRegistration =
        checkoutPaymentMethod?.accountIdentifier ===
        PAYPAL_PAYMENT_METHOD_PLACEHOLDER.accountIdentifier;

      // Check if the order can be committed with an existing payment method
      if (showAddPaymentMethod || reloadAddPaymentMethod || isPayPalRegistration) {
        if (selectedPaymentMethod?.cash) {
          await placeOrderWithAccount();
        } else {
          await placeOrder();
        }
      } else if (isOneTimeCardPayment) {
        await placeOrder();
      } else {
        await placeOrderWithAccount();
      }
    } catch (error) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      handleCommitError(error);
    } finally {
    }

    return setCommittingOrder(false);
  }, [
    checkoutPaymentMethod,
    handleCommitError,
    isCurbside,
    isOneTimeCardPayment,
    order,
    placeOrder,
    placeOrderWithAccount,
    reloadAddPaymentMethod,
    selectedPaymentMethod,
    serverOrder,
    showAddPaymentMethod,
  ]);

  useEffect(() => {
    if (isHandlingOrderCommit) {
      setIsHandlingOrderCommit(false);
      handleOrderCommit();
    }
  }, [handleOrderCommit, isHandlingOrderCommit]);

  const placeOrderAriaLabel = serviceMode
    ? formatMessage(
        { id: 'placeOrderDescription' },
        {
          serviceMode: getLocalizedServiceMode(serviceMode, formatMessage),
          price: formatCurrencyForLocale(serverOrder?.cart?.totalCents ?? 0),
        }
      )
    : isPlacingOrder
    ? formatMessage({ id: 'placingOrder' })
    : undefined;

  const [ServiceModeUnavailableErrorDialog, openServiceModeUnavailableErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Service Mode Unavailable',
    onConfirm: () => router.replace(routes.storeLocator),
  });

  return {
    cardIsValid,
    checkoutPaymentMethod,
    confirmStoreOnCheckout,
    dismissStoreConfirmationDialog,
    enableDelivery,
    enableImCloseDisplay,
    ErrorDialog,
    errors,
    focusElementWithError,
    setIsHandlingOrderCommit,
    handlePaymentMethodSelected,
    isApplePayLoading,
    isCashPayment: checkoutPaymentMethod?.accountIdentifier === CASH_ACCOUNT_IDENTIFIER,
    isCateringOrder,
    isGooglePayLoading,
    isOfferPaymentCardValid,
    isOrderPriceLoading,
    isPlacingOrder,
    isValidReloadPaymentMethodWithCard,
    isValidTip,
    needReloadAgain,
    offerIsValid,
    onChange,
    openServiceModeUnavailableErrorDialog,
    openStoreConfirmationModal,
    paymentMethods,
    paymentValues,
    placeOrderAriaLabel,
    reloadAmount,
    reloadPaymentValues,
    ServiceModeUnavailableErrorDialog,
    setCommitOrderAfterPricing,
    setReloadPaymentValues,
    setShowAddPaymentMethod,
    shouldCommitOrder,
    shouldConfirmStoreOnCheckout,
    showAddPaymentMethod,
    showPaymentMethodSelection,
    showPrepaidTransferBalance,
    showReloadPaymentMethods,
    StoreConfirmationDialog,
    validateCreditCardForm,
    isAddGiftCardMethodSelected,
    setIsAddGiftCardMethodSelected,
  };
};
