import { useEffect } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';

import { ActionSheet, tokens, useMqSelect } from '@fhs/ui';

import { GuideHeading } from './heading';
import { ItemContainer } from './item-container';
import { GuideProps } from './types';
import { useGuide } from './use-guide';

export const Guide = (props: GuideProps) => {
  const {
    handleItemSelect,
    handleSaveItemSelection,
    handleNavigationBack,
    stepsAmount,
    currentStep,
    selectedItemSlug,
    selectedItemId,
    handleClose,
    currentStepIndex,
    setCurrentStepIndex,
    isCustomization,
    goToNextStep,
    confirmationDialog,
  } = useGuide(props);

  useEffect(() => {
    if (props.initialStepIndex) {
      setCurrentStepIndex(props.initialStepIndex);
    }
  }, [props.initialStepIndex, setCurrentStepIndex]);

  const { height: screenHeight } = useWindowDimensions();
  const desktopStyle = useMqSelect({ $gteDesktop: { height: screenHeight } }, {});

  return (
    <ActionSheet
      onRequestClose={handleClose}
      onClose={handleClose}
      isVisible
      grabber={false}
      noPanGestureDetection
      containerStyle={{ height: '90%' }}
      drawerProps={{ style: { flex: 1, backgroundColor: tokens.colors.$white } }} // Needed to make the flat list scroll inside the actionsheet
    >
      <GuideHeading
        text={currentStep.displayName}
        onClose={handleClose}
        onPressPrev={handleNavigationBack}
        disableBackButton={currentStepIndex === 0}
        badge={
          <GuideHeading.StepBadge currentStep={currentStepIndex + 1} totalSteps={stepsAmount} />
        }
      />
      <View style={[styles.container, desktopStyle]}>
        <ItemContainer
          isCustomization={isCustomization}
          currentStep={currentStep}
          currentStepSelectedItemId={selectedItemId}
          selectedItemSlug={selectedItemSlug}
          onSaveItem={() => {
            handleSaveItemSelection(selectedItemId);
          }}
          onSelectItem={handleItemSelect}
          onSkip={goToNextStep}
        />
      </View>
      {confirmationDialog}
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  title: {
    borderBottomColor: 'rgba(0, 0, 0, 0.04)',
    borderBottomWidth: 1,
    paddingVertical: 16,
    textAlign: 'center',
  },
  item: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
  },
});
