import { CommonActions } from '@react-navigation/native';
import { useRootNavigationState } from 'expo-router';

// This method will recreate the existing route stack, but removing the last entry of the provided routeName.
// It's a hack to be used in situations where we want to force the page mount again but keeping the existing routes on our stack.
export const removeRouteFromStack = async (navigation, routeName): Promise<void> => {
  const routes = navigation.getState().routes;
  const lastIndex = routes.map(route => route.name).lastIndexOf(routeName);

  if (lastIndex === -1) {
    return Promise.resolve();
  }

  const filteredRoutes = routes.filter((_, index) => index !== lastIndex);

  await navigation.dispatch(
    CommonActions.reset({
      index: filteredRoutes.length - 1,
      routes: filteredRoutes,
    })
  );
};

export function useIsNavigationReady() {
  const rootNavigationState = useRootNavigationState();
  const isRootNavigationReady = rootNavigationState?.routes[0]?.state?.routes?.length > 0;

  return isRootNavigationReady;
}
