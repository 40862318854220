{
  "about": "About",
  "aboutTheApp": "About the App",
  "above": "above",
  "accept": "Accept",
  "acceptAllCookies": "Accept All Cookies",
  "acceptedCateringOrder": "Please have this order ready for {serviceMode} at {time}, {date}",
  "acceptEmailsAndSpecialOffers.bk": "I want to receive special offers and other information from Burger King via email",
  "acceptEmailsAndSpecialOffers.fhs": "I want to receive special offers and other information from Firehouse Subs®",
  "acceptEmailsAndSpecialOffers": "I want to receive special offers and other information via email",
  "acceptEmailsAndSpecialOffersPopeyes": "Yes! I want to receive emails to gain access to exclusive offers and be the first to know about the latest Popeyes promotions.",
  "acceptEmailsAndSpecialOffersPopeyesRewards": "Yes! I want to receive Popeyes® Perks emails to gain access to exclusive offers and be the first to know about the latest Popeyes promotions.",
  "acceptGiftError": "Please try again later or contact {supportLink} or by calling +1-888-601-1616.",
  "acceptGiftSuccess": "You have received a {gift} from {fullName}. The gift has been added to your account and will appear along with other offers. Don’t forget to activate it.",
  "acceptGiftSuccessHeading": "You got a gift!",
  "acceptOrDecline": "Accept or Decline This Order",
  "acceptOrder": "Accept Order",
  "acceptOrderModal": "Please have this order ready for {serviceMode} by {time} on {date}. You will not be able to decline an order after accepting it here.",
  "acceptPrivacyPolicyAndTerms": "I agree to the following: {privacyPolicyLink} and {termsOfServiceLink}",
  "acceptRollsCongrats": "Congrats!",
  "acceptRollsModalHeader": "You have received {numberOfRollsShared, plural, one {a} other {{numberOfRollsShared}}} RRROLL{numberOfRollsShared, plural, one {} other {S}}!",
  "acceptTermsAndPrivacyIntro": "By checking this box, you acknowledge that you have read and agree to our {termsOfServiceLink} including the mandatory arbitration clause, {privacyPolicyLink} and {rewardsPolicyLink}",
  "accessibility": "Accessibility",
  "accessibilityMode": "Accessibility Mode",
  "account": "Account",
  "accountDefault": "default",
  "accountDetails": "Account Details",
  "accountInfo": "Account Info",
  "accountInfoNameDisclaimer": "Name can not be adjusted during {promotionName} promotion.{jumpLine}If you need help, {support}.",
  "accountWithEmailAlreadyExists": "An account with the given email already exists.",
  "activate": "Activate",
  "activated": "Activated",
  "activateOffer": "Activate Offer",
  "activationFailed": "Activation failed!",
  "add": "Add",
  "addCreditCard": "Add a Credit Card",
  "addCreditCardVerification": "Add Credit Card Verification",
  "addExtras": "Add Extras",
  "addFavorite": "Add to Favorites",
  "addFromCheckout": "Add from checkout",
  "addItem": "Add Item",
  "addItemLabel": "Add one {itemName} to your cart?",
  "addItems": "Add Items",
  "addItemsLowerCase": "Add items",
  "addItemToCart": "Add {name} to cart",
  "addItemToCartWithModifiers": "Add {name} with {modifiers} to cart",
  "additionalAddressInformation": "Unit can be entered during checkout",
  "additionalInfoAdditives": "Additives",
  "additionalInfoIngredients": "Ingredients",
  "additionalInfoProducerDetails": "Producer Details",
  "additionalInfoTitle": "Additional item information",
  "additionalInstructions": "Additional instructions",
  "addMobileOrder": "Add to Mobile Order",
  "addMoreItems": "Add More Items",
  "addNewCard": "Add New Card",
  "addNewCreditCard": "Add a New Credit Card",
  "addNewPaymentMethod": "Add New Payment Method",
  "addNewPaymentMethodGiftCard.bk": "Add a Crown Card",
  "addNewPaymentMethodGiftCard": "Add Gift Card",
  "addOne": "Add one",
  "addOneItemToCart": "Add one {itemName} to your cart.",
  "addOneName": "Add one {name}",
  "addPaymentMethod": "Add Payment Method",
  "addPaymentMethodOrReload": "Add Payment Method",
  "addPhoneNumber": "Add Phone Number",
  "addPrepaidCard": "Add a Gift Card",
  "addQuantityAndItemToCart": "Add {quantity} {itemName} to cart",
  "addQuantityAndItemWithPrice": "Add {quantity} {itemName} to order for {price}",
  "addReloadMethod": "Add Reload Method",
  "addressError": "Oops! Address not recognized.",
  "addressFieldIsRequired": "Address field is required",
  "addressRequiredError": "Address is a required field.",
  "addReward": "Add Reward",
  "addSomethingExtra": "Add something extra to complete your order",
  "addSpecificInstructions": "Add any specific delivery instructions here",
  "addTag": "Add Tag",
  "addToAppleWalletAlt": "Add to Apple Wallet",
  "addToCart": "Add",
  "addToCartError": "Unable to add {itemName} to cart. Please try again.",
  "addToCartMultipleSuccess": "{itemName} and {quantity} others added to cart",
  "addToCartSuccess": "{itemName} added to cart.",
  "addToCartSuccessReorder": "We've updated your current cart with the items from the previous order.",
  "addToGooglePayAlt": "Add to Google Pay",
  "addToInRestaurantRedemptionCartSuccess": "{type} saved to redeem in restaurant.",
  "addToOrder": "Add to Order",
  "addToOrderHeader": "Add to Order",
  "adjustQuantityOfItem": "Adjust quantity of {itemName}",
  "afterDiscount": "After discount",
  "afternoon": "Afternoon",
  "afterSevenPurchases": "After every seven purchases, you can collect your reward.",
  "agreeToTermsOfService": "Agree to terms of service",
  "agreeToTermsOfServiceError": "You must agree to the Privacy Policy, Terms of Service and Firehouse Rewards Terms before signing up.",
  "agreeToTheFollowing": "I agree to the following:",
  "allergenInformation": "Allergen Information",
  "allergensDisclaimer": "This item {allergenContents}. Modifications are not represented in this list.",
  "allergensDisclaimerContains": "This item contains {allergens}. Modifications are not represented in this list.",
  "allergensDisclaimerContainsAndMayBeCookedOn": "This item contains {allergens} and may be cooked on same equipment as {maybeCookedOnAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerContainsAndMayContain": "This item contains {allergens} and may contain {maybeAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerContainsAndMayContainAndMayBeCooked": "This item contains {allergens}, may contain {maybeAllergens}, and may be cooked on same equipment as {maybeCookedOnAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerMayBeCookedOn": "This item may be cooked on same equipment as {maybeCookedOnAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerMayContain": "This item may contain {maybeAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerMayContainAndMayBeCookedOn": "This item may contain {maybeAllergens} and may be cooked on same equipment as {maybeCookedOnAllergens}. Modifications are not represented in this list.",
  "allergensDisclaimerUnknown": "This item may have allergens.",
  "allergensDownloadPDF": "Download Allergens",
  "allergensViewInBrowser": "Allergens View in Browser",
  "allergicTo": "I'm allergic to",
  "allMenu": "All Menu",
  "allowLocationServices": "Allow location services",
  "allowLocationServicesToFind": "Allow location services to find",
  "allRightsReserved": "All Rights Reserved.",
  "allSetCustomer": "All set, {firstName}!",
  "alreadyHaveAnAccount": "Already have an account?",
  "alreadyHaveAPhysicalCard": "Already have a physical card?",
  "alreadyLinkedACard": "Already linked a card?",
  "amount": "amount",
  "amountMore": "+{amountMore} more",
  "and": "and",
  "andConjunction": "{first} and {second}",
  "answer": "Answer",
  "anyQuestionsHeading": "Any questions?",
  "apartment": "Apartment",
  "appflowCompleted": "Update completed!",
  "appflowDownloading": "Downloading update.",
  "appflowError": "There was a problem downloading the update.",
  "appflowExtracting": "Extracting update.",
  "appflowProgress": "{progress} complete.",
  "appImage": "app-image",
  "applePay": "Apple Pay",
  "applePayPaymentNotAvailable": "We currently don’t accept Apple Pay on our websites. Please select or add a new payment method.",
  "apply": "Apply",
  "applyAReward": "Apply a reward",
  "applyOffer": "Apply Offer",
  "applyPromoCode": "Apply Promo Code",
  "approve": "Approve",
  "approxMinutesAway": "(Approx. 5 min. away)",
  "appUpdateRecommended": "App Update Recommended",
  "appUpdateSubtext": "We recommend updating to the latest version of our app before proceeding with your order. Would you like to update now?",
  "april": "April",
  "aptOrSuite": "Apt or Suite",
  "areDeliveryDetailsValid": "We are unable to confirm your phone number. Please confirm your phone number is correct or try a different one.",
  "asap": "ASAP",
  "asapOrder": "ASAP Order: {serviceMode}",
  "at": "at",
  "atARestaurant": "At a restaurant",
  "atRestaurantDetails": "Includes Take Out, Dine In, and more options",
  "august": "August",
  "authContinueSubHeader": "Would you like to continue in App or on the website?",
  "authContinueWeb": "Continue on the website",
  "authEmailCheckYourEmail": "Check your email",
  "authEmailSentEmail": "We sent an email with login instructions to {email}. {changeLink}",
  "authEmailTimeToReceive": "It may take up to 3 minutes to receive the email.{separator}Click the link in the message to sign in.",
  "authEnterCode": "Enter Verification Code Below:",
  "authError": "An error occurred logging in",
  "authExpiredLink": "Your link may have expired or you are using a different browser. Request a new link by clicking the “{sendNewLinkText}” button below.",
  "authMissingLink": "Sorry, we can't find that link.",
  "authNewLink": "Send new link",
  "authPhoneSentSms": "We sent an sms with login instructions to",
  "authRegionErrorDetails": "Your account was registered for a different region. You need to create a new account for this region to be able to have the best experience using this application. You will be automatically signed-out now.",
  "authRegionErrorTitle": "Account region error",
  "authRetryError": "An error occurred. Press below to try again.",
  "authVerifyWithCode": "Verify with Code",
  "autoReload": "Auto Reload",
  "autoReloadDefaultAmount": "Use {cardType} ending in {alias} to reload this amount:",
  "autoReloadPleaseSelectAFundingSource": "Please select a founding source",
  "autoSignInSuccess": "Congratulations! Your account has been created.",
  "available": "Available",
  "availableCrowns": "Available Crowns",
  "availablePoints": "Available Points",
  "back": "Back",
  "backgroundImage": "background image",
  "backToEarn": "Back to Earn",
  "backToMenu": "Back to menu",
  "backToOffers": "Back to offers",
  "backToSavedAddresses": "Back to Saved Addresses",
  "backToSummary": "Back to Summary",
  "backToTopics": "Back to topics",
  "backwardButton": "Backward Button",
  "bagEmpty": "Your bag is empty",
  "balanceReloadAmount": "Balance reload amount",
  "balanceTransfer": "Balance Transfer",
  "bankingPoints": "You're currently banking your Points",
  "bankMyRewards": "Bank my points",
  "bankOrRedeemPoints": "Bank or Redeem Points",
  "bankYourPointsForLater": "You can change your Reward Level at any time.",
  "beforeDiscount": "Before discount",
  "below": "below",
  "billingAddressIsSameAsDeliveryAddress": "Billing address is same as delivery address.",
  "billingCountryIsARequiredField": "Billing Country is a required field",
  "bk_aboutBK": "About BK",
  "bk_allRightsReserved": "All Rights Reserved.",
  "bk_bkApp": "BK App",
  "bk_bkCares": "BK Cares",
  "bk_bkcInfo": "BKC Info",
  "bk_bkCrownsExpirationText": "BK Crowns never expire with an active account. Maintain your balance with an order at least once every 6 months.",
  "bk_bkMcLAMOREFoundation": "BK McLAMORE Foundation",
  "bk_bkTrademarkAndCopyright": "TM & Copyright 2021 Burger King Corporation.‌‌",
  "bk_bringItUpAtBKC": "Bring it @ BKC",
  "bk_giftOrCrownCard": "Gift Cards / Crown Card",
  "bk_logo_title": "Burger King",
  "bk_myBK": "My BK",
  "bk_myBKRewards": "My BK Rewards®",
  "bk_pointsBalance": "BK Crown Rewards:",
  "bk.payWithMyPrepaidCard": "Pay with BK Crown Card",
  "bk.pypestreamLoadingMessage": "One moment while we fire up support.",
  "bk.routes.privacyPolicy": "/privacy-policy",
  "bk.routes.termsOfService": "/terms-of-service",
  "bkCareers": "BK Careers",
  "bkKingdom": "BK Kingdom",
  "bkRewards": "BK Rewards",
  "bonus": "Bonus",
  "bonusPoints": "{points} Bonus Point{points, plural, one {} other {s}}",
  "bonusPointsIncentiveDescription": "You've earned {points} bonus points completing this challenge!",
  "bonusPointsLabel": "Bonus Point{points, plural, one {} other {s}}",
  "breakfastDisclaimer": "Items available only for breakfast",
  "breakfastIsAvailableFromOpen": "Breakfast is available from open - {openTime}",
  "breakfastMenu": "Breakfast Menu",
  "brewingNewDownload": "Brewing new download",
  "browseOpportunities": "Browse Opportunities",
  "browseOurMenu": "Browse Our Menu",
  "browserSettings": "Browser Settings",
  "bsmConfirmationModalBody": "You will be taken to select a new service mode with a new restaurant or delivery address.",
  "bsmConfirmationModalHeading": "Are you sure you want to change your store?",
  "button": "button",
  "byDefault": "By Default",
  "cal": "Cal",
  "callYourSelectedStore": "call your selected store",
  "calculatingEta": "Calculating driver location",
  "calories": "Calories",
  "cameraUnavailable": "Camera is unavailable, review your settings",
  "canada": "Canada",
  "cancel": "Cancel",
  "cancelCateringClearCart": "Your cart will be cleared if you decide to cancel your catering order",
  "cancelCurbsideOrder": "Cancel Order",
  "cancelCurbsideOrderText": "It looks like you already have a curbside order in progress.",
  "cancelCurbsideOrderTitle": "Cancel Curbside Order?",
  "cancellationPolicy": "No changes to the order can be made after placing your order.",
  "cancellationPolicyTitle": "Cancellation Policy",
  "cancelledOrderAction": "We're sorry, but it looks like your order has been cancelled and refunded. If you'd like to place your order again you can use the button below.",
  "cancelledOrderTitle": "Oh no! Your order was cancelled.",
  "cancelOrder": "Cancel Order",
  "cannotDeliveryTo": "We cannot currently deliver to",
  "cannotPlaceOrder": "Search for other restaurants nearby!",
  "cannotRemoveStoreFromFavorites": "In order to optimize your ordering experience to make it seamless, we need at least one favorite store.",
  "cannotRemoveStoreFromFavoritesHeader": "Cannot Remove Store From Favorites",
  "carbohydrates": "Carbohydrates",
  "carbs": "Carbs",
  "card": "CARD",
  "cardAlias.bk": "Crown",
  "cardAlias": "Gift",
  "cardDeactivationError": "We were unable to process your request. Please try again or {link} for assistance.",
  "cardExpiration": "Card Expiration Date",
  "cardNumber": "Card Number",
  "cardNumberIsRequired": "Card number is required",
  "cardNumberRequiredError": "Card number is a required field.",
  "cardSuccessfullyLinked": "The card {cardId} was successfully linked to your account. Your Points may take a few minutes to appear in your account.",
  "cardXHasABalanceOfY": "Card {cardNumber} has a balance of {balance}",
  "careers": "Careers",
  "careersHome": "Careers Home",
  "cart": "Cart",
  "cartLoyaltyPoints": "You have {points} Points",
  "cartOfferRequirementsNotMetError": "The items in your cart do not meet the requirements of this offer",
  "cartPromotionOfferRequirementsNotMetError": "Your order does not meet the requirements of this promotion.",
  "cartTotal": "Cart total",
  "cartTotalMaxAlert": "We only accept orders up to {maxAmount} in our app. For larger orders, please {suggestion}",
  "cash": "Cash",
  "cashPayementsAcceptedAtTheCounter": "Cash payments will be completed at the counter",
  "catering": "Catering",
  "cateringChangeServiceModeDescription": "Exiting Catering service mode will result in your cart clearing. Continue changing service mode?",
  "cateringConfirm": "Confirm Catering",
  "cateringDashDelivery": "Catering - Delivery",
  "cateringDashPickUp": "Catering - Pickup",
  "cateringDelivery": "Catering Delivery",
  "cateringDeliveryUnavailable": "Catering Delivery - Unavailable",
  "cateringDialogTitle": "You are seeing the {cateringTextComponent} menu!",
  "cateringDialogTitleString": "You are seeing the Catering menu!",
  "cateringDialogBody": " Please be aware that the next available pick up time for catering may be delayed due to the order size.",
  "cateringMenu": "Catering Menu",
  "cateringMinimumNotReached": "Sorry! Minimum order for catering is {min}.",
  "cateringOrder": "Catering Order",
  "cateringOrderPendingApproval": "Your order is currently pending. You'll receive an email once it's accepted.",
  "cateringOrdersDisclaimer": "Catering orders take up to 24 hours to confirm. Your card will be charged.",
  "cateringOrderTimeout": "Catering order timeout",
  "cateringPickup": "Catering Pick Up",
  "cateringPickUp": "Catering Pick Up",
  "cateringPickUpAt": "Catering Pick up at",
  "cateringPickUpDate": "Please note that catering orders must be placed a day in advance of desired pickup date.",
  "cateringRewardsMessage": "Catering rewards can be redeemed on the website only.",
  "ccNumberIsNotValid": "Card Number is not valid",
  "CCPADontSellButton": "Do not sell or share my personal information",
  "celery": "celery",
  "challenge": "Challenge",
  "challengeCardPayment": "Challenge card payment",
  "challengeComplete": "Challenge Completed",
  "challengeCompleted": "Challenge Completed",
  "challengeOrderPayment": "Challenge order payment",
  "challenges": "Challenges",
  "challengeStarted": "Challenge Started",
  "change": "Change",
  "changeAddress": "Change Address",
  "changeAddressAriaLabel": "Link to change address location",
  "changeEmail": "Change Email",
  "changeFundingSource": "{method} Funding Source",
  "changeLanguage": "Select Language and Region",
  "changePaymentMethod": "This store doesn’t accept this card type. Change your payment method and try again.",
  "changeRedemptionToInStore": "Are you sure you want to change to in-store redemption?",
  "changeRedemptionToInStoreConfirmLabel": "Change to in-store",
  "changeRedemptionToInStoreDescription": "Changing to in-store redemption will clear your cart. Are you sure you want to proceed?",
  "changeRedemptionToPickUp": "Are you sure you want to change to a mobile order?",
  "changeRedemptionToPickUpConfirmLabel": "Change to mobile order",
  "changeRedemptionToPickUpDescription": "Changing from in-store redemption to mobile order will clear your active perks. Are you sure you want to proceed?",
  "changeRegionConfirmation": "Please confirm your region to continue.",
  "changeRegionDisclaimer": "Please note you will need to select your restaurant again.",
  "changeRegionOnOrder": "We need a little more information, please confirm your region to continue ordering",
  "changeRestaurant": "Change Restaurant",
  "changeServiceMode": "Change Service Mode",
  "changeStoreAriaLabel": "Link to change store location",
  "changeStoreItemDisclaimer": "Changing stores may change prices or item availability.",
  "changeStoreMessage": "Changing your store may result in differences in prices or item availability. Continue changing stores?",
  "changeStores": "Change Stores",
  "changeStoreSelectedOfferAndItemDisclaimer": "Your selected offer is not available for {serviceMode} and changing stores may change prices or item availability.",
  "changeStoreSelectedOfferDisclaimer": "Your selected offer is not available for {serviceMode}.",
  "changeStoreSelectedOfferNotAvailableDisclaimer": "Oops! Looks like the offer you have redeemed is not available at the location you have selected.",
  "changeServiceModeConfirmationConfirm": "Reset Cart and Proceed",
  "changeServiceModeConfirmationTitle": "Switching Location Will Reset Your Cart",
  "changeServiceModeConfirmationMessage": "Are you sure you want to remove all the items from your cart?",
  "changeServiceModeToDeliveryConfirmationTitle": "Switching to Delivery Will Reset Your Cart",
  "changingYourStoreDisclaimer": "Store changes may result in differences in price or item availability.",
  "chatWithSupport": "Chat with Support",
  "chatWithUs": "chat with us",
  "checkAvailability": "Check Availability",
  "checkCardBalance": "Check Card Balance",
  "checkForPricing": "One moment please! We’re checking with the Restaurant.",
  "checkingDeliveryAvailability": "Checking Delivery Availability",
  "checkingDeliveryAvailabilityText": "We are checking which restaurant will service your address. This may take a few moments.",
  "checkingPricesAvailability": "Checking prices and availability",
  "checkNetworkSettings": "Check your network settings and try again",
  "checkout": "Checkout",
  "checkoutPaymentMethodNeedRevaultError": "There was an issue with your payment method. Please re-add your payment method.",
  "cholesterol": "Cholesterol",
  "chooseADifferentPaymentMethodByPressingCancel": "You can choose a different payment method by pressing the Cancel button on the terminal.",
  "chooseALocation": "Choose a Location",
  "chooseAnOption": "Choose an option...",
  "chooseAnotherStore": "Choose Another Store",
  "chooseATime": "Choose a Time",
  "chooseDifferentItem": "Please choose a different item.",
  "chooseDifferentOffer": "Please choose a different offer.",
  "chooseDrink": "Next Choose Drink",
  "chooseItemAmount": "Choose {amount} {itemName}",
  "chooseLocation": "Choose location",
  "chooseMyRewardLevel": "Choose my Reward Level",
  "choosePaymentMethod": "Choose Payment Method:",
  "choosePromotion": "Choose a Promotion",
  "chooseSide": "Next Choose Side",
  "chooseYourLocation": "Choose location",
  "chooseYourRewardLevel": "Choose Your Reward Level",
  "city": "City",
  "cityRequiredError": "City is a required field",
  "claimPoints": "claim points",
  "clearFilter": "clear filter",
  "clearSearchBar": "Clear search bar",
  "clearText": "Clear Text",
  "clickHere": "Click here",
  "clickToSelectACloserStore": "{clickHere} to select a closer store",
  "clickToSelectLocation": "Click to select location",
  "close": "Close",
  "closed": "Closed",
  "closeDialog": "Close Dialog",
  "closeMessageToFindNearbyRestaurants": "Close this message to find other nearby restaurants",
  "closeMessageToFindNearbyStores": "Close this message to find stores near you that offer dine in, take out, or drive thru options.",
  "closesAt": "Closes at {time}",
  "closeWindow": "Close Window",
  "codeErrorMessage": "Couldn't retrieve your code.",
  "codeExpiredMessage": "Sorry, your promo code expired.",
  "codeExpiredMessageDetail": "Hit “Refresh” to get a new one.",
  "coffee": "Coffee",
  "combobox": "combobox",
  "comboOfferSavings": "Savings",
  "comeJoinTheTeam": "Come join the team",
  "comingSoon": "Coming soon!",
  "comments": "Comments",
  "communicationPreferences": "Communication Preferences",
  "community": "Community",
  "complete": "Complete",
  "completed": "Completed",
  "confettiRight": "confetti-right",
  "confirm": "confirm",
  "confirmAllItemsAreMissing": "Confirm all items are missing.",
  "confirmAndOrder": "Confirm & Order",
  "confirmArrival": "Confirm arrival",
  "confirmCatering": "Confirm Catering",
  "confirmationDifferentLocation": "Choose a different location",
  "confirmationPageInstructions": "Congratulations, we saved your order! Click I'm Here when you arrive to the restaurant",
  "confirmDeletePaymentMethod": "Are you sure you want to delete this payment method?",
  "confirmDeletePaymentMethodDeletionUnlink": "Are you sure you want to delete this payment method? Deletion will also unlink your card from your loyalty account and benefits.",
  "confirmDialogBody": "Please continue if you're ready to redeem in the restaurant.",
  "confirmDialogHeader": "This coupon will expire {br} in 15 minutes",
  "confirmOnlySomeItemsAreMissing": "Confirm only some items are missing.",
  "confirmRedemption": "When you confirm your in-store redemption, your points will be deducted immediately. Ask for your free item at the register to claim reward.",
  "confirmRedemptionHeader": "Are you sure you want to redeem in-store?",
  "confirmStore": "Confirm Store",
  "confirmYourPickupAddress": "Confirm Your Pickup Address",
  "confirmYourStore": "Confirm Your Store",
  "congratulations": "Congratulations",
  "connectCard": "Connect card",
  "connectWithUs": "Connect with Us",
  "contact": "Contact",
  "contactCourier": "You can contact your courier at:",
  "contactRestaurantAt": "Please contact your restaurant at:",
  "contactSupport": "Contact Support",
  "contactUs": "Contact Us",
  "containsAllergens": "Contains: {allergens}",
  "containsAllergensDisclaimer": "Contains {allergens}",
  "containsSelectedOption": " contains selected level {rewardTier}",
  "contentinfo": "contentinfo",
  "continue": "Continue",
  "continueChallenge": "Continue Challenge",
  "continueInWeb": "Take me to website and send me a new code",
  "continueOrder": "Continue Order",
  "continueOrdering": "Yes, continue ordering",
  "continueWithEmail": "Continue with email",
  "continueWithOffer": "Continue with this offer",
  "continueWithoutOffer": "Continue without offer",
  "cookieNotice": "Cookie Notice",
  "cookiePolicy": "By clicking \"{acceptCookies}\", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
  "cookiePolicyiOS14": "By clicking \"{acceptCookies}\", you agree to storing cookies on your device to enable features and analyze usage. We will not track you without consent.",
  "copy": "Copy",
  "couldNotLinkCard": "Couldn’t link your card",
  "couldNotLinkCardMessage": "We’re sorry we couldn’t link your card at this time. Please check if your card is already linked to another account. If not, check your connection and try again. Or chat with us for assistance.",
  "couldNotLocateStore": "The store could not be located to complete your order. Please try again later.",
  "couldntProcessPayment": "Unfortunately, we couldn't process your payment. Please take a moment to review your card information. If it looks correct, try submitting your order again.",
  "country": "Country",
  "countryRequiredError": "Country is a required field.",
  "couponApplied": "Coupon Applied",
  "couponEntryComingSoon": "coupon entry coming soon",
  "couponErrorBody": "Your order contains a coupon that's no longer available. You can still complete your order but we'll remove the coupon from the cart.",
  "couponErrorConfirm": "Complete Order",
  "couponErrorHeading": "Coupon Error",
  "couponOnlyAvailableOnPlatform": "Sorry, this coupon is only {br} available on our {platformName}",
  "couponWillExpire": "This coupon will expire in 15 minutes",
  "createAccountHeadline": "Create an Account to Get Started",
  "createAnAccount": "Create an Account",
  "credit": "Credit",
  "creditCardExpired": "Credit card number is expired",
  "creditCardNumber": "Credit Card Number",
  "crownCard": "Crown Card",
  "crownCardBalance": "Crown Card Balance",
  "crownPoints": "Crown Points",
  "crowns": "Crowns",
  "crownsApplied": "Crowns Applied",
  "crownsEarned": "{points} Crown{points, plural, one {} other {s}} Earned",
  "crownsPerDollar": "Earn {pointsPerDollar} crown{pointsPerDollar, plural, one {} other {s}} for every dollar you spend",
  "curbside": "Curbside",
  "curbsideAt": "Curbside at",
  "curbsideHours": "Curbside hours",
  "curbsideInstructionsText": "Let us know when you've arrived and we will bring your order to your vehicle when it is ready. Otherwise, we will prepare your order at {time}",
  "curbsideInstructionsTextHere": "We have started preparing your order. Please park in a designated curbside parking spot and we will bring your order to your vehicle.",
  "curbsideOrder": "Curbside Order",
  "curbsideOrderError": "There was an error with your curbside order. Please confirm your details or try a different service mode.",
  "curbsideUnavailable": "Curbside is currently unavailable",
  "curbsideVehicleDescription": "Help us find you by describing your vehicle e.g. red SUV/license plate starting ABC (optional). When you arrive at the restaurant, find a parking spot. When your order is ready, we will bring it you.",
  "curbsideVehicleDescriptionPlaceholder": "Vehicle description",
  "curbsideVehicleTitle": "Describe your vehicle",
  "currencyInfo": "The format of your currency is determined by your locale. Change your locale to update your format.",
  "currentlyHaveXRewards": "You currently have {availableRedemptions} rewards",
  "currentPage": "current page",
  "currentServiceModeUnavailable": "The restaurant is not accepting {serviceMode} orders at this time. Change service mode or select a new store",
  "customize": "Customize",
  "customizeYour": "Customize your {name}",
  "cvv": "CVV",
  "cvvIsRequired": "CVV is required",
  "cvvMustBeAtLeast3Digits": "CVV must be at least 3 digits",
  "dashboard": "Dashboard",
  "date": "Date",
  "dateOfBirth": "Date of birth",
  "dateOfBirthIsBelowMinimum": "The information you entered does not comply with our {termsLink} and our {privacyPolicyLink}",
  "dateOfBirthIsRequired": "Date of Birth is required",
  "dailyRedemptionLimit": "Daily Redemption Limit Exceeded",
  "dailyRedemptionLimitMessage": "You have reached the daily limit of {boldText}. Please return tomorrow to redeem more rewards.",
  "dailyRedemptionLimitDetail": "{maxLimitRedemption} loyalty redemptions",
  "datePlaceholder": "mm/dd/yyyy",
  "day": "Day",
  "daypartAfterEndTime": "It's past {dayPart} time!",
  "daypartBeforeStartTime": "It's not {dayPart} time yet!",
  "daypartChallengedCompleted": "Challenge Completed",
  "daypartEndsAt": "{dayPart} ends at {endTime}",
  "daypartStartsAt": "{dayPart} starts at {startTime}",
  "dayPlaceholder": "DD",
  "days": "{days} Day{days, plural, one {} other {s}}",
  "daysLeft": "{daysCount} days left",
  "daytime": "Daytime",
  "daytimeMenu": "Daytime Menu",
  "daytimeMenuHeader": "{daytimeStartTime} - {daytimeEndTime}",
  "daytimeMenuHeaderHours": "{daytimeStartTime} to close",
  "deactivateCard": "Deactivate Card",
  "debit": "Debit",
  "december": "Decmber",
  "decline": "Decline",
  "declineCateringOrderModal": "Please select a reason as to why you cannot accept this order.",
  "declineOrder": "Decline Order",
  "declineReason": "Decline reason",
  "decrementName": "Decrement {name}",
  "defaultReloadMethod": "Default Reload Method",
  "delete": "delete",
  "deleteAccount": "Delete Account",
  "deleteAndDisable": "Delete and Disable",
  "deleteButtonText": "Delete",
  "deletePaymentMethod": "Delete payment method",
  "delivered": "Delivered",
  "deliverHere": "Deliver Here",
  "deliveringTo": "Delivering to",
  "deliveringToYourDoorAt": "Delivering to your door at",
  "deliverTo": "Deliver to",
  "delivery": "Delivery",
  "deliveryAddNewAddress": "Add new address",
  "deliveryAddress": "Delivery Address",
  "deliveryAddressFormatted": "{addressLine1}{linebreak}{city}, {state} {zip}",
  "deliveryChangeRegionDisclaimer": "Please note you will need to enter your delivery address again. Changing regions may affect item availability.",
  "deliveryChangeRegionHeading": "Using this delivery address will change your region",
  "deliveryChangeRegionPrimaryText": "You have {userRegion} set as your region, while the delivery address you entered is in {restaurantRegion}.",
  "deliveryCheckingMessage": "We are checking which restaurant will service your address. This may take a few moments...",
  "deliveryComingSoon": "Delivery COMING SOON",
  "deliveryDelivered": "Delivered",
  "deliveryDetails": "Delivery details",
  "deliveryDetailsInstructions": "Delivery Instructions",
  "deliveryDriveEnroute": "Your driver will arrive soon!",
  "deliveryDriverArrived": "Driver Arrived",
  "deliveryDriverEnroute": "Driver Enroute",
  "deliveryFee": "Delivery Fee",
  "deliveryFeeWithFee": "{deliveryFee} Delivery Fee",
  "deliveryFeeBase": "Base Fee",
  "deliveryFeeLongDistance": "Long Distance Fee",
  "deliveryFeePerOrder": "{deliveryFee} Delivery Fee (Per Order)",
  "deliveryFeeMessage": "A {feeSurcharge} will be added to your order total.",
  "deliveryFeeBreakdown": "Fee breakdown:",
  "deliveryFeeBreakdownBase": "• Base Fee: {fee}",
  "deliveryFeeBreakdownLongDistance": "• Long Distance Fee: {fee}",
  "deliveryFeeBreakdownGeographical": "• Geographical Fee: {fee}",
  "deliveryFeeBreakdownService": "• Service Fee: {fee}",
  "deliveryFeeBreakdownSmallCartFee": "• Small Cart Fee: {fee}",
  "deliveryFeeDescription": "Fixed fee applicable to each delivery order.",
  "deliveryFeeFullTermsLink": "{link} for full delivery terms.",
  "deliveryFeeTerms": "Delivery Terms and Fees Apply.",
  "deliveryFeeTermsLink": "{link} for Details.",
  "deliveryFees": "Delivery Fees",
  "deliveryFeesApply": "Delivery Fees Apply",
  "deliveryImage": "Delivery Image",
  "deliveryInfo": "Delivery Info",
  "deliveryInstructions": "Delivery Instructions",
  "deliveryInstructionsPlaceholder": "e.g. \"Please leave with doorkeeper\"",
  "deliveryIsUnavailable": "Delivery is unavailable in your area but don't let that stop you from enjoying our tasty treats. Click on the button below to find restaurants in your area and don't forget to checkout our deals!",
  "deliveryLogoAlt": "Delivery service provider logo",
  "deliveryMinumumNotReached": "Sorry! Minimum order for delivery is {min}",
  "deliveryNotAvailable": "Delivery Unavailable",
  "deliveryOnly": "Delivery only",
  "deliveryOrder": "Delivery Order",
  "deliveryOrderBeingPrepared": "Being Prepared",
  "deliveryOrderPlaced": "Order Placed",
  "deliveryPhoneDisclosure": "Your phone number is required so that we can send you updates about your order and facilitate communication between you and your driver. Your phone number will not be visible to your driver.",
  "deliveryPhoneNumberError": "Please update your account phone number to continue",
  "deliveryPlaced": "Thanks. We received your Order",
  "deliveryPrepared": "Your order is being prepared",
  "deliverySelectYourAddress": "Select your Delivery Address",
  "deliveryStatusDriverAssigned": "Your order is in the works",
  "deliveryStatusDriverAtCustomer": "Your courier is nearby!",
  "deliveryStatusDriverAtStore": "Your courier just picked up your order",
  "deliveryStatusDriverStarting": "Your courier is picking up your order",
  "deliveryStatusDriverUnassigned": "Your order is in the works",
  "deliveryStatusOrderAbandoned": "Your order has been delivered",
  "deliveryStatusOrderCancelled": "Your order has been cancelled",
  "deliveryStatusOrderCreated": "Your order is in the works",
  "deliveryStatusOrderDroppedOff": "Your order has been delivered",
  "deliveryStatusOrderError": "Error processing your order",
  "deliveryStatusOrderPickedUp": "Your courier is out for delivery!",
  "deliveryStatusOrderTimeout": "Timeout processing your order",
  "deliveryStatusQuoteError": "Error processing your order",
  "deliveryStatusQuoteRequested": "We are processing your order",
  "deliveryStatusQuoteSuccessful": "We received your order",
  "deliveryStatusQuoteUnavailable": "We can't process your order",
  "deliverySurcharge": "Delivery Surcharge",
  "deliveryTerms": "Delivery Terms and Fees Apply. {link} for Details.",
  "deliveryTime": "Delivery Time",
  "deliveryTipLabel": "Your Driver's Tip",
  "deliveryUnavailable": "Delivery - Unavailable",
  "depositFee": "Deposit Fee",
  "describeIssue": "Describe your issue",
  "describeIssueAndIncludePhoneNumber": "Describe your issue (please include your phone number)",
  "describeYourVehicle": "Describe your vehicle",
  "descriptionLengthError": "Description must be between {min} to {max} characters long",
  "details": "Details",
  "deviceSettings": "Device Settings",
  "diagnostics": "Diagnostics",
  "dialog": "dialog",
  "didNotReceiveCodeHeading": "Didn't receive a code?",
  "didNotReceiveCodeSendANewOne": "Send a new one in {countdownSeconds}...",
  "didNotReceiveCodeSendANewOneCountDownSeconds": "{number}s",
  "differentDeviceError": "We noticed this isn't the device you requested this code from. To login with this device, please request a new code below.",
  "differentDeviceErrorPhoneVerify": "We noticed this isn't the device you requested this code from. To verify your phone number, please press the link again.",
  "differentRegion": "a different region",
  "digitalWalletsAndCreditCards": "Digital Wallets and Credit Cards",
  "dineIn": "Dine in",
  "dineInAt": "Dine in at",
  "dineInComingSoon": "Dine In COMING SOON",
  "dineInHours": "Dine in hours",
  "dineInOrder": "Dine In Order",
  "dineInUnavailable": "Dine In is currently unavailable",
  "directions": "Directions",
  "discard": "Discard",
  "discardChanges": "Discard Changes",
  "discountNotApplied": "Discount not applied",
  "dismiss": "Dismiss",
  "dismissDeliveryModal": "Dismiss",
  "distance": "Miles",
  "diversity": "Diversity",
  "documentTitle": "Firehouse Subs - Subs, Sub Sandwiches, Salads, Catering",
  "doItManually": "I'll do it manually",
  "donateTo": "Donate To {link}?",
  "donation": "Donation",
  "done": "Done",
  "dontForgetToOrderYourReward": "Don't forget to order your reward!",
  "dontForgetToPickYourOrder": "Don’t forget to pick up your order soon! The restaurant closes at {time}",
  "dontHaveAnAccount": "Don't have an account yet?",
  "dontHaveAPromoCode": "Don't have a promo code?",
  "downArrowChevronIcon": "Down Arrow",
  "downloadApp": "Download Our App",
  "downloadOnAppStore": "Download on the App Store",
  "downloadOurApp": "Download our App. Enter your mobile number and we'll send you a download link.",
  "downloadTheApp": "Download the App",
  "doYouHaveACouponAlready": "Do You Have A Coupon Already?",
  "drawerHeading": "drawer-heading",
  "driveThru": "Drive Thru",
  "driveThruAt": "Drive-thru at",
  "driveThruComingSoon": "Drive Thru COMING SOON",
  "driveThruHours": "Drive Thru hours",
  "driveThruOrder": "Drive Thru Order",
  "driveThruPickupInstructions": "Drive up to the ordering screen and let us know you ordered online for {name}.",
  "driveThruUnavailable": "Drive Thru is currently unavailable",
  "duplicateAccounts": "Duplicate Accounts",
  "duplicateSupportFormSubmission": "A support ticket was already created for your issue. Please give us up to 48 hours to respond",
  "earned": "You've earned",
  "earnedPoints": "Transaction History",
  "earnedReward": "You just earned a reward",
  "earnPoints": "Earn Points",
  "earnPointsForEveryPurchase": "Earn 10 points for every eligible purchase",
  "earnPointsToRedeem": "Earn points to redeem",
  "earnPointsEnjoyExclusiveOffersAndRewards": "Earn points, enjoy exclusive offers and free rewards.",
  "earnUsingRewardsCard": "Earn using Rewards Card",
  "eatIn": "Dine In",
  "edit": "Edit",
  "editOrder": "Edit Order",
  "eggs": "eggs",
  "enterYourCode": "Enter your code",
  "email": "Email",
  "emailAddress": "Email Address",
  "emailOrPhoneNumberRequiredError": "Email or phone number is required",
  "emailRequiredError": "Email is a required field.",
  "emailSuccessfullySubmitted": "Email successfully submitted",
  "emailVerificationSent": "Email sent! Please check your email for the verification link.",
  "emptyCart": "Empty Cart",
  "emptyCartMessage": "You don't have anything in your cart yet!",
  "emptyMenuHeading": "Select a Location to View Menu",
  "emptyMenuMessage": "Help us find your closest store by sharing your location or select a location manually",
  "emptyMyCodeHeading": "Select a Location to view My Code",
  "emptyMyCodeMessage": "Share your location or select a location manually to view My Code",
  "enableCameraPermission": "Please, enable camera permission",
  "enableExclusiveOffer": "Enable Exclusive Offer",
  "enableExclusiveOfferDescription": "If you received a promo code, you can enable an exclusive offer.",
  "enableLocationServices": "Enable Location Services",
  "enablePreciseLocation": "Enable Precise Location",
  "ends": "ends",
  "endsIn": "••••",
  "english": "English",
  "enjoyYourOrder": "Enjoy your order. See you soon!",
  "enterACode": "Enter A Code",
  "enterAddressOrDrag": "Enter an address or drag the map to find stores near you.",
  "enterANameForThisOrder": "Enter a name for this order",
  "enterAPromoCode": "Enter a Promo Code",
  "enterCardNumber": "Enter card number",
  "enterFourDigitPin": "Enter the 4-digit code from a printed coupon, BK.com, or mobile app here:",
  "enterManually": "Enter manually",
  "enterPromoCode": "Enter Promo Code",
  "enterSignInEmail": "Enter your email address.",
  "enterUnitPlaceholder": "e.g. \"Apartment 100\"",
  "enterVerificationCode": "Enter Verification Code",
  "enterYourDeliveryAddress": "Enter your address",
  "enterYourValidationCode": "Enter your Validation Code",
  "enterYourLovedOnesDeliveryAddress": "Enter your (or your loved one's) delivery address",
  "error.isRestaurantPosOnline": "Restaurant POS offline",
  "error.isStoreOpenAndAvailable": "Store is not open and available",
  "error.priceMutationError": "Price mutation error",
  "error.priceOrderFailure": "Price order failure",
  "error.unavailableCartItems": "Unavailable cart items",
  "error404": "Error 404",
  "errorBoundaryBody": "Something went wrong on our end. Take a look at our menu and offers while we take a look into this.",
  "errorBoundaryTitle": "Bummer",
  "errorCreateOrder": "An error occurred creating the order",
  "errorCreatingSupportCase": "There was an error submitting your support case. Please try again",
  "errorDialogCooldownMessage": "We see that you have recently placed an order at this restaurant. Please wait 5 minutes before placing another order at this restaurant.",
  "errorDialogCooldownTitle": "Duplicate Orders",
  "errorDialogFavoriteStoreMessage": "To set a favorite store, first sign up or log in to an account.",
  "errorDialogFavoriteStoreTitle": "Log in required",
  "errorLoadingUserOrders": "Error Loading User Orders",
  "errorLoadOffers": "An error occurred loading offer redemptions",
  "errorProcessingRequest": "Looks like there was an error processing your request.",
  "errorRetrievingFavoriteRestaurants": "Could not retrieve favorite restaurants",
  "errorRetrievingRecentRestaurants": "Could not retrieve recent restaurants",
  "errorRetrievingRestaurants": "Could not retrieve restaurants",
  "eta": "ETA",
  "etaFull": "Estimated Time of Arrival",
  "eu-calories": "Energy",
  "eu-carbohydrates": "Carbohydrates",
  "eu-cholesterol": "Cholesterol",
  "eu-fat": "Fat",
  "eu-fiber": "Fiber",
  "eu-proteins": "Proteins",
  "eu-salt": "Salt",
  "eu-saturatedFat": "Of which saturates",
  "eu-sodium": "Sodium",
  "eu-sugar": "Of which sugars",
  "eu-transFat": "Trans Fat",
  "eu-weight": "Weight",
  "euNutritionValues": "{calories} / {joules}",
  "evening": "Evening",
  "exampleInstructions": "e.g., Please leave delivery at the door",
  "exceedsBalanceLimit": "Exceeds Balance Limit",
  "exceedsRewardsBalance": "Points balance reached! To add this item please remove rewards added to your cart.",
  "existingUserVerifiedPhone": "An existing user has already verified this phone number. Please try another phone number.",
  "expirationDateIsRequired": "Expiration date is required",
  "expire": "exp.",
  "expired": "Expired",
  "expires": "Expires",
  "expiresIn": "Expires in {expiryDays} {expiryHours}",
  "expiresWithDate": "Expires {endDateText}",
  "expiringPoints": "Expiring Points",
  "exploreOurMenu": "Explore Our Menu",
  "faq": "Frequently Asked Questions",
  "fat": "Fat",
  "favorite": "Favorites",
  "favoriteLocationLabel": "Search Favorite Location",
  "favoriteLocationPlaceholder": "Select your location",
  "favoriteLocationRequiredError": "Favorite location is required.",
  "favoriteOrders": "Favorite Orders",
  "favoriteRemoveAllModalBody": "Removing all the items in this order will delete your favorite. Are you sure you want to delete this order?",
  "favoriteRemoveAllModalHeader": "Wait! You have no items!",
  "featured": "Featured",
  "february": "February",
  "fees": "Fees",
  "feet": "feet",
  "fhsLogoTitle": "Firehouse Subs",
  "fiber": "Fiber",
  "fifteenMin": "15 min",
  "filter": "filter",
  "filterByMonth": "Filter by month",
  "filters": "filters",
  "findARestaurant": "Find a Restaurant",
  "findAStoreNearMe": "Find a store near me",
  "findingYourNearestStores": "Finding your nearest stores",
  "findRestaurants": "Find Restaurants",
  "findRewardYouWantToRedeem": "Find the reward you want to redeem and click \"Redeem at Restaurant\".",
  "finish": "Finish",
  "finishOrder": "Finish order",
  "firstName": "First name",
  "fish": "fish",
  "fiveMin": "5 min",
  "followUsOnFacebook": "Follow us on Facebook",
  "followUsOnInstagram": "Follow us on Instagram",
  "followUsOnTiktok": "Follow us on TikTok",
  "followUsOnTwitter": "Follow us on Twitter",
  "followUsOnYoutube": "Follow us on YouTube",
  "foo": "foo",
  "foodQuality": "Food Quality",
  "forItemAvailability": "For availability",
  "forterSignUpError": "It seems that you may already have an account with us. Please try logging in to your existing account or contact support for assistance.",
  "forwardButton": "Forward Button",
  "foundation": "Foundation",
  "franchising": "Franchising",
  "free": "FREE",
  "freeCoffeeTeOrBakedGood": "Earn a FREE coffee, tea or baked good.",
  "freeDeliveryOnAllOrders": "Free Delivery on All Orders",
  "freeDeliveryOverAmount": "Free Delivery on Orders over {amount}",
  "freeRewardsUpsize": "Free Rewards Upsize",
  "freeUpsize": "Update Cart",
  "french": "French",
  "frequencyChallengedCompleted": "Challenge Completed",
  "freshlyExtracting": "Freshly extracting...",
  "freshNewDownload": "Fresh New Download",
  "friday": "Friday",
  "from": "From",
  "fromStoreAddress": "from {storeAddress}",
  "fullMenu": "Full Menu",
  "fundingSource": "Funding Source",
  "futureDateOfBirthProvided": "Date of birth cannot be a future date",
  "futureOrderingWarning": "You are placing an order for the future, please select a date and time that works for you.",
  "futureOrderingOfferValidationError": "You have an offer in your cart that is not available at the selected time and date.",
  "generateNewCode": "Generate New Code",
  "futureOrderingWorksForYou": "You can still place an order for the future by selecting a date and time that works for you.",
  "geographicalFee": "Geographical Fee",
  "geographicalFeeCO": "CO Retail Delivery Fee",
  "geographicalFeeDescription": "Additional fee charged in your area by the delivery aggregator.",
  "geolocationDeliveryImageAlt": "Geolocation delivery image",
  "geolocationImageAlt": "Geolocation image",
  "germany": "Germany",
  "getDirections": "Get Directions",
  "getOnGooglePlay": "Get it on Google Play",
  "getRewards": "Get rewards",
  "gift": "Gift",
  "giftCard": "GIFT CARD",
  "giftCardAddingError": "Gift card details may be incorrect or we are experiencing issues with your card, please try again or check with the issuer.",
  "giftCardAddingSuccess": "Your BK Crown Card has been added!",
  "giftCardExists": "Gift Card already exists in payment methods. You can only have one gift card in payment methods.",
  "giftCardNumber.bk": "Crown Card Number",
  "giftCardNumber": "Gift Card Number",
  "giftCardNumberIsNotValid": "Gift Card Number is not valid",
  "giftCardNumberIsRequired": "Gift card number is required",
  "giftCardUnavailableAtLocationError": "Prepaid Cards are unavailable at this location. Please select another form of payment.",
  "giftCardUnavailableAtLocationErrorHeading": "Payment Failed",
  "giftSent": "Gift on the way!",
  "giftSentError": "Unfortunately, we were not able to send the {gift} to {email}. Please try again or contact {supportLink}.",
  "giftSentSuccess": "You have gifted a {gift} to {email}.",
  "goToHomepage": "Go To Homepage",
  "goToSettings": "Go To Settings",
  "gluten": "gluten",
  "goodDayPeriodX": "Good {dayPeriod}, {name}",
  "googlePay": "Google Pay",
  "googlePayPaymentNotAvailable": "We currently don’t accept Google Pay on our websites. Please select or add a new payment method.",
  "googlePlay": "Google Play",
  "gotIt": "Got It!",
  "goToMenu": "Go To Menu",
  "goToPaymentMethods": "Go to Payment Methods",
  "goToPopeyes": "Go to Popeyes",
  "goToScanPage": "Go To Scan Page",
  "haveAPromoCode": "Have a Promo Code?",
  "haveFreeReward": "You have a Free Reward",
  "haveNotReceivedAuthCode": "If you don't receive a code after 3 minutes, you can request a new code.",
  "haveNotReceivedAuthEmail": "Didn't receive an email? If you don't receive an email after 3 minutes, check your spam folder, or {link}",
  "headsUpModalTitle": "Heads up",
  "headToRestaurant": "Head to restaurant",
  "help": "Help",
  "here": "here",
  "highVolumeMessage": "We are currently experiencing higher volumes than expected. We apologize for the longer wait times and are doing our best to serve you as soon as possible. Thank you for understanding.",
  "history": "history",
  "hiX": "Hi, {name}",
  "hockey1": "Hockey - 1 Pick",
  "hockey2": "Hockey - 2 Picks",
  "hockey3": "Hockey - 3 Picks",
  "home": "Home",
  "homeMainFabSignInUp": "Log In / Sign Up",
  "homepage": "Homepage",
  "hours": "Hours",
  "hoursAndInfo": "Hours & Info",
  "hoursOfOperationFallbackMessage": "Closed",
  "howItWorks": "How It Works",
  "howToRedeem": "How to Redeem",
  "howWouldYouLikeToReceiveThisOrder": "How would you like to receive this order?",
  "iAmHereButtonText": "I am here",
  "ifYouArePayingInRestaurant": "if you are paying in-restaurant",
  "iHaveArrived": "I Have Arrived",
  "image": "image",
  "imClose": "I'm Close",
  "imCraving": "I'm craving",
  "imHere": "I'm Here",
  "incentiveFeedbackCartRequirement": "Add the required items to your cart in order to redeem",
  "incentiveFeedbackDefault": "Your cart does not meet the requirements to redeem this {incentiveType}",
  "incentiveFeedbackExpired": "This offer expired on {endDate}",
  "incentiveFeedbackInvalidDay": "This offer is only valid on {targetValue}",
  "incentiveFeedbackInvalidStore": "This {incentiveType} is not available at your selected restaurant",
  "incentiveFeedbackInvalidTime": "This {incentiveType} is only valid between {startTime} and {endTime} check back at {startTime} to redeem",
  "incentiveFeedbackMaxRedemptions": "You have surpassed your allotted redemptions for this {incentiveType}",
  "incentiveFeedbackNotAvailableInStore": "Not available at this location",
  "incentiveFeedbackOfferNotAvailable": "Sorry, your offer is no longer available",
  "incentiveFeedbackOutOfDayPart": "Currently not available. Please check back later!",
  "incentiveFeedbackOutOfDayPartTime": "Available starting at {time}",
  "incentiveFeedbackPaymentMethod": "You must pay with {targetValue} in order to redeem this {incentiveType}",
  "incentiveFeedbackServiceMode": "This {incentiveType} is only available for {targetValue}",
  "incentiveFeedbackSubtotal": "Your subtotal must be ${targetValue} to redeem this {incentiveType}. Add ${calculatedValue} to redeem",
  "incentiveFeedbackWithinCooldown": "You have recently used an offer. You will be able to use another one in {calculatedValue} minutes.",
  "incidentInvolvingYourSafety": "Incident involving your safety.",
  "included": "Included",
  "includeRewardItem": "Include the reward item when placing your order. Scan before paying to use your points.",
  "incomplete": "Incomplete",
  "incrementName": "Increment {name}",
  "incrementRollText": "{rollsToShare} RRROLL{rollsToShare, plural, one {} other {S}}",
  "info": "Info",
  "information": "Information",
  "initializationNetworkRedirectNote": "<Bold>Please note:</Bold> Changes to your rewards tier and points may not be reflected right away.",
  "initializationNetworkRedirectOffline": "We are currently experiencing network issues. You are currently in offline mode. <Bold>You will only be earning points when you Scan.</Bold>",
  "inRestaurantOrder": "Order In-Restaurant ",
  "inRestaurantRedemptionMessage": "Scan or provide your phone number at the register to have your reward applied to your order.",
  "inStoreAmenities": "In-Store Amenities",
  "inStoreRedemptionErrorHeader": "Failed to redeem",
  "inStoreRedemptionErrorBody": "We apologize for the inconvenience. An error occurred while we were trying to redeem your points.",
  "inStoreRewardsStepOne": "Order desired item with the cashier",
  "inStoreRewardsStepTwo": "Scan or give your phone number:",
  "inStoreRewardsStepThree": "Reward will be applied ✅",
  "inStoreRewardsAltOption": "Use your loyalty points to redeem rewards.",
  "inStoreRewardsAddedHeader": "Identification QR Code",
  "inStoreRewardsAddedMessage": "You have a selected reward. Scan or give your phone number to the cashier to redeem:",
  "inStoreTitle": "In Store Code",
  "instructions": "Instructions",
  "insufficientBalanceReloadAgain": "Insufficient prepaid balance, please reload again to checkout.",
  "internalError": "Internal Error",
  "international": "International",
  "invalidAddressLine2": "Name is missing. Please enter your name.",
  "invalidCardNumber": "Invalid Card Number",
  "invalidCode": "Invalid Code!",
  "invalidDateMissingFields": "Invalid date: {missingFields} {count, plural, one {field is} other {fields are}} required",
  "invalidDateOfBirthProvided": "Invalid date of birth provided",
  "invalidEmail": "Invalid Email",
  "invalidEmailProvided": "Invalid email provided",
  "invalidLink": "Invalid Link",
  "invalidOfferError": "Your offer is invalid.",
  "invalidOffersRemoved": "Invalid offers have been removed.",
  "invalidOtpProvided": "The code you entered doesn't match the code we sent. Check your messages and try typing it in again.",
  "invalidPaymentCardTypeWithOffer": "Your selected payment method can not be applied to your current offer. Update your payment method to continue.",
  "invalidPaymentMethodError": "Your payment method is invalid. Please try a different payment method",
  "invalidQrCode": "Invalid QR code, please try another",
  "invalidReloadError": "We were unable to reload your card",
  "invalidTipAmountError": "Please submit a valid tip amount.",
  "invalidVerification": "Invalid Verification. Re-enter code below:",
  "inventory": "Inventory",
  "investorRelations": "Investor Relations",
  "investors": "Investors",
  "inWithLove": "in with Love",
  "isHalalTitle": "halal restaurant",
  "issuesDuringExperience": "Visit support for help with your current order.",
  "issueWithMyItems": "Issue with my item(s)",
  "item": "Item",
  "itemAddedToCart": "One {itemName} has been added to your cart.",
  "itemFavorited": "Item saved to favorites!",
  "itemFavoritedError": "Oops! There was an error with your favorite. Please try again later.",
  "itemFavoritedRemove": "Item removed from favorites!",
  "itemModifiers": "Item Modifiers",
  "itemNotAvailable": "Item not available",
  "items": "Items",
  "itemsInThisOrder": "Items in this Order",
  "itemsNeverArrived": "Item(s) Never Arrived",
  "itemsUnavailable": "Items Unavailable",
  "itemsWithNutrients": "I want items with",
  "itemTotal": "Item Total",
  "itsUsNotYou": "It's us, not you...",
  "january": "January",
  "joinNow": "Join Now",
  "july": "July",
  "june": "June",
  "keepCurbsideOrder": "No, Keep Order",
  "keepPaymentMethod": "Keep Payment Method",
  "keepPurchasingItemsToEarnRewards": "Keep purchasing items to earn rewards and redeem.",
  "keepPurchasngItemsToEarnRewards": "Keep purchasing items to earn rewards and redeem.",
  "kilometers": "km",
  "languageAndRegion": "Language and Region",
  "languageAndRegionSelector": "Language and region selector",
  "learnMore": "Learn More",
  "left": "Left",
  "legacyImportWelcomeMessageHeader": "Welcome! Bonjour!",
  "legacyImportWelcomeMessageText": "We've updated our app. Please follow the link below to sign back in",
  "legal": "Legal",
  "legalNotice": "Legal Notice",
  "lessOptions": "Less Options",
  "letsEat": "Let's eat",
  "letsLoadX": "Let's load {amount} to get you started.",
  "letTheCashierKnowYouPlacedAKioskOrderFor": "Let the cashier know you placed a kiosk order for",
  "letUsKnowYouOrderedForX": "Drive up to the ordering screen and let us know you ordered online for {name}",
  "limitedTimeOffer": "Limited time offer",
  "limitReached": "Limit Reached",
  "linkARewardsCardToYourAccountAndRedeem": "Link a Rewards card to your account and redeem all points stored in there.",
  "linkMyCard": "Link my card",
  "linkOpensInNewTab": "Link opens in new tab",
  "list": "List",
  "listbox": "listbox",
  "listOfWordsLayout": "{position, select, first {} last { and } other {, }}{word}",
  "listOfWordsLayoutOr": "{position, select, first {} last { or } other {, }}{word}",
  "loadCard": "Load Card",
  "loading": "One moment please!",
  "loadingMenu": "Loading Menu",
  "loadingRestaurantMenu": "One moment please! We are loading our menu.",
  "loadingRestaurantForMenu": "Please wait one sec while we validate your selected store is available",
  "loadingYourExperience": "Loading your personalized experience",
  "loadingNearbyLocations": "Loading nearby locations",
  "loadLoyaltyError": "There was an error loading your loyalty card.",
  "loadMore": "Load More",
  "loadNow": "Load Now",
  "loadWith": "Load with",
  "localeDateTime": "en-US",
  "location": "Location",
  "locationClosed": "Location closed. Mobile ordering is currently unavailable.",
  "locationDisclaimer": "We use your location to help you find nearby restaurant and provide you with more relevant marketing & offers.",
  "locations": "Locations",
  "locationSearch": "We are looking for restaurants.",
  "locatorChangeRegionDisclaimer": "Please note you will need to select your restaurant again. Changing regions may affect item availability.",
  "locatorChangeRegionHeading": "Selecting this location will change your region",
  "locatorChangeRegionPrimaryText": "You have {userRegion} set as your region, while this store is in {restaurantRegion}.",
  "locatorChangeRegionSelection": "Please select a new region to continue.",
  "locked": "Locked",
  "lockedOfferExpires": "Expires in {days} days, {hours} hours",
  "lockedOfferImage": "Locked offer image",
  "lockedPromoCodeOffer": "Use code to redeem",
  "logIn": "Log In",
  "logInDisclaimer": "Not your computer? Please make sure to log out before you leave",
  "logInToRedeem": "Log in to earn and redeem rewards",
  "logInToRedeemOnline": "Log in to Redeem Online",
  "logInWithExistingEmail": "An account with the given email already exists. Would you like to log in instead?",
  "logo": "BK logo",
  "logOut": "Log Out",
  "looksLikeThereWasAnIssue": "Looks like there was an error processing your request.",
  "looksLikeWereExperiencingIssues": "Looks like we are experiencing issues. Please try again!",
  "lookingForMoreOffers": "Looking for more offers?\n{link} for additional options.",
  "lookingForMore": "Looking For More {incentive}?",
  "lostNetworkConnectivity": "Looks like you have lost network connectivity",
  "loyaltyBannerDashboardButtonLabel": "Dashboard",
  "loyaltyBannerDetailsButtonLabel": "Details",
  "loyaltyBannerLoggedOutMessage": "Welcome! You can earn Crowns with every purchase and use them to redeem rewards.",
  "loyaltyCardAlreadyLinked": "This card has already been linked to an account.",
  "loyaltyCardImage": "Loyalty card image",
  "loyaltyCardMergeFailed": "We're sorry we couldn't link your card at this time. Please check if your card is already linked to another account. If not, check your connection and try again. Or, {link} for assistance.",
  "loyaltyCardValidationError": "This doesn't appear to be a valid card number. Please enter a card number that looks like ABCD-1234-EFGH.",
  "loyaltyCodeExpired": "Loyalty Code Expired",
  "loyaltyCodeExpiredDetails": "Tap the button below to generate a new one.",
  "loyaltyCreateAccountHeadline": "Create an Account to Get Started With",
  "loyaltyDetailsHeroImage": "Get More Of Your Favourite Things For Free",
  "loyaltyDropdownPoints": "{points} Points",
  "loyaltyEarnMorePointsForReward": "Earn {points} more Crown Points to your next reward.",
  "loyaltyHeaderGreeting": "Hi {firstName},",
  "loyaltyHeaderPointsMessage": "You have {points} points",
  "loyaltyHelp": "My Reward Level",
  "loyaltyLinkCardAlreadyUsed": "Card already used",
  "loyaltyLinkCardAlreadyUsedMessage": "This card has already been linked to an account. Please enter another card number.",
  "loyaltyLinkCardInvalidNumber": "This doesn’t appear to be a valid card number. Please enter a card number that looks like ABCD-1234-EFGH",
  "loyaltyLinkCardSuccessMessage": "You have added {points} Bonus Points to your Popeyes Rewards account. {br}These points may take few minutes to appear in your account.",
  "loyaltyLinkCardSuccessTitle": "Reward Points Added!",
  "loyaltyNotAvailableAtThisRestaurant": "Loyalty is not available at this restaurant",
  "loyaltyOfflineNoticeBlock": "You will <Bold>only be earning points</Bold> when you Scan. Changes to your rewards tier and points may not be updated.",
  "loyaltyPoints": "You have {points} Points",
  "loyaltyRewards": "Loyalty Rewards",
  "loyaltyTerms": "Loyalty Terms",
  "loyaltyUnavailableInRegionOops": "Oops!",
  "loyaltyUnlinkCardConfirmationAction": "Deactivate Card",
  "loyaltyUnlinkCardConfirmationMessage": "When deactivating, your card can no longer be used to earn points neither reactivate it.",
  "loyaltyUnlinkCardConfirmationTitle": "Are you sure you want to deactivate this card?",
  "loyaltyUseMyCrowns": "Use My Crowns",
  "loyaltyUseYourPoints": "Use your crown points today.",
  "lunch": "Lunch",
  "lupin": "lupin",
  "made": "Made",
  "main": "main",
  "mainMenu": "Main menu",
  "maintainYourPoints": "Make a purchase to maintain.",
  "makeAdditionalSelections": "Please make {quantity} selection{quantity, plural, one {} other {s}} in the \"{name}\" section to add this item to your cart.",
  "makeATrueDifference": "Make a true difference",
  "makeThisChange": "Make this change?",
  "map": "Map",
  "march": "March",
  "marketingTile": "Marketing Tile",
  "maxAttemptsReached": "You've reached the maximum login attempts we allow.",
  "maxAttemptsReachedPhoneVerify": "You've reached the maximum attempts we allow to verify your phone.",
  "maxAttemptsReachedRequestNewCode": "You've reached the maximum login attempts we allow, please request a new code below.",
  "maximumPriceError": "Your cart exceeds the maximum price for a mobile order.",
  "may": "May",
  "mayBeCookedOnSameEquipmentAs": "May be cooked on same equipment as {allergens}",
  "maybeLater": "Maybe Later",
  "mayContainAllergens": "May contain: {allergens}",
  "mayContainAllergensDisclaimer": "May contain {allergens}",
  "mayContainAllergensViaSharedEquipment": "May contain via shared equipment: {allergens}",
  "media": "Media",
  "menu": "Menu",
  "menuCheckoutButtonCta": "{count, plural, =0 {Your cart is empty} one {Review Order (# item)} other {Review Order (# items)}}",
  "menuCategoryLinks": "menu-category-links",
  "menuModifierAddOption": "Add {modifierName}",
  "menuModifierRemoveOption": "Remove {modifierName}",
  "menuTileUnavailableBody": "There are no items available at this time. Please check back later.",
  "menuTileUnavailableButtonText": "Back To Menu",
  "menuTileUnavailableHeading": "Sorry No Items Found",
  "message": "Message",
  "messageAllowLocation": "Please go to settings and allow location",
  "meters": "m",
  "method": "Method: {serviceMode}",
  "miles": "miles",
  "milk": "milk",
  "min": "min",
  "minimumPointsRequired": "Minimum points required 50",
  "minimumPriceError": "Your cart does not meet the minimum price required.",
  "minimumPriceForCateringError": "Your cart does not meet the minimum price for a catering order.",
  "minOrderMessage": "To redeem your offer, your cart subtotal may not be enough to cover the applied offer.",
  "minutes": "minutes",
  "missingItems": "Wait! You're missing items",
  "mobile": "Mobile",
  "mobileOrder": "Mobile Order",
  "mobileOrdering": "Mobile Ordering",
  "mobileOrderingAndPaymentTerms": "Mobile Ordering and Payment Terms",
  "mobileOrderingComingSoon": "Mobile Ordering Coming Soon!",
  "mobileOrderingUnavailable": "Mobile ordering is currently unavailable",
  "mobileOrderingUnavailableAtStore": "Mobile ordering is not available at this store",
  "modalHeading": "modal-heading",
  "mode": "Mode",
  "modTakeoutInstructionsText": "Let us know when you've arrived and we will bring your order to you. Otherwise, we will prepare your order at {time}",
  "modTakeoutInstructionsTextHere": "We are preparing your order. Please knock on the door to let us know you've arrived. A team member will bring your food out shortly after.",
  "monday": "Monday",
  "month": "Month",
  "monthPlaceholder": "MM",
  "monthYearShort": "MM/YY",
  "more": "More",
  "moreInfo": "More info",
  "moreItems": "{remainingItems, plural, =0 {} other {{remainingItems}}} {remainingItems, plural, =0 {} one {more item…} other {more items…}}",
  "moreLeft": "more{linebreak}left",
  "moreOptions": "More Options",
  "moreOrdersToGetYouAFreeItem": "more orders to get a free item!",
  "morning": "Morning",
  "mustard": "mustard",
  "mustBeLoggedInToRedeemCoupon": "Sorry, you must be logged in {br} to redeem this coupon.",
  "myAccount": "My Account",
  "myBalance": "My Balance",
  "myCateringOrder": "My Catering Order",
  "myChallenges": "My Challenges",
  "myCode": "My Code",
  "myOrder": "My Order",
  "myPoints": "My Points:",
  "myRewards": "My Rewards",
  "name": "Name",
  "nameOfTheDay": "Name of the Day",
  "nameOnCard": "Name on card",
  "nameOnCardIsRequired": "Name on card is required",
  "nameRequiredError": "Name is a required field.",
  "nameYourFavorite": "Name Your Favorite",
  "nativePaymentNotAvailable": "We currently don’t accept Google or Apple Pay on our websites. Please select or add a new payment method.",
  "navLinkReorder": "Reorder",
  "nearby": "Nearby",
  "nearMe": "Near me",
  "needHelp": "Need Help?",
  "needMoreHelpSendUsAMessage": "Need more help? Send us a message",
  "negativeCartError": "Your cart does not meet the minimum price required. Please add items to your cart to redeem this offer and continue.",
  "newCodeSent": "New code has been sent to your email!",
  "newCodeSentPhone": "New code has been sent to your phone number!",
  "newExclame": "New!",
  "newOffer": "New Offer",
  "newsAndPress": "News & Press",
  "newUpdateRequired": "New update required in order to continue",
  "next": "Next",
  "nextPage": "Next page",
  "no": "No",
  "noAppDriving": "Please don't use the app while driving",
  "noAppDrving": "Please don't use the app while driving",
  "noChangesToOrderTime": "No changes to the order time can be made at this point.",
  "noCourierName": "No Name",
  "noCourierPhone": "Not Available",
  "noCurrentRewardsAvailable": "No current rewards available.",
  "noDeliveryOptionsAvailable": "Sorry, no delivery options are currently available.",
  "noDirectPayAndNoPrepaidMethodError": "We are unable to accept your payment at this time. Please try again later.",
  "noFavoritesHeader": "Favorites",
  "noFavoritesSubheader": "Favorite an item from your cart or recents to save it as a favorite.",
  "noItemsFitCriteria": "No items fit your search criteria",
  "noLocationsMatchYourSearch": "There are no locations that match your search.",
  "nonAvailableItemInStore": "This item is currently not available at your selected store. This may be due to the store being closed; please try again once the store is open.",
  "nonAvailableOfferUntil": "This coupon is not available until {time}. Please check back later!",
  "nonAvailableReward": "This reward is not available for redemption.",
  "nonAvailableRewardUntilX": "This reward is not available until {time}. Please check back later!",
  "noneFound": "None Found",
  "noNewOffers": "No new offers",
  "nonRedeemableOffer": "Uh oh, offer cannot be applied",
  "noOrdersItemsYet": "RECENT ORDERS & ITEMS WILL APPEAR HERE",
  "noOrderYet": "RECENT ORDERS & ITEMS WILL APPEAR HERE",
  "noOffersAvailable": "No Offers Available",
  "noOffersAvailableMessage": "We currently don’t have any offers available. Please try again later, or enter a promo code if you have one.",
  "noPaymentMethodOnFile": "You currently have no payment methods on file",
  "noPaymentMethodSaved": "You currently have no stored payment methods.",
  "noPickupOptionsAvailable": "Sorry, no pickup options are currently available.",
  "noPointExpiry": "You haven’t earned any points yet, but don’t fret, once you do here’s where you’ll see when they expire.",
  "noRecentItemsHeader": "No Recent Items Yet",
  "noRecentItemsSubheader": "Place an order to see your recent items here.",
  "noRefundsForWrongLocaton": "No refunds for orders to the wrong location",
  "noRestaurantDeliveryCta": "Find Restaurants",
  "noSavedPromotions": "You have no saved promotions",
  "noStoresAreOpenForDelivery": "No Stores are Open for Delivery",
  "notApplicable": "N/A",
  "notAvailableAtStore": "Not available at selected store",
  "notEnoughPoints": "You do not have enough points",
  "noThanks": "No thanks",
  "noThankYou": "No thank you",
  "nothingInCart": "You dont have anything in your cart yet",
  "notifications": "Notifications",
  "notifyDescription": "Adjust the types of communication you receive from us, and how:",
  "notValidEmailError": "That doesn't look like a valid email.",
  "notValidEmailOrPhoneNumberError": "That doesn't look like a valid email or phone number",
  "notValidNameError": "That doesn't look like a valid name.",
  "notValidPhoneNumberError": "That doesn't look like a valid phone number",
  "notValidPhoneNumberPatternError": "Not a valid phone number format ex: 888-888-8888",
  "notValidRewardsErrorBody": "The barcode you scanned doesn't look like a Rewards card. Try a different barcode?",
  "notValidRewardsErrorHeading": "That doesn't look like a Rewards Card.",
  "november": "November",
  "now": "Now",
  "numberOfCrowns": "{crowns} crown{crowns, plural, one {} other {s}}",
  "nutritionalInformation": "Nutritional Information",
  "nutritionInfoDownloadPDF": "Download Nutrition Info",
  "october": "October",
  "of": "of",
  "offer": "Offer",
  "offerActivationError": "Error activating offer. Please try again.",
  "offerActivationSuccess": "Offer Activated!",
  "offerAdded": "Offer added",
  "offerAddedToCart": "Offer added to cart",
  "offerApplied": "Offer Applied",
  "offerAppliedSuccess": "Offer has been removed from your order",
  "offerConfirmDialogBody": "Closing this screen will mark this coupon as redeemed. Are you sure?",
  "offerConfirmDialogConfirmLabel": "Continue",
  "offerConfirmDialogHeading": "All Finished?",
  "offerDetails": "Offer Details",
  "offerExpiresDays": "{days} days left",
  "offerExpiresHours": "{hours} hours left",
  "offerFeedbackDefault": "Your cart does not meet the requirements to redeem this {incentiveType}",
  "offerFeedbackSubtotal": "Your subtotal must be {targetValue} to redeem this {incentiveType}. Add ${calculatedValue} to redeem",
  "offerInvalid": "Offer Invalid",
  "offerNotApplied": "Offer not applied",
  "offerNotAvailable": "Offer not available",
  "offerNotAvailableForMobileRedemption": "Online redemption is not available for this offer",
  "offerNotAvailableInRestaurantPos": "An item in your offer is not available at this restaurant.",
  "offerPicture": "Offer Picture",
  "offerRedeemableAgainIn": "You can redeem this offer again {redeemableIn}.",
  "offerRedeemableIn": "You can redeem this offer {redeemableIn}.",
  "offerRedeemableOn": "You can redeem this offer on {redeemableOn}.",
  "offerRequirements": "The offers in your cart have requirements that have not been met yet. Click \"Try Again\" to go back to the menu and add the required items to your cart.",
  "offers": "Offers",
  "offersAddedToCart": "Offers Added to Cart",
  "offersAreCurrentlyNotAvailable": "Your offers are on their way.",
  "offersCooldownDescription": "You just redeemed an offer and it will become available again in {minutes} minutes.",
  "offersCooldownInfo": "Limit one offer per visit. All other offers become unavailable for {period} minutes.",
  "offersCooldownTitle": "You just redeemed an offer",
  "offersDisclaimer": "One per customer. No substitutions. Non valid on delivery or with other coupons or offers. Available at participating US Popeyes® locations (except AK & HI). Void where prohibited or if reproduced, altered or transferred. Tax may apply. Cash value at 1/100¢. TM & © 2021 PLKI. All rights Reserved. Expires {deactivationDate}",
  "offersWarningOnCart": "Your items have been added to cart. Please visit our {offersPageLink} page to view our current offers.",
  "offerSelected": "Offer Selected",
  "offersEmptyStateText": "There's currently no offers available. Check back later for offers and updates.",
  "offersLoadingErrorMessage": "We ran into an issue loading the content of this page. Please try again.",
  "offersNotAvailableOnCatering": "We don't have offers available for catering at this time.",
  "offersNotAvailableOnDelivery": "We have a number of Delivery offers getting ready just for you! Check back soon for updates",
  "offersNotAvailableOnDeliveryChangeOrderType": "Change your order to pickup to see available offers.",
  "offersNotAvailableOnDeliveryChangeServiceMode": "Your cart includes an offer that is not currently available for delivery orders. If you’d like to place a delivery order press continue and we’ll remove the offer from your cart.",
  "offersNotAvailableOnDeliveryChangeServiceModeContinue": "Continue to delivery",
  "offerTerms": "Offer Terms",
  "offerUnavailableForDelivery": "This offer is unavailable for Delivery",
  "offerUnavailableForPickup": "This offer is unavailable for Pickup",
  "offerUpsellDiscard": "No, Thanks",
  "offerUpsellTitle": "Hungry For More?",
  "okay": "Okay",
  "okayButtonText": "Okay",
  "oneCouponPerOrder": "Only one coupon per order",
  "oneOfferPerOrder": "Only one offer per order",
  "oneOffer": "one offer",
  "oneReward": "one reward",
  "oneRewardPerOrder": "Only one reward per order",
  "onlineSoon": "Online Soon",
  "only": "only",
  "open": "Open",
  "open24Hours": "Open 24 hours",
  "openStoreDiagnosticModal": "Open a diagnostic modal for this store",
  "opportunities": "Opportunities",
  "option": "option",
  "optional100PercentGoesToDriver": "optional, 100% goes to driver",
  "optionalInformation": "Optional Information",
  "optionsLoaded": "Options have loaded.",
  "or": "or",
  "orAddNew": "or Add New",
  "order": "Order",
  "orderAccepted": "Order Accepted!",
  "orderAtThisStore": "Order at {storeLabel} store",
  "orderCannotBeRefundedMessage": "Seems like there was an error issuing your refund. Please {link} to report your issue. If you have already requested a refund, please allow 3-5 business days for the amount to reflect in your account.",
  "orderConfirmation": "Order Confirmation",
  "orderConfirmedHeading": "Order Confirmed",
  "orderDate": "Order Date",
  "orderDeclined": "Order Declined!",
  "orderDeliveryMinimumHeading": "Order Under Minimum",
  "orderDetailsHeading": "Order Details",
  "orderFailureMessage": "We encountered an error creating your order. We are aware of the issue and are working to resolve it. Please try again or contact {link}",
  "orderFailureMessageForApplePay": "Your payment with Apple Pay failed. Your card will not be charged and any pending transaction are typically released in 24-48 hours.",
  "orderFailureMessageForGooglePay": "Your payment with Google Pay failed. Your card will not be charged and any pending transaction are typically released in 24-48 hours.",
  "orderHasBeenRefundedMessage": "Your refund is being processed. You will receive an email once your refund has been completed.",
  "orderHere": "Order Here",
  "orderHistory": "Order History",
  "orderingFrom": "Ordering from",
  "orderingIssues": "Ordering Issues?",
  "orderingOptions": "Ordering Options",
  "orderInRestaurant": "Order In-Restaurant",
  "orderLimitHeading": "Order Limit Reached",
  "orderLimitMessage": "Looks like you are going to exceed our {maxLimit} order amount limit. Remove an item or 2 if you'd like to add this to your order.",
  "orderLimitWarningX": "Order can not exceed {limit}",
  "orderMinimumMessage": "Looks like you are under the {minLimit} order minimum. Add more items if you'd like to complete your order.",
  "orderMinimumWarningX": "Order must be over {min}",
  "orderMissingItemscontent": "Please click back and select \"Order Never Arrived\" if your order is completely missing.",
  "orderMissingItemsheading": "Issue with my item(s)",
  "orderMissingItemssubheading": "Confirm all the following items are missing.",
  "orderName": "Order Name",
  "orderNeverArrived": "Order Never Arrived",
  "orderNeverArrivedcontent": "Please click back and select \"Issue with my item(s)\" if your order is only partially missing.",
  "orderNeverArrivedheading": "Order Never Arrived",
  "orderNeverArrivedsubheading": "Confirm all the following items are missing.",
  "orderNotCompleted": "Your order could not be completed",
  "orderNow": "Order Now",
  "orderNowWithRewards": "Order now with rewards",
  "orderNumber": "Order number",
  "orderPayment": "Order Payment",
  "orderPickup": "Order Pickup",
  "orderPlaced": "Order Placed",
  "orderPlacedAt": "Order placed at",
  "orderPlacedBy": "Order placed by",
  "orderProcessingDisclaimer": "We won't start preparing your order and your card will not be charged until you press \"I'm Here\" on the next screen.",
  "orderProcessingDisclaimerCashPayment": "We won't start preparing your order until you press \"I'm Here\" on the next screen.",
  "orderProcessingDisclaimerImClose": "We won't start preparing your order and your card will not be charged until you press \"I'm Close\" on the next screen.",
  "orderProcessingDisclaimerImCloseCashPayment": "We won't start preparing your order until you press \"I'm Close\" on the next screen.",
  "orderRedemptionOrderType": "Offer Redemption: Select Order Type",
  "orderScheduledForDineIn": "Order {orderNumber} {linebreak}Has Been Scheduled for Dine In",
  "orderScheduledForPickup": "Order {orderNumber} {linebreak}Has Been Scheduled For Pick Up",
  "orderScheduledForRapidRescuePickUpLane": "Order Has Been Scheduled For Rapid Rescue Pick Up Lane",
  "orderScheduledForPickupMessage": "We have scheduled your order and it will be placed at the front counter when ready.",
  "ordersMustBePlaced24HrsAhead": "!Orders must be placed 24 hrs ahead",
  "ordersMustBePlacedHrsAhead": "Orders must be placed {hrs} hrs ahead!",
  "orderTime": "Order time",
  "orderConfirmationCheckoutInstruction": "Look for the Rapid Rescue signage in the restaurant to pick up your order.",
  "orderConfirmationCheckoutInstructionDineIn": "Thank you for your order!\n",
  "orderConfirmationCheckoutInstructionDineInSubtitle": "Come on in and let a team member know that you have arrived, and they will assist with your order.",
  "orderWillBeReadyName": "Your order will be ready soon, {name}",
  "orderXWillBeReadyAtY": "Order {orderNumber} {linebreak}Will Be Ready At {readyAtTime}",
  "orderXWillBeReadySoon": "Order {orderNumber} {linebreak}Will Be Ready Soon",
  "otaDownloadAndRestart": "Download and restart",
  "otaDownloading": "Downloading…",
  "otaNotNow": "Not now",
  "otaUpdateAvailable": "Update available",
  "otaUpdateAvailableDescription": "There is an important update available. Would you like to download and apply the update now?",
  "otaUpdateRequired": "Update required",
  "otaUpdateRequiredDescription": "There is a crucial update that must be applied to ensure this application continues to work properly.",
  "other": "Other",
  "otherDiscounts": "Other Discounts",
  "ourMenu": "Our Menu",
  "ourOffers": "Our Offers",
  "ourRestaurantsNearYou": "Our restaurants near you",
  "outdatedCartVersionMessage": "Hey, good news! We updated our experience. Sadly, any old items in your cart had to be cleared.",
  "outOfDayparts": "{dayParts} only",
  "outOfRangeHeading": "You're out of range",
  "outOfRangeMessage": "Unfortunately we cannot currently deliver to this address. Enter your email below to receive updates on availability in your area.",
  "outOfRangeMessageAuthenticated": "Unfortunately we cannot currently deliver to this address. Would you like to see wings availability at Popeyes?",
  "outOfRangeSuccessMessage": "Thank you for submitting your email. Would you like to see wings available at Popeyes?",
  "pageChangedReaderCarousel": "Displaying items {firstItemSlide} to {lastItemSlide} of {totalCards}",
  "pageMoved": "The page you were looking for was moved or doesn't exist",
  "pageNotFound": "Page Not found",
  "pageNotFoundDescription": "The page you are looking for might have been moved, deleted, or possibly never existed.",
  "payEarnReward": "Pay, Earn & Redeem",
  "paymentAddingError": "Credit card details may be incorrect or we are experiencing issues with your card, please try again or check with your bank.",
  "paymentDeclined": "We won’t be able to complete this transaction. Check with your card provider for details.",
  "paymentDeclinedDisclaimer": "Your order remains in the cart, you can go back to try again",
  "paymentDeclinedHeading": "Oops. Looks like something went wrong.",
  "paymentDeletingError": "There was an error deleting your payment method.",
  "paymentDetails": "Payment Details",
  "paymentDetailsError": "Check your card details and try again.",
  "paymentDetailsErrorHeading": "Oops. Card details are incorrect.",
  "paymentFailed": "Your payment failed to go through.",
  "paymentLoadingError": "There was an error loading your payment method.",
  "paymentMethod": "Payment Method",
  "paymentMethodAdded": "A new payment method was added.",
  "paymentMethodNotAvailable": "Not available",
  "paymentMethods": "Payment Methods",
  "paymentNotSupported": "Please try selecting another payment method.",
  "paymentNotSupportedHeading": "Oops. This restaurant is currently unable to accept your payment method.",
  "paymentProcessingError": "There was a problem processing your payment. Try again?",
  "paymentVerification": "Payment Verification",
  "paymentXEndingInY": "Payment: {cardType} ending in {ccLast4}",
  "payWith": "Pay with",
  "payWithCash": "Pay with cash",
  "payWithMyPrepaidCard": "Pay with my {cardAlias} Card",
  "payWithPayPal": "Pay with PayPal",
  "payWithYour": "Pay with your",
  "peanuts": "peanuts",
  "people": "People",
  "perks": "Perks",
  "perksReadyToUse": "Your Perks are Ready to Use",
  "perOneHundredGOrMl": "Per 100g or 100ml",
  "perServing": "Per serving",
  "personalized": "Personalized",
  "personalMessageToRecipient": "Personal message to the recipient.",
  "phoneAppStoresDisclaimers": "Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries. App Store is a service mark of Apple Inc. Google Play is a trademark of Google Inc. Terms apply.",
  "phoneNumber": "Phone Number",
  "phoneNumberDisclaimer": "The phone number on file will be used by drivers to get in touch with you. By continuing, you agree to share your contact information with the DoorDash or Postmates drivers that will deliver your food.",
  "phoneNumberError": "Please enter a valid phone number.",
  "phoneNumberPlaceholder": "+13050000000",
  "phoneNumberRequired": "Phone Number (Required)",
  "phoneNumberRequiredError": "Phone number is a required field.",
  "phoneNumberTooLongError": "Phone number inserted is too long.",
  "phoneNumberTooShortError": "Phone number inserted is too short.",
  "phoneNumberUpdatedSuccess": "Your phone number has been updated!",
  "phoneNumberWillBeSaved": "Phone number will be saved to your account.",
  "pickUp": "Pick Up",
  "pickUpAt": "Pick up at",
  "pickUpAtSelectALocation": "Pick up at: select a location",
  "pickUpBefore": "<pickup>Pick up</pickup> before {pickupTime}",
  "pickUpBeforeDayPart": "<pickup>Pick up</pickup> before {dayPartEndTime}",
  "pickupComingSoon": "Pickup COMING SOON",
  "pickUpConfirmationScreenReaderInstructions": "Your order has been saved! Before arriving at the store, please press the \"{screenReaderButtonName}\" button on this page so that our team members know when to start preparing your order.",
  "pickupDateTime": "{date}{linebreak}{time}",
  "pickupDetailsBackButton": "Back",
  "pickupDetailsHeading": "When will you pick up your order?",
  "pickupDetailsNextButton": "Next",
  "pickupDetailsSubheading": "Please order 72 hours in advance. If your order will be $1500 or more, please contact the restaurant directly.",
  "pickupInstructions": "Come inside the restaurant or drive into our drive thru and let us know you placed a mobile order for {name}.",
  "pickupModeContentHeading": "Order Now",
  "pickupModeCurbsideContent": "Swing by and we’ll bring your order to your vehicle.",
  "pickupModeCurbsideHeading": "Curbside",
  "pickupModeDineInContent": "Dine-in and enjoy your order in the restaurant",
  "pickupModeDineInHeading": "Dine-In",
  "pickupModeDriveThruContent": "Drive-thru and let us know you have a mobile order.",
  "pickupModeDriveThruHeading": "Drive-Thru",
  "pickupModeTakeoutContent": "Pick up and take your order to go.",
  "pickupModeTakeoutHeading": "Take-Out",
  "pickUpOnly": "Pick up only",
  "pickUpOrder": "Pick Up Order",
  "pickUpOrderAt": "I'll pick up my order at...",
  "pickUpOrderIn": "We'll start preparing your order in...",
  "pickUpOrderRestaurantClosing": "Store is not accepting digital orders at this time",
  "pickUpOrderSuccessfulAlert": "Your order has been successful, enjoy your order. Your order number is {orderNumber} in the {brand} at {address}.",
  "pickUpTime": "Pick up time",
  "pickupTime": "Pickup Time",
  "pickUpTimeAlt": "Pick Up Time",
  "pickupTimeChanged": "Pickup Time Changed",
  "pickupTimeChangedMessage": "Your available pickup times changed based on your order updates. Please review and confirm.",
  "pickUpUnavailable": "Pick Up is currently unavailable",
  "pickupUnavailable": "Pickup - Unavailable",
  "pickupWindow": "Rapid Rescue Lane",
  "pickupWindowHours": "Rapid Rescue Lane Hours",
  "pickupWindowInstructionsText": "Enter the drive-through lane at the restaurant to pick up your order!",
  "pin": "PIN",
  "placeOrder": "Place Order",
  "placeOrderDescription": "Place {serviceMode} order for {price}",
  "placeSecureOrder": "{secureOrderIcon} Place Secure Order",
  "placingOrder": "We are sending you order to the store.",
  "platformOnly": "{platform} only",
  "playNow": "Play Now",
  "pleaseCheckBackNextWeek": "Sit tight and check back again soon for more delicious deals.",
  "pleaseConfirmYourCreditCardInformation": "Please confirm your credit card details",
  "pleaseEnterYourCreditCardInformation": "Please enter your credit card information",
  "pleaseGoToTheFrontCounterToScanRewards": "Please go to the front counter to scan rewards",
  "pleaseHaveYourCashReady": "Please have your cash ready",
  "pleaseHeadToTheCounterToPayForYourOrder": "Please head to the counter to pay for your order",
  "pleaseMakeSelectionInXHours": "Please make your selection within {hour} hours.",
  "pleaseOrder24InAdvance": "Please order 24 hours in advance",
  "pleasePayForYourOrderUsingTheTerminal": "Please pay for your order using the terminal",
  "pleaseProvideYourPhoneNumberForTheOrder": "Please provide your phone number for the order",
  "pleaseSelect": "Please select",
  "pleaseSelectAPaymentOption": "Please select a payment option",
  "pleaseSelectOne": "Please select one",
  "pleaseTryAgainLater": "Please try again later.",
  "pleaseTryToRefresh": "Please try to refresh.",
  "pleaseTurnOnYourLocation": "Please Turn on Your Location Settings",
  "pleaseVerifyEmail": "Please verify your email.",
  "pleaseVerifyYourPhoneNumber": "Please verify your phone number",
  "plk_logo_title": "Popeyes Logo",
  "plk.pypestreamLoadingMessage": "Your support experience is marinating.",
  "plkLogoTitle": "Popeyes",
  "plkPointsApplied": "Applied",
  "plkPointsEarned": "{points} Point{points, plural, one {} other {s}} Earned",
  "plkRewards": "Popeyes Rewards",
  "pluralDays": "Day{days, plural, one {} other {s}}",
  "pluralHours": "Hour{hours, plural, one {} other {s}}",
  "pluralMins": "Min{minutes, plural, one {} other {s}}",
  "points": "Points",
  "pointsApplied": "Points Applied",
  "pointsEarned": "{pointsEarned, plural, one {You've earned {pointsEarned} point!} other {You've earned {pointsEarned} points!}}",
  "pointsEarnedLabel": "Point{points, plural, one {} other {s}} Earned",
  "pointsExpired": "Point{points, plural, one {} other {s}} Expired",
  "pointsExpireMonthly": "Points expire at the end of each month.",
  "pointsNumber": "{points} pts",
  "pointsUsed": "Point{points, plural, one {} other {s}} Used",
  "policies": "Policies",
  "popeyesCatering": "Popeyes Catering",
  "popeyesFoundation": "The Popeyes Foundation",
  "popeyesRewards": "Popeyes Rewards",
  "postalCode": "Postal Code",
  "postalCodeInvalid": "Postal Code Invalid",
  "postalCodeRequiredError": "Postal Code Required",
  "poweredByThirdPartyDeliveryProviders": "Powered by Third-Party Delivery Providers",
  "preferences": "Preferences",
  "premiumOptions": "Premium options",
  "preorder": "Pre-order: {serviceMode}",
  "prepaidCardBalance": "{cardAlias} Card Balance",
  "preparedOnEquipment": "Prepared on equipment used for products that contain {preparedOnEquipmentAllergens}.",
  "preparedToOrder": "Prepared to Order",
  "prepareIn10MinLabel": "Start preparing my order in ten minutes",
  "prepareIn15MinLabel": "Start preparing my order in fifteen minutes",
  "prepareIn20MinLabel": "Start preparing my order in twenty minutes",
  "prepareIn30MinLabel": "Start preparing my order in thirty minutes",
  "prepareIn45MinLabel": "Start preparing my order in forty five minutes",
  "prepareIn5MinLabel": "Start preparing my order in five minutes",
  "prepareIn60MinLabel": "Start preparing my order in 1 hour",
  "prepareInMinLabel": "Start preparing my order in {minutes} minutes",
  "prepareNow": "Prepare Now",
  "prepareNowLabel": "Start preparing my order now",
  "prepDeliveryTime": "Prep & Scheduled Time",
  "prepPickUpTime": "Prep & Pick Up Time",
  "presentQRCodeToEarnPoints": "Present this QR code to the cashier before payment to earn points on your order.",
  "pressOkToEnableCameraPermissions": "Press Ok to enable camera permissions in app settings",
  "previous": "Previous",
  "previousPage": "Previous page",
  "price": "Price: {price}",
  "pricedAndAvailableError": "This restaurant is currently unavailable.",
  "pricesDiscountsAndAvailabilityAreSbujectToChangeWhenYouReorder": "Prices, discounts and availability are subject to change when you reorder.",
  "primaryNavLinks": "Primary Navigation",
  "privacy": "Privacy",
  "privacyPolicy": "Privacy Policy",
  "prizes": "Prizes",
  "problemsDetected": "Problems Detected",
  "proceedToLogin": "Then proceed to log in or register",
  "product": "Product",
  "productChallengedCompleted": "Challenge Completed",
  "productDetailSummaryCustomizableTitle": "Customize Your Order",
  "productDetailSummaryTitle": "Your Order",
  "profile": "Profile",
  "progressbar": "progressbar",
  "promoCodeAddedToOffers": "Promo Code added to Offers",
  "promoCodeApplied": "Promo code applied successfully! Check the {page} section on the home page",
  "promoCodeAppliedPage": "Rewards & Offers",
  "promoCodeBadgeLabel": "Promo Code",
  "promoCodeBodyDetails": "Please enter the 12 digit promo code below",
  "promoCodeCartRedeemedCode": "Promo code has already been used or is saved to your promo codes. Click on the link below to view saved promotions.",
  "promoCodeCTA": "Add Promo Code",
  "promoCodeLabel": "Enter promo code",
  "promoCodeOfferExpiredCode": "The code you entered has expired",
  "promoCodeOfferInvalidCode": "Invalid Promo Code Entered",
  "promoCodeOfferInvalidOfferId": "The code you entered is not valid for this offer",
  "promoCodeOfferRedeemedCode": "The code you entered has already been redeemed",
  "promoCodeOfferRequired": "Promo code required to unlock offer",
  "promoCodeOfferUnknownException": "Something went wrong. Please try again.",
  "promoCodeSubmitBtnText": "Submit Code",
  "promotionalOrderLimitExceeded": "Promotional Order Limit Exceeded",
  "promotionEnded": "The promotional period has ended",
  "proteins": "Proteins",
  "province": "Province",
  "pts": "{points}pts",
  "pullToRefresh": "Pull down further to refresh",
  "purchaseAdded": "This purchase was added to your visit count",
  "purchaseAmount": "Amount",
  "purchaseDidNotCountTowardsReward": "This purchase did not count toward your next reward. Please see {link} to learn more.",
  "purchaseNecessary": "Purchase Necessary",
  "purchaseNecessaryDescription": "The discount will be applied when you purchase a qualifying item.",
  "purchaseSevenTimes": "Purchase 7 times",
  "push": "Push",
  "putThisCodeUnderTheScanner": "Scan to earn points and use rewards",
  "pypestreamWelcomeAnon": "Hey there! I'm the {brand} digital assistant, here to help resolve any order issues and answer your questions.  One moment while we look you up.",
  "pypestreamWelcomeUser": "Hey {name}! I'm the {brand} digital assistant, here to help resolve any order issues and answer your questions. One moment while we pull up your order history.",
  "qrScannerUnavailable": "QR Scanner unavailable. Please try updating to the latest app version.",
  "qty": "Qty: ",
  "quantity": "Quantity",
  "quantityItemInYourCart": "{quantity} {itemName} in your cart",
  "quantityOf": "Quantity of {name}",
  "quantityUpdatedAlert": "You now have {quantity} {itemName} in your cart.",
  "question": "Question",
  "questionsAboutOrder": "Have any questions about your order?",
  "quickNote": "Quick note",
  "readLess": "Read Less",
  "readMore": "Read More",
  "receipt": "Receipt",
  "recent": "Recent",
  "recentHistory": "Recent History",
  "recentItems": "Recent Items",
  "recentItemsFailed": "Oops! Recent Items failed to load",
  "recentOrders": "Recent Orders",
  "recentOrdersDisclaimer": "Prices, discounts and availability are subject to change when you reorder.",
  "recentOrdersEmptySubHeader": "Go start a new order now!",
  "recents": "Recents",
  "recipientsEmail": "Recipient's Email",
  "recyclingFee": "Recycling Fee",
  "redeem": "Redeem",
  "redeemACoupon": "Redeem a coupon",
  "redeemApply": "Use {points}",
  "redeemAtRestaurant": "Use at restaurant",
  "redeemedRewards": "{redeemedRewards, plural, one {You've redeemed {redeemedRewards} reward.} other {You've redeemed {redeemedRewards} rewards.}}",
  "redeemInNext": "Redeem in the next",
  "redeemInRestaurant": "Redeem in Restaurant",
  "redeemInRestaurantShortCodeInstruction": "Give this code to the cashier when you order to get the offer and earn points",
  "redeemItem": "Redeem Item",
  "redeemMyPoints": "Redeem my Points",
  "redeemNow": "Redeem Now",
  "redeemOfferConfirmation": "To redeem your offer, your cart subtotal must be equal to or greater than offer value. If you choose to continue, we'll remove the offer from the order.",
  "redeemPointsDisclaimer": "Your Points will only be used to redeem selected items",
  "redeemPointsForRewards": "Redeem Points for Rewards",
  "redeemRemove": "Remove {points}",
  "redeemReward": "Redeem your reward",
  "redeemRewards": "Redeem Rewards",
  "redeemThisCoupon": "redeem this coupon",
  "redemptionCode": "Redemption code",
  "refresh": "Refresh",
  "refreshCodeTextExpired": "Refresh to generate a new one.",
  "refreshCodeTitleExpired": "Refresh this page to continue",
  "refreshOffers": "Refresh my offers",
  "refreshPlease": "Refresh",
  "refundClickHere": "click here",
  "refundEligibilityInfo": "Orders are eligible for partial or full refunds. Select from your orders below to submit for a refund.",
  "refundNetworkError": "Seems like there was an error issuing your refund. Please {link}, to report your issue. If you have already requested a refund, please allow 3-5 business days for the amount to reflect in your account.",
  "register": "Register",
  "registerCardDescription": "Enter the digits on the back of the card to protect your balance, continue earning and track your rewards. Your previous transactions and rewards will be added to your account.",
  "registerYourPhysicalCard": "Link Physical Rewards Card",
  "releaseToRefresh": "Release to refresh!",
  "reload": "Reload",
  "reloadAndContinue": "Reload {amount} and continue",
  "reloadAndPlaceOrder": "Reload {amount} and place order",
  "reloadCard": "Reload Card",
  "reloadCardError": "There was an error loading your Tim Card.",
  "reloadCardWithAmount": "Reload {amount}",
  "reloadCrownCardCardError": "There was an error loading your BK Crown Card.",
  "reloadExplanation.bk": "Your current BK Crown Card balance won't cover the cost of today's order. When you place your order we'll load {reloadAmount} on your Crown Card.",
  "reloadMethods": "Reload Methods",
  "reloadNow": "Reload now",
  "reloadYourBalance": "Reload your balance",
  "reloadYourCard": "Reload Your Card",
  "remaining": "{hoursAndMinutes} Remaining",
  "remainingTags": "{remainingTags, plural, one {filter} other {filters}}",
  "rememberToMakeAPaymentDelivery": "Remember to make a payment upon delivery of your order",
  "rememberToMakeAPaymentPickup": "Remember to make a payment upon picking up your order ",
  "remove": "Remove",
  "removeAppliedCartPromoCodeAriaLabel": "Click here to remove current promotion from cart",
  "removeCustomizations": "This will remove any customizations you had previously made to this {itemType}.",
  "removeFavorite": "Remove from Favorites?",
  "removeFavoriteConfirm": "Are you sure you want to remove this favorite?",
  "removeFavoriteOrderSucess": "Order removed from favorites.",
  "removeFavoriteSucess": "Your favorite has been removed.",
  "removeFromCart": "Remove From Cart",
  "removeItem": "Remove Item",
  "removeItemFavorites": "Remove This Item From Favorites?",
  "removeItemFavoritesConfirm": "Are you sure you want to remove this favorite?",
  "removeItemFromCart": "Are you sure you want to remove your {item} from your cart?{offerText}",
  "removeItemFromCartOfferText": "Please note, removing this item will also remove the attached offer.",
  "removeItems": "Remove Items",
  "removeOffer": "Remove Offer",
  "removeOne": "Remove one",
  "removeOneItemFromCart": "Remove one {itemName} from your cart.",
  "removeOneName": "Remove one {name}",
  "removePaymentMethod": "Remove payment method",
  "removeRestaurantFromFavorites": "Remove this restaurant from favorites",
  "removeReward": "Remove Reward",
  "removeUnavailableEntries": "Remove Unavailable Entries?",
  "removeUnavailableItems": "Remove unavailable items",
  "reorder": "Reorder",
  "reorderWithOrderDescription": "Reorder {orderDesc}",
  "reorderWithOrderNumber": "Reorder number {ticketNumber}",
  "replace": "Replace Existing One",
  "replaceAppliedReward": "Would you like to replace the reward already in your cart?",
  "replaceCoupon": "Would you like to replace the coupon already in your cart?",
  "replaceItem": "Replace Item",
  "replaceOffer": "You can only use {oneOffer} per order. There is already one of them currently in your cart.",
  "replaceReward": "You can only use {oneReward} per order. There is already one of them currently in your cart.",
  "replaceIncentive": "You can only use {oneOffer} or {oneReward} per order. There is already one of them currently in your cart.",
  "replaceRewardText": "You already have a reward selected. Are you sure you want to replace it?",
  "reportAnIssue": "Report An Issue",
  "reportIssue": "Report Issue",
  "reportOrderIssuePageTitle": "Report Order Issue",
  "requestMyInfo": "Request my info",
  "requestMyInformation": "Request my information",
  "requestNewLinkAuthEmail": "request a new link",
  "required": "Required",
  "requiredDate": "A valid date is required",
  "requiredField": "This is a required field",
  "resend": "Resend",
  "resendCode": "Resend Code",
  "reset": "Reset",
  "resetCustomizationsDescription": "Selecting this option will clear any customization applied to your order. Would you like to continue?",
  "resetCustomizationsHeader": "Reset Customizations",
  "resetLocationButtonAria": "Click here to reset the map to your current location.",
  "responsibility": "Responsibility",
  "restaurantDetails": "Restaurant Details",
  "restaurantOpensAt": "Opens at {hour}",
  "restaurantOpensTomorrowAt": "Opens tomorrow at {hour}",
  "restaurantOpensOnDayAt": "Opens on {day} at {hour}",
  "restaurantOrder": "Restaurant Order",
  "restaurantOrderComingSoon": "Restaurant Order Coming Soon!",
  "restaurantRedemption": "Already at a restaurant and ready to redeem? {clickHere}",
  "restaurantRedemptionClickHere": "Click here",
  "restaurants": "Restaurants",
  "restaurantUnavailableErrorMessage": "The restaurant you are trying to order from is currently unavailable.",
  "restaurantUnavailableErrorTitle": "Restaurant is Unavailable",
  "resultsLeft": "{count} results left",
  "retry": "Retry",
  "retryMessage": "We’re having trouble showing your progress on this offer. Please refresh or check back later.",
  "returnToMenu": "Return to menu",
  "returnToRewards": "Return To Rewards",
  "review": "Review",
  "reviewYourOrder": "Review your order",
  "reward": "Reward",
  "rewardAfterXPurchases": "Get rewarded after {pointsUntil} purchases",
  "rewardApplied": "Reward applied",
  "rewardInvalid": "Reward Invalid",
  "rewardCategory": "{categoryName} reward levels{selectedOptionText}",
  "rewardInfo2": "Purchases over $0.50 and at least 30 minutes apart will qualify.",
  "rewardInfo3": "You can bank up to 5 Rewards at a time.",
  "rewardInfo4": "When you redeem a Reward, the most expensive eligible item in your order will be FREE",
  "rewardRedeemed": "Reward Redeemed",
  "rewardRedemptionListEmptyState": "Add {offers} and {rewards} to your order",
  "rewards": "Rewards",
  "rewardsAndOffers": "Rewards & Offers",
  "rewardSavings": "Reward Savings",
  "rewardsHistory": "Rewards History",
  "rewardsInStore": "In-Store Rewards",
  "rewardsLevelChange": "Reward level updated.",
  "rewardsMainImage": "Rewards main image",
  "rewardsManagementChangeRewardLevel": "Change Reward Level",
  "rewardsManagementChoosenLevel": "Chosen Reward Level",
  "rewardsManagementPoints": "{points} Points",
  "rewardsPointsAmount": "Rewards, {points} Points",
  "rewardsPointsAvailable": "You have {points} available",
  "rewardsPointsBalanceIsNow": "Your Tims Rewards Points balance is now {remainingPoints}.",
  "rewardsPointsDetailsMessage": "You have {points} points",
  "rewardsPointsTitle": "Tims Rewards Points",
  "rewardsPointsWillExpireDate": "All your points will expire on {date} unless you make any purchase before that date",
  "rewardsPolicy": "Firehouse Rewards Terms",
  "rewardsRedeemed": "Rewards Redeemed",
  "rewardUnlocked": "Nice! Reward Unlocked",
  "rollsAddedToAccountText": "RRROLL{numberOfRollsShared, plural, one { has} other {S have}} been added to your account.",
  "rollsClaimedMessage": "You've just claimed {numberOfRollsShared, plural, one {a} other {{numberOfRollsShared}}} RRROLL{numberOfRollsShared, plural, one {} other {S}}!",
  "routes.accepted": "/accepted",
  "routes.account": "/account",
  "routes.accountDelete": "/account/delete",
  "routes.accountRequestInfo": "/account/request-information",
  "routes.bkRewards": "/bkrewards",
  "routes.catering": "/catering",
  "routes.claimPointsBase": "/claim-points",
  "routes.claimPointsProcessing": "/claim",
  "routes.claimPointsSurvey": "/survey",
  "routes.deleteMyAccount": "/delete-my-account",
  "routes.deliveryTerms": "/delivery-terms",
  "routes.exploreMenu": "/explore-menu",
  "routes.favoriteStoreSearch": "/favorite-store-search",
  "routes.iCampaign": "/icampaign",
  "routes.linkPhysicalCard": "/link-card",
  "routes.loyaltyBannerAuthenticated": "/rewards/details",
  "routes.loyaltyBannerUnauthenticated": "/rewards/details",
  "routes.loyaltyDashboard": "/rewards/details",
  "routes.loyaltyHome": "/rewards",
  "routes.loyaltyOfferList": "/rewards/offers",
  "routes.loyaltyRewardList": "/rewards/list",
  "routes.mobileOrderingAndPaymentTerms": "/mobile-ordering-and-payment-terms",
  "routes.popeyesRewards": "/popeyes-rewards",
  "routes.privacyPolicy": "/privacy-policy",
  "routes.redemptionInRestaurant": "/redeem",
  "routes.reusables": "/reusable",
  "routes.rewards": "/rewards",
  "routes.rewardsHistory": "/rewards/history",
  "routes.rewardsPolicy": "/rewards-policy",
  "routes.roadtrip": "/roadtrip",
  "routes.scan": "scan",
  "routes.sendGift": "/gift",
  "routes.signIn": "/signin",
  "routes.signUp": "/signup",
  "routes.storeLocator.address": "/store-locator/address",
  "routes.storeLocator.serviceModeCatering": "/store-locator/service-mode-catering",
  "routes.storeLocator": "/store-locator",
  "routes.termsConditionsRewards": "/terms-conditions-rewards",
  "routes.termsOfService": "/terms-of-service",
  "royalPerks": "Royal Perks",
  "ruleSetsCannotRedeemAgain": "You have reached the limit for redemptions on this offer. {redeemableInString}",
  "ruleSetsCannotRedeemNow": "You cannot redeem this offer now. {redeemableInString}",
  "ruleSetsCartPropertyViolation": "Your cart does not meet the requirements for this offer.",
  "ruleSetsDayOfWeekViolation": "You cannot redeem this offer today. {redeemableInString}",
  "ruleSetsFirstOrderOnlyViolation": "You can only redeem this offer on your first order.",
  "ruleSetsPurchaseViolation": "You do not have the the required items in your cart to redeem this offer.",
  "ruleSetsRequiresAssignmentViolation": "You are not eligible for this offer.",
  "rutrYouHave": "You have",
  "safteyIssue": "Safety Issue",
  "salt": "Salt",
  "saturatedFat": "Saturated Fat",
  "saturday": "Saturday",
  "save": "Save",
  "saveAddressDisclaimer": "Please save my phone number and delivery address for faster checkout the next time I place an order.",
  "saveAddressLabel": "Save My Information. {disclaimer}",
  "saveCardDisclaimer": "Save Card, by saving your card you consent for your card to be stored and used for purchases.",
  "saveChanges": "Save Changes",
  "savedAddresses": "Saved Addresses",
  "savedOrder": "Your order has been saved.",
  "savedOrderTapImClose": "Order saved. Tap \"I'm Close\" when you are 5 minutes away. Your card won't be charged until then.",
  "savedOrdertapImHere": "Your order has been saved. Tap \"I'm Here\" when you arrive so we can prepare your order. Your card won't be charged until then.",
  "saveFavoriteOrderButton": "Save In Favorites",
  "saveFavoriteOrderNameError": "Please enter an order name.",
  "saveFavoriteOrderSucess": "Order saved to favorites!",
  "saveFavoriteOrderText": "What would you like to name this order?",
  "saveFavoriteOrderUnsaved": "You Have Unsaved Changes",
  "saveFavoriteOrderUnsavedBody": "Do you want to continue without saving your changes in the favorite order?",
  "saveInformationToMyAccount": "Remember my phone number and address for future delivery orders.",
  "saveMyPointsForLater": "Save my points for later",
  "scan": "Scan",
  "scanAndPay": "Scan & pay",
  "scanAndPayHasBeenDisabled": "Scan & Pay Has Been Disabled",
  "scanAndPayPaymentMethodUpdated": "Successfuly Updated Scan & Pay Payment Method",
  "scanBarcode": "Scan Barcode",
  "scanMoreRewards": "Scan for more rewards",
  "scanQr": "Scan QR code",
  "scanQRCode": "Scan your QR code if you are paying in restaurant.",
  "scanQrTitle": "Scan QR code before ordering",
  "scanRewards": "Scan Rewards",
  "scanRewardsCard": "Scan Rewards Card",
  "scanSuccessful": "Scan Successful!",
  "scanToEarnPoints": "Scan to Earn Points",
  "scanToEarnPointsPayAndUseRewards": "Scan to earn points, pay and use rewards",
  "scanToEarnRewardsAndPay": "Scan To Earn Rewards & Pay",
  "scheduleFutureOrder": "Schedule Future Order",
  "scheduleDelivery": "Schedule Delivery",
  "scheduleForDineIn": "Dine-In",
  "scheduleForDelivery": "Scheduled For Delivery",
  "scheduleForPickup": "Scheduled For Pick Up",
  "schedulePickup": "Schedule Pick Up",
  "school": "School",
  "searchLocation": "Search Location",
  "searchLocations": "Search Locations",
  "searchThisArea": "Search this area",
  "seasonalTreats": "Seasonal Treats",
  "seconds": "seconds",
  "seeAll": "See All",
  "seeDetails": "See Details",
  "seeMoreOptions": "See more options",
  "seeHistory": "See History",
  "select": "Select",
  "selectACountry": "Select a country",
  "selectAFilter": "Select a filter...",
  "selectBestRewardLevel": "Select the reward level that best fits you! You can change it at any time.",
  "selectDonationAmount": "Select Donation Amount",
  "selectLearnMore": "Select \"Learn More\" to access instructions on how to activate location permissions and find your nearest Firehouse Subs restaurant.",
  "selected": "Selected",
  "selectedRestaurant": "Selected Restaurant",
  "selectedRewardLevelAndBankMyRewards": "Selected Reward Level and Bank My Rewards",
  "selectGift": "Select Gift",
  "selectGiftError": "Please select a gift.",
  "selectingPaymentMethod": "Select Payment Method",
  "selectingStartOver": "Selecting Start Over will empty your cart and start a new order.",
  "selectionNotAvailable": "Sorry, your selection is not available.",
  "selectLanguage": "Select Language",
  "selectLocation": "Select Location",
  "selectManually": "Select Manually",
  "selectNewRestaurantButton": "Select a new restaurant",
  "selectPaymentMethod": "Select a payment method",
  "selectPaymentMethodToAddFunds": "Please select the payment method that will be used to add funds to your account.",
  "selectPermissions": "Select Permissions",
  "selectPickup": "Select pickup method",
  "selectQuantity": "Select Quantity",
  "selectDifferentAddress": "Select a Different Address",
  "selectDifferentDeliveryAddress": "Select a Different Delivery Address",
  "selectDifferentStore": "Select a Different Store",
  "selectDifferentPickupStore": "Select a Different Pick Up Store",
  "selectRestaurant": "Select Restaurant",
  "selectRestaurantToViewOffers": "Select restaurant to view local offers",
  "selectStore": "Select Store",
  "selectStoreToRedeem": "Select Store to Redeem",
  "selectTheCorrectAnswer": "Select the correct answer",
  "selectThisStore": "Select {storeLabel} store",
  "selectedRestaurant": "Selected Restaurant",
  "selectedRestaurantIsClosed": "The Selected Restaurant is Closed",
  "selectedRewardLevelAndBankMyRewards": "Selected Reward Level and Bank My Rewards",
  "selectingStartOver": "Selecting Start Over will empty your cart and start a new order.",
  "selectionNotAvailable": "Sorry, your selection is not available.",
  "selectingPaymentMethod": "Select Payment Method",
  "selectYourDeliveryAddress": "Select Your Delivery Address",
  "selectYourPickUpStore": "Select Your Pick Up Store",
  "sending": "Sending...",
  "sendAnotherGift": "Send Another Gift",
  "sendGift": "Send Gift",
  "sendGiftEmailRequiredError": "Please enter the recipient’s email.",
  "sendGiftImage": "Send a gift image",
  "sendGiftNameRequiredError": "Please enter your full name.",
  "sendNewCode": "Send New Code",
  "september": "September",
  "serviceFee": "Service Fee",
  "serviceFeeDescription": "Variable fee consists of {percent}% of your delivery order (excl. taxes).",
  "serviceModeDetailsCateringPickupDescription": "We’ll have your order ready at the counter when you arrive.",
  "serviceModeDetailsCateringPickupHeader": "Catering Pickup",
  "serviceModeDetailsCurbsideDescription": "Grab a parking spot and we’ll bring your order out to your car.",
  "serviceModeDetailsCurbsideHeader": "Curbside Pickup",
  "serviceModeDetailsDineInDescription": "Come on in and let a team member know that you have arrived, and they will assist with your order.",
  "serviceModeDetailsDineInHeader": "Dine In",
  "serviceModeDetailsDriveThruDescription": "Drive up and let us know you placed an online order.",
  "serviceModeDetailsDriveThruHeader": "Drive Thru",
  "serviceModeDetailsPickupDescription": "We’ll have your order ready at the counter when you arrive.",
  "serviceModeDetailsPickupHeader": "Pick Up",
  "serviceModeDetailsUnavailable": "Unavailable",
  "serviceModeDetailsPickupWindowHeader": "Rapid Rescue Lane",
  "serviceModeDetailsPickupWindowDescription": "We will have your order ready inside. Drive up through rapid rescue lane and say your name at the window.",
  "serviceModePrompt": "How would you like to get your food?",
  "serviceModePromptAlternative": "What type of order will this be?",
  "serviceVisit": "Support Points",
  "sesame": "sesame",
  "setPickup": "Set your pickup method ",
  "setRestaurantAsFavorite": "Set this restaurant as a favorite",
  "setRewardsTierError": "Sorry, we couldn't set your reward level please check your connection and try again.",
  "share": "Share",
  "sharedLandingButtonText": "Rrroll Now",
  "shareLocation": "Share location",
  "shareLocationToFindClosesStore": "{shareLocation} to find the closest store",
  "shareMyLocation": "Share Location",
  "shareRollsPageButtonText": "Share {rollsToShare} RRROLL{rollsToShare, plural, one {} other {S}}",
  "shareRollsPageRollToText": "RRROLL{rollsRemaining, plural, one {} other {S}} to",
  "shareYourLocation": "Share your location",
  "shareYourRoll": "Share your rolls and Roll Up to Win™!",
  "shellfish": "shellfish",
  "shoppingCartPreview": "Shopping cart preview",
  "shortCodeConfirmationBody": "Your Royal Perks have been added to the order.",
  "shortCodeConfirmationHeader": "You're All Set!",
  "showDetails": "Show details",
  "showLess": "Show Less",
  "showMeDeals": "Show me Deals",
  "showMore": "Show More",
  "signIn": "Sign In",
  "signInCodeSentToEmailAgain": "Your sign in code has been sent to your email! Please also check your spam folder.",
  "signInCodeSentToPhoneAgain": "Your sign in code has been sent to your phone number!",
  "signInIfYouAlreadyHaveAccount": "Log in if you already have an account",
  "signInOrCreateAccount": "Log In or Create Account",
  "signInSignUp": "Sign Up / Login",
  "signinToRedeem": "Log in to redeem",
  "signInToViewOffers": "Sign in to view offers",
  "signInToViewOrders": "Log In To View Orders",
  "signOut": "Sign Out",
  "signOutConfirmSubheader": "Are you sure you want to sign out?",
  "signUp": "Sign Up",
  "signupEmailSent": "We just sent an email with login instructions to {email}. Enter verification code below",
  "signUpError": "An error occurred while signing up.",
  "signUpForBkRewards": "Sign up for BK Rewards",
  "signUpForPromotionalEmails": "Sign Up for Promotional Emails",
  "signUpForPromotionalEmailsPopeyesRewards": "Yes! I want to receive Popeyes® Perks emails to gain access to exclusive offers and be the first to know about the latest Popeyes promotions",
  "signUpNameDisclaimer": "Restaurants will be checking IDs for {promotionName} promotion redemptions.",
  "signUpNow": "Sign Up Now",
  "signUpSuccessSignInFail": "Your account has been created. Please log in to continue.",
  "signUpToContinue": "Sign Up to Continue",
  "signUpToContinueDisclaimer": "Taxes and Fees will be calculated in your final cart. Sign up or log in to review and complete your order.",
  "signUpToCreateAccount": "Sign up to create an account",
  "signUpToGetStartedWith": "Sign up to get started with {rewardsLink}",
  "signUpToGetFreeRewards": "Sign Up To Get Free Rewards",
  "signUpToRedeem": "Sign Up To Redeem",
  "signUpToStartEarning": "Sign up for Royal Perks to start earning and redeeming BK Crowns in-store and online.",
  "singleUseCupFee": "Single Use Cup Fee",
  "sitemap": "Sitemap",
  "skip": "Skip",
  "skipTheLinePart1": "Skip",
  "skipTheLinePart2": "The",
  "skipTheLinePart3": "Line",
  "skipToContent": "Skip to content",
  "smallCartFee": "Small Cart Fee",
  "smallCartFeeDescription": "Additional fixed fee applicable to delivery orders less than {amount} (excl. taxes). You may add items to remove fee.",
  "sodium": "Sodium",
  "someItemsAreUnavailableInSelectedStore": "Some of the items in your cart are not available at this store. Please remove these items to continue with your selection or cancel to keep your current store.",
  "someItemsAreUnavailableMessage": "Some items in your cart aren't currently available from your selected store. Please remove these selections and try again.",
  "somethingMissingHere": "Something's missing here",
  "somethingWentWrong": "Something went wrong",
  "somethingWrong": "Something went wrong",
  "sorrySomethingWentWrong": "Sorry, something went wrong!",
  "soy": "soy",
  "specialOffers": "Special Offers",
  "staffing": "Staffing",
  "standardOptions": "Standard options",
  "standardsOfBusinessPractices": "Standards of business practices",
  "startCateringOrderNow": "Start your catering order now",
  "startChallenge": "Start Challenge",
  "startEarningRewards": "Start earning rewards",
  "startOrder": "Start Order",
  "startOver": "Start Over?",
  "startOverDisclaimer": "Selecting Start Over will empty your cart and start a new order.",
  "startOverInX": "Start over in {timeRemaining}",
  "startPlayingMessage": "Now you can start playing!",
  "startYourCateringOrderNow": "Start your Catering Order now",
  "startYourOrder": "Start Your Order",
  "startYourOrderHerePart1": "Start your",
  "startYourOrderHerePart2": "order here",
  "state": "State",
  "stateIsARequiredField": "State is a required field",
  "stillThere": "Still there?",
  "storeAddress": "{name}{conditionalLinebreak}{address1}{linebreak}{city}, {stateProvince} {postalCode}",
  "storeClosed": "THE STORE YOU SELECTED ISN'T OPEN AT THIS TIME",
  "storeConfirmationCateringDelivery": "Catering Delivery at {location}",
  "storeConfirmationCateringPickup": "Pick Catering up at {location}",
  "storeConfirmationCurbside": "Curbside at {location}",
  "storeConfirmationDefault": "Place Order at {location}",
  "storeConfirmationDelivery": "Delivery at {location}",
  "storeConfirmationDriveThru": "Drive Thru at {location}",
  "storeConfirmationEatIn": "Dine in at {location}",
  "storeConfirmationPickup": "Pick up at {location}",
  "storeConfirmationTakeout": "Takeout at {location}",
  "storeDoesNotHaveCateringOrders": "This store does not have catering orders.",
  "storeDoesNotHaveMobileOrdering": "This store does not have mobile ordering.",
  "storeInfo": "Info & Photo",
  "storeInfoAssistive": "See information for {storeLabel} store",
  "storeIsClosingError": "This store is closing soon and is no longer accepting mobile orders.",
  "storeLocations": "Store Locations",
  "storeLocator": "Store Locator",
  "storeLocatorEmptyStateDefaultBody": "Search for a restaurant near you.",
  "storeLocatorEmptyStateDefaultTitle": "Find a Location",
  "storeLocatorEmptyStateFavoritesErrorBody": "Looks like something went wrong on our end. Try reloading the page or searching for an address on the Nearby tab.",
  "storeLocatorEmptyStateFavoritesErrorTitle": "Uh oh! Sorry.",
  "storeLocatorEmptyStateFavoritesNoAuthBody": "To set a favorite location, first log in or sign up for an account.",
  "storeLocatorEmptyStateFavoritesNoAuthTitle": "Log In to Save Favorites",
  "storeLocatorEmptyStateFavoritesNoStoresBody": "Tap the heart on a store to save it as a favorite.",
  "storeLocatorEmptyStateFavoritesNoStoresTitle": "No Favorites Yet",
  "storeLocatorEmptyStateNearbyErrorBody": "Looks like something went wrong on our end. Try entering an address or finding your location on map.",
  "storeLocatorEmptyStateNearbyErrorTitle": "Uh oh! Sorry.",
  "storeLocatorEmptyStateNearbyNoLocationBody": "Let us know where you are so we can recommend nearby locations.",
  "storeLocatorEmptyStateNearbyNoLocationTitle": "Find a Location Nearby",
  "storeLocatorEmptyStateNearbyNoStoresBody": "No nearby locations are available at this time. Try entering a new address or city.",
  "storeLocatorEmptyStateNearbyNoStoresTitle": "No Nearby Locations",
  "storeLocatorEmptyStateRecentErrorBody": "Looks like something went wrong on our end. Try reloading the page or searching for an address on the Nearby tab.",
  "storeLocatorEmptyStateRecentErrorTitle": "Uh oh! Sorry.",
  "storeLocatorEmptyStateRecentNoAuthBody": "First log in or sign up for an account.",
  "storeLocatorEmptyStateRecentNoAuthTitle": "Log In to See Recent Locations",
  "storeLocatorEmptyStateRecentNoStoresBody": "You have no recent locations. Let's change that! Try entering an address and placing an order.",
  "storeLocatorEmptyStateRecentNoStoresTitle": "Oh! Hold Up",
  "storeNoOrderingBody": "Try selecting a different location, or visit us in person to place this order",
  "storeNoOrderingHeading": "The restaurant you selected isn't accepting mobile orders at this time",
  "storeUnavailable": "Store Unavailable",
  "streetAddress": "Street address",
  "submit": "Submit",
  "submitAnswer": "Submit Answer",
  "submitEmail": "Submit Email",
  "submitFeedback": "Submit Feedback",
  "subtotal": "Subtotal",
  "success": "Success",
  "successfulCardDeactivation": "Your card was successfully deactivated and your digital card has been updated",
  "successfullyCreatedSupportCase": "Your support case has been successfully submitted!",
  "successfullyUpdatedUserAccountInfo": "Account information has been updated.",
  "successfullyUpdatedCommunicationPreferences": "Communication preferences have been updated",
  "sugar": "Sugar",
  "sulphurDioxide": "sulphur dioxide",
  "sunday": "Sunday",
  "support": "Support",
  "supportFormDeliveryActive": "It looks like your order has not been dropped off yet, if you need additional help please reach out below.",
  "supportFormOrderAlreadyRefunded": "It looks like you have already received a refund for this order, if you need additional help please reach out below.",
  "supportFormRefundGenericError": "We are so sorry you experienced an issue with your order, please describe your issue below.",
  "supportFormSelectIssue": "Select Your Issue",
  "supportReachOut": "reach out to support.",
  "supportSubcategoryTitle": "{category} issues",
  "supportTopics": "Support Topics",
  "supportTopicsAll": "All Support Topics",
  "surprise": "surprise",
  "swipe": "Swipe",
  "switchServiceMode": "Switch Service Mode",
  "switchServiceModeToDeliveryBody": "Switch to Delivery to place an order",
  "switchServiceModeToDeliveryButton": "Switch to Delivery",
  "switchServiceModeToDeliveryHeader": "Offer only available for Delivery",
  "switchServiceModeToPickupBody": "Switch to Pickup to place an order",
  "switchServiceModeToPickupButton": "Switch to Pickup",
  "switchServiceModeToPickupHeader": "Offer only available for Pickup",
  "switzerland": "Switzerland",
  "tapAllowWhileUsing": "Tap Allow While Using",
  "tapAlwaysOrWileUsing": "Tap Always or While Using",
  "takeAdvantageOfLoyaltyProgram": "Take Advantage of Our Loyalty Program",
  "takeout": "Take Out",
  "takeOut": "Take Out",
  "takeOutComingSoon": "Take Out COMING SOON",
  "takeOutUnavailable": "Take Out is currently unavailable",
  "tapImClose": "Tap \"I’m close\" when you’re 5 minutes away so we can prepare your order. Your card won’t be charged until then.",
  "tapImCloseCashPayment": "Tap \"I’m close\" when you’re 5 minutes away so we can prepare your order.",
  "tapImHere": "Tap \"I'm Here\" when you arrive so we can prepare your order. Your card won't be charged until then.",
  "tapImHereCashPayment": "Tap \"I'm Here\" when you arrive so we can prepare your order.",
  "tapToOrder": "Tap to order",
  "tastySwaps": "Tasty Swaps",
  "tax": "Tax",
  "taxes": "Taxes",
  "taxesAndFees": "Taxes and Fees",
  "taxesAndFeesButtonAriaLabel": "Show taxes and fees",
  "taxesAndFeesNextStep": "Calculated at Final Step",
  "tenMin": "10 min",
  "termsAccessibilityLabel": "See terms for {cardTitle}",
  "termsConditions": "Terms & Conditions",
  "termsConditionsRewards": "Terms & Conditions",
  "termsOfService": "Terms of Service",
  "termsOfUse": "Terms of Use",
  "thankYou": "Thank you!",
  "theCardBalanceIs": "The card {cardNumber} has a balance of",
  "theEmailAddressIsInvalid": "Oops! It looks like the email address you entered is invalid. Please double-check your email and try again.",
  "thereAreErrorsInThisForm": "There are {numberOfErrors} errors in this form",
  "thereIsAnErrorInThisForm": "There is an error in this form",
  "theseAreOurFavorite": "These are our favorite",
  "thisIsScary": "This is scary... looks like your location services might not be on.",
  "thursday": "Thursday",
  "time": "Time",
  "timeline": "Timeline",
  "timing": "Timing",
  "tip": "Tip",
  "tipAmount": "Tip Amount",
  "tipAmountError": "Please use a numeric character.",
  "tipAmountMaxError": "Can't be more than $50",
  "tipDisclaimer": "* Tip is calculated based on the subtotal prior to any applicable discounts, offers, or rewards.",
  "tipField": "Enter a tip amount (dollars)",
  "tipInputPlaceholder": "e.g. $10.50",
  "titleAllowLocation": "Your location is not available",
  "to": "to",
  "toAddress": "to {address}",
  "today": "today",
  "todayAlt": "Today",
  "toEarnRolls": "to earn rolls",
  "toggleMenu": "Toggle menu",
  "togglePromoCode": "Enter Code Here",
  "togglePromoCodeAria": "Click here to add a promo code",
  "togglePromotionModal": "View saved promotions",
  "togglePromotionModalAria": "Click here to view saved promotions",
  "tomorrow": "tomorrow",
  "tomorrowAlt": "Tomorrow",
  "tooManyAddAccountAttempts": "You've tried adding a payment method too many times recently. Please try again later.",
  "total": "Total",
  "totalAmountAdded": "Total amount added",
  "totalChoices": "Choose up to {amount} total",
  "totalFat": "Total Fat",
  "track": "Track",
  "trackOrder": "Track order",
  "trademarkAndCopyright": "TM & Copyright 2021 Burger King Corporation.",
  "trademarkAndCopyrightPopeyes": "TM & © 2021 Popeyes Louisiana Kitchen, Inc.",
  "trademarks": "Trademarks Notice",
  "transFat": "Trans Fat",
  "transfer": "Transfer",
  "transferBalance": "Transfer Balance",
  "transferBalanceCompletedMessage": "Congratulations! Your balance has been transferred.",
  "transferBalanceDirections": "First, enter the digits from the back of the card to look up the balance.",
  "transferBalanceError": "We were unable to process your request. Please try again or {link} for assistance.",
  "transferBalanceFromAnotherCard": "Transfer balance from another {cardAlias} card",
  "transferBalanceFromAnotherCrownCard": "Transfer balance from another crown card",
  "transferBalanceFromCrownCard": "Transfer Crown Card Balance",
  "transferBalanceFromPrepaidCard": "Transfer {cardAlias} Card Balance",
  "transferNow": "Transfer Now",
  "transferSuccessful": "Transfer successful",
  "treatYourself": "Treat yourself",
  "treeNuts": "tree nuts",
  "trending": "Trending",
  "troubleLoadingOrder": "We had some trouble connecting to the restaurant. We suggest selecting a different restaurant or trying again at a later time.",
  "tryAgain": "Try again",
  "tryEnablingYourLocation": "Try enabling your location services in settings and then be sure to try again to get your free Whopper® coupon.",
  "tryingToEnterPromoCode": "Trying to enter a Promo Code? Enter it {below}.",
  "tuesday": "Tuesday",
  "twentyMin": "20 min",
  "uhoh": "Uh oh!",
  "unableToPlaceOrder": "Unable To Place Order",
  "unableToReloadCardError": "We were unable to reload your card.",
  "unauthenticatedFavoritesHeader": "Log In to Save Favorites",
  "unauthenticatedFavoritesSubtext": "Sign up or log in to save your favorites.",
  "unauthenticatedRecentItemsHeader": "Log In to View Recent Items",
  "unauthenticatedRecentItemsSubtext": "Once you're logged in, your recently ordered items will appear here.",
  "unavailable": "Unavailable",
  "unavailableAtLocation": "Unavailable at this location",
  "unit": "Unit",
  "unitedKingdom": "United Kingdom",
  "unitedStates": "United States",
  "unitNumber": "Unit {unitNumber}",
  "unitNumberLabel": "Unit",
  "unitNumberLabelRequired": "Unit (Required)",
  "unlock": "Unlock",
  "unlockedPromoCodeOffer": "Offer unlocked",
  "unregisterYourCard": "You may unregister your card at any time within Account settings.",
  "unsavedChangesModalDescription": "You made changes to the item. Do you want to save?",
  "unsavedChangesModalHeader": "Unsaved Changes",
  "unsupportedCardEntered": "Unsupported card entered",
  "unsupportedCardType": "Sorry, we don't currently support {cardType}",
  "until": "until",
  "unverified": "Unverified",
  "unwrapping": "Unwrapping...",
  "upArrowChevronIcon": "Up Arrow",
  "update": "Update",
  "updateApp": "Update App",
  "updateBrowserLocationSettings": "Please allow location access in your browser and operating system settings.",
  "updateCart": "Update Order",
  "updateCartSuccess": "{itemName} updated in cart.",
  "updateDeviceLocationSettings": "Go to your device settings to allow location access for the {brand} app",
  "updateInfoError": "An error occurred updating your information.",
  "updateItem": "Update Item",
  "updateLater": "Update Later",
  "updateNow": "Update Now",
  "updateOrderErrorMessage": "We encountered an error updating your order. Please try again or come to the counter to chat with us.",
  "updateQuantityAndItemWithPrice": "Update {quantity} {itemName} for {price}",
  "updateRewardLevel": "Update Reward Level",
  "uponOrderDelivery": "Upon order delivery",
  "uponOrderPickup": "Upon order pickup",
  "upsellItemLabel": "{itemName} {calories} {price}",
  "upsizeEligible": "You are eligible for one item upsize.",
  "upsizeForFree": "One Upsize Free!",
  "upsizeSelection": "Please select your upsize",
  "us-calories": "Calories",
  "us-carbohydrates": "Carbohydrates",
  "us-cholesterol": "Cholesterol",
  "us-fat": "Fat",
  "us-fiber": "Fiber",
  "us-proteins": "Proteins",
  "us-salt": "Salt",
  "us-saturatedFat": "Saturated Fat",
  "us-sodium": "Sodium",
  "us-sugar": "Sugar",
  "us-transFat": "Trans Fat",
  "us-weight": "Weight",
  "useCodeAtCashier": "Use code at cashier",
  "usedAllCoupons": "Looks like you have used all of the current coupons\\nWe update periodically so keep an eye out for new ones.\\nThanks for being a loyal customer.",
  "useDifferentAddress": "Use Different Address",
  "usePointsToday": "Use your points today",
  "userDoesNotExist": "This user does not exist",
  "useRewards": "My Reward Level",
  "userPhoneVerified": "You have successfully verified your phone number",
  "useSavedAddressPhone": "Use Saved Address & Phone",
  "validationCode": "Validation Code",
  "validationError": "Please check your order details and try again",
  "validationErrorHeading": "Order Error",
  "verified": "Verified",
  "verify": "Verify",
  "verifyEmailForRewards": "Verify your email to link a physical card",
  "verifyNow": "Verify now",
  "verifyYourEmail": "Verify Your E-mail",
  "verifyYourEmailInstructions": "In order to access all the features in your account, please verify your e-mail.",
  "verifyYourPhoneInstructions": "In order to sign in using your phone number, please verify your phone number.",
  "verifyYourPhoneNumber": "Verify Your Phone Number",
  "view": "View",
  "viewAll": "View all",
  "viewAllOffers": "View all offers",
  "viewAllPurchases": "View all your purchases.",
  "viewAllRecentItems": "View all recent items",
  "viewAllRewards": "View all rewards",
  "viewCart": "View Cart",
  "viewDetails": "View Details",
  "viewDetailsWithOrderNumber": "View details of order number {ticketNumber}",
  "viewMenu": "View Menu",
  "viewOffers": "View Offers",
  "viewOrder": "View Order",
  "viewOrderWithDescription": "View order {orderDesc}",
  "viewPurchasesAndOffers": "View all of your purchases here, including points earned and points redeemed.",
  "viewReceipt": "View Receipt",
  "viewRewards": "View Rewards",
  "viewTerms": "View Terms",
  "visits": "Visits",
  "visitsAwayFromReward": "visits away from your next reward",
  "visitSupport": "visit support",
  "visitSupportForHelpWithYourCurrentOrder": "Visit support for help with your current order.",
  "visuallyHiddenOpenInAppOrBrowser": "Please click the Open The App button to proceed to the mobile app. If you want to continue to the website, click the Continue on the Website button. This will trigger another login code for you to log in to the website with.",
  "visuallyHiddenSelectItem": "Select {itemName}",
  "visuallyHiddenSignInCodeSentToEmail": "We sent a 6 digit login code to your email address, please type the login code into the input box on the screen.",
  "visuallyHiddenSignInCodeSentToPhone": "We sent a 6 digit login code to your phone number, please type the login code into the input box on the screen.",
  "visuallyHiddenSignInSuccess": "You have been signed in!",
  "visuallyHiddenVerifyingOTP": "Verifying",
  "voucherCodeError": "Please enter a Promo code",
  "voucherNotFound": "Promo code invalid or expired. Trying to Link your Physical Card? Enter it {above}.",
  "voucherUsed": "This Promo code has already been used",
  "waitForReply": "Keep an eye on your email inbox with our response.",
  "waysToOrder": "Ways to order",
  "weArePreparingYourOrder.fhs": "We are preparing your order and it will be placed at the Rapid Rescue Pick Up station when ready.",
  "weArePreparingYourOrder": "We are preparing your order and it will be placed at the front counter when ready.",
  "weCantWaitToServeYou": "We can't wait to serve you",
  "wednesday": "Wednesday",
  "weight": "Weight",
  "welcomeBack": "Welcome Back!",
  "welcomeToBkLoyalty": "Welcome to the new BK Loyalty",
  "welcomeToYour": "Welcome to Your",
  "weNeedPermissionToUseYourLocation": "We Need Permission To Use Your Location",
  "weWillEmailYouWithOrderStatus": "We will email you with the order status once we hear back from the restaurant.",
  "weWillPrepareYourOrderAtTime": "We will prepare your order at {time}",
  "howCanWeHelpYou": "How can we help you?",
  "whatIsNew": "What's New",
  "whatIsTheIssue": "What is the issue?",
  "whatWouldYouLikeToContactUsAbout": "What would you like to contact us about?",
  "wheat": "wheat",
  "whoops": "Whoops!",
  "whyDoYouNeedMyPhoneNumber": "Why do you need my phone number?",
  "willPickUpOrder": "How will you be picking up your order?",
  "willSendConfirmationTo": "We'll send a confirmation email to",
  "willSendYourRequestedInfoTo": "We will send your requested information to {email}. It will take about a week for this process to be complete.",
  "winners": "Winners",
  "wontTakeLong": "It won’t take long – we know it sax to wait.",
  "work": "Work",
  "wouldYouKikeToAdd": "Would you like to add {amount} to your Crown Card?",
  "wouldYouLikeToAddADifferentCard": "Would you like to add a different {cardAlias} Card?",
  "wouldYouLikeToAddToPrepaidCard": "Would you like to add {amount} to your {cardAlias} Card?",
  "wouldYouLikeToUseOneNowFreeItem": "Would you like to use one now to get one of your items free?",
  "wow": "WOW!",
  "xItems": "{xItems, plural, one {# item} other {# items}}",
  "xMoreOrders": "You're on your way! {pointsUntil} more orders to get a free item.",
  "xPointsUntilReward": "{points} {points, plural, one {point} other {points}} until your next reward",
  "yallReady": "Y'all Ready?",
  "yay": "Yay!",
  "year": "Year",
  "yearPlaceholder": "YYYY",
  "yes": "Yes",
  "yesAddNewCard": "Yes, Add new {cardAlias} Card",
  "yesTransfer": "Yes, Transfer {amount}",
  "youCanPayWithACardRightHere": "You can pay with a card right here!",
  "youCurrentlyHaveXRewards": "You currently have {availableRedemptions} rewards",
  "youHaveNumAvailableRewards": "You have {availableRedemptions} rewards.",
  "youHaveQuantityItemInCart": "You have {itemQuantity}-{itemName} in your cart now.",
  "youHaveSuccessfullySubmittedTheForm": "Your form has been successfully submitted",
  "youHaveXPoints": "YOU HAVE {pointsNumber} POINTS",
  "youMightBeFarAway": "You might be far away",
  "youQualifyFreeDelivery": "You qualify for Free Delivery!",
  "youSeemFarAway": "You seem far away",
  "yourAddress": "Your Address",
  "yourBalanceIs": "Your current balance is {currentBalance}.",
  "yourCardHasBeenCharged": "Your card has been charged. You will receive email confirmation within 24 hours.",
  "yourCardHasBeenSuccessfullyRegistered": "Your card has been successfully registered.",
  "yourCardIsLinked": "Your card is linked!",
  "yourCart": "Your Cart",
  "yourCartWillBeClearedIfStartCateringOrder": "Your cart will be cleared if you decide to start a catering order",
  "yourClosestStore": "Your closest store",
  "yourClosestStoreX": "Your closest store is {storeAddress}",
  "yourCourier": "Your Courier",
  "yourCrownOrders": "Your Crown Orders",
  "yourEmail": "Your Email",
  "youreXAwayFromFreeDelivery": "You're {amount} away from Free Delivery!",
  "yourFavorites": "Favorites",
  "yourFullName": "Your Full Name",
  "yourLeastExpensiveItemFree": "Your least expensive item in this order will be free!",
  "yourLocationCannotAcceptDelivery": "Sorry, your location can’t accept deliveries at this time.",
  "yourLocationHasBeenUpdated": "Your location has been updated.",
  "yourLoyaltyHistory": "Your Rewards Orders",
  "yourName": "your name",
  "yourNewCardBalance": "Your new {cardAlias} Card balance is {amount}",
  "yourNewPaymentMethodHasBeenSuccessfullySaved": "Your new payment method has been successfully saved",
  "yourOrder": "Your Order",
  "yourOrderHasBeenPlaced": "Your order has been placed in line with other customers and will be ready as soon as possible!",
  "yourOrderIsBeingConfirmed": "Your order is being confirmed!",
  "yourOrderIsBeingPrepared": "Your order is being prepared!",
  "yourOrderIsBeingPreparedCurbside": "Your order is being prepared and will be brought to you soon.",
  "yourOrderIsConfirmed": "Your order is confirmed!",
  "yourPointsWillExpire": "You have {points} about to expire on {date} unless you make any purchase before that date.",
  "yourRewards": "Your Rewards",
  "yourRewardsCard": "Your Rewards Card",
  "yourSelectedRestaurantIs": "Your selected restaurant is {storeAddress}",
  "yourSelectedStore": "Your selected store",
  "yourUpdateIsntSavedYetBody": "Your reward level update to {selectedTierName} has not been saved yet. Select Update Reward Level for this change to take affect.",
  "yourUpdateIsntSavedYetHeading": "Your update isn’t saved yet",
  "yourXPointsAway": "You are {points} points away",
  "youveEarnedAFreeItem": "You've earned a free item!",
  "youWillBeCharged": "You will be charged {reloadAmount}.",
  "zipCode": "Zip Code",
  "zipCodeError": "Please enter either a valid American zip code or a valid Canadian or U.K. postal code",
  "zipCodeInvalid": "Zip Code Invalid",
  "zipCodeRequiredError": "Zip code is a required field",
  "zoomInToSearch": "Zoom In to Search"
}
