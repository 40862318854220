import { FlashList } from '@shopify/flash-list';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, ProductListTile, ProductListTileProps } from '@fhs/ui';

import { AddedToCart } from './added-to-cart';

const numColumns = 2;

export const ItemList = ({ currentStep, currentStepSelectedItemId, onSelectItem }) => {
  const items = currentStep.sections[0]?.items ?? [];
  return (
    <FlashList<ProductListTileProps & { requiresCustomization: boolean }>
      showsVerticalScrollIndicator={false}
      data={items}
      estimatedItemSize={200}
      numColumns={numColumns}
      renderScrollComponent={ActionSheet.ScrollView}
      contentContainerStyle={{ paddingHorizontal: 12, paddingBottom: 20 }}
      extraData={{ currentStepSelectedItemId }}
      keyExtractor={item => item.id}
      renderItem={({ item, index, extraData }) => {
        const isFirstColumn = index % numColumns === 0;
        const isLastColumn = index % numColumns === numColumns - 1;
        return (
          <View
            style={[
              styles.item,
              isFirstColumn && styles.firstColumn,
              isLastColumn && styles.lastColumn,
            ]}
          >
            <ProductListTile
              size="lg"
              {...item}
              onPress={() => {
                onSelectItem({
                  itemId: item.id,
                  itemSlug: item.slug,
                  requiresCustomization: item.requiresCustomization,
                });
              }}
            />
            {extraData.currentStepSelectedItemId === item.id && (
              <View style={styles.addedToCartContainer}>
                <AddedToCart />
              </View>
            )}
          </View>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
  },
  title: {
    borderBottomColor: 'rgba(0, 0, 0, 0.04)',
    borderBottomWidth: 1,
    paddingVertical: 16,
    textAlign: 'center',
  },
  item: {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 12,
  },
  firstColumn: {
    paddingLeft: 0,
  },
  lastColumn: {
    paddingRight: 0,
  },
  addedToCartContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
});
