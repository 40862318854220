import { useCallback } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { IconChevronRight, Skeleton, SkeletonTextBlock, tokens } from '@fhs/ui';

const TierItemSkeleton = () => {
  return (
    <View style={styles.tierItem}>
      <Skeleton style={styles.tierImage} />
      <View style={styles.tierInfo}>
        <SkeletonTextBlock
          lines={2}
          style={{ width: '50%' }}
          lineStyle={{ backgroundColor: tokens.colors.$houseLight }}
        />
      </View>
      <IconChevronRight size={24} color={tokens.colors.$blackOpacity10} />
    </View>
  );
};

const data = new Array(3).fill({});
export function TierSelectorLoading() {
  const { bottom } = useSafeAreaInsets();

  const renderLoadingTier = useCallback(() => {
    return <TierItemSkeleton />;
  }, []);

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      style={{ flex: 1 }}
      data={data}
      renderItem={renderLoadingTier}
      ListFooterComponent={() => <View style={{ height: bottom + 5 }} />}
    />
  );
}

const styles = StyleSheet.create({
  tierItem: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity04,
  },
  tierImage: {
    height: 40,
    width: 40,
    backgroundColor: tokens.colors.$houseLight,
  },
  tierInfo: {
    paddingHorizontal: 12,
    flex: 1,
  },
});
