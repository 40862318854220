import { useEffect, useRef } from 'react';
import { Platform, View } from 'react-native';

import { Pressable, PressableProps } from '../pressable';

import { useRadioItem } from './context';

export type RadioPressableProps = Omit<
  PressableProps,
  'role' | 'aria-checked' | 'aria-label' | 'onPress' | 'disabled'
>;

export function RadioPressable(props: RadioPressableProps) {
  const radioItem = useRadioItem();
  const pressableRef = useRef<View>(null);
  const mountedRef = useRef(false);

  useEffect(() => {
    // Checking first if mounted, because if initialized with selected, we do not want to
    // trigger the focus.
    // Focus radio pressable when item selected state changes (maybe from clicking RadioLabel)
    if (mountedRef.current && radioItem.selected && Platform.OS === 'web') {
      // You cannot focus a pressable in native, it causes a crash
      pressableRef.current?.focus();
    }

    mountedRef.current = true;
  }, [radioItem.selected]);

  return (
    <Pressable
      ref={pressableRef}
      role="radio"
      aria-checked={radioItem.selected}
      disabled={radioItem.disabled}
      onPress={radioItem.handlePress}
      aria-label={radioItem.label}
      {...props}
    />
  );
}
