import { createSelector } from '@reduxjs/toolkit';
import { keyBy, uniqBy } from 'lodash';

import { OfferRedemptionType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';
import { IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { isDiscountLoyaltyOffer } from 'state/loyalty/utils';

import { incentiveErrorsFilteredList } from './offers.utils';

export const selectAppliedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.appliedOffers;
};

export const selectOfferFeedbackMap = ({ loyalty }: RootState) => {
  return loyalty.offers.offersFeedbackMap;
};

export const selectOffersEligibleItems = ({ loyalty }: RootState) => {
  return loyalty.offers.offersEligibleItems;
};

export const selectOfferEligibleItem = ({ loyalty }: RootState, lineId) => {
  return loyalty.offers.offersEligibleItems.find(eligibleItem => eligibleItem.lineId === lineId);
};

export const selectOffersLoading = ({ loyalty }: RootState) => {
  return loyalty.offers.offersLoading;
};

export const selectOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.offers;
};

export const selectUserOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.userOffers;
};

export const selectPersonalizedOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.personalizedOffers;
};

export const selectSurpriseAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.surpriseAvailable;
};

export const selectUpsizeAvailable = ({ loyalty }: RootState) => {
  return loyalty.offers.upsizeAvailable;
};

export const selectSelectedOffer = ({ loyalty }: RootState) => {
  return loyalty.offers.selectedOffer;
};

export const selectSelectedOfferSelections = ({ loyalty }: RootState) => {
  return loyalty.offers.selectedOfferSelections;
};

const selectOffersFeedbackMap = ({ loyalty }: RootState) => {
  return loyalty.offers.offersFeedbackMap;
};

export const selectIncentiveErrorsFilteredByCode = createSelector(
  [
    selectOffersFeedbackMap,
    (_state: RootState, errorCodeToFilter: IncentiveEvaluationErrorCodes) => errorCodeToFilter,
  ],
  (offersFeedbackMap, errorCodeToFilter) => {
    return incentiveErrorsFilteredList(
      offersFeedbackMap,
      evaluationError => evaluationError.code !== errorCodeToFilter
    );
  }
);

export const selectIncentiveErrorsWithCode = createSelector(
  [
    selectOffersFeedbackMap,
    (_state, errorCodeToFilter: IncentiveEvaluationErrorCodes) => errorCodeToFilter,
  ],
  (offersFeedbackMap, errorCodeToFilter) => {
    return incentiveErrorsFilteredList(
      offersFeedbackMap,
      evaluationError => evaluationError.code === errorCodeToFilter
    );
  }
);

export const selectCmsOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.cmsOffers;
};

export const selectCmsSurpriseOffers = createSelector([selectCmsOffers], cmsOffers =>
  cmsOffers.filter(offer => offer.redemptionType === OfferRedemptionType.SURPRISE)
);

export const selectAppliedCmsOffers = createSelector(
  [
    ({ loyalty }: RootState) => loyalty.offers.appliedOffers,
    selectCmsOffers,
    ({ loyalty }: RootState) => loyalty.offers.cmsOffersV2,
  ],
  (appliedOffers, cmsOffers, cmsOffersV2) => {
    const set = new Set(appliedOffers.map(({ id }) => id));

    return cmsOffers.concat(cmsOffersV2)?.filter(cmsOffer => set.has(cmsOffer.loyaltyEngineId));
  }
);

export const selectDiscountAppliedCmsOffer = createSelector(
  selectAppliedCmsOffers,
  appliedCmsOffers =>
    //Return first match because there should never be two applied offer discounts
    appliedCmsOffers?.find(isDiscountLoyaltyOffer)
);

export const selectOfferRedemptionAvailableAfter = ({ loyalty }: RootState) => {
  return loyalty.offers.offerRedemptionAvailableAfter;
};

export const selectIncentivesIds = createSelector(
  [({ loyalty }: RootState) => loyalty.offers.incentivesIds],
  incentivesIds => {
    return new Set(incentivesIds);
  }
);

export const selectIsDiscountNotApplied = ({ loyalty }: RootState) => {
  return loyalty.offers.isDiscountNotApplied;
};

export const selectShouldRefetchOffers = ({ loyalty }: RootState) => {
  return loyalty.offers.shouldRefetchOffers;
};

export const selectCombinedOffers = createSelector(
  selectPersonalizedOffers,
  selectUserOffers,
  selectCmsOffers,
  selectAppliedOffers,
  (personalizedOffers, userOffers, cmsOffers, appliedOffers) => {
    const baseOffersMap = keyBy(userOffers, 'id');
    const personalizedOffersMap = keyBy(personalizedOffers, 'loyaltyEngineId');
    const _offers: LoyaltyOffer[] = [];
    //Evaluating personalizedOffers first
    const mixedOffers = uniqBy([...personalizedOffers, ...cmsOffers], 'loyaltyEngineId');
    mixedOffers?.forEach((offer: LoyaltyOffer) => {
      if (!offer.loyaltyEngineId || !offer._id) {
        return;
      }
      const personalizedOffer = personalizedOffersMap[offer.loyaltyEngineId];
      const engineOffer = baseOffersMap[offer.loyaltyEngineId];
      const metadata = personalizedOffer ? personalizedOffer?.metadata : engineOffer?.metadata;
      _offers.push({
        ...offer,
        metadata,
      });
    });
    return _offers.filter(
      offer => !appliedOffers.find(appliedOffer => appliedOffer.id === offer.loyaltyEngineId)
    );
  }
);
