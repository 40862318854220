import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Alert, Box, HStack, InlineAlert } from '@fhs-legacy/universal-components';
import { IServerOrder } from '@rbi-ctg/menu';
import ActionButton, { ActionLink } from 'components/action-button';
import { CheckoutErrorsModal } from 'components/checkout-errors-modal';
import { MaxCartTotalAlert } from 'components/max-cart-total-alert';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useCartLimit } from 'hooks/use-cart-limit';
import { useGetPaymentErrors } from 'hooks/use-get-payment-errors';
import useIsSignUpAfterCart from 'hooks/use-is-sign-up-after-cart';
import { useCartContext } from 'state/cart';
import { useCRMEventsContext } from 'state/crm-events';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OrderStatus, useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';
import { routes } from 'utils/routing';
import { isCatering, isDelivery } from 'utils/service-mode';
import { CART_PAGE_CONTINUE_BUTTON } from 'utils/test-ids';

import { CtaWrapper, EnsureBottomPadding, SectionContainer } from '../styled';

import DeliveryCancellationPolicy from './delivery-cancellation-policy';
import SignUpToContinueActionLink from './sign-up-to-continue-action-link';
import { SignUpToContinueDisclaimer } from './styled';
import { logCartContinue } from './utils';

export interface IPaymentNewProps {
  disabled?: boolean;
  offerIsValid: boolean;
  orderStatus: OrderStatus | null;
  serverOrder: IServerOrder | null;
  shouldSkipPriceOrder?: boolean;
}

const Payment: React.FC<React.PropsWithChildren<IPaymentNewProps>> = ({
  disabled = false,
  offerIsValid,
  serverOrder,
  shouldSkipPriceOrder,
}) => {
  useRumPageView('payment', 'Payment');
  const { formatCurrencyForLocale } = useUIContext();
  const { serviceMode, cartEntries, cartCateringPriceTooLow } = useCartContext();
  const order = useOrderContext();

  const [isCheckoutErrorsModalOpen, setIsCheckoutErrorsModalOpen] = useState(false);

  const { formatMessage } = useIntl();

  const sectionRef = useRef<HTMLDivElement>(null);

  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);
  const checkoutCateringPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM);

  const isSignUpAfterCart = useIsSignUpAfterCart();

  const isCateringOrder = isCatering(serviceMode);
  const isDeliveryOrder = isDelivery(serviceMode);

  const checkoutPriceMinimum = isDeliveryOrder ? checkoutDeliveryPriceMinimum : 0;

  const cartSubTotal =
    isSignUpAfterCart || shouldSkipPriceOrder
      ? order.calculateCartTotal(cartEntries)
      : serverOrder?.cart?.subTotalCents;
  const cartPriceTooLow = cartSubTotal < checkoutPriceMinimum;

  const { getErrorMessages } = useGetPaymentErrors({ cartPriceTooLow });

  const enableDelivery = useFlag(LaunchDarklyFlag.ENABLE_DELIVERY);

  const { logRBIEvent } = useCRMEventsContext();

  const handleContinuePress = () => {
    logCartContinue(logRBIEvent);
  };

  const errorMessage = getErrorMessages({
    invalidOfferError: !offerIsValid,
  });

  const hasError = errorMessage.length > 0;

  const { isOverCartLimit } = useCartLimit();

  const continueButton = hasError ? (
    <ActionButton
      onPress={() => {
        logCartContinue(logRBIEvent);
        setIsCheckoutErrorsModalOpen(true);
      }}
      fullWidth
      noLogOnLongLoading
      disabled={disabled || !offerIsValid || cartPriceTooLow || shouldSkipPriceOrder}
    >
      {formatMessage({ id: 'continue' })}
    </ActionButton>
  ) : (
    <ActionLink
      onPress={handleContinuePress}
      to={routes.cartPayment}
      fullWidth
      noLogOnLongLoading
      testID={CART_PAGE_CONTINUE_BUTTON}
      disabled={disabled || cartPriceTooLow || isOverCartLimit}
    >
      {formatMessage({ id: 'continue' })}
    </ActionLink>
  );

  return (
    <SectionContainer testID="cart-payment" ref={sectionRef}>
      {isCheckoutErrorsModalOpen && (
        <CheckoutErrorsModal
          checkoutErrors={errorMessage}
          closeModal={() => {
            setIsCheckoutErrorsModalOpen(false);
          }}
        />
      )}
      <EnsureBottomPadding>
        {(cartPriceTooLow || cartCateringPriceTooLow) && (
          <>
            <InlineAlert
              status="error"
              borderRadius={8}
              marginBottom="$2"
              message={formatMessage(
                {
                  id: isCateringOrder ? 'cateringMinimumNotReached' : 'deliveryMinumumNotReached',
                },
                {
                  min: isCateringOrder
                    ? formatCurrencyForLocale(checkoutCateringPriceMinimum)
                    : formatCurrencyForLocale(checkoutDeliveryPriceMinimum),
                }
              )}
            />
            {isCateringOrder ? null : (
              <InlineAlert
                status="error"
                borderRadius={8}
                marginBottom="$2"
                message={formatMessage({ id: 'addSomethingExtra' })}
              />
            )}
          </>
        )}
        {isOverCartLimit && (
          <Box
            backgroundColor="token.background-inline-alert-error"
            p={{
              base: '$2.5',
              desktop: '$3',
            }}
            borderRadius="sm"
            mb="$5"
          >
            <HStack alignItems="center" space="$2">
              <Alert.Icon />
              <MaxCartTotalAlert isMaxCartTotalBold fontSize={{ base: 'xs', desktop: 'md' }} />
            </HStack>
          </Box>
        )}
        {!isDeliveryOrder || (enableDelivery && serviceMode) ? (
          <CtaWrapper>
            {isSignUpAfterCart ? (
              <Box>
                <SignUpToContinueDisclaimer>
                  {formatMessage({ id: 'signUpToContinueDisclaimer' })}
                </SignUpToContinueDisclaimer>
                <SignUpToContinueActionLink />
              </Box>
            ) : (
              continueButton
            )}
            {isDeliveryOrder && enableDelivery && <DeliveryCancellationPolicy />}
          </CtaWrapper>
        ) : (
          <Box marginTop="$4">
            <ActionButton disabled>delivery coming soon</ActionButton>
          </Box>
        )}
      </EnsureBottomPadding>
    </SectionContainer>
  );
};

export default Payment;
