import { Image } from 'expo-image';
import { useFocusEffect } from 'expo-router';
import { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, Button, IconShoppingBag, ItemStepper, Text, tokens } from '@fhs/ui';
import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from '@fhs-legacy/frontend/src/state/crm-events';

import { SimplyOfferClickEventLabels, SimplyOfferEventNames } from '../../analytics';
import { useOrderLegacyStates } from '../../hooks/use-legacy-order-states';
import { useOfferCart } from '../../state/offer-guide-cart-state';

import { BottomButtonContainer } from './bottom-button';

export function ItemInCart() {
  const { logRBIEvent } = useCRMEventsContext();
  const { offerInfo, steps: offerSteps, goFromScratch, applyPreSelectedItems } = useOfferCart();
  const { image } = offerInfo ?? {};
  const { cartEntries } = useOrderLegacyStates();
  const steps = useMemo(
    () =>
      offerSteps.map(step => {
        const selectedItem = cartEntries?.find(
          cartEntry => cartEntry.cartId === step.preSelectedItem
        );
        const eligibleItemImage = step.eligibleItems.find(
          eligibleItem => eligibleItem.id === selectedItem?._id
        )?.image?.asset?.uri;

        return {
          isActive: !selectedItem,
          image: eligibleItemImage,
        };
      }),
    [cartEntries, offerSteps]
  );

  useFocusEffect(
    useCallback(() => {
      logRBIEvent({
        name: SimplyOfferEventNames.OFFER_GUIDE_HAS_ITEMS_IN_CART,
        type: EventTypes.Other,
        attributes: {
          offerId: offerInfo?.id,
          sanityId: offerInfo?.cmsId,
          itemIds: cartEntries.map(entry => entry._id),
        },
      });
    }, [offerInfo?.id, offerInfo?.cmsId, cartEntries, logRBIEvent])
  );

  const goFromScratchAndLog = useCallback(() => {
    goFromScratch();
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: SimplyOfferClickEventLabels.OFFER_GUIDE_SELECT_FROM_SCRATCH,
      },
    });
  }, [goFromScratch, logRBIEvent]);

  const applyAndLogPreSelectedOfferItems = useCallback(() => {
    applyPreSelectedItems(cartEntries);
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component: ClickEventComponentNames.BUTTON,
        text: SimplyOfferClickEventLabels.OFFER_GUIDE_USE_CART_ITEMS,
      },
    });
  }, [applyPreSelectedItems, logRBIEvent, cartEntries]);

  return (
    <View style={{ flex: 1 }}>
      <ActionSheet.ScrollView
        style={styles.actionSheet}
        overScrollMode="never"
        showsVerticalScrollIndicator={false}
        scrollToOverflowEnabled={false}
        alwaysBounceVertical={false}
      >
        <Image source={{ uri: image }} contentFit="contain" style={{ height: 216 }} />
        <View style={styles.content}>
          <View style={styles.shoppingBagContainer}>
            <IconShoppingBag size={40} />
          </View>
          <Text.Heading type="one" style={{ textAlign: 'center' }}>
            It seems like you have some items in your cart
          </Text.Heading>
          <View style={styles.stepContainer}>
            <Text.Ui size="sm">Items are already in cart</Text.Ui>
            <ItemStepper steps={steps} />
          </View>
        </View>
      </ActionSheet.ScrollView>
      <BottomButtonContainer style={styles.buttonContainer}>
        <Button size="xl" onPress={applyAndLogPreSelectedOfferItems}>
          <Button.Text>Use These Items</Button.Text>
        </Button>
        <Button type="outline" size="xl" onPress={goFromScratchAndLog}>
          <Button.Text>Select From Scratch</Button.Text>
        </Button>
      </BottomButtonContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  actionSheet: {
    flex: 1,
  },
  content: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    alignItems: 'center',
    gap: 16,
  },
  shoppingBagContainer: {
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 200,
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepContainer: {
    width: '100%',
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
    padding: 16,
    borderRadius: 8,
    gap: 16,
    alignItems: 'center',
  },
  buttonContainer: {
    gap: 12,
  },
});
