import * as React from 'react';
import { FC } from 'react';

// TODO: expo-router - Fix useRumPageView hook
// import { useRumPageView } from 'hooks/rum/use-rum-page-view';

import { StoreLocator as StoreLocatorRefresh } from './new-ui';

/**
 *
 * StoreLocator purpose
 *
 */
export const StoreLocator: FC<React.PropsWithChildren<unknown>> = props => {
  // useRumPageView('store-locator', 'Store Locator'); // TODO: expo-router - Fix useRumPageView hook
  return <StoreLocatorRefresh {...props} />;
};

export default StoreLocator;
