import { router, useLocalSearchParams } from 'expo-router';
import Head from 'expo-router/head';
import React, { FC, useEffect, useState } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';

import { ILoyaltyTransaction, IServerOrder } from '@rbi-ctg/menu';
import CartError, { SelectNewRestaurantActionButton } from 'components/cart-error';
import { RbiOrderStatus } from 'generated/rbi-graphql';
import { useOrderStatus } from 'hooks/order-status';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import { useToast } from 'hooks/use-toast';
import { LoyaltyRewardsBalance } from 'pages/loyalty/loyalty-rewards-balance';
import NotFound from 'pages/not-found';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useOffersContext } from 'state/offers';
import { OrderStatus } from 'state/order';
import { OrderSuccessFailureStatuses } from 'state/order/constants';
import { useStoreContext } from 'state/store';
import { isTest } from 'utils/environment';
import { routes } from 'utils/routing';
import { isDelivery } from 'utils/service-mode';
import { ORDER_CONFIRMATION_PAGE } from 'utils/test-ids';

import { OrderConfirmationPageContent } from './order-confirmation-page-content';
import { OrderConfirmationContainer } from './styled.default';

const BSM_GET_USER_ORDER_STATUSES = [
  RbiOrderStatus.INSERT_SUCCESSFUL,
  RbiOrderStatus.UPDATE_SUCCESSFUL,
  RbiOrderStatus.REFUND_SUCCESSFUL,
];

export const getRewardsAndPointsMessage = ({
  loyaltyTransaction,
  formatMessage,
  loyaltyEnabled,
}: {
  loyaltyTransaction: ILoyaltyTransaction | undefined;
  formatMessage: IntlFormatters['formatMessage'];
  loyaltyEnabled: boolean;
}): string => {
  if (!loyaltyEnabled || !loyaltyTransaction) {
    return '';
  }

  const successMessages: string[] = [];
  const { rewardsUsed, pointsEarned, bonusPointsEarned } = loyaltyTransaction;

  if (loyaltyTransaction.rewardsUsed) {
    successMessages.push(
      formatMessage(
        { id: 'redeemedRewards' },
        {
          redeemedRewards: rewardsUsed,
        }
      )
    );
  }

  if (pointsEarned || bonusPointsEarned) {
    successMessages.push(
      formatMessage(
        { id: 'pointsEarned' },
        {
          pointsEarned: pointsEarned + bonusPointsEarned,
        }
      )
    );
  }

  return successMessages.join('\n');
};

export const OrderConfirmationPage: FC<
  React.PropsWithChildren<{
    serverOrder: IServerOrder | null;
    loyaltyEnabled: boolean;
  }>
> = ({ children, serverOrder, loyaltyEnabled }) => {
  useRumPageView('order-confirmation', 'Order Confirmation');
  const { resetStore } = useStoreContext();
  const { formatMessage } = useIntl();
  const enableClearStoreAfterOrder = useFlag(LaunchDarklyFlag.ENABLE_CLEAR_STORE_AFTER_ORDER);
  const toast = useToast();
  const params = useLocalSearchParams<{ back: string; showToast: string }>();

  // Both routers handle QS params parsing differently
  const showToast = params.showToast === 'true'; //|| params.showToast === true;

  const message =
    showToast && serverOrder?.loyaltyTransaction
      ? getRewardsAndPointsMessage({
          loyaltyTransaction: serverOrder.loyaltyTransaction,
          formatMessage,
          loyaltyEnabled,
        })
      : '';

  useEffect(() => {
    if (message) {
      toast.show({
        text: message,
        variant: 'positive',
      });
    }
  }, [message, toast]);

  useEffectOnUnmount(() => {
    // reset needs to happen on unmount as it is needed to display content of page
    if (enableClearStoreAfterOrder) {
      resetStore();
    }
  });

  return (
    <OrderConfirmationContainer testID={ORDER_CONFIRMATION_PAGE}>
      {children}
    </OrderConfirmationContainer>
  );
};

export interface IConfirmationUIProps {
  serverOrder: IServerOrder;
  isInsideModal?: boolean;
}

const OrderConfirmation = () => {
  const { refetchOffersList } = useOffersContext();
  const { formatMessage } = useIntl();
  const { rbiOrderId } = useLocalSearchParams<{ rbiOrderId: string }>();

  const [skipOrderStatusPolling, setSkipOrderStatusPolling] = useState(false);
  const { serverOrder, loading, orderStatus } = useOrderStatus({
    failureStatuses: OrderSuccessFailureStatuses.failures,
    rbiOrderId,
    successStatuses: OrderSuccessFailureStatuses.success,
    skip: skipOrderStatusPolling || isTest,
  });

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { refetchLoyaltyUser } = useLoyaltyContext();
  const isDeliveryServiceMode = isDelivery(serverOrder?.cart?.serviceMode ?? null);

  useEffect(() => {
    if (orderStatus && (BSM_GET_USER_ORDER_STATUSES as string[]).includes(orderStatus)) {
      // Refetch loyaltyUser to obtain new point balance
      if (loyaltyEnabled) {
        refetchLoyaltyUser();
      }
      // Refetch offers
      refetchOffersList();
    }
  }, [loyaltyEnabled, orderStatus, refetchLoyaltyUser, refetchOffersList]);

  useEffect(() => {
    if (!isDeliveryServiceMode) {
      setSkipOrderStatusPolling(false);
    }
  }, [isDeliveryServiceMode]);

  if (!loading && !serverOrder) {
    return <NotFound />;
  }

  const insertFailed =
    orderStatus &&
    !![OrderStatus.PAYMENT_ERROR, OrderStatus.CATERING_ERROR, OrderStatus.INSERT_ERROR].includes(
      orderStatus
    );

  if (insertFailed) {
    if (orderStatus === OrderStatus.PAYMENT_ERROR) {
      return (
        <CartError
          title={formatMessage({ id: 'paymentDeclinedHeading' })}
          body={formatMessage({ id: 'paymentDeclined' })}
          disclaimer={formatMessage({ id: 'paymentDeclinedDisclaimer' })}
          onDismiss={() => router.navigate(routes.menu)}
          additionalLoggingContext={{ orderStatus, insertFailed }}
        />
      );
    }
    return (
      <CartError
        actionButtons={<SelectNewRestaurantActionButton />}
        additionalLoggingContext={{ orderStatus, insertFailed }}
      />
    );
  }

  return (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'orderConfirmation' })} - Firehouse Subs`}</title>
      </Head>
      <LoyaltyRewardsBalance />
      <OrderConfirmationPage serverOrder={serverOrder} loyaltyEnabled={loyaltyEnabled}>
        <OrderConfirmationPageContent loading={loading} serverOrder={serverOrder} />
      </OrderConfirmationPage>
    </>
  );
};

export default OrderConfirmation;
