import {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
} from '@fhs-legacy/frontend/src/generated/graphql-gateway';

export type CreateOtpInput = {
  loginIdentifier: string;
  identifierType: AuthIdentifiers;
};

export type SignUpInput = {
  name?: string;
  email?: string;
  phoneNumber?: string;
  dob?: string;
  wantsPromotionalEmail?: boolean;
};

export type ValidatedIdentifiers = {
  phoneNumber?: string;
  emailAddress?: string;
};

export type IdentifyAuthChoice = {
  redactedName: string;
  redactedEmail: string;
};

export type IdentifyAuthJwt = {
  cognitoId: string;
  loginIdentifier: string;
  redactedIdentity: IdentifyAuthChoice;
  jwt: string;
};

export type LoginJwt = {
  identifierType: AuthIdentifiers;
  loginIdentifier: string;
  deviceId: string;
  validatedIdentifiers: ValidatedIdentifiers;
};

export type AuthV2ErrorState = string | null;

export type IAuthV2Context = {
  //Mutations
  createOtp: (partialInput: CreateOtpInput) => Promise<void>;
  validateOtp: (otpInput: string) => Promise<void>;
  signUp: (input: SignUpInput) => Promise<void>;
  //Auth State
  error: string;
  setError: React.Dispatch<React.SetStateAction<String>>;
  identifyAuthJwts: IdentifyAuthJwt[];
  setIdentifyAuthJwts: React.Dispatch<React.SetStateAction<IdentifyAuthJwt[]>>;
  showIdentifyPopup: boolean;
  setShowIdentifyPopup: React.Dispatch<React.SetStateAction<boolean>>;
  //Utility functions
  getJwtIdentifier: () => LoginJwt;
  routeWithJWTContext: (route: string) => void;
};

export {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
};
