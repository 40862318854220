import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { Text, XStack } from '@fhs/ui';
import { Box, Divider, VStack } from '@fhs-legacy/universal-components';
import Currency from 'components/currency';
import { DeliveryStatus } from 'generated/rbi-graphql';
import { ITotals } from 'pages/cart/your-cart/totals/types';
import { useCartContext } from 'state/cart';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useOrderContext } from 'state/order';

import GrandTotal from './grand-total';
import { GrandTotalLabel, TotalsRow, totalSectionStyles } from './styled';
import TaxesAndFees from './taxes-and-fees';
import { useTotalTipCalculations } from './use-total-tip-calculations';

const Wrapper = VStack.withConfig({
  width: 'full',
  marginY: '$8',
});

const Separator = Divider.withConfig({
  marginX: 0,
  marginTop: '$4',
  thickness: '1',
});

export const Totals: FC<React.PropsWithChildren<ITotals>> = ({ serverOrder, serviceMode }) => {
  const { calculateCartTotalWithDiscount } = useCartContext();
  const {
    loading,
    cart,
    isDeliveryOrder,
    delivery,
    deliveryFee,
    deliveryFeeDiscount,
    subTotalCents = 0,
    taxCents = 0,
    serverOrderTip,
    hideTipAmount,
  } = useTotalTipCalculations({ serverOrder, serviceMode });

  const { formatMessage } = useIntl();
  const enableDeliveryFeesOnCart = useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEES_ON_CART);

  const { cartTotal = 0 } = calculateCartTotalWithDiscount();
  const orderContext = useOrderContext();

  const dropoffState = serverOrder?.delivery?.dropoff?.state;
  const deliveryLoading = serverOrder?.delivery?.status === DeliveryStatus.QUOTE_REQUESTED;
  const priceIsLoading = loading || !serverOrder || (isDeliveryOrder && !serverOrder.delivery);
  const donationAmount = serverOrder?.cart.donations?.reduce((acc, donation) => {
    return donation.totalCents + acc;
  }, 0);

  const renderPickupContent = () => {
    return (
      <TotalsRow>
        <GrandTotalLabel>
          {formatMessage({
            id: 'subtotal',
          })}
          {'* '}
        </GrandTotalLabel>
        <Currency
          testID="sub-total"
          amount={cartTotal}
          isBold
          textProps={{
            margin: 0,
            flexGrow: 0,
          }}
        />
      </TotalsRow>
    );
  };

  const renderDeliveryContent = () => {
    if (!enableDeliveryFeesOnCart) {
      return (
        <TotalsRow>
          <GrandTotalLabel>
            {formatMessage({
              id: 'subtotal',
            })}
            {'* '}
          </GrandTotalLabel>
          <Currency
            testID="sub-total"
            amount={cartTotal}
            isBold
            textProps={{
              margin: 0,
              flexGrow: 0,
            }}
          />
        </TotalsRow>
      );
    }

    if (priceIsLoading) {
      // if loading is true there may have been a pricing error
      // so we should not attempt to render values that depend
      // on the cart
      // We also don't want to show the loading skeleton in this case, just render the prices whenever they are loaded.
      return <></>;
    }

    return (
      <>
        <XStack style={totalSectionStyles.feeRow}>
          <Text style={totalSectionStyles.feeLabel}>
            {formatMessage({
              id: 'subtotal',
            })}
          </Text>
          <Currency amount={cartTotal} textProps={totalSectionStyles.feeLabel} />
        </XStack>
        <TaxesAndFees
          deliveryFee={deliveryFee}
          deliveryFeeDiscount={deliveryFeeDiscount}
          donationCents={donationAmount ?? 0}
          dropoffState={dropoffState}
          serviceFeeCents={delivery?.serviceFeeCents ?? 0}
          smallCartFeeCents={delivery?.smallCartFeeCents ?? 0}
          geographicalFeeCents={delivery?.geographicalFeeCents ?? 0}
          baseDeliveryFeeCents={delivery?.baseDeliveryFeeCents ?? 0}
          deliverySurchargeFeeCents={delivery?.deliverySurchargeFeeCents ?? 0}
          tipCents={orderContext.tipAmount}
          taxCents={taxCents}
          serviceMode={serviceMode}
          subTotalCents={subTotalCents}
          isLoading={loading || deliveryLoading}
          isCartPage
        />
        <Separator />
        <GrandTotal
          cart={cart}
          serverOrderTip={serverOrderTip}
          tipAmount={isDeliveryOrder && !hideTipAmount ? orderContext.tipAmount : 0}
        />
      </>
    );
  };

  return (
    <Wrapper>
      <Box gap="$1">{isDeliveryOrder ? renderDeliveryContent() : renderPickupContent()}</Box>
    </Wrapper>
  );
};

export default React.memo(Totals);
