import React, { useCallback, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, YStack, tokens, useMqSelect } from '@fhs/ui';

import { useComboSelectionContext } from '../../../state/combo-selection';
import { MenuPickerProvider, useMenuPicker } from '../../../state/menu-picker';
import { GuideCurrentSelection } from '../current-selection';
import { ItemList } from '../item-list';
import { GuideLabeledDivider } from '../labeled-divider';
import { GuideSubheading } from '../subheading';

import { ItemDetailsContent } from './item-details-content';
import { ItemDetailsFooter } from './item-details-footer';

export const ItemDetailsContainer = ({
  selectedItemSlug,
  isCustomization,
  currentStep,
  onSaveItem,
}: {
  selectedItemSlug: string;
  isCustomization: boolean;
  currentStep: any;
  onSaveItem: () => void;
}) => {
  const menuPicker = useMenuPicker();
  const { addOrUpdateSelectedPicker } = useComboSelectionContext();

  // Mount and save the picker object using current menu picker
  const handleSaveItem = useCallback(() => {
    const {
      query,
      slug: itemSlug,
      displayName,
      priceCents,
      instructions,
      selectedPickerAspectOptionId,
      selectedPickerAspectOption,
      customizationSelections,
      defaultCustomizationSelections,
      draftCustomizationSelections,
      pickerSelections,
      customizationDetailedSelections,
      image,
    } = menuPicker;

    const updatedPicker = {
      id: query.data?.id,
      slug: itemSlug,
      displayName,
      priceCents,
      instructions,
      selectedPickerAspectOptionId,
      selectedPickerAspectOption,
      customizationSelections,
      defaultCustomizationSelections,
      draftCustomizationSelections,
      pickerSelections,
      customizationDetailedSelections,
      image,
      requiresCustomization: isCustomization,
    };

    addOrUpdateSelectedPicker(currentStep.id, updatedPicker);
    onSaveItem();
  }, [addOrUpdateSelectedPicker, onSaveItem, menuPicker, currentStep, isCustomization]);

  // Side-effect to handle adding the item after the menu picker is loaded
  useEffect(() => {
    // Item is selected and has no customization, so we want to add it on the selections already
    if (!isCustomization && selectedItemSlug && menuPicker.query.isFetched) {
      handleSaveItem();
    }
  }, [isCustomization, selectedItemSlug, menuPicker.query.isFetched, handleSaveItem]);

  // If the picker has customization, we want to customize it before adding in the combo
  if (isCustomization) {
    return (
      <View style={{ flex: 1 }}>
        <ItemDetailsContent selectedPickerId={currentStep.id} />
        <ItemDetailsFooter onSaveItem={handleSaveItem} />
      </View>
    );
  }

  return <></>;
};

export const ItemContainer = ({
  selectedItemSlug,
  isCustomization,
  currentStep,
  onSaveItem,
  onSkip,
  onSelectItem,
  currentStepSelectedItemId,
}) => {
  const { selectedPickers, isEditingPickers } = useComboSelectionContext();
  const desktopStyle = useMqSelect({ $gteDesktop: { paddingHorizontal: 28 } }, {});

  const selectedPicker = selectedPickers[currentStep.id];
  const showPreviousSelection = isEditingPickers && selectedPicker && !isCustomization;
  const previousSelectionTitle =
    showPreviousSelection && selectedPicker.requiresCustomization
      ? 'Edit Your Selection:'
      : 'Your Selection:';

  return (
    <View style={{ flex: 1 }}>
      <YStack style={[{ flex: 11 }, desktopStyle]}>
        {showPreviousSelection && (
          <YStack style={styles.editSelectionSection}>
            <GuideSubheading text={previousSelectionTitle} />
            <GuideCurrentSelection
              label={selectedPicker.displayName}
              image={selectedPicker.image}
              onEditPress={
                selectedPicker.requiresCustomization
                  ? () =>
                      // When editing, we want to open the product details page, regardless of the item having customization
                      onSelectItem({
                        itemId: selectedPicker.id,
                        itemSlug: selectedPicker.slug,
                        requiresCustomization: true,
                      })
                  : undefined
              }
            />
            <GuideLabeledDivider style={styles.currentSelectionDivider} labelText="or" />
          </YStack>
        )}
        <MenuPickerProvider slug={selectedItemSlug}>
          <ItemDetailsContainer
            isCustomization={isCustomization}
            currentStep={currentStep}
            selectedItemSlug={selectedItemSlug}
            onSaveItem={onSaveItem}
          />
        </MenuPickerProvider>
        {!isCustomization && (
          <ItemList
            currentStep={currentStep}
            currentStepSelectedItemId={currentStepSelectedItemId}
            onSelectItem={onSelectItem}
          />
        )}
      </YStack>
      {showPreviousSelection && (
        <YStack style={styles.listEditFooter}>
          <Button type="outline" onPress={onSkip} style={styles.skipButton}>
            <Button.Text style={styles.skipButtonText}>Skip</Button.Text>
          </Button>
        </YStack>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  editSelectionSection: {
    gap: 12,
    padding: 16,
  },
  currentSelectionDivider: {
    marginTop: 4,
  },
  listEditFooter: {
    backgroundColor: tokens.colors.$white,
    padding: 16,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 52,
    borderTopWidth: 1,
    borderColor: tokens.colors.$black10,
  },
  skipButton: {
    flex: 1,
    fontSize: 18,
    height: 48,
  },
  skipButtonText: {
    fontSize: 18,
  },
});
