import { Image, ImageBackground } from 'expo-image';
import { StyleSheet, View } from 'react-native';

import { IconDelivery, IconStore, YStack } from '@fhs/ui';
import { useClickLogs } from '@fhs-legacy/frontend/src/hooks/use-click-logs';

import { CtaButton } from '../shared/cta-button';

export type WelcomeCardProps = {
  pickUpHref: string;
  deliveryHref: string;
};

export function WelcomeCard({ pickUpHref, deliveryHref, ...props }: WelcomeCardProps) {
  const { logPickUpOrderClickEvent, logDeliveryOrderClickEvent } = useClickLogs();

  return (
    <ImageBackground
      source={require('./welcome-card-background.webp')}
      placeholder={{ thumbhash: 'tigKI4YIiLiKidin93Zzfxc' }}
      {...props}
      style={styles.welcomeBanner}
    >
      <YStack style={styles.contentContainer}>
        <Image
          source={require('./welcome.webp')}
          alt="Welcome to Firehouse Subs"
          placeholder={{ thumbhash: 'TKmCDIQkR4eMhn0Gd5b4h4SPgItHmHeGBQ' }}
          contentFit="contain"
          style={styles.welcomeImage}
        />
        <Image
          source={require('./start-your-order.webp')}
          alt="Start your order"
          placeholder={{ thumbhash: 'wQeCAYARCGeId3j3d0ZQOCeIiHeAe6c' }}
          contentFit="contain"
          style={styles.startYourOrderImage}
        />
        <View style={styles.buttonsContainer}>
          <CtaButton
            href={pickUpHref}
            onPress={logPickUpOrderClickEvent}
            text="Pick Up Order"
            icon={<IconStore />}
          />
          <CtaButton
            href={deliveryHref}
            onPress={logDeliveryOrderClickEvent}
            text="Delivery Order"
            icon={<IconDelivery />}
            type="outline"
          />
        </View>
      </YStack>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  welcomeBanner: {
    aspectRatio: 1920 / 757,
    position: 'relative',
  },
  welcomeImage: {
    aspectRatio: 415 / 333,
    width: '17%',
  },
  startYourOrderImage: {
    aspectRatio: 610 / 35,
    width: '30%',
    marginTop: 36,
  },
  contentContainer: {
    top: '20%',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 32,
    gap: 12,
  },
});
