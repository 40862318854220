import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { HStack, Text } from '@fhs-legacy/universal-components';
import { NumberInput } from 'components/ucl/number-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ClickEventComponentNames } from 'state/crm-events/constants';
import { useOrderContext } from 'state/order';
import { MAX_CART_QUANTITY, MIN_CART_QUANTITY, getMenuObjectCartQuantity } from 'utils/cart';

import { useRedeemReward } from '../redeem-reward/use-redeem-reward';
import { ICartItemQuantityProps } from '../types';

import {
  displayValueStyleProps,
  incrementorStyleProps,
  showQuantityLabel,
} from './number-input-props';

enum IncrementorActions {
  Increment = 'increment',
  Decrement = 'decrement',
}

const CartItemQuantity: React.FC<React.PropsWithChildren<ICartItemQuantityProps>> = ({
  item,
  updateQuantity,
}) => {
  const { formatMessage } = useIntl();

  const { hasSufficientBalance, incentiveNotInMenu, limitPerOrderMet } = useRedeemReward(item);
  const { cartEntries } = useOrderContext() ?? {};
  const { maxCartQuantityMet } = getMenuObjectCartQuantity({ menuObject: item, cartEntries });
  const disableForRewardRedemption =
    (incentiveNotInMenu && !hasSufficientBalance) || limitPerOrderMet;
  const isDecrementDisabled = item.isExtra || item.quantity === MIN_CART_QUANTITY;
  const isIncrementDisabled = item.isExtra || disableForRewardRedemption || maxCartQuantityMet;

  const { logRBIEvent } = useCRMEventsContext();

  const handleQuantityChange = useCallback(
    (value: number) => {
      logRBIEvent({
        name: CustomEventNames.CLICK_EVENT,
        type: EventTypes.Other,
        attributes: {
          component: ClickEventComponentNames.BUTTON,
          text:
            value < item.quantity ? 'Checkout Product Decrementor' : 'Checkout Product Incrementor',
        },
      });
      updateQuantity(
        value,
        value < item.quantity ? IncrementorActions.Decrement : IncrementorActions.Increment
      );
    },
    [item.quantity, updateQuantity]
  );

  const decrementAriaLabel = formatMessage({ id: 'decrementName' }, { name: item.name });
  const incrementAriaLabel = formatMessage({ id: 'incrementName' }, { name: item.name });
  const ariaLabel = formatMessage(
    { id: 'quantityUpdatedAlert' },
    {
      quantity: item.quantity,
      itemName: item.name,
    }
  );

  return (
    <HStack alignItems="center">
      {showQuantityLabel && (
        <Text marginRight={4} color={Styles.color.black}>
          {item.labelAsPerPerson
            ? formatMessage({
                id: 'people',
              })
            : formatMessage({
                id: 'quantity',
              })}
          :
        </Text>
      )}
      <NumberInput
        testID="modifier-number-input"
        min={MIN_CART_QUANTITY}
        max={MAX_CART_QUANTITY}
        disabled={item.isExtra}
        disableDecrement={isDecrementDisabled}
        disableIncrement={isIncrementDisabled}
        hideNumberInput
        value={item.quantity}
        onChange={handleQuantityChange}
        displayedValue={item.quantity}
        ariaLabel={`${item.quantity}-${item.name}`}
        decrementAriaLabel={decrementAriaLabel}
        incrementAriaLabel={incrementAriaLabel}
        displayValueStyleProps={displayValueStyleProps}
        incrementorStyleProps={incrementorStyleProps}
      />
      <VisuallyHidden accessibilityLabel={ariaLabel} role="alert" />
    </HStack>
  );
};
export default CartItemQuantity;
