import { Platform } from 'react-native';

import { Header } from '@fhs/backend';
export { Header } from '@fhs/backend';
import { getConfigValue } from '@fhs-legacy/frontend/src/utils/environment';
import { loadLanguage } from '@fhs-legacy/frontend/src/utils/intl/language';
import { loadRegion } from '@fhs-legacy/frontend/src/utils/intl/region';

const framework = Platform.select({
  native: 'react-native',
  default: 'react-dom',
});

type HttpHeaders = Record<Header, string>;

/**
 * HTTP headers to be sent with each GraphQL request
 *
 * Since this value is only consumed by the Apollo client, it is not necessary to
 * trigger a re-render when the value changes. Therefore, we can use a plain object.
 */
export const headers: Partial<HttpHeaders> = {
  [Header.LANGUAGE]: loadLanguage(),
  [Header.UI_PLATFORM]: Platform.select({ web: 'web', default: 'app' }),
  /** This header is used to identify when to send forter the cookie vs the mobile token for fraud protection */
  [Header.PLATFORM_FRAMEWORK]: framework,
  [Header.UI_VERSION]: getConfigValue({ key: 'applicationVersion' }),
  [Header.UI_COMMIT_SHA]: getConfigValue({ key: 'revisionId' }),
  [Header.REGION]: loadRegion(),
};
/** Setter for dynamic header values to be sent with each GraphQL request */
export const setHeaders = (key: Header, value: string) => {
  headers[key] = value;
};
