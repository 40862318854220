import React, { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, EmailInput } from '@fhs/ui';

import { AuthIdentifiers, useAuthV2Context } from '../context';

export const ContinueWithEmail = () => {
  const { createOtp } = useAuthV2Context();
  const [loginIdentifier, setLoginIdentifier] = useState<string>();

  const attemptLogin = useCallback(async () => {
    await createOtp({
      loginIdentifier,
      identifierType: AuthIdentifiers.EMAIL,
    });
  }, [createOtp, loginIdentifier]);
  return (
    <>
      <EmailInput value={loginIdentifier} onChange={setLoginIdentifier} />
      <View style={styles.continueButton}>
        <Button onPress={attemptLogin}>
          <Button.Text>Continue</Button.Text>
        </Button>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  continueButton: {
    height: 48,
    marginVertical: 12,
    width: '100%',
  },
});
