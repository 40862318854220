import {
  LazyQueryHookOptions,
  LazyQueryResultTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  SkipToken,
  SuspenseQueryHookOptions,
  TypedDocumentNode,
  UseSuspenseQueryResult,
  useLazyQuery,
  useQuery,
  useSuspenseQuery,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { merge } from 'lodash';

import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { usePrevious } from 'hooks/use-previous';
import { useLocale } from 'state/intl';
import { useNetworkContext } from 'state/network';

const useSanityGqlEndpoint = (isV2: boolean = false) => {
  const { sanityEndpoints } = useNetworkContext();
  return isV2 ? sanityEndpoints.graphqlV2 : sanityEndpoints.graphql;
};

export function useSanityQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> = {},
  sanityOptions: { isV2: boolean } = { isV2: false }
): QueryResult<TData, TVariables> {
  const uri = useSanityGqlEndpoint(sanityOptions.isV2);
  const { language, region } = useLocale();
  const prevLanguage = usePrevious(language);
  const prevRegion = usePrevious(region);

  const queryOptions = merge(options, { context: { uri } });

  const queryResult = useQuery<TData, TVariables>(query, queryOptions);

  const { refetch } = queryResult;

  useEffectOnUpdates(() => {
    if (prevLanguage !== language || prevRegion !== region) {
      refetch();
    }
  }, [prevLanguage, prevRegion, language, region, refetch]);

  return queryResult;
}

export function useLazySanityQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>> = {},
  sanityOptions: { isV2: boolean } = { isV2: false }
): LazyQueryResultTuple<TData, TVariables> {
  const uri = useSanityGqlEndpoint(sanityOptions.isV2);

  const queryOptions = merge(options, { context: { uri } });

  return useLazyQuery<TData, TVariables>(query, queryOptions);
}

// Factory function to create the hook with a high level configuration and be able to reuse the generated hook
// NOTE: Untested, use at own risk!
export function useSuspenseSanityQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode,
  options: SuspenseQueryHookOptions<TData, TVariables> | SkipToken = {},
  sanityOptions: { isV2: boolean } = { isV2: false }
): UseSuspenseQueryResult<TData, TVariables> {
  const uri = useSanityGqlEndpoint(sanityOptions.isV2);
  const { language, region } = useLocale();
  const prevLanguage = usePrevious(language);
  const prevRegion = usePrevious(region);

  const queryOptions = merge(options, { context: { uri } });

  const queryResult = useSuspenseQuery<TData, TVariables>(query, queryOptions);

  const { refetch } = queryResult;

  useEffectOnUpdates(() => {
    if (prevLanguage !== language || prevRegion !== region) {
      refetch();
    }
  }, [prevLanguage, prevRegion, language, region, refetch]);

  return queryResult;
}
