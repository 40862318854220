import { GestureResponderEvent } from 'react-native';

import { OrderDisplayType } from '@fhs/ui/src/components/order-display/types';

import { HomeAlertLaunchDarklyPayload } from './home-alert';

export type DisplayedOrder = {
  id: string;
  type: OrderDisplayType;
  storeName: string;
  serviceModeTitle: string;
  items: Array<{
    quantity: number;
    name: string;
    image: string;
  }>;
};

export type ScreenHomeProps = {
  isDeliveryEnabled: boolean;
  isCateringEnabled: boolean;
  shouldShowDelivery: boolean;
  alert: HomeAlertLaunchDarklyPayload | null;
  userPointExpireAlert: HomeAlertLaunchDarklyPayload | null;
  displayedOrder: null | DisplayedOrder;
  cateringRedirectPath: string;
  pickUpRedirectPath: string;
  deliveryRedirectPath: string;
  foundationPath: string;
  careersPath: string;
  reorderLoading?: boolean;
  displayedOrderCardLoading?: boolean;
  redirectHref: string;
  donateHref: string;
  onDeliverySelect: (event: GestureResponderEvent) => void;
  onReorder: () => void;
  logReorderCardPress: () => void;
  logPickUpOrderClickEvent: (event: GestureResponderEvent) => void;
  logDeliveryOrderClickEvent: (event: GestureResponderEvent) => void;
  logCateringOrderClickEvent: (event: GestureResponderEvent) => void;
};
