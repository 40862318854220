import { client } from '@fhs/client';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';

import type { MenuPickerContext } from '../state/menu-picker';
import { ComboItem } from '../types';

const convertCustomizationSelectionsToModifiersForCart = (
  customizationSelections: MenuPickerContext['customizationSelections']
) => {
  const modifiers: Array<{ id: string; quantity: number }> = [];

  for (const customizationOptionKey in customizationSelections) {
    const selection = customizationSelections[customizationOptionKey];

    if (typeof selection === 'string') {
      modifiers.push({ id: `${customizationOptionKey}:${selection}`, quantity: 1 });
      continue;
    }

    for (const customizationOptionOptionKey in selection) {
      const qty = selection[customizationOptionOptionKey];

      if (qty === 0) {
        continue;
      }

      modifiers.push({
        id: `${customizationOptionKey}:${customizationOptionOptionKey}`,
        quantity: qty,
      });
    }
  }

  return modifiers;
};

export function useAddToCart() {
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();
  const { addItemToCart } = useOrderContext();
  const storeId = store?.number;
  const simplyBetterCartServiceEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE
  );

  return async ({
    itemId,
    quantity,
    customizationSelections,
    isCombo = false,
    pickerSelections,
    comboItems = [],
    price,
  }: {
    itemId: string;
    quantity: number;
    customizationSelections: MenuPickerContext['customizationSelections'];
    isCombo?: boolean;
    pickerSelections: MenuPickerContext['pickerSelections'];
    comboItems?: ComboItem[];
    price: number;
  }) => {
    if (!serviceMode || !storeId || simplyBetterCartServiceEnabled) {
      // @TODO Do something about this situation?
      return;
    }

    const { data: cartEntry, errors } = await client.queries.convertMenuItemFormat({
      storeId,
      serviceMode,
      itemId,
      quantity,
      isCombo,
      comboItemsJson: JSON.stringify(comboItems),
      modifiersJson: JSON.stringify(
        convertCustomizationSelectionsToModifiersForCart(customizationSelections)
      ),
      pickerSelections: JSON.stringify(pickerSelections),
      price,
    });

    if (errors || !cartEntry) {
      //@TODO: Handle these errors...
      return;
    }

    addItemToCart(reifyCartEntry(cartEntry));
  };
}

function reviver(key: string, value: unknown) {
  if ((key === 'children' || key === 'pickerSelections') && typeof value === 'string') {
    return JSON.parse(value, reviver);
  }

  return value;
}

// 'convertMenuItemFormat' Endpoint is sending back 'children' and 'pickerSelections'
// still in JSON format. We need to fully parse them before adding to cart
const reifyCartEntry = (obj: Record<string, unknown>) => {
  const copy = { ...obj };

  if ('children' in obj && typeof obj.children === 'string') {
    copy.children = JSON.parse(obj.children, reviver);
  }

  if ('pickerSelections' in obj && typeof obj.pickerSelections === 'string') {
    copy.pickerSelections = JSON.parse(obj.pickerSelections, reviver);
  }

  return copy;
};
