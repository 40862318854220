import { router, useFocusEffect } from 'expo-router';
import { useCallback, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Button,
  ErrorBoundary,
  MqSwitch,
  NotFound,
  QuantityCounter,
  createMqStyles,
  tokens,
} from '@fhs/ui';
import { isWeb } from '@fhs/utils';

import { MakeComboActionSheet } from '../components/make-combo-action-sheet';
import { MenuErrorFallback } from '../components/menu-error-fallback';
import { MenuItemDetails } from '../components/menu-item-details';
import { MenuItemSkeletonLoading } from '../components/menu-item-skeleton';
import { useConfirmUnsavedCustomizationModal } from '../components/use-confirm-unsaved-customization-modal';
import { useComboSelectionContext } from '../state/combo-selection';
import { useMenuPicker } from '../state/menu-picker';
import { mapPickerSelections } from '../utils';
import { useAddToCart } from '../utils/use-add-to-cart';
import { useMenuItemPricePreview } from '../utils/use-menu-item-price-preview';

export function ScreenMenuItem({ menuPicker }: { menuPicker: ReturnType<typeof useMenuPicker> }) {
  const mqStyles = useMqStyles();
  const { selectedPickers, isComboEnabled } = useComboSelectionContext();

  const [isPending, setIsPending] = useState(false);
  const [isShowingNutrition, setIsShowingNutrition] = useState(false);

  const [quantity, setQuantity] = useState(1);
  const addToCart = useAddToCart();

  const { hasUnsavedCustomizationSelections } = menuPicker;
  const { confirmationDialog, openConfirmationDialog } = useConfirmUnsavedCustomizationModal();
  useFocusEffect(
    useCallback(() => {
      if (hasUnsavedCustomizationSelections && isWeb) {
        openConfirmationDialog();
      }
    }, [hasUnsavedCustomizationSelections, openConfirmationDialog])
  );

  const { totalPriceCents, formattedTotalPrice } = useMenuItemPricePreview({
    menuPicker,
    quantity,
  });

  // Check fotter requirement
  const requiresPickAspectOptionSelection = menuPicker.query.data?.pickerAspect.options.length > 1;
  const hasCustomizationGroups =
    (menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups ?? []).length > 0;
  const hasCustomizationsContent = requiresPickAspectOptionSelection || hasCustomizationGroups;

  const [showMakeComboActionSheet, setShowMakeComboActionSheet] = useState(false);

  const handleAddToCart = async () => {
    // Always toggle show errors to on, it doesn't matter if there are existing error messages or not.
    menuPicker.setShowErrors(true);

    if (Object.keys(menuPicker.errorMessages).length) {
      // There are errors, don't submit
      return;
    }

    const itemId = menuPicker.selectedPickerAspectOption?.itemId;

    if (!itemId) {
      throw new Error('No itemId found');
    }

    setIsPending(true);
    try {
      const comboItems = mapPickerSelections(selectedPickers);

      await addToCart({
        itemId,
        quantity,
        isCombo: isComboEnabled,
        customizationSelections: menuPicker.customizationSelections,
        pickerSelections: menuPicker.pickerSelections,
        price: totalPriceCents,
        comboItems,
      });

      router.push('/v2/menu');
    } finally {
      setIsPending(false);
    }
  };

  return (
    <View style={styles.screen}>
      <MakeComboActionSheet
        isOpen={showMakeComboActionSheet}
        onClose={() => setShowMakeComboActionSheet(false)}
        menuPicker={menuPicker}
        onCancel={() => handleAddToCart()}
      />
      {confirmationDialog}
      <ErrorBoundary fallback={<MenuErrorFallback />}>
        <View style={styles.flex1}>
          <MenuItemDetails
            isShowingNutrition={isShowingNutrition}
            setIsShowingNutrition={setIsShowingNutrition}
          />
        </View>

        <View style={styles.layout}>
          <View style={[mqStyles.footer, !hasCustomizationsContent && mqStyles.footerCenter]}>
            <QuantityCounter
              size="md"
              value={quantity}
              onChangeValue={newValue => setQuantity(newValue)}
              maxValue={10}
              minValue={1}
            >
              <QuantityCounter.Stepper />
            </QuantityCounter>
            <Button
              loading={isPending}
              onPress={async () => {
                if (!!menuPicker.query.data?.combo && !Object.keys(selectedPickers).length) {
                  setShowMakeComboActionSheet(true);
                  return;
                }
                handleAddToCart();
              }}
              style={[
                styles.flex1,
                mqStyles.addItemCTAButton,
                !hasCustomizationsContent && mqStyles.addItemCTAButtonCenter,
              ]}
            >
              <MqSwitch
                $base={() => (
                  <Button.Text style={styles.fontSize16}>
                    {['Add Item', formattedTotalPrice].filter(Boolean).join(' | ')}
                  </Button.Text>
                )}
                $gteDesktop={() => (
                  <View style={styles.addItemToCartButton}>
                    <Button.Text style={styles.fontSize16}>Add to Order</Button.Text>
                    <Button.Text style={styles.fontSize16}>{formattedTotalPrice}</Button.Text>
                  </View>
                )}
              />
            </Button>
          </View>
        </View>
      </ErrorBoundary>
    </View>
  );
}

export function ScreenMenuItemWithQuery() {
  const menuPicker = useMenuPicker();

  if (menuPicker.query.isLoading) {
    return (
      <View style={styles.screen}>
        <MenuItemSkeletonLoading />
      </View>
    );
  }

  // TODO: add refresh logic
  if (menuPicker.query.isError) {
    return (
      <View style={styles.screen}>
        <MenuErrorFallback />
      </View>
    );
  }

  if (!menuPicker.query.data) {
    return <NotFound buttonText="Go To Menu" buttonUrl="/menu" />;
  }

  return <ScreenMenuItem menuPicker={menuPicker} />;
}

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    backgroundColor: tokens.colors.$white,
  },
  flex1: {
    flex: 1,
  },
  layout: {
    backgroundColor: tokens.colors.$white,
    padding: 16,
    paddingBottom: 8,
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
  addItemToCartButton: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
  },
  fontSize16: {
    fontSize: 16,
  },
});

const useMqStyles = createMqStyles({
  footer: {
    $base: {
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: 28,
    },
    $gteDesktop: {
      justifyContent: 'flex-end',
      maxWidth: 1440,
      paddingRight: 52,
      gap: 52,
    },
  },
  footerCenter: {
    $gteDesktop: {
      justifyContent: 'space-between',
      maxWidth: 548,
      paddingRight: 0,
      gap: 52,
    },
  },
  addItemCTAButton: {
    $base: {
      maxWidth: 336,
    },
    $gteDesktop: {
      right: 26,
    },
  },
  addItemCTAButtonCenter: {
    $gteDesktop: {
      right: 0,
    },
  },
});
