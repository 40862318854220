import { format } from 'date-fns';
import React, { useEffect, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, Button, IconCalendarNextFill, IconTime, Text, tokens } from '@fhs/ui';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';

const NextAvailableTimeCard = ({ futureDate }: { futureDate: Date }) => {
  const hourFormatted = useMemo(() => format(futureDate, 'h:mm a'), [futureDate]);
  const dateFormatted = useMemo(() => format(futureDate, 'MMMM do'), [futureDate]);
  return (
    <View style={styles.nextAvailableTimeCard}>
      <View style={styles.iconTimeContainer}>
        <IconTime size={24} />
      </View>

      <View style={{ marginLeft: 12 }}>
        <Text.Ui size="xs">Next Pick Up Time:</Text.Ui>
        <Text.Ui size="md" weight="semibold">
          Tomorrow at {hourFormatted}
        </Text.Ui>
        <Text.Ui size="sm">{dateFormatted}</Text.Ui>
      </View>
    </View>
  );
};

interface Props {
  isVisible: boolean;
  onPressOk: () => void;
  onClose: () => void;
  futureDate: Date;
}

export const FutureOrderWarning = ({ isVisible, onPressOk, futureDate, onClose }: Props) => {
  const { logRBIEvent } = useCRMEventsContext();

  useEffect(() => {
    logRBIEvent({
      name: CustomEventNames.ACTION_SHEET_VIEW,
      type: EventTypes.Other,
      attributes: {
        actionSheetHeader: 'The Store Is Closing',
      },
    });
  }, [logRBIEvent]);

  return (
    <ActionSheet grabber={true} isVisible={isVisible} onClose={onClose}>
      <View style={{ padding: 16 }}>
        <View style={styles.iconCalendarContainer}>
          <IconCalendarNextFill size={40} />
        </View>
        <Text.Heading type="two" style={styles.title}>
          The Store Is Closing
        </Text.Heading>
        <Text.Ui size="lg" style={styles.description}>
          Your order can't be completed today, but you can schedule it for tomorrow.
        </Text.Ui>
        <NextAvailableTimeCard futureDate={futureDate} />
        <Button size="xl" onPress={onPressOk} style={{ marginTop: 24 }}>
          <Button.Text>Okay</Button.Text>
        </Button>
      </View>
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  nextAvailableTimeCard: {
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
    flexDirection: 'row',
    padding: 16,
    marginTop: 24,
    alignItems: 'center',
  },
  iconTimeContainer: {
    padding: 8,
    backgroundColor: tokens.colors.$blackOpacity04,
    height: 40,
    width: 40,
    borderRadius: 100,
  },
  iconCalendarContainer: {
    padding: 8,
    backgroundColor: tokens.colors.$blackOpacity04,
    height: 64,
    width: 64,
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  title: {
    textAlign: 'center',
    marginTop: 16,
  },
  description: {
    textAlign: 'center',
    marginTop: 8,
  },
});
