import { Image } from 'expo-image';
import { StyleSheet } from 'react-native';

import { Button, ImageAssetWithAltText, Text, XStack, YStack, tokens } from '@fhs/ui';

import { optimizeSanityImageUri } from '../../utils';

type CurrentSelectionProps = {
  image?: ImageAssetWithAltText;
  label: string;
  onEditPress?: () => void;
};

const CURRENT_SELECTION_IMAGE_DIMENSION = 56;

export function GuideCurrentSelection(props: CurrentSelectionProps) {
  return (
    <XStack style={styles.outerContainer}>
      {props.image?.asset?.uri && (
        <Image
          contentFit="contain"
          style={styles.image}
          alt={props.image.altText}
          source={{
            uri: optimizeSanityImageUri(props.image.asset.uri, CURRENT_SELECTION_IMAGE_DIMENSION),
          }}
          placeholder={{ blurhash: props.image.asset.blurHash }}
        />
      )}
      <XStack style={styles.innerContainer}>
        <YStack style={styles.textStack}>
          <Text style={styles.currentSelectionText} ellipsizeMode="tail" numberOfLines={1}>
            Current Selection
          </Text>
          <Text weight="semibold" style={styles.label} ellipsizeMode="tail" numberOfLines={1}>
            {props.label}
          </Text>
        </YStack>
        {props.onEditPress && (
          <Button size="xs" type="solid" onPress={props.onEditPress}>
            <Button.Text>Edit</Button.Text>
          </Button>
        )}
      </XStack>
    </XStack>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 16,
    gap: 12,
  },
  image: {
    width: CURRENT_SELECTION_IMAGE_DIMENSION,
    height: CURRENT_SELECTION_IMAGE_DIMENSION,
    maxWidth: CURRENT_SELECTION_IMAGE_DIMENSION,
    maxHeight: CURRENT_SELECTION_IMAGE_DIMENSION,
  },
  innerContainer: {
    paddingVertical: 8,
    gap: 16,
    flexShrink: 1,
    flexGrow: 1,
    alignItems: 'center',
  },
  textStack: {
    flexShrink: 1,
    flexGrow: 1,
  },
  currentSelectionText: {
    color: tokens.colors.$blackOpacity75,
    fontSize: 10,
    lineHeight: 14,
  },
  label: {
    fontSize: 14,
    lineHeight: 19.6,
  },
});
