import { client, useQuery } from '@fhs/client';

import type { MenuGuideOpts } from './types';

export const useMenuGuideQuery = ({
  comboId,
  restaurantId,
  serviceMode,
  region,
  enabled,
}: MenuGuideOpts) =>
  useQuery({
    enabled: Boolean(enabled && restaurantId && serviceMode),
    queryKey: ['menus', comboId, restaurantId, serviceMode],
    queryFn: async () => {
      if (!(comboId && restaurantId && serviceMode)) {
        return;
      }
      const { data: menuGuide, errors } = await client.queries.menuGuide({
        id: comboId,
        restaurantId,
        serviceMode,
        region,
      });

      if (errors?.length) {
        throw new Error(errors[0].message);
      }
      return menuGuide;
    },
  });
