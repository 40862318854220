import { Platform } from 'react-native';

export const isWeb = Platform.OS === 'web';
export const isNative = !isWeb;

export const isMobile =
  (typeof navigator !== 'undefined' && /Mobile/.test(navigator.userAgent)) || isNative;

export const isSafari =
  isWeb &&
  typeof navigator !== 'undefined' &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
