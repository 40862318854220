import { Image } from 'expo-image';
import React from 'react';
import { View } from 'react-native';

import { Checkbox, type CheckboxProps, Text, XStack, useCheckbox } from '@fhs/ui';

import { optimizeSanityImageUri } from '../../utils';

import { BORDER_RADIUS, IMAGE_HEIGHT } from './constants';
import { ListItemUnavailableBadge } from './list-item-unavailable-badge';
import { listStyles } from './shared-styles';
import type { ListItemType } from './types';

type ListItemCheckboxProps = ListItemType & {
  onChange: CheckboxProps['onChange'];
  checked: CheckboxProps['checked'];
  disabled?: CheckboxProps['disabled'];
};

const IndicatorText = ({ indicatorText }: { indicatorText: string }) => {
  const { checked } = useCheckbox();
  return (
    <Text.Ui
      size="sm"
      numberOfLines={1}
      style={checked ? listStyles.indicatorTextSelected : listStyles.indicatorText}
    >
      {indicatorText}
    </Text.Ui>
  );
};

export function ListItemCheckbox({ minHeightStyle = 'static', ...props }: ListItemCheckboxProps) {
  return (
    <Checkbox
      onChange={props.onChange}
      checked={props.checked}
      label={[props.title, props.subtitle].filter(Boolean).join(' - ')}
      disabled={props.disabled}
    >
      <Checkbox.Pressable
        style={[
          listStyles.item,
          minHeightStyle === 'static' && listStyles.minHeightStyleStatic,
          props.isLastItem && listStyles.lastItem,
        ]}
        hoveredStyle={listStyles.itemHover}
        borderTopLeftRadius={props.isFirstItem ? BORDER_RADIUS : 0}
        borderTopRightRadius={props.isFirstItem ? BORDER_RADIUS : 0}
        borderBottomLeftRadius={props.isLastItem ? BORDER_RADIUS : 0}
        borderBottomRightRadius={props.isLastItem ? BORDER_RADIUS : 0}
      >
        {props.image?.asset?.uri && (
          <Image
            contentFit="contain"
            style={[listStyles.image, props.disabled && listStyles.imageDisabled]}
            alt={props.image.altText}
            source={{ uri: optimizeSanityImageUri(props.image.asset.uri, IMAGE_HEIGHT) }}
            placeholder={{ blurhash: props.image.asset.blurHash }}
          />
        )}
        <View style={listStyles.titleTextContainer}>
          <View>
            <Text.Ui
              weight={props.titleFontWeight ?? 'semibold'}
              size="md"
              numberOfLines={1}
              style={props.disabled && listStyles.textDisabled}
            >
              {props.title}
            </Text.Ui>
          </View>
          {Boolean(props.subtitle) && (
            <View>
              <Text.Ui size="sm" numberOfLines={1} style={listStyles.subtitle}>
                {props.subtitle}
              </Text.Ui>
            </View>
          )}
        </View>
        {props.disabled ? (
          <ListItemUnavailableBadge />
        ) : (
          // Hide price and checkbox in case it is unavailable.
          <XStack style={listStyles.indicatorWithTextWrapper}>
            {Boolean(props.indicatorText) && <IndicatorText indicatorText={props.indicatorText} />}
            <Checkbox.Indicator />
          </XStack>
        )}
      </Checkbox.Pressable>
    </Checkbox>
  );
}
