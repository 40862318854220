import { useCallback, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';

import { AlertDialog, BadgeType, Button, createMqStyles, useMqSelect } from '@fhs/ui';

import { useComboSelectionContext } from '../../state/combo-selection';

import { GuideProps } from './types';

export const useGuide = ({ steps, onGuideFinish, onRequestClose }: GuideProps) => {
  const { selectedPickers, setSelectedPickers, setIsComboEnabled } = useComboSelectionContext();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>(
    new Array(steps.length).fill(null)
  );
  const selectedItemsIdsRef = useRef<string[]>(selectedItemsIds);

  const [selectedItemSlug, setSelectedItemSlug] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');
  const [isCustomization, setIsCustomization] = useState(false);

  const currentStep = useMemo(() => {
    return {
      ...steps[currentStepIndex],
      sections: steps[currentStepIndex].sections.map(section => ({
        ...section,
        items: section.items.map(item => ({
          title: item.displayName,
          description: item.descriptionShort,
          badge: item.badge as BadgeType,
          isAvailable: item.isAvailable,
          id: item.id,
          slug: item.slug,
          url: item.url,
          image: item.image,
          requiresCustomization: item.requiresCustomization,
        })),
      })),
    };
  }, [currentStepIndex, steps]);

  const currentStepSelectedItemId = selectedItemsIds[currentStepIndex];
  const nextStepIndex = steps[currentStepIndex + 1] ? currentStepIndex + 1 : undefined;

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState(false);

  const mqStyles = useMqStyles();
  const confirmText = useMqSelect({ $gteDesktopLg: 'Continue Selection' }, 'Stay Here');

  const confirmationDialog = (
    <AlertDialog
      visible={isConfirmationDialogVisible}
      headingContent="You Didn't Finalize Your Combo Selections"
      bodyContent="If you leave now, your combo will not be activated. Are you sure you want to leave?"
      onRequestClose={() => setIsConfirmationDialogVisible(false)}
      onDismiss={() => setIsConfirmationDialogVisible(false)}
      actions={
        <View style={mqStyles.actionsContainer}>
          <Button
            size="xl"
            type="solid"
            onPress={() => {
              setIsConfirmationDialogVisible(false);
            }}
            style={mqStyles.cta}
          >
            <Button.Text>{confirmText}</Button.Text>
          </Button>
          <Button
            size="xl"
            type="outline"
            onPress={() => {
              setSelectedPickers({});
              setIsComboEnabled(false);
              onRequestClose();
              setIsConfirmationDialogVisible(false);
            }}
            style={mqStyles.cta}
          >
            <Button.Text>Leave</Button.Text>
          </Button>
        </View>
      }
    />
  );

  const handleClose = useCallback(() => {
    // Identify missing selections from selectedPickers
    const missingIndexes = steps
      .map((step, index) => (!selectedPickers[step.id]?.slug ? index : null))
      .filter(index => index !== null); // Keep only missing indices

    // Check if all missing selections exist in selectedItemsIdsRef at the same index
    const missingSelectionsExistInRef = missingIndexes.every(index =>
      Boolean(selectedItemsIdsRef.current[index!])
    );

    if (missingIndexes.length > 0 && !missingSelectionsExistInRef) {
      setIsConfirmationDialogVisible(true);
    } else {
      onGuideFinish(selectedItemsIdsRef.current);
    }
  }, [onGuideFinish, setIsConfirmationDialogVisible, steps, selectedPickers, selectedItemsIdsRef]);

  const handleNavigationBack = () => {
    // In case we are customizing a combo item, we want stop the customization instead of going back to previous step
    if (isCustomization) {
      setSelectedItemSlug('');
      setSelectedItemId('');
      setIsCustomization(false);
      return;
    }

    if (currentStepIndex === 0) {
      handleClose();
    } else {
      setCurrentStepIndex(curr => curr - 1);
    }
  };

  const goToNextStep = () => {
    if (nextStepIndex) {
      setCurrentStepIndex(nextStepIndex);
    } else {
      handleClose();
    }
  };

  const handleSaveItemSelection = (itemId: string) => {
    setSelectedItemsIds(curr => {
      const currCopy = [...curr];
      currCopy[currentStepIndex] = itemId;
      selectedItemsIdsRef.current = currCopy;
      return currCopy;
    });
    setSelectedItemSlug(''); // clear item slug after saving an item
    setIsCustomization(false);

    // Design spec: we want a manual delay so the app displays the "selected mark"
    setTimeout(() => {
      goToNextStep();
    }, 500);
  };

  const handleItemSelect = ({
    itemId,
    itemSlug,
    requiresCustomization,
  }: {
    itemId: string;
    itemSlug: string;
    requiresCustomization: boolean;
  }) => {
    setSelectedItemSlug(itemSlug);
    setSelectedItemId(itemId);
    setIsCustomization(requiresCustomization);
  };

  const stepsAmount = steps.length;

  return {
    currentStep,
    stepsAmount,
    handleItemSelect,
    handleSaveItemSelection,
    handleNavigationBack,
    currentStepSelectedItemId,
    selectedItemSlug,
    selectedItemId,
    handleClose,
    isCustomization,
    currentStepIndex,
    setCurrentStepIndex,
    goToNextStep,
    confirmationDialog,
  };
};

const useMqStyles = createMqStyles({
  actionsContainer: {
    $base: {
      gap: 10,
    },
    $gteDesktopLg: {
      flexDirection: 'row-reverse',
    },
  },
  cta: {
    $gteDesktopLg: {
      flex: 1,
    },
  },
});
