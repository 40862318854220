import type { ReactNode } from 'react';
import type { ImageStyle, StyleProp, TextStyle, ViewStyle } from 'react-native';

import type { mediaQueryTests } from './media-query-tests';

export type MediaQueryKey = keyof typeof mediaQueryTests;
export type StyleType = ViewStyle | TextStyle | ImageStyle;
export type StyleTypeIntersection = ViewStyle & TextStyle & ImageStyle;

export type NamedStyles<T> = { [P in keyof T]: StyleType };

export type NamedStylesWithQueries<T> = {
  [P in keyof T]: Partial<{ [K in MediaQueryKey]: StyleType }>;
};
export type NamedResolvedStyles<T> = {
  [P in keyof T]: Array<StyleProp<StyleTypeIntersection>>;
};

export type MediaQueriesStoreState = {
  [query in MediaQueryKey]: boolean;
};

export type UseMqSelectQueries<T> = Partial<{
  [query in MediaQueryKey]: T;
}>;

export type MqSwitchProps = UseMqSelectQueries<() => ReactNode>;

export interface UseMediaQueries {
  (): MediaQueriesStoreState;
  <U>(selector: (state: MediaQueriesStoreState) => U, equalityFn?: (a: U, b: U) => boolean): U;
}
