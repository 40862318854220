/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const cart = /* GraphQL */ `query Cart {
  cart {
    appliedIncentives {
      appliedCartEntries
      benefitErrors {
        id
        __typename
      }
      description
      offerId
      ruleErrors {
        id
        __typename
      }
      savingsAmount
      title
      type
      __typename
    }
    cartType
    createdAt
    deleteItemAt
    delivery {
      dropoffAddress {
        addressLine1
        addressLine2
        city
        country
        deliveryInformation {
          arrivalTime
          estimatedTimeWindow {
            afterTime
            beforeTime
            __typename
          }
          __typename
        }
        latitude
        longitude
        state
        zip
        __typename
      }
      phoneNumber
      pickupAddress {
        addressLine1
        addressLine2
        city
        country
        deliveryInformation {
          arrivalTime
          estimatedTimeWindow {
            afterTime
            beforeTime
            __typename
          }
          __typename
        }
        latitude
        longitude
        state
        zip
        __typename
      }
      quoteId
      __typename
    }
    donationAmount
    entries {
      displayName
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      instructions
      itemId
      lineId
      modifiers {
        displayName
        id
        price
        quantity
        __typename
      }
      pointCost
      price
      slug
      __typename
    }
    id
    metadata {
      pricingData {
        fees {
          name
          totalValueCents
          __typename
        }
        subTotalCents
        taxCents
        totalCents
        __typename
      }
      userSelectedOrderTime
      __typename
    }
    rbiOrderId
    serviceMode
    state
    store {
      addressLine1
      addressLine2
      city
      country
      id
      latitude
      longitude
      name
      phoneNumber
      posRestaurantId
      posVendor
      state
      zip
      __typename
    }
    updatedAt
    userId
    version
    __typename
  }
}
` as GeneratedQuery<APITypes.CartQueryVariables, APITypes.CartQuery>;
export const convertMenuItemFormat = /* GraphQL */ `query ConvertMenuItemFormat(
  $comboItemsJson: AWSJSON
  $isCombo: Boolean
  $itemId: String!
  $modifiersJson: AWSJSON!
  $pickerSelections: AWSJSON!
  $price: Int!
  $quantity: Int!
  $serviceMode: ConvertMenuItemFormatServiceMode
  $storeId: String!
) {
  convertMenuItemFormat(
    comboItemsJson: $comboItemsJson
    isCombo: $isCombo
    itemId: $itemId
    modifiersJson: $modifiersJson
    pickerSelections: $pickerSelections
    price: $price
    quantity: $quantity
    serviceMode: $serviceMode
    storeId: $storeId
  ) {
    _id
    cartId
    children
    image {
      asset {
        _id
        __typename
      }
      __typename
    }
    isDonation
    isExtra
    lineId
    name
    pickerSelections
    price
    quantity
    sanityId
    type
    url
    vendorConfigs {
      ncr {
        constantPlu
        parentChildPlu
        pluType
        sizeBasedPlu {
          comboPlu
          comboSize
          __typename
        }
        __typename
      }
      ncrDelivery {
        constantPlu
        parentChildPlu
        pluType
        sizeBasedPlu {
          comboPlu
          comboSize
          __typename
        }
        __typename
      }
      toast {
        constantPlu
        parentChildPlu
        pluType
        sizeBasedPlu {
          comboPlu
          comboSize
          __typename
        }
        __typename
      }
      toastDelivery {
        constantPlu
        parentChildPlu
        pluType
        sizeBasedPlu {
          comboPlu
          comboSize
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConvertMenuItemFormatQueryVariables,
  APITypes.ConvertMenuItemFormatQuery
>;
export const convertToMenuItem = /* GraphQL */ `query ConvertToMenuItem(
  $cartEntry: AWSJSON!
  $serviceMode: ConvertToMenuItemServiceMode
  $storeId: String!
) {
  convertToMenuItem(
    cartEntry: $cartEntry
    serviceMode: $serviceMode
    storeId: $storeId
  ) {
    isCombo
    itemId
    modifiersJson
    pickerSelections
    quantity
    serviceMode
    storeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConvertToMenuItemQueryVariables,
  APITypes.ConvertToMenuItemQuery
>;
export const getCart = /* GraphQL */ `query GetCart($id: ID!) {
  getCart(id: $id) {
    appliedIncentives {
      appliedCartEntries
      benefitErrors {
        id
        __typename
      }
      description
      offerId
      ruleErrors {
        id
        __typename
      }
      savingsAmount
      title
      type
      __typename
    }
    cartType
    createdAt
    deleteItemAt
    delivery {
      dropoffAddress {
        addressLine1
        addressLine2
        city
        country
        deliveryInformation {
          arrivalTime
          estimatedTimeWindow {
            afterTime
            beforeTime
            __typename
          }
          __typename
        }
        latitude
        longitude
        state
        zip
        __typename
      }
      phoneNumber
      pickupAddress {
        addressLine1
        addressLine2
        city
        country
        deliveryInformation {
          arrivalTime
          estimatedTimeWindow {
            afterTime
            beforeTime
            __typename
          }
          __typename
        }
        latitude
        longitude
        state
        zip
        __typename
      }
      quoteId
      __typename
    }
    donationAmount
    entries {
      displayName
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      instructions
      itemId
      lineId
      modifiers {
        displayName
        id
        price
        quantity
        __typename
      }
      pointCost
      price
      slug
      __typename
    }
    id
    metadata {
      pricingData {
        fees {
          name
          totalValueCents
          __typename
        }
        subTotalCents
        taxCents
        totalCents
        __typename
      }
      userSelectedOrderTime
      __typename
    }
    rbiOrderId
    serviceMode
    state
    store {
      addressLine1
      addressLine2
      city
      country
      id
      latitude
      longitude
      name
      phoneNumber
      posRestaurantId
      posVendor
      state
      zip
      __typename
    }
    updatedAt
    userId
    version
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCartQueryVariables, APITypes.GetCartQuery>;
export const getLoyaltyIncentives = /* GraphQL */ `query GetLoyaltyIncentives(
  $loyaltyId: String
  $serviceMode: String!
  $storeNumber: String!
) {
  getLoyaltyIncentives(
    loyaltyId: $loyaltyId
    serviceMode: $serviceMode
    storeNumber: $storeNumber
  ) {
    offers {
      cardDescription
      cmsId
      description
      discountFees
      errors
      expiryDate
      id
      image
      incentiveType
      isAvailable
      name
      pricingStrategy {
        defaultPrice
        groups {
          price
          restaurants
          __typename
        }
        maxItemQuantityDiscount
        maxPriceToDiscount
        pricingStrategy
        tier {
          price {
            defaultPrice
            groups {
              price
              restaurants
              __typename
            }
            __typename
          }
          products {
            id
            quantity
            type
            __typename
          }
          __typename
        }
        type
        value
        __typename
      }
      requireGuideFlow
      requireTierSelector
      tag
      termsAndConditions
      __typename
    }
    rewards {
      id
      image
      isAvailable
      name
      points
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLoyaltyIncentivesQueryVariables,
  APITypes.GetLoyaltyIncentivesQuery
>;
export const getMenu = /* GraphQL */ `query GetMenu($region: String!, $restaurantId: ID!, $serviceMode: String!) {
  getMenu(
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    createdAt
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    region
    restaurantId
    sections {
      descriptionShort
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      items {
        _legacySlug
        _legacyUrl
        badge
        baseCalories
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        priceCents
        requiresCustomization
        slug
        url
        __typename
      }
      slug
      url
      __typename
    }
    serviceMode
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMenuQueryVariables, APITypes.GetMenuQuery>;
export const getMenuGuide = /* GraphQL */ `query GetMenuGuide($id: String!) {
  getMenuGuide(id: $id) {
    createdAt
    description
    displayName
    id
    region
    restaurantId
    serviceMode
    stepCount
    steps {
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      preSelectedItemId
      sections {
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        items {
          _legacySlug
          _legacyUrl
          badge
          baseCalories
          descriptionShort
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          priceCents
          requiresCustomization
          slug
          url
          __typename
        }
        slug
        url
        __typename
      }
      shouldShowSections
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuGuideQueryVariables,
  APITypes.GetMenuGuideQuery
>;
export const getMenuItem = /* GraphQL */ `query GetMenuItem($id: ID!) {
  getMenuItem(id: $id) {
    config {
      createdAt
      id
      loyalty {
        points
        __typename
      }
      menuItem {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      menuItemId
      updatedAt
      vendors {
        ncr
        toast
        __typename
      }
      __typename
    }
    content {
      badge
      baseCalories
      basePriceCents
      customizations {
        displayGroups {
          displayGroup {
            displayName
            displayRank
            id
            showOnProductPage
            __typename
          }
          options {
            defaultOptionKey
            description
            displayName
            displayType
            key
            maxAmount
            minAmount
            options {
              caloriesOffset
              displayName
              id
              isAvailable
              isDefault
              key
              multiplier
              prefix
              upChargeCents
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      isVisible
      menuPickerSlug
      nutrition {
        displayName
        displayValue
        key
        units
        value
        __typename
      }
      slug
      url
      __typename
    }
    createdAt
    id
    menuPicker {
      badge
      combo {
        basePriceCents
        description
        displayName
        id
        isRequired
        slots {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isRequired
          __typename
        }
        __typename
      }
      createdAt
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      menuItems {
        items {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      pickerAspect {
        defaultOptionKey
        description
        displayName
        displayType
        id
        options {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isDefault
          item {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          itemId
          key
          __typename
        }
        __typename
      }
      slug
      updatedAt
      url
      __typename
    }
    menuPickerSlug
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    restaurantMenuItems {
      items {
        basePriceCents
        createdAt
        errors
        hasErrors
        id
        isAvailable
        menuItem {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        menuItemId
        options {
          id
          isAvailable
          upChargeCents
          __typename
        }
        region
        restaurantId
        serviceMode
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemQueryVariables,
  APITypes.GetMenuItemQuery
>;
export const getMenuItemConfig = /* GraphQL */ `query GetMenuItemConfig($id: ID!) {
  getMenuItemConfig(id: $id) {
    createdAt
    id
    loyalty {
      points
      __typename
    }
    menuItem {
      config {
        createdAt
        id
        loyalty {
          points
          __typename
        }
        menuItem {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        menuItemId
        updatedAt
        vendors {
          ncr
          toast
          __typename
        }
        __typename
      }
      content {
        badge
        baseCalories
        basePriceCents
        customizations {
          displayGroups {
            displayGroup {
              displayName
              displayRank
              id
              showOnProductPage
              __typename
            }
            options {
              defaultOptionKey
              description
              displayName
              displayType
              key
              maxAmount
              minAmount
              __typename
            }
            __typename
          }
          __typename
        }
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        isVisible
        menuPickerSlug
        nutrition {
          displayName
          displayValue
          key
          units
          value
          __typename
        }
        slug
        url
        __typename
      }
      createdAt
      id
      menuPicker {
        badge
        combo {
          basePriceCents
          description
          displayName
          id
          isRequired
          slots {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isRequired
            __typename
          }
          __typename
        }
        createdAt
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        menuItems {
          items {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        pickerAspect {
          defaultOptionKey
          description
          displayName
          displayType
          id
          options {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isDefault
            item {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            itemId
            key
            __typename
          }
          __typename
        }
        slug
        updatedAt
        url
        __typename
      }
      menuPickerSlug
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      restaurantMenuItems {
        items {
          basePriceCents
          createdAt
          errors
          hasErrors
          id
          isAvailable
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          options {
            id
            isAvailable
            upChargeCents
            __typename
          }
          region
          restaurantId
          serviceMode
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    menuItemId
    updatedAt
    vendors {
      ncr
      toast
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemConfigQueryVariables,
  APITypes.GetMenuItemConfigQuery
>;
export const getMenuItemForRestaurant = /* GraphQL */ `query GetMenuItemForRestaurant(
  $id: String!
  $region: String!
  $restaurantId: String!
  $serviceMode: String!
) {
  getMenuItemForRestaurant(
    id: $id
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    config {
      createdAt
      id
      loyalty {
        points
        __typename
      }
      menuItem {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      menuItemId
      updatedAt
      vendors {
        ncr
        toast
        __typename
      }
      __typename
    }
    content {
      badge
      baseCalories
      basePriceCents
      customizations {
        displayGroups {
          displayGroup {
            displayName
            displayRank
            id
            showOnProductPage
            __typename
          }
          options {
            defaultOptionKey
            description
            displayName
            displayType
            key
            maxAmount
            minAmount
            options {
              caloriesOffset
              displayName
              id
              isAvailable
              isDefault
              key
              multiplier
              prefix
              upChargeCents
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      isVisible
      menuPickerSlug
      nutrition {
        displayName
        displayValue
        key
        units
        value
        __typename
      }
      slug
      url
      __typename
    }
    createdAt
    id
    menuPicker {
      badge
      combo {
        basePriceCents
        description
        displayName
        id
        isRequired
        slots {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isRequired
          __typename
        }
        __typename
      }
      createdAt
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      menuItems {
        items {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      pickerAspect {
        defaultOptionKey
        description
        displayName
        displayType
        id
        options {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isDefault
          item {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          itemId
          key
          __typename
        }
        __typename
      }
      slug
      updatedAt
      url
      __typename
    }
    menuPickerSlug
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    restaurantMenuItems {
      items {
        basePriceCents
        createdAt
        errors
        hasErrors
        id
        isAvailable
        menuItem {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        menuItemId
        options {
          id
          isAvailable
          upChargeCents
          __typename
        }
        region
        restaurantId
        serviceMode
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemForRestaurantQueryVariables,
  APITypes.GetMenuItemForRestaurantQuery
>;
export const getMenuPicker = /* GraphQL */ `query GetMenuPicker($slug: String!) {
  getMenuPicker(slug: $slug) {
    badge
    combo {
      basePriceCents
      description
      displayName
      id
      isRequired
      slots {
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isRequired
        __typename
      }
      __typename
    }
    createdAt
    description
    displayName
    id
    image {
      altText
      asset {
        blurHash
        uri
        __typename
      }
      __typename
    }
    isAvailable
    menuItems {
      items {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    pickerAspect {
      defaultOptionKey
      description
      displayName
      displayType
      id
      options {
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isDefault
        item {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        itemId
        key
        __typename
      }
      __typename
    }
    slug
    updatedAt
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuPickerQueryVariables,
  APITypes.GetMenuPickerQuery
>;
export const getMenuPickerForRestaurant = /* GraphQL */ `query GetMenuPickerForRestaurant(
  $region: String!
  $restaurantId: String!
  $serviceMode: String!
  $slug: String!
) {
  getMenuPickerForRestaurant(
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
    slug: $slug
  ) {
    badge
    combo {
      basePriceCents
      description
      displayName
      id
      isRequired
      slots {
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isRequired
        __typename
      }
      __typename
    }
    createdAt
    description
    displayName
    id
    image {
      altText
      asset {
        blurHash
        uri
        __typename
      }
      __typename
    }
    isAvailable
    menuItems {
      items {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    pickerAspect {
      defaultOptionKey
      description
      displayName
      displayType
      id
      options {
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isDefault
        item {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        itemId
        key
        __typename
      }
      __typename
    }
    slug
    updatedAt
    url
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuPickerForRestaurantQueryVariables,
  APITypes.GetMenuPickerForRestaurantQuery
>;
export const getMenuSectionsForRestaurant = /* GraphQL */ `query GetMenuSectionsForRestaurant(
  $region: String!
  $restaurantId: String!
  $serviceMode: String!
) {
  getMenuSectionsForRestaurant(
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    createdAt
    metadata {
      availabilityLastUpdatedAt
      isCacheHit
      menuLastUpdatedAt
      processingTimeMs
      __typename
    }
    region
    restaurantId
    sections {
      descriptionShort
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      items {
        _legacySlug
        _legacyUrl
        badge
        baseCalories
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        priceCents
        requiresCustomization
        slug
        url
        __typename
      }
      slug
      url
      __typename
    }
    serviceMode
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuSectionsForRestaurantQueryVariables,
  APITypes.GetMenuSectionsForRestaurantQuery
>;
export const getMenuSyncRecord = /* GraphQL */ `query GetMenuSyncRecord($id: ID!) {
  getMenuSyncRecord(id: $id) {
    createdAt
    id
    log {
      count
      durationTotalMs
      errors {
        error
        id
        message
        type
        __typename
      }
      requestInfo {
        region
        restaurantId
        serviceMode
        __typename
      }
      syncType
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuSyncRecordQueryVariables,
  APITypes.GetMenuSyncRecordQuery
>;
export const getOfferDetailUi = /* GraphQL */ `query GetOfferDetailUi(
  $cartEntriesV1: AWSJSON!
  $loyaltyId: String
  $offerId: String!
  $region: String
  $serviceMode: String!
  $storeNumber: String!
) {
  getOfferDetailUi(
    cartEntriesV1: $cartEntriesV1
    loyaltyId: $loyaltyId
    offerId: $offerId
    region: $region
    serviceMode: $serviceMode
    storeNumber: $storeNumber
  ) {
    cmsId
    description
    discountFees
    errors
    expiryDate
    id
    image
    incentiveType
    isAvailable
    name
    pricingStrategy {
      defaultPrice
      groups {
        price
        restaurants
        __typename
      }
      maxItemQuantityDiscount
      maxPriceToDiscount
      pricingStrategy
      tier {
        price {
          defaultPrice
          groups {
            price
            restaurants
            __typename
          }
          __typename
        }
        products {
          id
          quantity
          type
          __typename
        }
        __typename
      }
      type
      value
      __typename
    }
    requireGuideFlow
    requireTierSelector
    steps {
      eligibleItems {
        _legacySlug
        _legacyUrl
        badge
        baseCalories
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        priceCents
        requiresCustomization
        slug
        url
        __typename
      }
      key
      preSelectedItem
      rank
      subtitle
      title
      __typename
    }
    tag
    termsAndConditions
    tiers {
      description
      id
      image
      name
      steps {
        eligibleItems {
          _legacySlug
          _legacyUrl
          badge
          baseCalories
          descriptionShort
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          priceCents
          requiresCustomization
          slug
          url
          __typename
        }
        key
        preSelectedItem
        rank
        subtitle
        title
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOfferDetailUiQueryVariables,
  APITypes.GetOfferDetailUiQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    appliedIncentives {
      appliedCartEntries
      benefitErrors {
        id
        __typename
      }
      description
      offerId
      ruleErrors {
        id
        __typename
      }
      savingsAmount
      title
      type
      __typename
    }
    createdAt
    donationAmount
    entries {
      displayName
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      instructions
      itemId
      lineId
      modifiers {
        displayName
        id
        price
        quantity
        __typename
      }
      pointCost
      price
      slug
      __typename
    }
    id
    orderTime
    pricingData {
      subTotalCents
      taxCents
      totalCents
      __typename
    }
    serviceMode
    store {
      addressLine1
      addressLine2
      city
      country
      id
      latitude
      longitude
      name
      phoneNumber
      posRestaurantId
      posVendor
      state
      zip
      __typename
    }
    updatedAt
    userId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const getRestaurant = /* GraphQL */ `query GetRestaurant($id: ID!) {
  getRestaurant(id: $id) {
    createdAt
    id
    name
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestaurantQueryVariables,
  APITypes.GetRestaurantQuery
>;
export const getRestaurantMenuItem = /* GraphQL */ `query GetRestaurantMenuItem(
  $id: ID!
  $region: String!
  $restaurantId: String!
  $serviceMode: String!
) {
  getRestaurantMenuItem(
    id: $id
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    basePriceCents
    createdAt
    errors
    hasErrors
    id
    isAvailable
    menuItem {
      config {
        createdAt
        id
        loyalty {
          points
          __typename
        }
        menuItem {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        menuItemId
        updatedAt
        vendors {
          ncr
          toast
          __typename
        }
        __typename
      }
      content {
        badge
        baseCalories
        basePriceCents
        customizations {
          displayGroups {
            displayGroup {
              displayName
              displayRank
              id
              showOnProductPage
              __typename
            }
            options {
              defaultOptionKey
              description
              displayName
              displayType
              key
              maxAmount
              minAmount
              __typename
            }
            __typename
          }
          __typename
        }
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        isVisible
        menuPickerSlug
        nutrition {
          displayName
          displayValue
          key
          units
          value
          __typename
        }
        slug
        url
        __typename
      }
      createdAt
      id
      menuPicker {
        badge
        combo {
          basePriceCents
          description
          displayName
          id
          isRequired
          slots {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isRequired
            __typename
          }
          __typename
        }
        createdAt
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        menuItems {
          items {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        pickerAspect {
          defaultOptionKey
          description
          displayName
          displayType
          id
          options {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isDefault
            item {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            itemId
            key
            __typename
          }
          __typename
        }
        slug
        updatedAt
        url
        __typename
      }
      menuPickerSlug
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      restaurantMenuItems {
        items {
          basePriceCents
          createdAt
          errors
          hasErrors
          id
          isAvailable
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          options {
            id
            isAvailable
            upChargeCents
            __typename
          }
          region
          restaurantId
          serviceMode
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    menuItemId
    options {
      id
      isAvailable
      upChargeCents
      __typename
    }
    region
    restaurantId
    serviceMode
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRestaurantMenuItemQueryVariables,
  APITypes.GetRestaurantMenuItemQuery
>;
export const legacyUserPaymentInformation = /* GraphQL */ `query LegacyUserPaymentInformation {
  legacyUserPaymentInformation {
    paymentConfig {
      applePay {
        prepaidMerchantId
        schemeMerchantId
        __typename
      }
      frontendIntegration
      googlePay {
        prepaidMerchantId
        schemeMerchantId
        __typename
      }
      __typename
    }
    paymentPreferences {
      storedPaymentMethodId
      __typename
    }
    storedPaymentMethods {
      brand
      expiryMonth
      expiryYear
      fullName
      id
      last4
      panToken
      type
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LegacyUserPaymentInformationQueryVariables,
  APITypes.LegacyUserPaymentInformationQuery
>;
export const listCartByRbiOrderIdAndCartType = /* GraphQL */ `query ListCartByRbiOrderIdAndCartType(
  $cartType: ModelStringKeyConditionInput
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
  $rbiOrderId: String!
  $sortDirection: ModelSortDirection
) {
  listCartByRbiOrderIdAndCartType(
    cartType: $cartType
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    rbiOrderId: $rbiOrderId
    sortDirection: $sortDirection
  ) {
    items {
      appliedIncentives {
        appliedCartEntries
        benefitErrors {
          id
          __typename
        }
        description
        offerId
        ruleErrors {
          id
          __typename
        }
        savingsAmount
        title
        type
        __typename
      }
      cartType
      createdAt
      deleteItemAt
      delivery {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        phoneNumber
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        quoteId
        __typename
      }
      donationAmount
      entries {
        displayName
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        instructions
        itemId
        lineId
        modifiers {
          displayName
          id
          price
          quantity
          __typename
        }
        pointCost
        price
        slug
        __typename
      }
      id
      metadata {
        pricingData {
          fees {
            name
            totalValueCents
            __typename
          }
          subTotalCents
          taxCents
          totalCents
          __typename
        }
        userSelectedOrderTime
        __typename
      }
      rbiOrderId
      serviceMode
      state
      store {
        addressLine1
        addressLine2
        city
        country
        id
        latitude
        longitude
        name
        phoneNumber
        posRestaurantId
        posVendor
        state
        zip
        __typename
      }
      updatedAt
      userId
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCartByRbiOrderIdAndCartTypeQueryVariables,
  APITypes.ListCartByRbiOrderIdAndCartTypeQuery
>;
export const listCartByUserIdAndCartType = /* GraphQL */ `query ListCartByUserIdAndCartType(
  $cartType: ModelStringKeyConditionInput
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
  $userId: ID!
) {
  listCartByUserIdAndCartType(
    cartType: $cartType
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
    userId: $userId
  ) {
    items {
      appliedIncentives {
        appliedCartEntries
        benefitErrors {
          id
          __typename
        }
        description
        offerId
        ruleErrors {
          id
          __typename
        }
        savingsAmount
        title
        type
        __typename
      }
      cartType
      createdAt
      deleteItemAt
      delivery {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        phoneNumber
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        quoteId
        __typename
      }
      donationAmount
      entries {
        displayName
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        instructions
        itemId
        lineId
        modifiers {
          displayName
          id
          price
          quantity
          __typename
        }
        pointCost
        price
        slug
        __typename
      }
      id
      metadata {
        pricingData {
          fees {
            name
            totalValueCents
            __typename
          }
          subTotalCents
          taxCents
          totalCents
          __typename
        }
        userSelectedOrderTime
        __typename
      }
      rbiOrderId
      serviceMode
      state
      store {
        addressLine1
        addressLine2
        city
        country
        id
        latitude
        longitude
        name
        phoneNumber
        posRestaurantId
        posVendor
        state
        zip
        __typename
      }
      updatedAt
      userId
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCartByUserIdAndCartTypeQueryVariables,
  APITypes.ListCartByUserIdAndCartTypeQuery
>;
export const listCarts = /* GraphQL */ `query ListCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      appliedIncentives {
        appliedCartEntries
        benefitErrors {
          id
          __typename
        }
        description
        offerId
        ruleErrors {
          id
          __typename
        }
        savingsAmount
        title
        type
        __typename
      }
      cartType
      createdAt
      deleteItemAt
      delivery {
        dropoffAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        phoneNumber
        pickupAddress {
          addressLine1
          addressLine2
          city
          country
          deliveryInformation {
            arrivalTime
            estimatedTimeWindow {
              afterTime
              beforeTime
              __typename
            }
            __typename
          }
          latitude
          longitude
          state
          zip
          __typename
        }
        quoteId
        __typename
      }
      donationAmount
      entries {
        displayName
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        instructions
        itemId
        lineId
        modifiers {
          displayName
          id
          price
          quantity
          __typename
        }
        pointCost
        price
        slug
        __typename
      }
      id
      metadata {
        pricingData {
          fees {
            name
            totalValueCents
            __typename
          }
          subTotalCents
          taxCents
          totalCents
          __typename
        }
        userSelectedOrderTime
        __typename
      }
      rbiOrderId
      serviceMode
      state
      store {
        addressLine1
        addressLine2
        city
        country
        id
        latitude
        longitude
        name
        phoneNumber
        posRestaurantId
        posVendor
        state
        zip
        __typename
      }
      updatedAt
      userId
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCartsQueryVariables, APITypes.ListCartsQuery>;
export const listMenuGuides = /* GraphQL */ `query ListMenuGuides(
  $filter: ModelMenuGuideFilterInput
  $id: String
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenuGuides(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      description
      displayName
      id
      region
      restaurantId
      serviceMode
      stepCount
      steps {
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        preSelectedItemId
        sections {
          descriptionShort
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          items {
            _legacySlug
            _legacyUrl
            badge
            baseCalories
            descriptionShort
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            priceCents
            requiresCustomization
            slug
            url
            __typename
          }
          slug
          url
          __typename
        }
        shouldShowSections
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuGuidesQueryVariables,
  APITypes.ListMenuGuidesQuery
>;
export const listMenuItemConfigs = /* GraphQL */ `query ListMenuItemConfigs(
  $filter: ModelMenuItemConfigFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenuItemConfigs(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      id
      loyalty {
        points
        __typename
      }
      menuItem {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      menuItemId
      updatedAt
      vendors {
        ncr
        toast
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuItemConfigsQueryVariables,
  APITypes.ListMenuItemConfigsQuery
>;
export const listMenuItems = /* GraphQL */ `query ListMenuItems(
  $filter: ModelMenuItemFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenuItems(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      config {
        createdAt
        id
        loyalty {
          points
          __typename
        }
        menuItem {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        menuItemId
        updatedAt
        vendors {
          ncr
          toast
          __typename
        }
        __typename
      }
      content {
        badge
        baseCalories
        basePriceCents
        customizations {
          displayGroups {
            displayGroup {
              displayName
              displayRank
              id
              showOnProductPage
              __typename
            }
            options {
              defaultOptionKey
              description
              displayName
              displayType
              key
              maxAmount
              minAmount
              __typename
            }
            __typename
          }
          __typename
        }
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        isVisible
        menuPickerSlug
        nutrition {
          displayName
          displayValue
          key
          units
          value
          __typename
        }
        slug
        url
        __typename
      }
      createdAt
      id
      menuPicker {
        badge
        combo {
          basePriceCents
          description
          displayName
          id
          isRequired
          slots {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isRequired
            __typename
          }
          __typename
        }
        createdAt
        description
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        isAvailable
        menuItems {
          items {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        pickerAspect {
          defaultOptionKey
          description
          displayName
          displayType
          id
          options {
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isDefault
            item {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            itemId
            key
            __typename
          }
          __typename
        }
        slug
        updatedAt
        url
        __typename
      }
      menuPickerSlug
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      restaurantMenuItems {
        items {
          basePriceCents
          createdAt
          errors
          hasErrors
          id
          isAvailable
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          options {
            id
            isAvailable
            upChargeCents
            __typename
          }
          region
          restaurantId
          serviceMode
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuItemsQueryVariables,
  APITypes.ListMenuItemsQuery
>;
export const listMenuPickerById = /* GraphQL */ `query ListMenuPickerById(
  $filter: ModelMenuPickerFilterInput
  $id: ID!
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenuPickerById(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      badge
      combo {
        basePriceCents
        description
        displayName
        id
        isRequired
        slots {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isRequired
          __typename
        }
        __typename
      }
      createdAt
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      menuItems {
        items {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      pickerAspect {
        defaultOptionKey
        description
        displayName
        displayType
        id
        options {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isDefault
          item {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          itemId
          key
          __typename
        }
        __typename
      }
      slug
      updatedAt
      url
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuPickerByIdQueryVariables,
  APITypes.ListMenuPickerByIdQuery
>;
export const listMenuPickers = /* GraphQL */ `query ListMenuPickers(
  $filter: ModelMenuPickerFilterInput
  $limit: Int
  $nextToken: String
  $slug: String
  $sortDirection: ModelSortDirection
) {
  listMenuPickers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    slug: $slug
    sortDirection: $sortDirection
  ) {
    items {
      badge
      combo {
        basePriceCents
        description
        displayName
        id
        isRequired
        slots {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isRequired
          __typename
        }
        __typename
      }
      createdAt
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      isAvailable
      menuItems {
        items {
          config {
            createdAt
            id
            loyalty {
              points
              __typename
            }
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            updatedAt
            vendors {
              ncr
              toast
              __typename
            }
            __typename
          }
          content {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          createdAt
          id
          menuPicker {
            badge
            combo {
              basePriceCents
              description
              displayName
              id
              isRequired
              __typename
            }
            createdAt
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            menuItems {
              nextToken
              __typename
            }
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            pickerAspect {
              defaultOptionKey
              description
              displayName
              displayType
              id
              __typename
            }
            slug
            updatedAt
            url
            __typename
          }
          menuPickerSlug
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          restaurantMenuItems {
            items {
              basePriceCents
              createdAt
              errors
              hasErrors
              id
              isAvailable
              menuItemId
              region
              restaurantId
              serviceMode
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      pickerAspect {
        defaultOptionKey
        description
        displayName
        displayType
        id
        options {
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isDefault
          item {
            badge
            baseCalories
            basePriceCents
            customizations {
              __typename
            }
            description
            displayName
            id
            image {
              altText
              __typename
            }
            isAvailable
            isVisible
            menuPickerSlug
            nutrition {
              displayName
              displayValue
              key
              units
              value
              __typename
            }
            slug
            url
            __typename
          }
          itemId
          key
          __typename
        }
        __typename
      }
      slug
      updatedAt
      url
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuPickersQueryVariables,
  APITypes.ListMenuPickersQuery
>;
export const listMenuSyncRecords = /* GraphQL */ `query ListMenuSyncRecords(
  $filter: ModelMenuSyncRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenuSyncRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      createdAt
      id
      log {
        count
        durationTotalMs
        errors {
          error
          id
          message
          type
          __typename
        }
        requestInfo {
          region
          restaurantId
          serviceMode
          __typename
        }
        syncType
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuSyncRecordsQueryVariables,
  APITypes.ListMenuSyncRecordsQuery
>;
export const listMenus = /* GraphQL */ `query ListMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
  $regionServiceMode: ModelMenuPrimaryCompositeKeyConditionInput
  $restaurantId: ID
  $sortDirection: ModelSortDirection
) {
  listMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    regionServiceMode: $regionServiceMode
    restaurantId: $restaurantId
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      metadata {
        availabilityLastUpdatedAt
        isCacheHit
        menuLastUpdatedAt
        processingTimeMs
        __typename
      }
      region
      restaurantId
      sections {
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        items {
          _legacySlug
          _legacyUrl
          badge
          baseCalories
          descriptionShort
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          priceCents
          requiresCustomization
          slug
          url
          __typename
        }
        slug
        url
        __typename
      }
      serviceMode
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMenusQueryVariables, APITypes.ListMenusQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrders(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      appliedIncentives {
        appliedCartEntries
        benefitErrors {
          id
          __typename
        }
        description
        offerId
        ruleErrors {
          id
          __typename
        }
        savingsAmount
        title
        type
        __typename
      }
      createdAt
      donationAmount
      entries {
        displayName
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        instructions
        itemId
        lineId
        modifiers {
          displayName
          id
          price
          quantity
          __typename
        }
        pointCost
        price
        slug
        __typename
      }
      id
      orderTime
      pricingData {
        subTotalCents
        taxCents
        totalCents
        __typename
      }
      serviceMode
      store {
        addressLine1
        addressLine2
        city
        country
        id
        latitude
        longitude
        name
        phoneNumber
        posRestaurantId
        posVendor
        state
        zip
        __typename
      }
      updatedAt
      userId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const listRestaurantMenuItemByRestaurantIdAndServiceModeAndRegion = /* GraphQL */ `query ListRestaurantMenuItemByRestaurantIdAndServiceModeAndRegion(
  $filter: ModelRestaurantMenuItemFilterInput
  $limit: Int
  $nextToken: String
  $restaurantId: String!
  $serviceModeRegion: ModelRestaurantMenuItemRestaurantMenuItemsByRestaurantIdAndServiceModeAndRegionCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
) {
  listRestaurantMenuItemByRestaurantIdAndServiceModeAndRegion(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    restaurantId: $restaurantId
    serviceModeRegion: $serviceModeRegion
    sortDirection: $sortDirection
  ) {
    items {
      basePriceCents
      createdAt
      errors
      hasErrors
      id
      isAvailable
      menuItem {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      menuItemId
      options {
        id
        isAvailable
        upChargeCents
        __typename
      }
      region
      restaurantId
      serviceMode
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantMenuItemByRestaurantIdAndServiceModeAndRegionQueryVariables,
  APITypes.ListRestaurantMenuItemByRestaurantIdAndServiceModeAndRegionQuery
>;
export const listRestaurantMenuItems = /* GraphQL */ `query ListRestaurantMenuItems(
  $filter: ModelRestaurantMenuItemFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $restaurantIdServiceModeRegion: ModelRestaurantMenuItemPrimaryCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
) {
  listRestaurantMenuItems(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    restaurantIdServiceModeRegion: $restaurantIdServiceModeRegion
    sortDirection: $sortDirection
  ) {
    items {
      basePriceCents
      createdAt
      errors
      hasErrors
      id
      isAvailable
      menuItem {
        config {
          createdAt
          id
          loyalty {
            points
            __typename
          }
          menuItem {
            config {
              createdAt
              id
              menuItemId
              updatedAt
              __typename
            }
            content {
              badge
              baseCalories
              basePriceCents
              description
              displayName
              id
              isAvailable
              isVisible
              menuPickerSlug
              slug
              url
              __typename
            }
            createdAt
            id
            menuPicker {
              badge
              createdAt
              description
              displayName
              id
              isAvailable
              slug
              updatedAt
              url
              __typename
            }
            menuPickerSlug
            metadata {
              availabilityLastUpdatedAt
              isCacheHit
              menuLastUpdatedAt
              processingTimeMs
              __typename
            }
            restaurantMenuItems {
              nextToken
              __typename
            }
            updatedAt
            __typename
          }
          menuItemId
          updatedAt
          vendors {
            ncr
            toast
            __typename
          }
          __typename
        }
        content {
          badge
          baseCalories
          basePriceCents
          customizations {
            displayGroups {
              __typename
            }
            __typename
          }
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          isVisible
          menuPickerSlug
          nutrition {
            displayName
            displayValue
            key
            units
            value
            __typename
          }
          slug
          url
          __typename
        }
        createdAt
        id
        menuPicker {
          badge
          combo {
            basePriceCents
            description
            displayName
            id
            isRequired
            slots {
              description
              displayName
              id
              isRequired
              __typename
            }
            __typename
          }
          createdAt
          description
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          menuItems {
            items {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            nextToken
            __typename
          }
          metadata {
            availabilityLastUpdatedAt
            isCacheHit
            menuLastUpdatedAt
            processingTimeMs
            __typename
          }
          pickerAspect {
            defaultOptionKey
            description
            displayName
            displayType
            id
            options {
              description
              displayName
              id
              isDefault
              itemId
              key
              __typename
            }
            __typename
          }
          slug
          updatedAt
          url
          __typename
        }
        menuPickerSlug
        metadata {
          availabilityLastUpdatedAt
          isCacheHit
          menuLastUpdatedAt
          processingTimeMs
          __typename
        }
        restaurantMenuItems {
          items {
            basePriceCents
            createdAt
            errors
            hasErrors
            id
            isAvailable
            menuItem {
              createdAt
              id
              menuPickerSlug
              updatedAt
              __typename
            }
            menuItemId
            options {
              id
              isAvailable
              upChargeCents
              __typename
            }
            region
            restaurantId
            serviceMode
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      menuItemId
      options {
        id
        isAvailable
        upChargeCents
        __typename
      }
      region
      restaurantId
      serviceMode
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantMenuItemsQueryVariables,
  APITypes.ListRestaurantMenuItemsQuery
>;
export const listRestaurants = /* GraphQL */ `query ListRestaurants(
  $filter: ModelRestaurantFilterInput
  $id: ID
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRestaurants(
    filter: $filter
    id: $id
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      createdAt
      id
      name
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRestaurantsQueryVariables,
  APITypes.ListRestaurantsQuery
>;
export const menuGuide = /* GraphQL */ `query MenuGuide(
  $id: String
  $region: MenuGuideRegion
  $restaurantId: String!
  $serviceMode: MenuGuideServiceMode
) {
  menuGuide(
    id: $id
    region: $region
    restaurantId: $restaurantId
    serviceMode: $serviceMode
  ) {
    createdAt
    description
    displayName
    id
    region
    restaurantId
    serviceMode
    stepCount
    steps {
      description
      displayName
      id
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      preSelectedItemId
      sections {
        descriptionShort
        displayName
        id
        image {
          altText
          asset {
            blurHash
            uri
            __typename
          }
          __typename
        }
        items {
          _legacySlug
          _legacyUrl
          badge
          baseCalories
          descriptionShort
          displayName
          id
          image {
            altText
            asset {
              blurHash
              uri
              __typename
            }
            __typename
          }
          isAvailable
          priceCents
          requiresCustomization
          slug
          url
          __typename
        }
        slug
        url
        __typename
      }
      shouldShowSections
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.MenuGuideQueryVariables, APITypes.MenuGuideQuery>;
export const order = /* GraphQL */ `query Order($id: ID!) {
  order(id: $id) {
    appliedIncentives {
      appliedCartEntries
      benefitErrors {
        id
        __typename
      }
      description
      offerId
      ruleErrors {
        id
        __typename
      }
      savingsAmount
      title
      type
      __typename
    }
    createdAt
    donationAmount
    entries {
      displayName
      image {
        altText
        asset {
          blurHash
          uri
          __typename
        }
        __typename
      }
      instructions
      itemId
      lineId
      modifiers {
        displayName
        id
        price
        quantity
        __typename
      }
      pointCost
      price
      slug
      __typename
    }
    id
    orderTime
    pricingData {
      subTotalCents
      taxCents
      totalCents
      __typename
    }
    serviceMode
    store {
      addressLine1
      addressLine2
      city
      country
      id
      latitude
      longitude
      name
      phoneNumber
      posRestaurantId
      posVendor
      state
      zip
      __typename
    }
    updatedAt
    userId
    __typename
  }
}
` as GeneratedQuery<APITypes.OrderQueryVariables, APITypes.OrderQuery>;
export const orderTimeslots = /* GraphQL */ `query OrderTimeslots {
  orderTimeslots {
    date
    isDisabled
    options {
      time
      __typename
    }
    warningMessage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderTimeslotsQueryVariables,
  APITypes.OrderTimeslotsQuery
>;
