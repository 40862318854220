import { Stack, router } from 'expo-router';
import * as React from 'react';
import { useCallback } from 'react';

import {
  Button,
  IconDelivery,
  IconLocationFill,
  IconStore,
  ScrollView,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';
import { Box } from '@fhs-legacy/universal-components';
import { useGetNearbyRestaurantsFromDelivery } from 'hooks/use-get-nearby-restaurants-from-delivery-context';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { ServiceMode } from 'state/service-mode';
import { convertMilesToMeters } from 'utils/distance';

import { AddressBox } from './address-box';
import { ButtonContainer, Container, LoadingText } from './delivery-unavailable.styled';
import { IDeliveryUnavailableErrorProps, IDeliveryUnavailableProps } from './types';
const searchRadiusInMiles = 20;

const useDeliveryUnavailableAction = onChooseNewAddress => {
  const { logRBIEvent } = useCRMEventsContext();
  const onSwitchToPickup = useCallback(() => {
    logRBIEvent({
      name: CustomEventNames.DELIVERY_UNAVAILABLE_CHANGE_SERVICE_MODE,
      type: EventTypes.Navigation,
    });
    router.push('/store-locator/service-mode');
  }, [logRBIEvent]);
  const onReselectDeliveryAddress = useCallback(() => {
    logRBIEvent({
      name: '',
      type: EventTypes.Navigation,
    });
    onChooseNewAddress();
  }, [logRBIEvent, onChooseNewAddress]);
  return {
    onSwitchToPickup,
    onReselectDeliveryAddress,
  };
};

export const DeliveryUnavailableError: React.FC<
  React.PropsWithChildren<IDeliveryUnavailableErrorProps>
> = ({ onChooseNewAddress }) => {
  const { onSwitchToPickup, onReselectDeliveryAddress } =
    useDeliveryUnavailableAction(onChooseNewAddress);
  const styles = useMqStyles();
  return (
    <Container style={styles.page} testID="delivery-unavailable-for-address-error">
      <Stack.Screen options={{ title: 'Delivery Order' }} />
      <ScrollView style={styles.content} contentContainerStyle={{ justifyContent: 'center' }}>
        <Box style={styles.header}>
          <IconDelivery size={150} color={tokens.colors.$blackOpacity10} />
          <Text.Heading type="two">We're sorry!</Text.Heading>
          <LoadingText fontSize="md" ellipsizeMode="tail" numberOfLines={2}>
            Delivery is currently unavailable
          </LoadingText>
        </Box>
      </ScrollView>
      <ButtonContainer>
        <Button onPress={onSwitchToPickup} size="xl" style={styles.button}>
          <Button.Icon>
            <IconStore />
          </Button.Icon>
          <Button.Text>Pick Up Elsewhere</Button.Text>
        </Button>
        <Button
          type="outline"
          onPress={onReselectDeliveryAddress}
          testID="use-different-address"
          size="xl"
          style={styles.button}
        >
          <Button.Text>Change Delivery Address</Button.Text>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

/**
 *
 * DeliveryUnavailable informs the guest that the address they entered
 * is unable to receive delivery at this time
 *
 */

export const DeliveryUnavailable: React.FC<React.PropsWithChildren<IDeliveryUnavailableProps>> = ({
  address,
  coordinates,
  onChooseNewAddress,
}) => {
  const queryCoordinates = {
    userLat: coordinates?.lat,
    userLng: coordinates?.lng,
    searchRadius: convertMilesToMeters(20),
  };
  const { restaurants, loading, error } = useGetNearbyRestaurantsFromDelivery(
    queryCoordinates,
    ServiceMode.DELIVERY,
    searchRadiusInMiles
  );

  const styles = useMqStyles();

  const { onSwitchToPickup, onReselectDeliveryAddress } =
    useDeliveryUnavailableAction(onChooseNewAddress);

  const suggestions = restaurants ?? [];

  if (error) {
    return <DeliveryUnavailableError onChooseNewAddress={onChooseNewAddress} />;
  }

  const HeaderContent = (
    <Box style={styles.header}>
      <IconDelivery size={80} color={tokens.colors.$blackOpacity10} />
      <Text.Heading type="two">Sorry, No Restaurants Nearby</Text.Heading>
      <LoadingText fontSize="md" ellipsizeMode="tail" numberOfLines={2}>
        {suggestions.length > 0
          ? 'No delivery restaurants found within 10 miles of your address.'
          : 'None found within 20 miles of your address'}
      </LoadingText>
    </Box>
  );

  const YourAddress = (
    <Box textAlign="left" style={styles.addressContainer}>
      <Text.Heading type="four" style={styles.headerContainer}>
        Your Address:
      </Text.Heading>
      <AddressBox
        icon={<IconLocationFill size={26} color={tokens.colors.$houseDark} />}
        address={address}
        activeCoordinates={coordinates}
      />
    </Box>
  );

  const Suggestions = (
    <Box width="100%">
      {loading && <LoadingText>Looking for pick up options</LoadingText>}
      {suggestions.length > 0 && (
        <Box width="100%" textAlign="left" flexDirection="column" gap={2} flex={1}>
          <Text.Heading type="four" style={styles.headerContainer}>
            {' '}
            Restaurants For Pick Up:
          </Text.Heading>
          {suggestions.slice(0, 2).map(suggestion => {
            return (
              <AddressBox
                icon={<IconStore size={26} color={tokens.colors.$houseDark} />}
                restaurant={suggestion}
                activeCoordinates={coordinates}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );

  return (
    <Container style={styles.page} testID="delivery-unavailable-for-address">
      <ScrollView style={styles.content} bounces={false} contentContainerStyle={styles.scroll}>
        {HeaderContent}
        {YourAddress}
        {Suggestions}
      </ScrollView>

      <Box style={styles.buttonContainer}>
        <Button onPress={onSwitchToPickup} size="xl" style={styles.button}>
          <Button.Icon>
            <IconStore />
          </Button.Icon>
          <Button.Text>Pick Up Elsewhere</Button.Text>
        </Button>
        <Button
          type="outline"
          onPress={onReselectDeliveryAddress}
          testID="use-different-address"
          size="xl"
          style={styles.button}
        >
          <Button.Text>Change Delivery Address</Button.Text>
        </Button>
      </Box>
    </Container>
  );
};

const useMqStyles = createMqStyles({
  addressContainer: {
    $base: {
      paddingVertical: 24,
      flexDirection: 'column',
      width: '100%',
    },
  },
  button: {
    $base: {
      height: 48,
      width: '100%',
    },
  },
  buttonContainer: {
    $base: {
      display: 'flex',
      paddingHorizontal: 16,
      paddingVertical: 20,
      borderColor: tokens.colors.$blackOpacity10,
      borderTopWidth: 1,
      borderStyle: 'solid',
      width: '100%',
      gap: 12,
      alignSelf: 'stretch',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  content: {
    $base: {
      display: 'flex',
      paddingVertical: 64,
      paddingHorizontal: 16,
      gap: 24,
      width: '100%',
      overflow: 'scroll',
    },
  },
  header: {
    $base: {
      gap: 16,
      alignItems: 'center',
      width: '100%',
    },
  },
  headerContainer: {
    $base: {
      paddingBottom: 8,
    },
  },
  page: {
    $base: {
      flex: 1,
      backgroundColor: tokens.colors.$white,
    },
  },
  scroll: {
    $base: { paddingBottom: 80 },
  },
});
