import { Link, usePathname } from 'expo-router';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import {
  Button,
  Dropdown,
  Favicon,
  HeaderText,
  IconCartFill,
  IconCatering,
  IconChevronDown,
  IconDelivery,
  MqSwitch,
  RapidRescue,
  Wordmark,
  XStack,
  YStack,
  tokens,
} from '@fhs/ui';
import { useClickLogs } from '@fhs-legacy/frontend/src/hooks/use-click-logs';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { isExternalLink } from '@fhs-legacy/frontend/src/utils/is-external-link';

import { useHrefs } from '../../../use-hrefs';

export function HeaderLeftDesktop() {
  const currentPathname = usePathname();
  const { isAuthenticated } = useAuthContext();
  const { pickUpHref, deliveryHref, cateringHref, foundationHref } = useHrefs();
  const { formatMessage } = useIntl();
  const isCateringExternal = isExternalLink(cateringHref);

  const { logPickUpOrderClickEvent, logDeliveryOrderClickEvent, logCateringOrderClickEvent } =
    useClickLogs();

  const pickUpOrderText = formatMessage({ id: 'pickUpOrder' });
  const deliveryOrderText = formatMessage({ id: 'deliveryOrder' });

  return (
    <>
      <XStack style={styles.wordmarkContainer}>
        <Link href="/">
          <MqSwitch
            $ltDesktopLg={() => <Favicon size={44} />}
            $gteDesktopLg={() => <Wordmark width={142} />}
          />
        </Link>
      </XStack>

      <XStack style={styles.container}>
        <XStack style={styles.links}>
          <Dropdown>
            <Dropdown.Trigger>
              {({ isExpanded }) => (
                <XStack style={styles.textWithCaret}>
                  <HeaderText isHighlighted={isExpanded}>Order</HeaderText>
                  <IconChevronDown
                    color={isExpanded ? tokens.colors.$houseRedDarken : undefined}
                    size={20}
                  />
                </XStack>
              )}
            </Dropdown.Trigger>
            <Dropdown.Pane style={styles.orderDropdownPane}>
              <YStack style={styles.orderDropdownGraphicAndButtonsStack}>
                <Dropdown.ClosePaneOnChange value={currentPathname} />
                <RapidRescue />
                <YStack style={styles.orderDropdownButtonStack}>
                  <Link href={pickUpHref} asChild>
                    <Button type="solid" size="lg" onPress={logPickUpOrderClickEvent}>
                      <Button.Icon>
                        <IconCartFill />
                      </Button.Icon>
                      <Button.Text>{pickUpOrderText}</Button.Text>
                    </Button>
                  </Link>

                  {Boolean(deliveryHref) && (
                    <Link href={deliveryHref} asChild>
                      <Button type="outline" size="lg" onPress={logDeliveryOrderClickEvent}>
                        <Button.Icon>
                          <IconDelivery />
                        </Button.Icon>
                        <Button.Text>{deliveryOrderText}</Button.Text>
                      </Button>
                    </Link>
                  )}

                  {Boolean(cateringHref) && (
                    <Link
                      {...(isCateringExternal
                        ? {
                            href: '',
                            target: '_blank',
                            onPressOut: () => {
                              window.open(cateringHref);
                            },
                          }
                        : { href: cateringHref })}
                      asChild
                      onPressIn={logCateringOrderClickEvent}
                    >
                      <Button type="outline" size="lg">
                        <Button.Icon>
                          <IconCatering />
                        </Button.Icon>
                        <Button.Text>Catering Order</Button.Text>
                      </Button>
                    </Link>
                  )}
                </YStack>
              </YStack>
            </Dropdown.Pane>
          </Dropdown>

          {isAuthenticated && (
            <Link href="/rewards/details">
              <HeaderText isHighlighted={currentPathname === '/rewards/details'}>
                Rewards
              </HeaderText>
            </Link>
          )}

          {Boolean(cateringHref) && (
            <Link href={cateringHref} {...(isCateringExternal ? { target: '_blank' } : {})}>
              <HeaderText>Catering</HeaderText>
            </Link>
          )}

          <Link href={foundationHref} target="_blank">
            <HeaderText>Foundation</HeaderText>
          </Link>
        </XStack>
      </XStack>
    </>
  );
}

const styles = StyleSheet.create({
  wordmarkContainer: {
    alignItems: 'center',
  },
  container: {
    flexShrink: 1,
    gap: 24,
    alignItems: 'center',
  },
  links: {
    gap: 24,
    alignItems: 'center',
  },
  textWithCaret: {
    height: '100%',
    gap: 4,
    alignItems: 'center',
  },
  orderDropdownPane: {
    minWidth: 276,
    paddingTop: 32,
    paddingHorizontal: 16,
    paddingBottom: 16,
    alignItems: 'center',
  },
  orderDropdownGraphicAndButtonsStack: {
    gap: 24,
    width: '100%',
    alignItems: 'center',
  },
  orderDropdownButtonStack: {
    width: '100%',
    gap: 8,
  },
});
