import React from 'react';

import { Box, Divider, Header } from '@fhs-legacy/universal-components';
import { EnvItem, EnvList, EnvListContainer, ServiceModeItem } from 'pages/diagnostics/components';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import AuthStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';

import { ConfigSettings, LDFlags, LocalStorageItems } from './components';

export const InternalDiagnostics = () => {
  const enableInternalDiagnosticTools = useFlag(LaunchDarklyFlag.ENABLE_INTERNAL_DIAGNOSTIC_TOOLS);

  const { noStoreSelected, serviceModeStatus } = useStoreContext();

  if (!enableInternalDiagnosticTools) {
    return null;
  }

  return (
    <>
      <Header pt={4} variant="headerFour">
        User Auth Info
      </Header>
      <EnvItem envKey="Auth Token" value={AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN)} />
      <Divider />
      <Divider />
      <ConfigSettings />
      <Divider />
      <LDFlags />
      <Divider />
      <LocalStorageItems />
      <Divider />
      <Header pt={4} variant="headerThree">
        Store Info
      </Header>
      {!noStoreSelected ? (
        <>
          <Header variant="headerFour">Store Service Modes Availability</Header>
          <EnvListContainer>
            <EnvList
              data={serviceModeStatus}
              renderItem={(key, value) => <ServiceModeItem envKey={key} value={value} />}
            />
          </EnvListContainer>
        </>
      ) : (
        <Box>No store selected</Box>
      )}
    </>
  );
};
