import { useCallback } from 'react';

import { Guide } from '../guide';
import { Step } from '../guide/types';

export const ComboGuide = ({
  steps,
  onClose,
  initialStepIndex = 0,
}: {
  onClose: VoidFunction;
  steps: Step[];
  initialStepIndex?: number;
}) => {
  return (
    <Guide
      onRequestClose={onClose}
      steps={steps}
      initialStepIndex={initialStepIndex}
      onGuideFinish={useCallback(() => {
        onClose();
      }, [onClose])}
    />
  );
};
