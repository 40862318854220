import { isEmpty } from 'lodash';
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { IStore } from '@rbi-ctg/store';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import { useToast } from 'hooks/use-toast';
import { actions, useAppDispatch } from 'state/global-state';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useEvaluateLoyaltyAtRestaurant } from 'state/loyalty/hooks/use-evaluate-loyalty-at-restaurant';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { StorageKeys } from 'utils/local-storage';

import { useLoyaltyUser } from '../hooks/use-loyalty-user';

import { ICartEntryType, useInRestaurantCart } from './hooks/use-in-restaurant-redemption-cart';
import type { ICartEntry, ICreateCartEntryParams } from './hooks/use-in-restaurant-redemption-cart';
import { ShortCodeState, useShortCode } from './hooks/use-short-code';
import { ShortCodePollingStatus, useShortCodePollStatus } from './hooks/use-short-code-poll-status';
import useStaticIdentifier from './hooks/use-static-identifier';
import { isRedemptionOnCurrentDevice } from './is-redemption-on-current-device';
import { ShortCodeConfirmationModal, ShortCodeOverrideModal } from './modals';
import {
  IInRestaurantFlagVariations,
  IInRestaurantRedemptionContext,
  InRestaurantRedemptionFlagsState,
} from './types';
import { useIsServiceModeValid } from './use-is-service-mode-valid';
import { generateOrderLineItems, getAppliedOffersFromInRestaurantCart } from './utils';

interface IOverrideModalConfirmation {
  overrideShortCodeCallback: () => void;
}

export const InRestaurantRedemptionContext = createContext<IInRestaurantRedemptionContext>(
  {} as IInRestaurantRedemptionContext
);
export const useInRestaurantRedemptionContext = () => useContext(InRestaurantRedemptionContext);

export const InRestaurantRedemptionProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { refetchLoyaltyUser } = useLoyaltyContext();
  const { loyaltyUser, loyaltyUserId } = useLoyaltyUser();
  const { formatMessage } = useIntl();
  const { store } = useStoreContext();
  const { evaluateLoyaltyAtRestaurant } = useEvaluateLoyaltyAtRestaurant();
  const { serviceMode } = useServiceModeContext();
  const isServiceModeValid = useIsServiceModeValid(serviceMode);
  const toast = useToast();

  const [ShortCodeOverrideModalConfirmation, openOverrideModalConfirmation] =
    useDialogModal<IOverrideModalConfirmation>({
      // @ts-expect-error TS(2322) FIXME: Type 'FC<IShortCodeOverrideModalProps>' is not ass... Remove this comment to see the full error message
      Component: ShortCodeOverrideModal,
      // @ts-expect-error TS(2322) FIXME: Type '({ overrideShortCodeCallback }: IOverrideMod... Remove this comment to see the full error message
      onConfirm: ({ overrideShortCodeCallback }: IOverrideModalConfirmation) => {
        overrideShortCodeCallback();
      },
    });
  const shouldForceRestaurantSelection = Boolean(
    useFlag(LaunchDarklyFlag.FORCE_RESTAURANT_SELECTION_FOR_REWARDS)
  );

  const enableInRestaurantVariations = useFlag<IInRestaurantFlagVariations>(
    LaunchDarklyFlag.ENABLE_IN_RESTAURANT_VARIATIONS
  );
  const enableInRestaurantRedemption = !isEmpty(enableInRestaurantVariations);
  const inRestaurantRedemptionEnabled = Boolean(
    isRedemptionOnCurrentDevice() && enableInRestaurantRedemption && isServiceModeValid
  );

  const [
    { inRestaurantLoyaltyEnabledAtRestaurant, inRestaurantLoyaltyEnabledAtRestaurantLoading },
    setFlagsConfig,
  ] = useState<InRestaurantRedemptionFlagsState>({
    inRestaurantLoyaltyEnabledAtRestaurantLoading: true,
    inRestaurantLoyaltyEnabledAtRestaurant: inRestaurantRedemptionEnabled,
  });

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [scanSucceeded, setScanSucceeded] = useState<boolean>(false);

  const setScanSuccess = (success: boolean) => {
    setScanSucceeded(success);
  };

  /** Evaluates InRestaurantRedemption feature availability against a store different from the one set in context */
  const isInRestaurantRedemptionEnabledAtStore = useCallback(
    async (storeToCheck: IStore, serviceModeToCheck: ServiceMode | null) => {
      if (!inRestaurantRedemptionEnabled) {
        return false;
      }

      return shouldForceRestaurantSelection
        ? await evaluateLoyaltyAtRestaurant(storeToCheck, serviceModeToCheck)
        : true;
    },
    [evaluateLoyaltyAtRestaurant, inRestaurantRedemptionEnabled, shouldForceRestaurantSelection]
  );

  /** Evaluates restaurant loyalty ability against the current Store in the context */
  useEffect(() => {
    let mounted = true;

    async function setEnabledAtRestaurant() {
      setFlagsConfig(prev =>
        prev.inRestaurantLoyaltyEnabledAtRestaurantLoading
          ? prev
          : { ...prev, inRestaurantLoyaltyEnabledAtRestaurantLoading: true }
      );
      let isEnabledAtRestaurant = false;

      if (store) {
        isEnabledAtRestaurant = await isInRestaurantRedemptionEnabledAtStore(
          store as IStore,
          serviceMode
        );
      }

      if (mounted) {
        setFlagsConfig({
          inRestaurantLoyaltyEnabledAtRestaurant: isEnabledAtRestaurant,
          inRestaurantLoyaltyEnabledAtRestaurantLoading: false,
        });
      }
    }

    setEnabledAtRestaurant();

    return () => {
      mounted = false;
    };
  }, [isInRestaurantRedemptionEnabledAtStore, serviceMode, store]);

  const inRestaurantRedemptionStatusPollIntervalMs = useFlag(
    LaunchDarklyFlag.LOYALTY_IN_RESTAURANT_STATUS_POLL_INTERVAL_MS
  );
  const dispatch = useAppDispatch();

  const {
    inRestaurantCart,
    addInRestaurantCartEntry,
    removeInRestaurantCartEntry: removeInRestaurantRedemption,
    updateInRestaurantCartEntryQuantity: updateInRestaurantRedemptionQuantity,
    resetInRestaurantCart,
    isInRestaurantCartEmpty,
    existEntryTypeInRestaurantCart,
    clearInRestaurantCartAllRewards,
    lastModificationDate,
    updateLastModificationDate,
    removeTypeFromCart,
  } = useInRestaurantCart();
  const {
    resetShortCode,
    getNewShortCode,
    shortCodeLoading,
    shortCodeState,
    setShortCodeClaimed,
    setShortCodeExpired,
    shortCode,
  } = useShortCode();

  const clearInRestaurantCartAllOffers = useCallback(() => {
    removeTypeFromCart(ICartEntryType.OFFER);
    removeTypeFromCart(ICartEntryType.LEGACY_OFFER);
  }, [removeTypeFromCart]);
  const { staticIdentifier, isStaticIdentifierEnabled } = useStaticIdentifier();

  const skipPolling: boolean =
    !inRestaurantRedemptionStatusPollIntervalMs ||
    shortCodeLoading ||
    shortCodeState !== ShortCodeState.Pending;

  const { pollingShortCodeStatus, resetPollingStatus } = useShortCodePollStatus({
    loyaltyId: loyaltyUserId,
    skipPolling,
    shortCode,
    pollInterval: inRestaurantRedemptionStatusPollIntervalMs,
  });

  const storeNumber = store?.number;

  const resetInRestaurantState = useCallback(() => {
    resetPollingStatus();
    resetShortCode();
    resetInRestaurantCart();
  }, [resetInRestaurantCart, resetPollingStatus, resetShortCode]);

  const resetInRestaurantRedemption = useCallback(
    (shouldResetAvailableRewardsMap = true) => {
      // reset all the in-restaurant state
      resetInRestaurantState();

      // reset loyalty state
      removeAppliedRewardsInStorage();
      dispatch(
        actions.loyalty.resetLoyaltyRewardsState({
          points: loyaltyUser?.points ?? 0,
          shouldResetAvailableRewardsMap,
        })
      );
    },
    [dispatch, loyaltyUser, resetInRestaurantState]
  );

  const generateShortCode = useCallback(() => {
    if (loyaltyUserId) {
      getNewShortCode({
        appliedOffers: getAppliedOffersFromInRestaurantCart(inRestaurantCart),
        inRestaurantOrder: generateOrderLineItems(inRestaurantCart),
        loyaltyId: loyaltyUserId,
        restaurantId: storeNumber,
        serviceMode,
      });
    }
  }, [getNewShortCode, inRestaurantCart, loyaltyUserId, serviceMode, storeNumber]);

  const enabeQrAndShortCode =
    useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_QR_AND_SHORT_CODE) && !inRestaurantCart.length;

  useEffectOnUpdates(() => {
    const skipCodeGeneration = isStaticIdentifierEnabled && isInRestaurantCartEmpty;
    if (skipCodeGeneration) {
      return;
    }
    resetShortCode();
    // generate a new short code on every cart update
    generateShortCode();
  }, [inRestaurantCart]);

  useEffect(() => {
    if (pollingShortCodeStatus === ShortCodePollingStatus.Confirmed) {
      setShortCodeClaimed();
    } else if (pollingShortCodeStatus === ShortCodePollingStatus.NotFound) {
      // expiring the code if it's not found by the polling request
      setShortCodeExpired();
    }
  }, [pollingShortCodeStatus, setShortCodeClaimed, setShortCodeExpired]);

  useEffect(() => {
    // Reset in restaurant redepmtion if  the feature isn't enabled
    if (
      !inRestaurantRedemptionEnabled ||
      (!inRestaurantLoyaltyEnabledAtRestaurant && !inRestaurantLoyaltyEnabledAtRestaurantLoading)
    ) {
      resetInRestaurantRedemption(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inRestaurantLoyaltyEnabledAtRestaurantLoading,
    inRestaurantRedemptionEnabled,
    inRestaurantLoyaltyEnabledAtRestaurant,
  ]);

  useEffect(() => {
    if (shortCodeState === ShortCodeState.Claimed && !enabeQrAndShortCode) {
      setShowConfirmationModal(true);
    } else if (shortCodeState === ShortCodeState.OfferValidationError) {
      toast.show({
        text: formatMessage({ id: 'invalidOffersRemoved' }),
        variant: 'neutral',
      });
      // TODO: clear only invalid offers
      clearInRestaurantCartAllOffers();
    }
  }, [clearInRestaurantCartAllOffers, formatMessage, shortCodeState, toast, enabeQrAndShortCode]);

  const { enableOfferRedemption = false, enableRewardRedemption = false } =
    enableInRestaurantVariations || {};
  const canRedeemLoyaltyIncentives = enableOfferRedemption || enableRewardRedemption;

  useEffect(() => {
    if (!enableOfferRedemption) {
      clearInRestaurantCartAllOffers();
    }
    if (!enableRewardRedemption) {
      clearInRestaurantCartAllRewards();
    }
  }, [
    clearInRestaurantCartAllOffers,
    clearInRestaurantCartAllRewards,
    enableOfferRedemption,
    enableRewardRedemption,
    removeTypeFromCart,
  ]);

  /**
   * Adds the redemption object to the cart and applies the reward if necessary,
   * and displays a toast if the action was successful, and redirects the user
   * to the in-restaurant redemption page.
   */
  const addInRestaurantRedemptionEntry = useCallback(
    (item: ICreateCartEntryParams) => {
      const cartEntry = addInRestaurantCartEntry(item);

      if (cartEntry) {
        let entityType = 'offer';

        if (cartEntry.type === ICartEntryType.REWARD) {
          entityType = 'reward';
          // apply the reward
          const { engineReward } = cartEntry.details;
          dispatch(
            actions.loyalty.applyReward({
              cartId: engineReward.id,
              rewardBenefitId: engineReward.rewardBenefitId,
            })
          );
        }

        toast.show({
          text: formatMessage(
            { id: 'addToInRestaurantRedemptionCartSuccess' },
            {
              type: formatMessage({
                id: entityType,
              }),
            }
          ),
          variant: 'positive',
        });
      }
    },
    [toast, addInRestaurantCartEntry, dispatch, formatMessage]
  );

  /**
   * Removes the cart entry from the cart and remove the reward from `applied rewards` if necessary.
   */
  const removeInRestaurantRedemptionEntry = useCallback(
    (cartEntry: ICartEntry) => {
      removeInRestaurantRedemption(cartEntry);
      updateLastModificationDate(new Date());
      if (cartEntry.type === ICartEntryType.REWARD) {
        const { engineReward } = cartEntry.details;

        resetInRestaurantState();

        // remove the applied reward
        dispatch(
          actions.loyalty.removeAppliedReward({
            cartId: engineReward.id,
            rewardBenefitId: engineReward.rewardBenefitId,
          })
        );
      }
    },
    [dispatch, removeInRestaurantRedemption, updateLastModificationDate]
  );

  /**
   * Updates the cart entry quantity and apply or unapply the reward depending on the given and previous quantity.
   */
  const updateInRestaurantRedemptionEntryQuantity = useCallback(
    (cartEntry: ICartEntry, quantity: number) => {
      updateInRestaurantRedemptionQuantity(cartEntry, quantity);

      // apply or un-apply the reward as many times as necessary
      if (cartEntry.type === ICartEntryType.REWARD) {
        const {
          details: { engineReward },
          quantity: beforeQuantity,
        } = cartEntry;
        const { applyReward, unApplyReward } = actions.loyalty;
        const isDecrementAction = quantity - beforeQuantity < 0;
        const handleAction = isDecrementAction ? unApplyReward : applyReward;

        const { id: cartId, rewardBenefitId } = engineReward;
        for (let i = 0; i < Math.abs(quantity - beforeQuantity); i++) {
          dispatch(
            handleAction({
              rewardBenefitId,
              cartId,
              loyaltyUser,
            })
          );
        }
      }
    },
    [dispatch, loyaltyUser, updateInRestaurantRedemptionQuantity]
  );

  /**
   * The actions that have the ability to override the short code, should be wrapper by
   * this function in order to display a confirmation modal before the action is called.
   */
  const overrideShortCodeActionWrapper = useCallback(
    <T extends (...args: any) => void>(callback: T): ((...args: Parameters<T>) => void) =>
      (...args: Parameters<T>) => {
        if (pollingShortCodeStatus === ShortCodePollingStatus.Processing) {
          // call to the callback to override the state
          openOverrideModalConfirmation({
            overrideShortCodeCallback: () => callback(...args),
          });
        } else {
          callback(...args);
        }
      },
    [openOverrideModalConfirmation, pollingShortCodeStatus]
  );

  const onConfirmationModalAction = useCallback(() => {
    resetInRestaurantRedemption();

    // re-fetching user points
    refetchLoyaltyUser();
    setShowConfirmationModal(false);
  }, [refetchLoyaltyUser, resetInRestaurantRedemption]);

  useSetResetCartTimeout({
    storageKey: StorageKeys.IN_RESTAURANT_CART_LAST_UPDATE,
    cart: inRestaurantCart,
    resetCartCallback: resetInRestaurantRedemption,
  });

  const addInRestaurantRedemptionEntryMemo = useMemo(
    () =>
      overrideShortCodeActionWrapper<typeof addInRestaurantRedemptionEntry>(
        addInRestaurantRedemptionEntry
      ),
    [overrideShortCodeActionWrapper, addInRestaurantRedemptionEntry]
  );
  return (
    <InRestaurantRedemptionContext.Provider
      value={{
        enableOfferRedemption,
        enableRewardRedemption,
        inRestaurantLoyaltyEnabledAtRestaurant,
        inRestaurantLoyaltyEnabledAtRestaurantLoading,
        inRestaurantRedemptionEnabled,
        generateShortCode,
        inRestaurantRedemptionCart: inRestaurantCart,
        // applying the override short code wrapper to catch up when the cart is changing
        // and display a confirmation modal
        addInRestaurantRedemptionEntry: addInRestaurantRedemptionEntryMemo,
        removeInRestaurantRedemptionEntry: overrideShortCodeActionWrapper<
          typeof removeInRestaurantRedemptionEntry
        >(removeInRestaurantRedemptionEntry),
        updateInRestaurantRedemptionEntryQuantity: overrideShortCodeActionWrapper<
          typeof updateInRestaurantRedemptionEntryQuantity
        >(updateInRestaurantRedemptionEntryQuantity),
        isInRestaurantRedemptionCartEmpty: isInRestaurantCartEmpty,
        resetInRestaurantRedemption,
        shortCodeLoading,
        shortCodeState,
        shortCode,
        existEntryTypeInRestaurantRedemptionCart: existEntryTypeInRestaurantCart,
        clearInRestaurantRedemptionAllRewards: clearInRestaurantCartAllRewards,
        // @ts-expect-error TS(2322) FIXME: Type '(storeToCheck: IStore, serviceModeToCheck: S... Remove this comment to see the full error message
        isInRestaurantRedemptionEnabledAtStore,
        inRestaurantRedemptionLastModificationDate: lastModificationDate,
        staticIdentifier,
        isStaticIdentifierEnabled,
        scanSucceeded,
        setScanSuccess,
        shouldForceRestaurantSelection,
        canRedeemLoyaltyIncentives,
      }}
    >
      {showConfirmationModal && (
        <ShortCodeConfirmationModal
          onAction={onConfirmationModalAction}
          onDismiss={onConfirmationModalAction}
        />
      )}
      <ShortCodeOverrideModalConfirmation />

      {children}
    </InRestaurantRedemptionContext.Provider>
  );
};
