import { useLocalSearchParams } from 'expo-router';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet, View } from 'react-native';

import { ILocation } from '@rbi-ctg/frontend';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { FormValidationState } from 'utils/form';
import { IPlaceAddress } from 'utils/geolocation';
import logger from 'utils/logger';

import { IDeliveryAddressFormProps, IPhoneAddressValidation } from '../types';

import DeliveryAddressFormFooter from './delivery-address-form-footer';
import DeliveryAddressFormInput from './delivery-address-form-input';
import { AdditionalInformation } from './styled';

export const isCompleteAddress = (address: IPlaceAddress): boolean => {
  const { addressLine1, city, state, zip } = address;
  return !!(addressLine1 && city && state && zip);
};

const DeliveryAddressForm: React.FC<React.PropsWithChildren<IDeliveryAddressFormProps>> = ({
  instructions,
  user,
  setCreateAddressView,
  getDetailsPlaceData,
  showDeliveryAddresses,
  setAddress,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  const { address: addressUrlParam } = useLocalSearchParams<{ address?: string }>();
  const unitRef = useRef<HTMLInputElement>(null);
  const deliveryAddressInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [placeId, setPlaceId] = useState('');

  const [validation, setValidation] = useState<IPhoneAddressValidation>({
    phoneNumber: FormValidationState.PRISTINE,
    address: FormValidationState.PRISTINE,
  });
  const [addressComponents, setAddressComponents] = useState<IPlaceAddress | null>(null);
  const [coordinates, setCoordinates] = useState<ILocation | undefined>();

  const [addressString, setAddressString] = useState(addressUrlParam ?? '');
  const [deliveryInstructions, setDeliveryInstructions] = useState(instructions || '');

  const phoneNumber = user?.details?.phoneNumber || '';

  const handleSubmit = async () => {
    setMessage('');

    if (!addressComponents || validation.address === FormValidationState.INVALID) {
      setMessage(formatMessage({ id: 'addressError' }));

      if (deliveryAddressInputRef.current) {
        deliveryAddressInputRef.current.focus();
      }
      return;
    }

    setAddress({
      address: addressComponents,
      deliveryInstructions,
      phoneNumber,
      coordinates,
      placeId,
    });
  };

  useEffect(() => {
    if (!placeId) {
      return;
    }

    getDetailsPlaceData(placeId)
      .then(placeData => {
        if (!isEqual(placeData.address, addressComponents)) {
          setDeliveryInstructions('');
        }

        if (!placeData.address.addressLine1) {
          setValidation({ ...validation, address: FormValidationState.INVALID });
          setMessage(formatMessage({ id: 'addressError' }));
          return;
        }

        setCoordinates(placeData.coordinates);
        setAddressComponents(placeData.address);
      })
      .catch(error => {
        logger.error({ error, message: 'Error using selected address from predictions' });
      });
  }, [formatMessage, getDetailsPlaceData, placeId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <View style={styles.wrapperView}>
      <View>
        <AdditionalInformation>
          {formatMessage({ id: 'additionalAddressInformation' })}
        </AdditionalInformation>
        <DeliveryAddressFormInput
          setAddressString={setAddressString}
          setPlaceId={setPlaceId}
          setValidation={setValidation}
          setMessage={setMessage}
          setAddressComponents={setAddressComponents}
          unitRef={unitRef}
          validation={validation}
          setCoordinates={setCoordinates}
          addressString={addressString}
          initialFocus={!!addressUrlParam}
          setValue={setAddressString}
          message={message}
          deliveryAddressInputRef={deliveryAddressInputRef}
          data-private
          aria-invalid={!!message}
        />
        {!!message && (
          <VisuallyHidden
            role="alert"
            accessibilityLabel={formatMessage({ id: 'thereIsAnErrorInThisForm' })}
          />
        )}
      </View>

      <View style={styles.zIndexLow}>
        <DeliveryAddressFormFooter
          submitAddress={handleSubmit}
          setCreateAddressView={setCreateAddressView}
          showDeliveryAddresses={showDeliveryAddresses}
          isDisabled={!addressComponents?.addressLine1 || !!message}
          isLoading={isLoading}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  zIndexLow: {
    // negative z index so this appears underneath the auto complete instead of on top
    zIndex: -1,
  },
  wrapperView: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 16,
  },
});

export default DeliveryAddressForm;
