import { Stack, router } from 'expo-router';
import { useEffect } from 'react';

import { useIsNavigationReady } from '@fhs/utils';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

export default function OffersLayout() {
  const isNavigationReady = useIsNavigationReady();
  const enableSimplyOfferUi = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_OFFER_UI);
  useEffect(() => {
    if (isNavigationReady && enableSimplyOfferUi) {
      router.replace(routes.offersV2);
    }
  }, [enableSimplyOfferUi, isNavigationReady]);
  return (
    <Stack
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="index" />
      <Stack.Screen name="[incentiveId]" />
    </Stack>
  );
}
