import { Image } from 'expo-image';
import { router } from 'expo-router';
import { useLocalSearchParams } from 'expo-router';
import { useCallback, useMemo } from 'react';
import { ImageStyle, StyleProp, TouchableOpacity, View, useWindowDimensions } from 'react-native';

import {
  ActionSheet,
  Button,
  IconClose,
  IconDelete,
  ScrollView,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { IncentiveEvaluationErrorCodes } from '@fhs-legacy/frontend/src/state/loyalty/hooks/types';

import { OfferDetailsSkeleton } from '../../components';
import { useApplyOffer } from '../../hooks/use-apply-offer';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';
import { useRemoveOffer } from '../../hooks/use-remove-offer';
import { useOfferIncentive } from '../../queries/loyalty.queries';

import { ErrorRenderer } from './error-renderer';

export function OfferDetailScreen() {
  const styles = useMqStyles();
  const { offerId } = useLocalSearchParams<{
    offerId: string;
  }>();
  const { loyaltyUserReady, selectedOffer } = useLoyaltyLegacyStates();
  const { data: currentOffer, isLoading: isLoyaltyIncentivesLoading } = useOfferIncentive(offerId);
  const isLoading = isLoyaltyIncentivesLoading || !loyaltyUserReady;
  const isOfferSelected = useMemo(
    () => selectedOffer?._id === offerId,
    [offerId, selectedOffer?._id]
  );
  const { applyOffer } = useApplyOffer();
  const { removeOffer } = useRemoveOffer();
  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const { height: screenHeight } = useWindowDimensions();

  const error = currentOffer?.errors?.[0] as IncentiveEvaluationErrorCodes | undefined;
  const onClose = useCallback(() => {
    router.dismiss();
  }, []);
  const onPressRemoveOffer = useCallback(() => {
    removeOffer({
      onConfirm: () => {
        router.dismiss();
      },
    });
  }, [removeOffer]);

  const onPressApplyOffer = useCallback(() => {
    if (currentOffer) {
      router.dismiss();
      applyOffer(currentOffer);
    }
  }, [currentOffer, applyOffer]);

  const containerStyle = isDesktop ? { height: screenHeight } : ({ height: '100%' } as const);

  return (
    <ActionSheet isVisible onClose={onClose} containerStyle={{ height: '90%' }}>
      <View style={containerStyle}>
        <View style={styles.headerContainer}>
          <Text.Ui size="md" weight="semibold" style={styles.headerTitle}>
            Offer Details
          </Text.Ui>
          <TouchableOpacity style={styles.closeIcon} onPress={onClose}>
            <IconClose size={24} />
          </TouchableOpacity>
        </View>
        {isLoading ? (
          <OfferDetailsSkeleton textBlockLines={15} />
        ) : (
          <View style={styles.content}>
            <ScrollView style={styles.scrollViewContainer}>
              <View style={styles.imageContainer}>
                <Image
                  style={styles.image as StyleProp<ImageStyle>}
                  source={{ uri: currentOffer?.image }}
                  contentFit="contain"
                />
              </View>
              <View style={styles.scrollContent}>
                <View style={styles.titleContainer}>
                  <Text.Heading type="one" style={styles.name}>
                    {currentOffer?.name}
                  </Text.Heading>
                  <Text.Ui size="md" style={styles.description}>
                    {currentOffer?.description}
                  </Text.Ui>
                  {error && <ErrorRenderer error={error} />}
                </View>
                <Text.Paragraph style={styles.termsAndConditions} size="sm">
                  {currentOffer?.termsAndConditions}
                </Text.Paragraph>
              </View>
            </ScrollView>
            <View style={styles.footer}>
              {isOfferSelected ? (
                <Button style={styles.buttonContainer} size="xl" onPress={onPressRemoveOffer}>
                  <Button.Icon color={tokens.colors.$white}>
                    <IconDelete />
                  </Button.Icon>
                  <Button.Text>Remove Offer</Button.Text>
                </Button>
              ) : (
                <Button
                  disabled={!currentOffer?.isAvailable}
                  style={styles.buttonContainer}
                  size="xl"
                  onPress={onPressApplyOffer}
                >
                  <Button.Text>Apply Offer</Button.Text>
                </Button>
              )}
            </View>
          </View>
        )}
      </View>
    </ActionSheet>
  );
}

const useMqStyles = createMqStyles({
  headerContainer: {
    $base: {
      paddingVertical: 12,
      paddingHorizontal: 16,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderColor: tokens.colors.$black10,
    },
    $gteDesktop: {
      paddingVertical: 28,
      paddingHorizontal: 28,
      justifyContent: 'flex-start',
    },
  },
  closeIcon: {
    $base: {
      position: 'absolute',
      right: 16,
    },
  },
  header: {
    $gteDesktop: {
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: 28,
      height: 90,
      width: 520,
      backgroundColor: tokens.colors.$white,
    },
  },
  headerTitle: {
    $base: {
      fontSize: 16,
    },
    $gteDesktop: {
      fontSize: 24,
    },
  },
  content: {
    $base: {
      flex: 1,
      backgroundColor: tokens.colors.$white,
    },
    $gteDesktop: {
      width: 520,
    },
  },

  imageContainer: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
    },
  },
  image: {
    $base: {
      height: 216,
    },
  },
  titleContainer: {
    $base: {
      borderBottomWidth: 1,
      borderBottomColor: tokens.colors.$blackOpacity04,
      paddingVertical: 20,
    },
  },
  name: {
    $base: {
      textAlign: 'center',
      color: tokens.colors.$houseDark,
      paddingTop: 4,
    },
  },
  description: {
    $base: {
      textAlign: 'center',
      color: tokens.colors.$houseDark,
      paddingTop: 4,
    },
  },
  scrollViewContainer: {
    $base: {
      flex: 1,
    },
  },
  scrollContent: {
    $base: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    $gteDesktop: {
      paddingLeft: 28,
      paddingRight: 28,
    },
  },
  termsAndConditions: {
    $base: {
      color: tokens.colors.$blackOpacity55,
      paddingVertical: 20,
    },
  },
  footer: {
    $base: {
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity10,
      alignItems: 'center',
      padding: 16,
    },
    $gteDesktop: {
      padding: 28,
    },
  },
  buttonContainer: {
    $base: {
      width: '100%',
    },
  },
});
