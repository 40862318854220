export const REORDER_HOMEPAGE_STARTED_LOG = '[Reorder] Homepage reorder started';
export const REORDER_DIFFERENT_SERVICE_MODE_LOG =
  '[Reorder] Displayed order has a different service mode from the selected service mode';
export const REORDER_NO_PREVIOUS_STORE_SELECT_LOG = '[Reorder] No previous store selected';
export const REORDER_DIFFERENT_STORE_SELECTED_LOG = '[Reorder] Different store previously selected';
export const REORDER_PROCEEDING_WITH_RECENT_STORE_LOG = '[Reorder] Proceeding with recent store';
export const REORDER_PROCEEDING_WITH_SELECTED_STORE_LOG =
  '[Reorder] Proceeding with selected store';
export const REORDER_PROCEEDING_WITH_ANOTHER_STORE_LOG = '[Reorder] Proceeding with another store';
export const REORDER_PROCEEDING_WITH_ANOTHER_ADDRESS_LOG =
  '[Reorder] Proceeding with another address';
export const REORDER_NO_STORE_SELECTED_LOG = '[Reorder] No store selected';
