import { useCallback } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import { MenuListItemSkeleton } from '@fhs/menu/src/components/menu-list-item-skeleton';
import { MediaQueriesContext, Skeleton, mobileMediaQueries, tokens } from '@fhs/ui';

const data = new Array(8).fill({});

export function OfferGuideLoading() {
  const renderLoadingOffer = useCallback(() => {
    return <MenuListItemSkeleton />;
  }, []);

  const renderHeader = useCallback(() => {
    return (
      <View style={styles.header}>
        <Skeleton style={styles.titleSkeleton} />
        <Skeleton style={styles.subtitleSkeleton} />
        <View style={styles.divider} />
      </View>
    );
  }, []);

  return (
    <MediaQueriesContext.Provider value={mobileMediaQueries}>
      <FlatList
        showsVerticalScrollIndicator={false}
        style={styles.container}
        data={data}
        renderItem={renderLoadingOffer}
        ListHeaderComponent={renderHeader}
        numColumns={2}
        columnWrapperStyle={styles.columnWrapper}
        contentContainerStyle={styles.contentContainer}
      />
    </MediaQueriesContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  columnWrapper: {
    columnGap: 12,
  },
  contentContainer: {
    rowGap: 4,
  },
  header: {
    alignItems: 'center',
    paddingTop: 16,
    marginBottom: 8,
    marginHorizontal: -16,
  },
  titleSkeleton: {
    width: 169,
    height: 14,
    backgroundColor: tokens.colors.$houseLight,
  },
  subtitleSkeleton: {
    width: 233,
    height: 20,
    marginTop: 9,
    backgroundColor: tokens.colors.$houseLight,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
    marginTop: 18,
  },
});
