import { useMemo } from 'react';
import { StyleSheet, type ViewProps } from 'react-native';

import { XStack } from '../stack';

import { SwitchContext } from './context';

export type SwitchProps = ViewProps & {
  disabled?: boolean;
  label?: string;
  onChange: (newValue: boolean) => void;
  value: boolean;
};

export function Switch({
  disabled = false,
  label = '',
  onChange,
  value,
  ...viewProps
}: SwitchProps) {
  const context = useMemo(
    () => ({
      disabled,
      handlePress: () => {
        if (disabled) {
          return;
        }

        onChange(!value);
      },
      label,
      value,
    }),
    [disabled, value, label, onChange]
  );

  return (
    <SwitchContext.Provider value={context}>
      <XStack style={styles.container} {...viewProps} />
    </SwitchContext.Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    gap: 16,
  },
});
