import { router } from 'expo-router';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  ConfirmLocationActionSheet,
  ConfirmStoreActionSheet,
} from '@fhs/routing/src/components/home-reorder-confirmation';
import { IHistoricalOrder } from '@rbi-ctg/menu';
import useSearchAddress from 'hooks/geolocation/use-search-address';
import { getServiceModeBottomCTAText } from 'pages/cart/types';
import { ServiceMode, useServiceModeContext } from 'state/service-mode';
import logger from 'utils/logger';
import { routes } from 'utils/routing';
import { isDelivery as _isDelivery } from 'utils/service-mode';

import { useOrderContext } from '..';
import {
  REORDER_DIFFERENT_SERVICE_MODE_LOG,
  REORDER_NO_STORE_SELECTED_LOG,
  REORDER_PROCEEDING_WITH_ANOTHER_ADDRESS_LOG,
  REORDER_PROCEEDING_WITH_ANOTHER_STORE_LOG,
  REORDER_PROCEEDING_WITH_RECENT_STORE_LOG,
  REORDER_PROCEEDING_WITH_SELECTED_STORE_LOG,
} from '../constants/reorder-logs';

import { useUserRecentOrder } from './use-user-recent-order';

export const useHomepageReorder = (singleOrder?: IHistoricalOrder) => {
  const [showReorderStoreConfirmationActionsheet, setShowReorderStoreConfirmationActionsheet] =
    useState(false);

  const [
    showReorderLocationConfirmationActionsheet,
    setShowReorderLocationConfirmationActionsheet,
  ] = useState(false);

  const { serviceMode: selectedServiceMode, setServiceMode } = useServiceModeContext();
  const { formatMessage } = useIntl();
  const {
    store,
    noStoreSelected,
    recentStoreNumber,
    recentOrder,
    handlePickupReorder,
    handleDeliveryReorder,
    selectDeliveryAddress,
    handleStoreSelectAndReorder,
    recentOrderLoading,
    isReorderingLoading,
  } = useUserRecentOrder({ singleOrder }); // We can specify order if we already have it.

  const displayedOrder = useMemo(() => {
    if (recentOrder) {
      return recentOrder;
    }

    return null;
  }, [recentOrder]);

  const { deliveryAddress, setDeliveryAddress } = useOrderContext();

  const searchAddress = useSearchAddress();

  const handleReorderCallback = useCallback(() => {
    if (!displayedOrder || !displayedOrder.serviceMode) {
      return;
    }
    // Displayed order has a different service mode from the selected service mode
    if (selectedServiceMode && displayedOrder.serviceMode !== selectedServiceMode) {
      logger.info({
        message: REORDER_DIFFERENT_SERVICE_MODE_LOG,
        context: {
          displayedOrder,
          serviceMode: displayedOrder.serviceMode,
        },
      });

      setShowReorderLocationConfirmationActionsheet(true);
      return;
    }

    // There's no store selected
    if (noStoreSelected) {
      logger.info({
        message: REORDER_NO_STORE_SELECTED_LOG,
        context: {
          displayedOrder,
        },
      });
      setShowReorderLocationConfirmationActionsheet(true);
      return;
    }

    // Displayed order is a Delivery order
    if (_isDelivery(displayedOrder.serviceMode as ServiceMode)) {
      // Delivery scenarios
      handleDeliveryReorder(() => setShowReorderLocationConfirmationActionsheet(true));
      return;
    }

    // Displayed order is a Pickup order
    handlePickupReorder(() => setShowReorderStoreConfirmationActionsheet(true));
  }, [
    selectedServiceMode,
    displayedOrder,
    handlePickupReorder,
    handleDeliveryReorder,
    setShowReorderStoreConfirmationActionsheet,
    setShowReorderLocationConfirmationActionsheet,
    noStoreSelected,
  ]);

  const locationConfirmDialogOptions = useMemo(() => {
    if (!displayedOrder || !recentStoreNumber || !displayedOrder.serviceMode) {
      return null;
    }

    const currentServiceModeFormatted = selectedServiceMode
      ? getServiceModeBottomCTAText(formatMessage)[selectedServiceMode]
      : '';

    const recentOrderIsDelivery = _isDelivery(displayedOrder.serviceMode as ServiceMode);
    const currentSelectModeIsDelivery = _isDelivery(selectedServiceMode);

    return {
      option1: {
        isDelivery: recentOrderIsDelivery,
        storeAddress:
          recentOrderIsDelivery && displayedOrder.delivery
            ? displayedOrder.delivery.dropoff?.addressLine1 || displayedOrder.storeAddress
            : displayedOrder.storeAddress,
        storeName: `${displayedOrder.serviceModeTitle} • ${displayedOrder.storeName}`,
        onPress: async () => {
          setShowReorderLocationConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_RECENT_STORE_LOG,
            context: {
              displayedOrder,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          setServiceMode(displayedOrder.serviceMode as ServiceMode);

          if (recentOrderIsDelivery && displayedOrder.delivery) {
            const address = await searchAddress(
              displayedOrder.delivery.dropoff?.addressLine1 ?? displayedOrder.storeAddress ?? ''
            );

            if (!address) {
              selectDeliveryAddress({
                address:
                  displayedOrder.delivery.dropoff?.addressLine1 ??
                  displayedOrder.storeAddress ??
                  '',
                orderId: displayedOrder.id,
              });
              return;
            }

            setDeliveryAddress(address);
          }

          handleStoreSelectAndReorder(recentStoreNumber.toString());
        },
      },
      option2: noStoreSelected
        ? undefined
        : {
            isDelivery: currentSelectModeIsDelivery,
            storeAddress: currentSelectModeIsDelivery
              ? deliveryAddress.addressLine1
              : store.physicalAddress?.address1 || store.name,
            storeName: `${currentServiceModeFormatted} • ${store.name}`,
            onPress: async () => {
              setShowReorderLocationConfirmationActionsheet(false);

              logger.info({
                message: REORDER_PROCEEDING_WITH_SELECTED_STORE_LOG,
                context: {
                  displayedOrder,
                  store,
                  serviceMode: displayedOrder.serviceMode,
                },
              });

              if (currentSelectModeIsDelivery) {
                const address = await searchAddress(deliveryAddress.addressLine1);

                if (!address) {
                  selectDeliveryAddress({
                    address: deliveryAddress.addressLine1,
                    orderId: displayedOrder.id,
                  });
                  return;
                }

                setDeliveryAddress(address);
              }

              handleStoreSelectAndReorder(store?.number ?? '');
            },
          },
      option3: {
        label: formatMessage({ id: 'selectDifferentPickupStore' }),
        onPress: () => {
          setShowReorderLocationConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_ANOTHER_STORE_LOG,
            context: {
              displayedOrder,
              store,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          router.navigate({
            pathname: routes.serviceMode,
            params: { reorderOrderId: displayedOrder.id },
          });
        },
      },
      option4: {
        label: formatMessage({ id: 'selectDifferentDeliveryAddress' }),
        onPress: () => {
          setShowReorderLocationConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_ANOTHER_ADDRESS_LOG,
            context: {
              displayedOrder,
              store,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          selectDeliveryAddress({
            address: '',
            orderId: displayedOrder.id,
          });
        },
      },
    };
  }, [
    displayedOrder,
    recentStoreNumber,
    selectedServiceMode,
    formatMessage,
    deliveryAddress.addressLine1,
    store,
    setServiceMode,
    handleStoreSelectAndReorder,
    searchAddress,
    setDeliveryAddress,
    selectDeliveryAddress,
    noStoreSelected,
  ]);

  const storeSelectDialogOptions = useMemo(() => {
    if (!displayedOrder || !recentStoreNumber || !displayedOrder.serviceMode) {
      return null;
    }

    const currentServiceModeFormatted = selectedServiceMode
      ? getServiceModeBottomCTAText(formatMessage)[selectedServiceMode]
      : '';

    if (_isDelivery(displayedOrder.serviceMode as ServiceMode)) {
      return {
        isDelivery: true,
        option1: {
          storeName: displayedOrder.storeAddress ?? '',
          storeAddress: `${displayedOrder.serviceModeTitle} • ${displayedOrder.storeAddress}`,
          onPress: () => {
            setShowReorderStoreConfirmationActionsheet(false);

            logger.info({
              message: REORDER_PROCEEDING_WITH_RECENT_STORE_LOG,
              context: {
                displayedOrder,
                serviceMode: displayedOrder.serviceMode,
              },
            });

            selectDeliveryAddress({
              address: displayedOrder.storeAddress ?? '',
              orderId: displayedOrder.id,
            });
          },
        },
        option2: {
          storeName: store.physicalAddress?.address1 ?? '',
          storeAddress: `${currentServiceModeFormatted}. • ${store.physicalAddress?.address1}, ${store.physicalAddress?.stateProvince}`,
          onPress: () => {
            setShowReorderStoreConfirmationActionsheet(false);

            logger.info({
              message: REORDER_PROCEEDING_WITH_SELECTED_STORE_LOG,
              context: {
                displayedOrder,
                store,
                serviceMode: displayedOrder.serviceMode,
              },
            });

            selectDeliveryAddress({
              address: store?.physicalAddress?.address1,
              orderId: displayedOrder.id,
            });
          },
        },
        option3: {
          label: formatMessage({ id: 'selectDifferentAddress' }),
          onPress: () => {
            setShowReorderStoreConfirmationActionsheet(false);

            logger.info({
              message: REORDER_PROCEEDING_WITH_ANOTHER_ADDRESS_LOG,
              context: {
                displayedOrder,
                store,
                serviceMode: displayedOrder.serviceMode,
              },
            });

            selectDeliveryAddress({
              address: '',
              orderId: displayedOrder.id,
            });
          },
        },
      };
    }

    return {
      isDelivery: false,
      option1: {
        storeName: displayedOrder.storeName ?? '',
        storeAddress: `${displayedOrder.serviceModeTitle} • ${displayedOrder.storeAddress}`,
        onPress: () => {
          setShowReorderStoreConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_RECENT_STORE_LOG,
            context: {
              displayedOrder,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          handleStoreSelectAndReorder(recentStoreNumber.toString());
        },
      },
      option2: {
        storeName: store.name ?? '',
        storeAddress: `${currentServiceModeFormatted}. • ${store.physicalAddress?.address1}, ${store.physicalAddress?.stateProvince}`,
        onPress: () => {
          setShowReorderStoreConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_SELECTED_STORE_LOG,
            context: {
              displayedOrder,
              store,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          handleStoreSelectAndReorder(store?.number ?? '');
        },
      },
      option3: {
        label: formatMessage({ id: 'selectDifferentStore' }),
        onPress: () => {
          setShowReorderStoreConfirmationActionsheet(false);

          logger.info({
            message: REORDER_PROCEEDING_WITH_ANOTHER_STORE_LOG,
            context: {
              displayedOrder,
              store,
              serviceMode: displayedOrder.serviceMode,
            },
          });

          router.navigate({
            pathname: routes.serviceMode,
            params: { reorderOrderId: displayedOrder.id },
          });
        },
      },
    };
  }, [
    displayedOrder,
    recentStoreNumber,
    handleStoreSelectAndReorder,
    formatMessage,
    selectedServiceMode,
    selectDeliveryAddress,
    store,
  ]);

  const confirmLocationActionSheetComponent = useMemo(() => {
    if (
      !locationConfirmDialogOptions ||
      !showReorderLocationConfirmationActionsheet ||
      showReorderStoreConfirmationActionsheet
    ) {
      return null;
    }

    return (
      <ConfirmLocationActionSheet
        isVisible
        option1={locationConfirmDialogOptions.option1}
        option2={locationConfirmDialogOptions.option2}
        option3={locationConfirmDialogOptions.option3}
        option4={locationConfirmDialogOptions.option4}
        onClose={() => setShowReorderLocationConfirmationActionsheet(false)}
      />
    );
  }, [
    locationConfirmDialogOptions,
    showReorderLocationConfirmationActionsheet,
    showReorderStoreConfirmationActionsheet,
    setShowReorderLocationConfirmationActionsheet,
  ]);

  const confirmStoreActionSheetComponent = useMemo(() => {
    if (
      !storeSelectDialogOptions ||
      !showReorderStoreConfirmationActionsheet ||
      showReorderLocationConfirmationActionsheet
    ) {
      return null;
    }

    return (
      <ConfirmStoreActionSheet
        isVisible
        isDelivery={storeSelectDialogOptions.isDelivery}
        option1={storeSelectDialogOptions.option1}
        option2={storeSelectDialogOptions.option2}
        option3={storeSelectDialogOptions.option3}
        onClose={() => setShowReorderStoreConfirmationActionsheet(false)}
      />
    );
  }, [
    storeSelectDialogOptions,
    showReorderStoreConfirmationActionsheet,
    showReorderLocationConfirmationActionsheet,
    setShowReorderStoreConfirmationActionsheet,
  ]);

  return {
    displayedOrder,
    recentOrderLoading,
    isReorderingLoading,
    handleReorderCallback,
    confirmLocationActionSheetComponent,
    confirmStoreActionSheetComponent,
  };
};
