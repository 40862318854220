import { createSelector } from '@reduxjs/toolkit';
import { useIntl } from 'react-intl';

import { LoyaltySecondaryIdentifierType } from 'generated/graphql-gateway';
import { RootState } from 'state/global-state';

// For more complex multiline selectors, export from this file
export const selectUser = ({ loyalty }: RootState) => loyalty.user.loyaltyUser ?? null;

export const selectLoyaltyLoading = ({ loyalty }: RootState) => loyalty.user.loyaltyLoading;

export const selectLoyaltyUser = createSelector(
  [selectUser, selectLoyaltyLoading],
  (loyaltyUser, loading) => {
    return {
      loyaltyUser,
      loading,
    };
  }
);

export const select12DigitSwipeCard = ({ loyalty }: RootState) =>
  loyalty.user.loyaltyUser?.secondaryIdentifiers?.find(
    identifier =>
      identifier?.identifierType === LoyaltySecondaryIdentifierType.LOYALTY12DIGITSWIPECARD
  );

export const selectLoyaltyUserId = createSelector(
  selectLoyaltyUser,
  loyaltyUser => loyaltyUser?.loyaltyUser?.id
);

export const createDailyRewardTransactionCountSelector =
  (intl: ReturnType<typeof useIntl>) =>
  ({ loyalty }: RootState) => {
    const transactions = loyalty.user.loyaltyUserTransactions;

    const { formatDate } = intl;
    const formatDateOptions = {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    } as const;
    const currentDateFormatted = formatDate(new Date(), formatDateOptions);

    const dailyTransactionCount = transactions.filter(transaction => {
      const transactionDate = formatDate(transaction.createdAt, formatDateOptions);
      return currentDateFormatted === transactionDate && transaction.hasRewards;
    });
    return dailyTransactionCount.length;
  };

export const createLoyaltyUserExpireDateFormattedTextSelector =
  (intl: ReturnType<typeof useIntl>) =>
  ({ loyalty }: RootState) => {
    const pointsExpiryDateKey = loyalty.user.loyaltyUser?.pointsExpiryDateKey;
    if (!pointsExpiryDateKey) {
      return null;
    }
    const { formatDate, formatMessage } = intl;
    const day = `${formatDate(pointsExpiryDateKey, {
      day: '2-digit',
    })}`;

    const month = `${formatDate(pointsExpiryDateKey, {
      month: 'long',
    })}`;
    const dateTime = formatMessage(
      {
        id: 'ordinalDateTime',
        description: {},
        defaultMessage: `${month} {day, selectordinal,
      one {#st}
      two {#nd}
      few {#rd}
      other {#th}
  }`,
      },
      {
        day,
      }
    );
    return dateTime;
  };
