import { v4 as uuidv4 } from 'uuid';

import { useSendPhoneVerificationMutation } from 'generated/rbi-graphql';
import { storeOtpCredentials } from 'state/auth/hooks/use-account-authentication';
import { platform, welcomeEmailDomain } from 'utils/environment';
import logger from 'utils/logger';

const useUserSendPhoneVerification = () => {
  const [sendPhoneVerification, { loading }] = useSendPhoneVerificationMutation();
  const phoneVerification = async () => {
    try {
      const sessionId = uuidv4();
      storeOtpCredentials({ sessionId });
      await sendPhoneVerification({
        variables: {
          input: {
            platform: platform(),
            stage: welcomeEmailDomain(),
            sessionId,
          },
        },
      });
    } catch (error) {
      logger.error({ error, message: 'Failed to send phone verification' });
      throw error;
    }
  };
  return {
    loading,
    phoneVerification,
  };
};

export default useUserSendPhoneVerification;
