import './polyfill';
import 'styles';

import { PortalRenderer } from '@react-native-aria/overlays';
import * as SplashScreen from 'expo-splash-screen';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Alert, Platform, View } from 'react-native';
import Crash from 'react-native-crash-tester';
import { v4 as uuidv4 } from 'uuid';

import ErrorBoundary from 'components/error-boundary-static';
import { ForceUpdateModal } from 'components/force-update-modal';
import ReduceProviders from 'components/reduce-providers';
import { initStatuses, useAppBlockerInit } from 'hooks/use-app-init';
import { theme as uclTheme } from 'styles/theme';
import 'utils/accessibility/text-size';
import 'utils/keyboard/keyboard-config';
import { BrazeLifecycle } from 'utils/braze';
import { brand, isLocalDev, isNative, isSSG, platform } from 'utils/environment';
import analytics from 'utils/firebase-analytics';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { Measures, PerformanceMarks, logMeasurement, setMark } from 'utils/timing';

import bindExceptionHandler from './exception-handler';
import { ExceptionHandler, GtmEvent } from './types';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [StorageKeys.USER_AUTH_TOKEN, StorageKeys.USER];

declare global {
  interface Window {
    dataLayer: GtmEvent[];
    LOADING_START_TIME: number;
    /**
     * window.Cypress exists when our app is running within Cypress
     * Places we currently check for this:
     * - frontend/src/state/graphql/links/index.ts - avoid BatchHttpLink
     *
     * https://docs.cypress.io/faq/questions/using-cypress-faq.html#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
     */
    Cypress?: any;
    // When run with cypress for cypress-v2 package,
    // we instantiate the LD client with predefined flags
    _initial_cypress_feature_flags?: object;
    // Skip the interval for checking for unavailable items.
    // Avoids race condition in cart with recorded tests
    _skipUnavailableItemsIntervalCheck?: boolean;
  }
}

// Added this to prevent crash on Android
// See https://github.com/andyearnshaw/Intl.js#regexp-cache--restore
// and https://github.com/expo/expo/issues/6536
if (Platform.OS === 'android') {
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

// Config options for exception handler
const errorTitle = 'Fatal Error';
// const errorBoundaryBody = 'Something went wrong on our end. Please restart the app.';

const exceptionHandler: ExceptionHandler = (error, isFatal) => {
  const uuid = uuidv4();
  if (isFatal) {
    const message = `FatalError: ${error.message} (${uuid})`;
    logger.fatal({ error: { ...error, message } });
    Alert.alert(
      errorTitle,
      `${error.message} (id: ${uuid})`,
      [
        {
          text: 'Reload',
          onPress: () => {
            LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
            Crash.nativeCrash('ForcedCrash');
          },
          style: 'default',
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      }
    );
  } else {
    // Using warn, since non-fatal errors will be caught by the error boundary
    const message = `NonFatalError: ${error.message} (${uuid})`;
    logger.warn({ error: { ...error, message } });
  }
};

bindExceptionHandler(exceptionHandler);

// Create start time before trying to render the app for true performance numbers
const LOADING_START_TIME = performance.now?.() ?? 0;

// Log an event to Google Analytics only in native
if (isNative) {
  analytics().logEvent('app_loaded', {
    isReactNative: true,
  });
}

const App = ({
  children,
  onRequestReload,
}: PropsWithChildren<{
  onRequestReload(): void;
}>) => {
  const blockingStatus = useAppBlockerInit();

  const appIsReady = blockingStatus !== initStatuses.LOADING;

  // TODO: RN - properly initialize GTM in RN.
  useEffect(() => {
    if (!isLocalDev) {
      const renderSuccessTime = performance.now?.();
      const durationTillRenderSuccess = renderSuccessTime - LOADING_START_TIME;
      (window.dataLayer || []).push({
        event: 'APP_REACT_RENDER_SUCCESS',
        brand: brand(),
        timestamp: renderSuccessTime,
        duration: durationTillRenderSuccess,
        platform: platform(),
      });
    }
  }, []);

  useEffect(() => {
    if (blockingStatus === initStatuses.INITIALIZED) {
      const shouldClear = !LocalStorage.isCurrentVersion();

      if (shouldClear) {
        LocalStorage.clearAll();
        LocalStorage.setCurrentVersion();
      }
    }
  }, [blockingStatus]);

  useEffect(() => {
    if (appIsReady) {
      SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  let AppContent: ReactElement | null = null;

  switch (blockingStatus) {
    default:
    case initStatuses.LOADING:
      setMark(PerformanceMarks.APP_LOADING_START);
      break;
    // TODO: Create a new error screen for when the app fails to load
    case initStatuses.ERROR:
      AppContent = (
        <ReduceProviders uclTheme={uclTheme} onErrorBoundaryRequestReload={onRequestReload}>
          <ErrorBoundary onRequestReload={onRequestReload} errorReason="initStatuses.ERROR" />
          <BrazeLifecycle />
          <PortalRenderer />
        </ReduceProviders>
      );
      break;
    case initStatuses.INITIALIZED:
      logMeasurement(Measures.APP_LOADING, PerformanceMarks.APP_LOADING_START);
      AppContent = (
        <ReduceProviders uclTheme={uclTheme} onErrorBoundaryRequestReload={onRequestReload}>
          {children}
          {!isSSG && (
            <>
              <ForceUpdateModal />
              <BrazeLifecycle />
              <PortalRenderer />
            </>
          )}
        </ReduceProviders>
      );
      break;
  }

  if (!appIsReady) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} testID="homepage">
      {AppContent}
    </View>
  );
};

setMark(PerformanceMarks.APP_RN_TSX_START);
const AppWithFatalHandler = ({ children }: PropsWithChildren<{}>) => {
  const reloadCount = useRef(0);
  const [forcingReload, setForceReload] = useState(false);

  // If the app crashes and the error boundary is caught
  // our best attempt at recovering is simply to unmount the entire application
  // and remount it. This may have other downstream consequences...
  // We currently will try recovering the app three times:
  //
  // 1. The first time we just unmount and remount.
  // 2. If that crashes the app again, we clear local storage items and unmount/remount
  // 3. If taht crashes again, we force a true app crash to demand a full session reset
  const onRequestReload = useCallback(() => {
    reloadCount.current++;
    setForceReload(true);

    if (reloadCount.current > 1) {
      LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
    }

    setTimeout(() => setForceReload(false), 1);
  }, []);

  if (forcingReload) {
    return null;
  }

  if (reloadCount.current > 2) {
    throw new Error('Intentionally crashing the app. Error recovery failing.');
  }

  return <App onRequestReload={onRequestReload} children={children} />;
};

export default AppWithFatalHandler;
