import { Redirect } from 'expo-router';
import Head from 'expo-router/head';
import { useIntl } from 'react-intl';

import AuthHome from '@fhs-legacy/frontend/src/pages/authentication/auth-home';
import { AuthMethod } from '@fhs-legacy/frontend/src/pages/authentication/auth-home/types';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function SigninPage() {
  const { formatMessage } = useIntl();
  const enableSimplyAuth = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLY_AUTH_FLOW);

  return !enableSimplyAuth ? (
    <>
      <Head>
        <title>{`${formatMessage({ id: 'signUp' })} - Firehouse Subs`}</title>
      </Head>
      <AuthHome method={AuthMethod.SIGN_IN} />
    </>
  ) : (
    <Redirect href="/v2/signin" />
  );
}
