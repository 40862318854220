import { Stack } from 'expo-router';
import React from 'react';

import { AuthProvider } from '@fhs/auth/src/context';
import { tokens } from '@fhs/ui';

import { AppHeader } from '../../../components/app-header';

function header() {
  return <AppHeader />;
}
export default function AuthLayout() {
  return (
    <AuthProvider>
      <Stack
        initialRouteName={'index'}
        screenOptions={{
          title: 'SignIn',
          header,
          contentStyle: { backgroundColor: tokens.colors.$white },
        }}
      >
        <Stack.Screen name="index" />
        <Stack.Screen name="(signup)" />
        <Stack.Screen name="(continuewithemail)" />
        <Stack.Screen name="(verify)" />
      </Stack>
    </AuthProvider>
  );
}
