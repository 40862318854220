import type { Placement } from './types';
import { type UseMqSelectQueries, useMqSelect } from '@fhs/ui';
import { useSegments } from 'expo-router';

type PlacementProps = Partial<{
  top: number | string;
  right: number | string;
  bottom: number | string;
  left: number | string;
  justifyContent: 'flexStart' | 'center';
  alignItems: 'flexStart' | 'center';
}>;

type PositionMapping = Record<Placement, UseMqSelectQueries<PlacementProps>>;

export function usePlacementMappingProps(placement: Placement): PlacementProps {
  const segments = useSegments();
  console.log({ segments });
  const routeName = segments.join('/');

  const mapping =
    routeName in perRoutePositionOverrides
      ? perRoutePositionOverrides[routeName]
      : defaultPositionMapping;

  return useMqSelect(mapping[placement], {} as PlacementProps);
}

export const defaultPositionMapping: PositionMapping = {
  top: {
    $base: {
      top: 50,
      left: 0,
      right: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  bottom: {
    $base: {
      bottom: '170px',
      left: 0,
      right: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    $gteDesktop: {
      alignItems: 'flexStart',
      bottom: '40px',
      left: '40px',
    },
  },
};

export const perRoutePositionOverrides: Record<string, PositionMapping> = {
  'v2/menu/(menu)/[slug]': {
    top: {
      $base: {
        top: 50,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    bottom: {
      $base: {
        bottom: '170px',
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
      },
      $gteDesktop: {
        bottom: `94px`,
        alignItems: 'flexStart',
      },
    },
  },
};
