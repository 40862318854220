import { router } from 'expo-router';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
  Button,
  IconArrowForward,
  MqSwitch,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { ILoyaltyPointsBalanceProps, LoyaltyPointsBalance } from '../../components';
import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';

const DAYS_TO_SHOW_POINT_EXPIRE_DISCLAIMER = 15;

interface Props {
  onPressSeeAll: () => void;
}

export function RewardsSectionHeader({ onPressSeeAll }: Props) {
  const { isAuthenticated } = useAuthContext();
  const mqStyles = useMqStyles();

  const { formatMessage } = useIntl();
  const { loyaltyUser, loyaltyUserExpireDateFormattedText, loyaltyUserLoading } =
    useLoyaltyLegacyStates();

  const shouldShowExpiryDisclaimer = useMemo(() => {
    if (loyaltyUser?.pointsExpiryDateKey) {
      const now = new Date();
      const loyaltyPointExpireDate = new Date(loyaltyUser?.pointsExpiryDateKey);

      const diffInDays = Math.round(
        (loyaltyPointExpireDate.getTime() - now.getTime()) / (1000 * 3600 * 24)
      );

      return diffInDays < DAYS_TO_SHOW_POINT_EXPIRE_DISCLAIMER;
    }
    return false;
  }, [loyaltyUser?.pointsExpiryDateKey]);

  const loyaltyPointsBalanceProps: ILoyaltyPointsBalanceProps = useMemo(
    () => ({
      onPressSeeHistory: () => router.navigate(routes.rewardsHistory),
      expirationDateFormatted:
        shouldShowExpiryDisclaimer && loyaltyUserExpireDateFormattedText
          ? loyaltyUserExpireDateFormattedText
          : undefined,
      isLoading: loyaltyUserLoading,
      points: loyaltyUser?.points,
    }),
    [
      loyaltyUser?.points,
      loyaltyUserLoading,
      loyaltyUserExpireDateFormattedText,
      shouldShowExpiryDisclaimer,
    ]
  );

  return (
    <View style={mqStyles.container}>
      <View style={mqStyles.content}>
        <Text.Heading type={useMqSelect({ $gteDesktop: 'one' }, 'three')}>
          {formatMessage({ id: 'rewards' })}
        </Text.Heading>
        {isAuthenticated && (
          <MqSwitch
            $base={() => (
              <Button type="ghost" size="sm" onPress={onPressSeeAll}>
                <Button.Text>{formatMessage({ id: 'seeAll' })}</Button.Text>
                <Button.Icon>
                  <IconArrowForward />
                </Button.Icon>
              </Button>
            )}
            $gteDesktop={() => (
              <>
                <View style={mqStyles.separator} />
                <LoyaltyPointsBalance
                  style={mqStyles.pointsBalance}
                  {...loyaltyPointsBalanceProps}
                  expirationDateFormatted={undefined}
                />
              </>
            )}
          />
        )}
      </View>
      {isAuthenticated && (
        <>
          <MqSwitch
            $ltDesktop={() => (
              <LoyaltyPointsBalance style={mqStyles.pointsBalance} {...loyaltyPointsBalanceProps} />
            )}
          />

          {shouldShowExpiryDisclaimer && (
            <MqSwitch
              $gteDesktop={() => (
                <View style={mqStyles.disclaimerContainer}>
                  <Text.Ui size="sm">
                    {formatMessage(
                      { id: 'rewardsPointsWillExpireDate' },
                      {
                        date: (
                          <Text.Ui weight="semibold" size="md">
                            {loyaltyUserExpireDateFormattedText}
                          </Text.Ui>
                        ),
                      }
                    )}
                  </Text.Ui>
                </View>
              )}
            />
          )}
        </>
      )}
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: '100%',
      paddingTop: 32,
    },
    $gteDesktop: {
      paddingTop: 64,
    },
  },
  content: {
    $base: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  separator: {
    $base: {
      height: 20,
      width: 1,
      marginHorizontal: 24,
      backgroundColor: tokens.colors.$blackOpacity10,
    },
  },
  pointsBalance: {
    $base: {
      flex: 1,
    },
    $ltDesktop: {
      marginTop: 4,
    },
  },
  disclaimerContainer: {
    $base: {
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 8,
      borderWidth: 1,
      borderRadius: 8,
      padding: 24,
      borderColor: tokens.colors.$blackOpacity10,
    },
  },
});
