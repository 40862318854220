/// <reference types="@types/applepayjs" />
import delve from 'dlv';
import { useCallback, useState } from 'react';

import { useApplePayValidationLazyQuery } from 'generated/graphql-gateway';
import { PaymentProcessor } from 'generated/rbi-graphql';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useAuthContext } from 'state/auth';
import { usePaymentContext } from 'state/payment';
import { IApplePayDetails } from 'state/payment/types';
import { useStoreContext } from 'state/store';
import { fullBrandName } from 'utils/environment';
import { sanitizeAlphanumeric } from 'utils/form';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import logger from 'utils/logger';
import { buildFormattedBillingAddress } from 'utils/native-actions';

import { applePayCardDetails } from './apple-pay-payment-details';
import { IUseApplePay } from './types';
import useFormatApplePayCartPayload from './use-format-apple-pay-cart-payload';
import useRequestWorldpayLowValueToken from './use-request-world-pay-low-value-token';

declare global {
  interface Window {
    ApplePaySession: ApplePaySession | undefined;
  }
}

export default function useApplePay({
  prepaid = false,
  order,
  serverOrder,
  handleApplePay,
}: IUseApplePay) {
  const [requestingPayment, setRequestingPayment] = useState(false);
  const { isRestaurantAvailable, store, isStoreAvailable } = useStoreContext();
  const auth = useAuthContext();
  const appleConfig = useConfigValue({ key: 'apple', defaultValue: {}, isRegionalized: false });
  const billingCountry = delve(auth, 'user.details.isoCountryCode') || ISOs.USA;
  const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
  const { canUseApplePay } = usePaymentContext();
  const payeeName = fullBrandName();
  const merchantId = appleConfig.merchantId;
  const prepaidMerchantId = appleConfig.prepaidMerchantId;
  const { requestWorldpayLowValueToken } = useRequestWorldpayLowValueToken({ serverOrder });
  const { formatApplePayCartPayload } = useFormatApplePayCartPayload({ serverOrder, order });
  const paymentsNetworks = useConfigValue({
    key: 'apple',
    defaultValue: {},
    isRegionalized: false,
  }).paymentsNetworks;
  const [queryApplePayValidation] = useApplePayValidationLazyQuery();
  const onError = useCallback(
    (error: string) => {
      setRequestingPayment(false);
      logger.error({
        message: 'Device Apple Payment Failed',
        error,
      });
      handleApplePay?.({ error });
    },
    [handleApplePay]
  );
  const requestApplePayPayment = useCallback(
    ({
      total,
      cartLabels,
      cartPrices,
      paymentProcessor,
    }: {
      total: number;
      cartLabels?: string[];
      cartPrices?: number[];
      paymentProcessor?: string | null | undefined;
    }) => {
      if (!canUseApplePay || requestingPayment) {
        return;
      }
      setRequestingPayment(true);
      let lineItems;
      if (cartLabels && cartPrices && cartLabels?.length > 0 && cartPrices?.length > 0) {
        lineItems = cartLabels?.map((label, index) => ({
          label,
          amount: (cartPrices?.[index] || 0).toString(),
        }));
      } else {
        lineItems = [
          {
            label: payeeName,
            amount: total.toString(),
          },
        ];
      }
      const paymentRequestData: ApplePayJS.ApplePayPaymentRequest = {
        countryCode,
        currencyCode,
        supportedNetworks: paymentsNetworks,
        requiredBillingContactFields: ['postalAddress'],
        merchantCapabilities: ['supports3DS'],
        total: { label: payeeName, amount: (total / 100).toString() },
        lineItems,
      };
      const session = new ApplePaySession(1, paymentRequestData);
      session.oncancel = () => {
        setRequestingPayment(false);
      };
      session.onvalidatemerchant = async event => {
        let merchantSession;
        try {
          merchantSession = await queryApplePayValidation({
            variables: {
              displayName: payeeName,
              merchantIdentifier: merchantId,
              validationUrl: event.validationURL,
              initiative: 'web',
              initiativeContext: window.location.host,
            },
          });
          if (!merchantSession?.data?.applePayValidation) {
            throw new Error();
          }
          session.completeMerchantValidation(merchantSession?.data?.applePayValidation);
        } catch (error) {
          logger.error('Error validating apple merchant');
          session.abort();
          setRequestingPayment(false);
        }
      };
      session.onpaymentauthorized = async event => {
        const isRestaurantAvailableForOrdering = await isRestaurantAvailable(store);
        if (!isRestaurantAvailableForOrdering && !isStoreAvailable) {
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          return onError('Store is not available');
        }
        const {
          billingContact,
          token: { paymentData, paymentMethod },
        } = event.payment;
        if (!billingContact || !paymentData) {
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          return onError('Missing billing contact/payment data');
        }
        const {
          signature,
          version,
          data,
          header: { ephemeralPublicKey, publicKeyHash, transactionId },
        } = paymentData;
        const applicationData = '';
        const {
          locality: city,
          addressLines: street = [],
          country,
          postalCode,
          administrativeArea: state,
        } = billingContact;
        const formattedBillingAddress = buildFormattedBillingAddress({
          street: String(street[0]),
          city: String(city),
          state: String(state),
          postalCode: String(postalCode),
          country: String(country),
        });

        const billingAddress = {
          locality: city,
          postalCode: sanitizeAlphanumeric(postalCode),
          region: state,
          streetAddress: street[0],
        };

        const decoratedPaymentData: IApplePayDetails = {
          signature,
          applicationData,
          country: countryCode,
          data,
          decryptAlias: prepaid ? prepaidMerchantId : merchantId,
          ephemeralPublicKey,
          formatted: formattedBillingAddress,
          paymentData: JSON.stringify(paymentData),
          primary: true,
          publicKeyHash,
          transactionId,
          type: 'home',
          version,
          billingAddress,
          PaymentMethodData: {
            paymentType: paymentMethod.network,
            displayName: paymentMethod.displayName,
          },
        };
        // If using Worldpay, need to make eProtect request to obtain low value token
        if (paymentProcessor === PaymentProcessor.WORLDPAY) {
          try {
            const lowValueToken = await requestWorldpayLowValueToken({
              applePayDetails: decoratedPaymentData,
            });
            decoratedPaymentData.paymentData = lowValueToken;
          } catch (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            return onError(`Error requesting low value token. ${errorMessage}`);
          }
        }

        handleApplePay?.({ data: decoratedPaymentData });
        // You can see a sample `payment` object in an image below.
        // Use the token returned in `payment` object to create the charge on your payment gateway.
        const chargeCreationSucceeds = true;
        if (chargeCreationSucceeds) {
          // Capture payment from Apple Pay
          session.completePayment(ApplePaySession.STATUS_SUCCESS);
        } else {
          // Release payment from Apple Pay
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          return onError('Error charging user with apple pay');
        }
        setRequestingPayment(false);
      };
      session.begin();
    },
    [
      canUseApplePay,
      countryCode,
      currencyCode,
      handleApplePay,
      isRestaurantAvailable,
      isStoreAvailable,
      merchantId,
      onError,
      payeeName,
      paymentsNetworks,
      prepaid,
      prepaidMerchantId,
      queryApplePayValidation,
      requestWorldpayLowValueToken,
      requestingPayment,
      store,
    ]
  );

  return {
    requestingPayment,
    requestApplePayPayment,
    applePayCardDetails,
    formatApplePayCartPayload,
  };
}
