import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';

import {
  ActionSheet,
  Button,
  FormControlTextInput,
  IconClose,
  IconOffer,
  IconOfferOutline,
  Text,
  createMqStyles,
  tokens,
} from '@fhs/ui';

import { useRedeemPromoCode } from './use-redeem-promo-code';
export interface PromoCodeDrawerProps {
  isOpen: boolean;
  onDismiss: () => void;
  promoCode?: string;
}

export enum PromoCodeErrorReason {
  redeemedCode = 'REDEEMED_CODE',
  expiredCode = 'EXPIRED_CODE',
  invalidCode = 'INVALID_CODE',
}

export class PromoCodeError extends Error {
  public message: PromoCodeErrorReason;

  constructor(message: PromoCodeErrorReason) {
    super();
    this.message = message;
  }
}

export const PromoCodeDrawer = ({ isOpen, onDismiss, promoCode }: PromoCodeDrawerProps) => {
  const [promoCodeValue, setPromoCodeValue] = useState(promoCode ?? '');
  const mqStyles = useMqStyles();
  const { redeemPromoCode, errorMessage, isRedeeming } = useRedeemPromoCode({ onDismiss });
  const inputRef = useRef<TextInput>(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, [isOpen]);

  return (
    <ActionSheet isVisible={isOpen} onClose={onDismiss} grabber>
      <View style={mqStyles.modalContainer}>
        <View style={styles.headerContainer}>
          <View style={styles.iconCircle}>
            <IconOfferOutline size={40} color={tokens.colors.$black} />
          </View>
          <Text.Ui size="lg" weight="semibold" style={mqStyles.headerTitle}>
            Enable Exclusive Offer
          </Text.Ui>
          <Text.Paragraph
            size="lg"
            numberOfLines={2}
            ellipsizeMode="tail"
            style={styles.headerDescription}
          >
            If you received a promo code, you can enable an exclusive offer.
          </Text.Paragraph>
          <Button
            size="md"
            style={styles.closeButton}
            type={'unstyled'}
            onPress={() => onDismiss()}
          >
            <Button.Icon>
              <IconClose />
            </Button.Icon>
          </Button>
        </View>
        <View style={styles.formContainer}>
          <View style={styles.inputContainer}>
            <Text.Ui size="md" weight="semibold">
              Promo Code
            </Text.Ui>
            <FormControlTextInput
              ref={inputRef}
              placeholder={'Enter your code'}
              onChangeText={setPromoCodeValue}
              value={promoCodeValue}
              leftIcon={<IconOffer size={24} style={mqStyles.inputLeftIcon} />}
              style={errorMessage ? styles.errorInputBox : null}
            />
            {errorMessage && (
              <Text.Ui size={'sm'} style={styles.errorMessage}>
                {errorMessage}
              </Text.Ui>
            )}
          </View>
          <View>
            <Button
              style={styles.applyButton}
              loading={isRedeeming}
              disabled={isRedeeming}
              size="xl"
              onPress={() => {
                redeemPromoCode(promoCodeValue);
              }}
            >
              <Button.Text>Apply Promo Code</Button.Text>
            </Button>
          </View>
        </View>
      </View>
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  applyButton: {
    height: 48,
    marginTop: 20,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: -9,
    borderColor: tokens.colors.$transparent,
    borderWidth: 0,
  },
  formContainer: {
    width: '100%',
    flexDirection: 'column',
    marginTop: 16,
  },
  inputContainer: {
    gap: 4,
  },
  headerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  headerDescription: {
    textAlign: 'center',
    paddingHorizontal: 16,
    paddingTop: 8,
  },
  iconCircle: {
    height: 64,
    width: 64,
    borderRadius: 200,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: tokens.colors.$houseLight,
  },
  errorInputBox: {
    borderColor: tokens.colors.$error,
  },
  errorMessage: {
    color: tokens.colors.$error,
  },
});

const useMqStyles = createMqStyles({
  modalContainer: {
    $base: {
      borderRadius: 12,
      paddingBottom: 16,
      paddingTop: 20,
      paddingHorizontal: 16,
    },
    $gteDesktop: {
      paddingHorizontal: 24,
      paddingBottom: 28,
      paddingTop: 28,
    },
  },
  inputLeftIcon: {
    $base: {
      marginRight: 8,
      color: tokens.colors.$blackOpacity30,
    },
    $ltDesktop: {
      color: tokens.colors.$blackOpacity75,
    },
  },
  headerTitle: {
    $base: {
      paddingTop: 8,
    },
    $gteDesktop: {
      fontSize: 24,
    },
  },
});
