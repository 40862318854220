import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import type { Schema } from '@fhs/client';
import { IconChevronRight, Text, tokens } from '@fhs/ui';
import { isNative } from '@fhs/utils';

import { useOfferCart } from '../../state/offer-guide-cart-state';

import { APPROX_TIER_ITEM_HEIGHT, TIER_SELECTOR_HEIGHT } from './constants';

const TierItem = ({ image, name, description, id }: Schema['Tier']['type']) => {
  const { setSelectedTierId } = useOfferCart();
  return (
    <TouchableOpacity style={styles.tierItem} onPress={() => setSelectedTierId(id)}>
      <Image source={{ uri: image }} style={styles.tierImage} />
      <View style={styles.tierInfo}>
        <Text.Heading type="four" numberOfLines={1}>
          {name}
        </Text.Heading>
        <Text.Ui size="sm" numberOfLines={1}>
          {description}
        </Text.Ui>
      </View>
      <IconChevronRight size={24} />
    </TouchableOpacity>
  );
};

const renderTierItem = ({ item }: { item: Schema['Tier']['type'] }) => {
  return <TierItem {...item} />;
};

export function TierSelector() {
  const { offerInfo } = useOfferCart();
  const { bottom } = useSafeAreaInsets();
  const moreThanThreeTiers = offerInfo?.tiers?.length && offerInfo.tiers.length > 3;
  const bottomMargin =
    moreThanThreeTiers && isNative ? bottom + APPROX_TIER_ITEM_HEIGHT : bottom + 5;

  return (
    <FlatList
      style={{ maxHeight: TIER_SELECTOR_HEIGHT, flexGrow: 0 }}
      contentContainerStyle={{ flexGrow: 0 }}
      data={offerInfo?.tiers}
      renderItem={renderTierItem}
      ListFooterComponent={() => <View style={{ height: bottomMargin }} />}
    />
  );
}

const styles = StyleSheet.create({
  tierItem: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity04,
  },
  tierImage: {
    height: 40,
    width: 40,
  },
  tierInfo: {
    paddingHorizontal: 12,
    flex: 1,
  },
});
