import { router } from 'expo-router';
import { useIntl } from 'react-intl';

import { tokens } from '@fhs/ui';
import { ILocation } from '@rbi-ctg/frontend';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useLocale } from 'state/intl';
import { ServiceMode, useOrderContext } from 'state/order';
import { Geography } from 'utils/rbi-common';
import { readableDistanceFromStore } from 'utils/restaurant';
import { routes } from 'utils/routing';

import { AddressBoxSuggestion } from './address-box-suggestion';
import { AddressBoxAddress, BorderedContainer, Circle } from './delivery-unavailable.styled';

export type AddressBoxProps = {
  icon: React.ReactElement;
  address?: string;
  restaurant?: IRestaurantNode;
  activeCoordinates?: ILocation;
};

export const AddressBox: React.FC<React.PropsWithChildren<AddressBoxProps>> = ({
  icon,
  address,
  restaurant,
  activeCoordinates,
}) => {
  const { formatMessage } = useIntl();
  const { region } = useLocale();
  const { selectStore, selectServiceMode } = useOrderContext();
  const { logRBIEvent } = useCRMEventsContext();

  const getAddress = (store: IRestaurantNode) => {
    const addressFields = ['address1', 'address2', 'city', 'state', 'country', 'zip'];
    const addressField = store.physicalAddress ?? {};

    return addressFields
      .map(field => addressField[field])
      .filter(Boolean)
      .join(', ');
  };

  const distance = Geography.coordinateDistance(
    { latitude: activeCoordinates?.lat ?? 0, longitude: activeCoordinates?.lng ?? 0 },
    {
      latitude: restaurant?.latitude ?? 0,
      longitude: restaurant?.longitude ?? 0,
    }
  );
  const distanceText = readableDistanceFromStore(distance, region, formatMessage);

  const pickStore = async () => {
    await selectStore(
      restaurant,
      () => {
        logRBIEvent({
          name: CustomEventNames.DELIVERY_UNAVAILABLE_SELECT_PICKUP_STORE,
          type: EventTypes.Navigation,
        });
        selectServiceMode(ServiceMode.TAKEOUT);
        router.navigate(routes.menu);
      },
      ServiceMode.TAKEOUT
    );
  };

  return (
    <BorderedContainer>
      <Circle backgroundColor={tokens.colors.$blackOpacity04}>{icon}</Circle>
      {restaurant ? (
        <AddressBoxSuggestion
          pickStoreAction={pickStore}
          storeName={restaurant?.name ?? ''}
          distance={distanceText}
          address={getAddress(restaurant)}
        />
      ) : (
        <AddressBoxAddress fontSize="md">{address}</AddressBoxAddress>
      )}
    </BorderedContainer>
  );
};
