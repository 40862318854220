import { useState } from 'react';

import { AlertDialog, Button } from '@fhs/ui';

import { useMenuPicker } from '../../state/menu-picker';

type ConfirmUnsavedCustomizationModalParams = {
  onOpen?: () => void;
  onClose?: () => void;
};

export const useConfirmUnsavedCustomizationModal = ({
  onOpen,
  onClose,
}: ConfirmUnsavedCustomizationModalParams = {}) => {
  const {
    setCustomizationSelections,
    setDraftCustomizationSelections,
    draftCustomizationSelections,
    customizationSelections,
  } = useMenuPicker();

  const [isOpen, setIsOpen] = useState(false);

  const confirmationDialog = (
    <AlertDialog
      visible={isOpen}
      headingContent="You Have Unsaved Customizations"
      bodyContent="Are you sure you want to leave without saving your customizations?"
      onRequestClose={() => setIsOpen(false)}
      onDismiss={() => setIsOpen(false)}
      actions={
        <>
          <Button
            size="xl"
            type="solid"
            onPress={() => {
              setCustomizationSelections(draftCustomizationSelections);
              onClose?.();
              setIsOpen(false);
            }}
          >
            <Button.Text>Save Customizations</Button.Text>
          </Button>
          <Button
            size="xl"
            type="outline"
            onPress={() => {
              setDraftCustomizationSelections(customizationSelections);
              onClose?.();
              setIsOpen(false);
            }}
          >
            <Button.Text>Don't Save</Button.Text>
          </Button>
        </>
      }
    />
  );

  const openConfirmationDialog = () => {
    onOpen?.();
    setIsOpen(true);
  };

  return {
    confirmationDialog,
    openConfirmationDialog,
  };
};
