import { type ReactNode, forwardRef } from 'react';
import { Modal, type ModalProps, StyleSheet, View, type ViewProps } from 'react-native';

import { createMqStyles } from '../../mq-styles';
import { tokens } from '../../tokens';
import { YStack } from '../stack';
import { Text } from '../text';

export type AlertDialogProps = Omit<ModalProps, 'presentationStyle' | 'transparent' | 'visible'> & {
  // Always use `visible` prop to determine visibility. Do not conditionally mount.
  // Set visible to false instead of unmounting the dialog.
  // e.g.,
  // ```tsx
  // const [modalIsVisible, setModalIsVisible] = useState(false);
  // return <AlertDialog visible={modalIsVisible} .../>
  // ```
  // This prevents flickering issues that can happen otherwise.
  visible: boolean;
  backdropStyle?: ViewProps['style'];
  headingContent?: ReactNode;
  bodyContent?: ReactNode;
  actions?: ReactNode;
};

export const AlertDialog = forwardRef<Modal, AlertDialogProps>(
  ({ backdropStyle, headingContent, bodyContent, actions, ...modalProps }, forwardedRef) => {
    const mqStyles = useMqStyles();

    return (
      <Modal ref={forwardedRef} transparent animationType="fade" {...modalProps}>
        <View style={[mqStyles.modalBackdrop, backdropStyle]}>
          <YStack style={[mqStyles.modalContent]}>
            <YStack style={mqStyles.textContentArea}>
              {headingContent &&
                (typeof headingContent === 'string' ? (
                  <Text.Heading type="one">{headingContent}</Text.Heading>
                ) : (
                  headingContent
                ))}
              {bodyContent &&
                (typeof bodyContent === 'string' ? (
                  <Text.Paragraph size="md">{bodyContent}</Text.Paragraph>
                ) : (
                  bodyContent
                ))}
            </YStack>
            <YStack style={mqStyles.actionArea}>{actions}</YStack>
          </YStack>
        </View>
      </Modal>
    );
  }
);

const useMqStyles = createMqStyles({
  modalBackdrop: {
    $base: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: tokens.colors.$blackOpacity75,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  modalContent: {
    $base: {
      borderRadius: 20,
      backgroundColor: tokens.colors.$white,
      maxWidth: 330,
      padding: 24,
      gap: 20,
    },
    $gteDesktop: {
      maxWidth: 520,
      gap: 24,
    },
  },
  textContentArea: {
    $base: {
      gap: 12,
    },
  },
  actionArea: {
    $base: {
      gap: 8,
    },
  },
});
