import { Dispatch, SetStateAction, useState } from 'react';

import { Text, tokens } from '@fhs/ui';

import { useMenuPicker } from '../state/menu-picker';
import type { MenuPickerData } from '../types';
import { formatCaloriesOffsetText, formatCentsToDollarsWithPlusSign } from '../utils';

import {
  ListItemCheckbox,
  ListItemGroup,
  type ListItemGroupProps,
  ListItemGroupRadioGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
  ListItemQuantity,
  ListItemStepper,
  ListItemType,
} from './list-item-group-section';
import { PickerActionSheet } from './picker-action-sheet';

type CustomizationGroupDataType =
  MenuPickerData['pickerAspect']['options'][number]['item']['customizations']['displayGroups'][number];

type CustomizationGroupDisplayType = 'RADIO' | 'QUANTITY' | 'MIXED';

function getCustomizationGroupDisplayType(
  customizationGroup: CustomizationGroupDataType
): CustomizationGroupDisplayType {
  if (customizationGroup.options.length === 1) {
    const optionDisplayType = customizationGroup.options[0].displayType;
    if (optionDisplayType === 'RADIO' || optionDisplayType === 'QUANTITY') {
      return optionDisplayType;
    }
  }

  return 'MIXED';
}

export type CustomizationGroupProps = Omit<ListItemGroupProps, 'items'> & {
  customizationGroup: CustomizationGroupDataType;
  customizationSelections: Record<string, string | Record<string, number>>;
  shouldSaveDetailedCustomization?: boolean;
  setCustomizationSelections: Dispatch<
    SetStateAction<Record<string, string | Record<string, number>>>
  >;
};

export function CustomizationGroup({
  customizationGroup,
  customizationSelections,
  setCustomizationSelections,
  shouldSaveDetailedCustomization = false,
  ...listItemGroupProps
}: CustomizationGroupProps) {
  const {
    defaultCustomizationSelections,
    showErrors,
    errorMessages,
    customizationDetailedSelections,
    setCustomizationDetailedSelections,
  } = useMenuPicker();

  const [pickerActionSheetVisibility, setPickerActionSheetVisibility] = useState<
    Record<string, boolean>
  >({});
  const displayType = getCustomizationGroupDisplayType(customizationGroup);

  const listGroupItems: ListItemGroupProps['items'] = customizationGroup.options.map(
    customizationOption => {
      return {
        id: customizationOption.key,
        render: (listItemProps: ListItemType) => {
          switch (customizationOption.displayType) {
            case 'CHECKBOX': {
              const onOption = customizationOption.options.find(o => o.multiplier > 0);
              const offOption = customizationOption.options.find(o => o.multiplier === 0);
              const checked =
                (customizationSelections[customizationOption.key] ??
                  defaultCustomizationSelections[customizationOption.key]) === onOption?.key;

              return (
                <ListItemCheckbox
                  {...listItemProps}
                  checked={checked}
                  onChange={nextState => {
                    if (shouldSaveDetailedCustomization) {
                      if (nextState) {
                        setCustomizationDetailedSelections(prev => ({
                          ...prev,
                          [customizationOption.key]: {
                            id: onOption.id,
                            name: onOption.displayName,
                            upChargeCents: onOption.upChargeCents,
                          },
                        }));
                      } else {
                        const updatedCustomizationDetailedSelections = Object.fromEntries(
                          Object.entries(customizationDetailedSelections).filter(
                            ([key]) => key !== onOption.id
                          )
                        );

                        setCustomizationDetailedSelections(updatedCustomizationDetailedSelections);
                      }
                    }

                    return setCustomizationSelections(prev => ({
                      ...prev,
                      [customizationOption.key]: nextState ? onOption?.key : offOption?.key,
                    }));
                  }}
                  image={onOption?.image}
                  title={customizationOption.displayName}
                  subtitle={formatCaloriesOffsetText(onOption?.caloriesOffset)}
                  indicatorText={formatCentsToDollarsWithPlusSign(onOption?.upChargeCents)}
                  disabled={!onOption.isAvailable} // if option is disable, set it to disable
                />
              );
            }

            case 'SELECT': {
              const currentValue =
                customizationSelections[customizationOption.key] ??
                defaultCustomizationSelections[customizationOption.key];
              const selectedOption = customizationOption.options.find(o => o.key === currentValue);

              return (
                <>
                  <ListItemLinkPressable
                    {...listItemProps}
                    onPress={() =>
                      setPickerActionSheetVisibility(visibility => ({
                        ...visibility,
                        [customizationOption.key]: true,
                      }))
                    }
                    image={selectedOption?.image}
                    title={customizationOption.displayName}
                    subtitle={[
                      formatCentsToDollarsWithPlusSign(selectedOption.upChargeCents),
                      selectedOption.displayName,
                    ]
                      .filter(Boolean)
                      .join(' • ')}
                  />
                  <PickerActionSheet
                    headerTitle="Choose Your Bread" // In the future, we may need this title to be dynamic. Ideally the BE should provide this field for us.
                    isVisible={Boolean(pickerActionSheetVisibility[customizationOption.key])}
                    onClose={() =>
                      setPickerActionSheetVisibility(visibility => ({
                        ...visibility,
                        [customizationOption.key]: false,
                      }))
                    }
                    selectedOptionKey={selectedOption.key}
                    onSelect={optionSelected => {
                      setCustomizationSelections(selections => ({
                        ...selections,
                        [customizationOption.key]: optionSelected.key,
                      }));
                    }}
                    options={customizationOption.options}
                  />
                </>
              );
            }

            case 'STEPPER': {
              const currentValue = (customizationSelections[customizationOption.key] ??
                defaultCustomizationSelections[customizationOption.key]) as string; // forcing to string because stepper will never have quantities

              const selectedOption = customizationOption.options.find(o => o.key === currentValue);

              const availableOptions = customizationOption.options.filter(o => o.isAvailable);
              const hasAvailableOption = availableOptions.some(
                o => o.isAvailable && o.prefix !== 'No'
              );

              return (
                <ListItemStepper
                  {...listItemProps}
                  options={availableOptions.map(o => ({
                    label: o.prefix ?? o.displayName,
                    value: o.key,
                  }))}
                  value={currentValue}
                  onChangeValue={nextValue => {
                    if (shouldSaveDetailedCustomization) {
                      const newCustomizationValue = customizationOption.options.find(
                        o => o.key === nextValue
                      );

                      const updatedSelections = {
                        ...customizationDetailedSelections,
                        [customizationOption.key]: {
                          id: newCustomizationValue.id,
                          name: newCustomizationValue.displayName,
                          upChargeCents: newCustomizationValue.upChargeCents,
                        },
                      };

                      // Remove the previous selected option from the updated selections
                      delete updatedSelections[selectedOption.id];

                      setCustomizationDetailedSelections(updatedSelections);
                    }

                    setCustomizationSelections(selections => ({
                      ...selections,
                      [customizationOption.key]: nextValue,
                    }));
                  }}
                  image={selectedOption?.image}
                  title={customizationOption.displayName}
                  subtitle={formatCaloriesOffsetText(selectedOption?.caloriesOffset)}
                  disabled={!hasAvailableOption}
                />
              );
            }

            default: {
              return null;
            }
          }
        },
      };
    }
  );

  switch (displayType) {
    case 'MIXED': {
      return (
        <ListItemGroupSection heading={customizationGroup.displayGroup.displayName}>
          <ListItemGroup {...listItemGroupProps} items={listGroupItems} />
        </ListItemGroupSection>
      );
    }

    case 'QUANTITY': {
      const customizationOption = customizationGroup.options[0];

      const quantityState = (customizationSelections[customizationOption.key] ??
        defaultCustomizationSelections[customizationOption.key]) as Record<string, number>; // forcing to record because quantity will always have quantities

      const totalSelectedQuantity = Object.values(quantityState).reduce(
        (total, curr) => total + curr,
        0
      );

      const sectionDescription =
        customizationOption.minAmount &&
        customizationOption.maxAmount &&
        customizationOption.minAmount !== customizationOption.maxAmount
          ? `Choose ${customizationOption.minAmount} to ${customizationOption.maxAmount} options`
          : `Choose ${customizationOption.minAmount} options`;

      const errorMessage = showErrors && errorMessages[customizationOption.key];

      const remainingQuantity = customizationOption.maxAmount - totalSelectedQuantity;

      return (
        <ListItemGroupSection
          heading={customizationGroup.displayGroup.displayName}
          description={sectionDescription}
          errorMessage={errorMessage}
        >
          <ListItemGroup
            {...listItemGroupProps}
            errorMessage={errorMessage}
            items={customizationOption.options.map(opt => ({
              id: opt.id,
              render: (listItemProps: ListItemType) => (
                <ListItemQuantity
                  {...listItemProps}
                  title={opt.displayName}
                  subtitle={
                    <>
                      {Boolean(opt.upChargeCents) && (
                        <>
                          <Text.Ui
                            size="sm"
                            numberOfLines={1}
                            weight="semibold"
                            style={{ color: tokens.colors.$blackOpacity75 }}
                          >
                            {formatCentsToDollarsWithPlusSign(opt.upChargeCents)}
                          </Text.Ui>
                          {opt.caloriesOffset && (
                            <Text.Ui size="sm" weight="semibold">
                              {' '}
                              •{' '}
                            </Text.Ui>
                          )}
                        </>
                      )}
                      {opt.caloriesOffset && `${opt.caloriesOffset} Cal`}
                    </>
                  }
                  image={opt.image}
                  minValue={0}
                  maxValue={remainingQuantity + (quantityState[opt.key] || 0)}
                  value={quantityState[opt.key]}
                  onChangeValue={nextAmount =>
                    setCustomizationSelections(selections => ({
                      ...selections,
                      [customizationOption.key]: {
                        ...quantityState,
                        [opt.key]: nextAmount,
                      },
                    }))
                  }
                  disabled={!opt.isAvailable}
                />
              ),
            }))}
          />
        </ListItemGroupSection>
      );
    }

    case 'RADIO': {
      const customizationOption = customizationGroup.options[0];

      const value = (customizationSelections[customizationOption.key] ??
        defaultCustomizationSelections[customizationOption.key]) as string; // forcing to string because radio will never have quantities

      return (
        <ListItemGroupSection heading={customizationGroup.displayGroup.displayName}>
          <ListItemGroupRadioGroup
            {...listItemGroupProps}
            value={value}
            onChange={nextValue =>
              setCustomizationSelections(selections => ({
                ...selections,
                [customizationOption.key]: nextValue,
              }))
            }
            options={customizationOption.options.map(opt => ({
              value: opt.key,
              title: opt.displayName,
              subtitle: formatCaloriesOffsetText(opt.caloriesOffset),
              indicatorText: formatCentsToDollarsWithPlusSign(opt.upChargeCents),
              image: opt.image,
              disabled: !opt.isAvailable,
            }))}
          />
        </ListItemGroupSection>
      );
    }
  }
}
