import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { tokens, useMqSelect } from '@fhs/ui';
interface Props {
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode;
}

export function BottomButtonContainer({ children, style }: Props) {
  const { bottom } = useSafeAreaInsets();
  const buttonMargin = useMqSelect(
    { $gteDesktop: { padding: 28 } },
    {
      padding: 16,
      paddingBottom: bottom + 16,
    }
  );

  return <View style={[styles.buttonContainer, buttonMargin, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  buttonContainer: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
});
