import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';
import {
  FlatList,
  FlatListProps,
  LayoutChangeEvent,
  ListRenderItem,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  View,
} from 'react-native';

const isNative = Platform.OS !== 'web';

export interface CarouselPaging {
  currentPage: number;
  totalPages: number;
}

export interface CarouselProps<ItemType = any> extends FlatListProps<ItemType> {
  itemWidth: number;
  renderItem: ListRenderItem<ItemType>;
  data: ItemType[];
  enableNativeSnap?: boolean;
  onChangePage?: ({ currentPage, totalPages }: CarouselPaging) => void;
  minimumSeparatorSize?: number;
  justifyContent?: 'center' | 'space-around';
  renderCarouselLoading?: () => React.ReactNode;
}

export interface CarouselRef {
  nextPage: () => void;
  prevPage: () => void;
  goToPage: (page: number) => void;
  goToIndex: (index: number) => void;
}

export const Carousel = forwardRef<CarouselRef, CarouselProps>(
  (
    {
      data,
      renderItem,
      itemWidth,
      enableNativeSnap,
      onChangePage,
      minimumSeparatorSize = 4,
      justifyContent = 'center',
      renderCarouselLoading,
      ...flatListProps
    },
    ref
  ) => {
    const isSpaceAround = justifyContent === 'space-around';
    const centerContent = isNative && enableNativeSnap ? false : flatListProps.centerContent;
    const [currentIndex, setCurrentIndex] = useState(0);
    const listRef = useRef<FlatList>(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const totalPerPage = useMemo(
      () => Math.floor(containerWidth / (itemWidth + minimumSeparatorSize)) || 1,
      [containerWidth, itemWidth, minimumSeparatorSize]
    );
    const totalPages = useMemo(
      () => (containerWidth > 0 ? Math.ceil(data.length / totalPerPage) : 1),
      [containerWidth, totalPerPage, data.length]
    );

    const hasOnly1PageWithCenterContent = centerContent && data.length <= totalPerPage;

    const separatorSize = containerWidth / totalPerPage - itemWidth;

    useEffect(() => {
      if (data.length > 0) {
        const indexToScroll =
          currentIndex < 0 ? 0 : currentIndex >= data.length ? data.length - 1 : currentIndex;

        const currentPage = Math.floor(indexToScroll / totalPerPage);
        const firstIndexOfThePage = currentPage * totalPerPage || 0;

        listRef.current?.scrollToIndex({
          index: firstIndexOfThePage,
          animated: true,
        });

        onChangePage && onChangePage({ currentPage, totalPages });
      }
    }, [containerWidth, totalPerPage, currentIndex, data.length, onChangePage, totalPages]);

    const nextPage = useCallback(() => {
      setCurrentIndex(prevCurrentIndex => {
        if (prevCurrentIndex + totalPerPage >= data.length) {
          return prevCurrentIndex;
        }
        return prevCurrentIndex + totalPerPage;
      });
    }, [totalPerPage, data.length]);

    const prevPage = useCallback(() => {
      setCurrentIndex(prevCurrentIndex => {
        if (prevCurrentIndex - totalPerPage > 0) {
          return prevCurrentIndex - totalPerPage;
        }
        return 0;
      });
    }, [totalPerPage]);

    const goToPage = useCallback(
      (page: number) => {
        const firstIndexOfThePage = page * totalPerPage || 0;
        setCurrentIndex(firstIndexOfThePage);
      },
      [totalPerPage]
    );

    const goToIndex = useCallback(
      (index: number) => {
        if (index >= 0 && index < data.length) {
          setCurrentIndex(index);
        }
      },
      [data.length]
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          nextPage,
          prevPage,
          goToPage,
          goToIndex,
        };
      },
      [nextPage, prevPage, goToPage, goToIndex]
    );

    const renderCarouselItem = useCallback(
      (props: ListRenderItemInfo<any>) => {
        const firstIndexOfTheLastPage = (totalPages - 1) * totalPerPage;
        const isLastPage = props.index >= firstIndexOfTheLastPage;

        if (centerContent && isLastPage) {
          const totalLeft = data.length - firstIndexOfTheLastPage;
          if (isSpaceAround) {
            const availableSpaceForEach = containerWidth / totalLeft;
            const newSeparatorSize = (availableSpaceForEach - itemWidth) / 2;
            const emptySpace = containerWidth - totalLeft * (newSeparatorSize + itemWidth);

            return (
              <View
                style={{
                  marginHorizontal: emptySpace / totalLeft,
                }}
              >
                {renderItem(props)}
              </View>
            );
          }

          const isFirstIndexOfThePage = props.index !== 0 && props.index % totalPerPage === 0;

          const emptySpace = containerWidth - totalLeft * (separatorSize + itemWidth);

          const marginLeft = isFirstIndexOfThePage
            ? emptySpace / 2 + separatorSize / 2
            : separatorSize / 2;

          return (
            <View
              style={{
                marginRight: separatorSize / 2,
                marginLeft,
              }}
            >
              {renderItem(props)}
            </View>
          );
        }

        return <View style={{ marginHorizontal: separatorSize / 2 }}>{renderItem(props)}</View>;
      },
      [
        totalPerPage,
        data.length,
        renderItem,
        centerContent,
        containerWidth,
        itemWidth,
        separatorSize,
        totalPages,
        isSpaceAround,
      ]
    );

    const renderFooter = useCallback(() => {
      if (hasOnly1PageWithCenterContent) {
        return null;
      }
      return <View style={{ width: totalPerPage * itemWidth }} />;
    }, [totalPerPage, itemWidth, hasOnly1PageWithCenterContent]);

    const getItemLayout = useCallback(
      (_, index: number) => ({
        length: itemWidth,
        offset: (itemWidth + separatorSize) * index,
        index,
      }),
      [separatorSize, itemWidth]
    );

    const onScrollEndDrag = useCallback(
      ({ nativeEvent }: NativeSyntheticEvent<NativeScrollEvent>) => {
        const onePageSize = totalPerPage * (itemWidth + separatorSize);
        const currentPage = Math.floor(
          (nativeEvent.contentOffset.x + 0.5 * onePageSize) / onePageSize
        );
        const firstIndexOfThePage = currentPage * totalPerPage || 0;

        setCurrentIndex(firstIndexOfThePage);
      },
      [itemWidth, separatorSize, totalPerPage]
    );

    const onLayout = useCallback((event: LayoutChangeEvent) => {
      setContainerWidth(event.nativeEvent.layout.width);
    }, []);

    const CarouselLoading = useMemo(
      () => (containerWidth === 0 ? renderCarouselLoading : null),
      [renderCarouselLoading, containerWidth]
    );

    return (
      <View style={{ width: '100%' }} onLayout={onLayout}>
        {CarouselLoading ? (
          <CarouselLoading />
        ) : (
          <FlatList
            // @ts-ignore
            ref={listRef}
            horizontal
            centerContent={centerContent}
            data={data}
            pagingEnabled
            renderItem={renderCarouselItem}
            ListFooterComponent={renderFooter}
            scrollEnabled={isNative && !!enableNativeSnap}
            showsHorizontalScrollIndicator={false}
            onScrollEndDrag={onScrollEndDrag}
            overScrollMode="never"
            getItemLayout={getItemLayout}
            {...flatListProps}
          />
        )}
      </View>
    );
  }
);
