import { StyleSheet, View } from 'react-native';

import type { Schema } from '@fhs/client';
import { useMutation } from '@fhs/client';
import { Button, IconClose, IconStarRateFill, Text, tokens } from '@fhs/ui';

import { removePointUsageFromItem } from '../../api';
import { CartItem } from '../cart-item';

import { Badge } from './badge';

type Props = {
  incentive: Schema['CartIncentive']['type'];
  entry: Schema['CartItemEntry']['type'] & { quantity: number };
};

export function CartRewardItem(props: Props) {
  const { isPending, mutate } = useMutation(removePointUsageFromItem);

  return (
    <View>
      <View style={styles.offer}>
        <View style={styles.row}>
          <Badge>Reward</Badge>
          <View style={styles.seperator} />
          <View style={styles.rewardHeaderText}>
            <IconStarRateFill color={tokens.colors.$houseYellow} size={20} />
            <Text.Ui size="md">{props.incentive.title}</Text.Ui>
          </View>
          <View style={styles.seperator} />
          <Button
            disabled={isPending}
            type="outline"
            style={styles.removeOfferButton}
            onPress={() => mutate({ lineId: props.entry.lineId })}
          >
            <Button.Icon size={16}>
              <IconClose />
            </Button.Icon>
          </Button>
        </View>
      </View>
      <CartItem
        appliedSavings={props.incentive.savingsAmount}
        item={props.entry}
        canUsePoints={false}
        showQuantitySelectors={false}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  rewardHeaderText: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  seperator: {
    height: 16,
    width: 1,
    backgroundColor: tokens.colors.$black10,
  },
  removeOfferButton: {
    // resets the padding size so it can scale properly
    paddingLeft: 0,
    paddingRight: 0,
    // resets a base value, needed to get a true circle
    minHeight: 'auto',
    height: 28,
    width: 28,
  },
  row: {
    flexDirection: 'row',
    borderBottomColor: tokens.colors.$black10,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingHorizontal: 16,
    paddingVertical: 14,
  },
  offer: {
    backgroundColor: tokens.colors.$houseLight,
  },
});
