import * as React from 'react';
import { useIntl } from 'react-intl';

import { InlineAlert, Text } from '@fhs-legacy/universal-components';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { routes } from 'utils/routing';

import { PointsExpirationContainer, PointsExpirationContent } from './points-expiration.styled';

export const PointsExpirationCard = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { loyaltyUser } = useLoyaltyUser();
  const { claimPointsEnabled } = useLoyaltyContext();
  const loyaltyUserExpireDateFormattedText = useAppSelector(
    selectors.loyalty.createLoyaltyUserExpireDateFormattedTextSelector(intl)
  );

  const formattedMessage = formatMessage(
    {
      id: 'yourPointsWillExpire',
      description: {},
    },
    {
      points: <Text bold={true}>{loyaltyUser?.points || 0} Points</Text>,
      date: <Text bold={true}>{loyaltyUserExpireDateFormattedText}</Text>,
    }
  );

  const hidePointsExpirationEnabled = useFlag(LaunchDarklyFlag.ENABLE_HIDE_POINTS_EXPIRATION);
  const shouldRenderExpirationCard =
    Number(loyaltyUser?.points) > 0 &&
    loyaltyUser?.pointsExpiryDateKey &&
    !hidePointsExpirationEnabled;
  return (
    <PointsExpirationContainer testID="points-expiration-card">
      <PointsExpirationContent>
        {claimPointsEnabled && (
          <ActionButton variant={ActionButtonVariants.OUTLINE} to={routes.claimPointsBase}>
            {formatMessage({
              id: 'missingPoints',
              defaultMessage: 'Missing points? Add Transaction ID',
            })}
          </ActionButton>
        )}
        {shouldRenderExpirationCard && (
          <InlineAlert
            message={formattedMessage}
            status="warning"
            iconCentered={true}
            style={{ alignItems: 'center' }}
            mt="$5"
          />
        )}
      </PointsExpirationContent>
    </PointsExpirationContainer>
  );
};
