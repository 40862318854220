import { useLazySanityQuery, useSanityQuery, useSuspenseSanityQuery } from './query-hooks';

export { useLazySanityQuery, useSanityQuery, useSuspenseSanityQuery } from './query-hooks';

export type {
  LazyQueryHookOptions,
  QueryHookOptions,
  SuspenseQueryHookOptions,
  SkipToken,
} from '@apollo/client';

export { skipToken } from '@apollo/client';

// for apollo codegen compatibility
export {
  useSanityQuery as useQuery,
  useLazySanityQuery as useLazyQuery,
  useSuspenseSanityQuery as useSuspenseQuery,
};
