import { Link, useLocalSearchParams } from 'expo-router';
import { useCallback, useState } from 'react';
import { View } from 'react-native';

import { Button, YStack, createMqStyles, tokens, useMqSelect } from '@fhs/ui';
import { NutritionalInformation } from '@fhs/ui/src/components/nutritional-information';
import { SodiumDisclaimer } from '@fhs/ui/src/components/nutritional-information/sodium-disclaimer';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useStore } from '@fhs-legacy/frontend/src/state/store/hooks';

import { useMenuGuideQuery } from '../queries/use-menu-guide-query';
import { useMenuQueryOpts } from '../queries/use-menu-query-opts';
import { useComboSelectionContext } from '../state/combo-selection';
import { useMenuPicker } from '../state/menu-picker';

import { ComboGuide } from './combo-guide';
import { CustomizationGroup } from './customization-group';
import {
  ListItemGroup,
  ListItemGroupSection,
  ListItemLinkPressable,
} from './list-item-group-section';
import { MakeComboSection } from './make-combo-section';
import { PickerAspectOptionSelector } from './picker-aspect-option-selector';
import { ExtraInstructions } from './product-detail-extra-instructions';
import { ProductDetailLayout } from './product-detail-layout';
import { ProductNameAndImage } from './product-name-and-image';

const enabledStatesForSodiumDisclaimer = ['PA', 'NY'];

type NutritionalInformationProps = {
  isShowingNutrition: boolean;
  setIsShowingNutrition: (isShowingNutrition: boolean) => void;
  showSodiumDisclaimer: boolean;
};

const NutritionalInformationTile = ({
  isShowingNutrition,
  setIsShowingNutrition,
  showSodiumDisclaimer,
}: NutritionalInformationProps) => {
  const menuPicker = useMenuPicker();

  return (
    <View>
      <ListItemGroup
        items={[
          {
            render: ({ isFirstItem, isLastItem }) => (
              <ListItemLinkPressable
                titleFontWeight="normal"
                minHeightStyle="content"
                isFirstItem={isFirstItem}
                isLastItem={isLastItem}
                title="Nutritional Information"
                onPress={() => setIsShowingNutrition(true)}
              />
            ),
          },
        ]}
      />

      {showSodiumDisclaimer && <SodiumDisclaimer />}

      <NutritionalInformation
        nutritionFacts={{
          title: menuPicker.selectedPickerAspectOption?.item?.displayName,
          image: menuPicker.selectedPickerAspectOption?.item?.image,
          // @ts-ignore
          facts: menuPicker.selectedPickerAspectOption?.item?.nutrition ?? {},
        }}
        isVisible={isShowingNutrition}
        onClose={() => setIsShowingNutrition(false)}
      />
    </View>
  );
};

type MenuDetailsProps = Omit<NutritionalInformationProps, 'showSodiumDisclaimer'>;

export function MenuItemDetails(props: MenuDetailsProps) {
  const mqStyles = useMqStyles();
  const menuPicker = useMenuPicker();
  const {
    setIsEditingPickers,
    isComboEnabled,
    setIsComboEnabled,
    isComboGuideOpen,
    setIsComboGuideOpen,
    selectedPickers,
  } = useComboSelectionContext();
  const extraInstructionsEnabled = useFlag(LaunchDarklyFlag.ENABLE_EXTRA_INSTRUCTIONS_ON_ITEM);
  const params = useLocalSearchParams<{ slug: string }>();
  const requiresPickAspectOptionSelection = menuPicker.query.data?.pickerAspect.options.length > 1;
  const combo = menuPicker.query.data?.combo;

  const customizationGroups =
    menuPicker.selectedPickerAspectOption?.item?.customizations.displayGroups ?? [];
  const topLevelCustomizationGroups = customizationGroups.filter(
    customizationGroup => customizationGroup.displayGroup.showOnProductPage
  );

  const hasCustomizations = customizationGroups.length > 0;
  const baseCaloriesDisplay = menuPicker.selectedPickerAspectOption?.item?.baseCalories
    ? `${menuPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`
    : null;

  const hasCustomizationsContent = requiresPickAspectOptionSelection || hasCustomizations;
  const asHero = useMqSelect({ $gteDesktop: true }, !requiresPickAspectOptionSelection);

  const [comboGuideInitialStepIndex, setComboGuideInitialStepIndex] = useState(0);
  const onComboGuideClose = useCallback(() => {
    setIsComboGuideOpen(false);
    setIsEditingPickers(false);
  }, [setIsEditingPickers]);

  const { store } = useStore();
  const showSodiumDisclaimer =
    store.physicalAddress.stateProvinceShort &&
    enabledStatesForSodiumDisclaimer.includes(
      store.physicalAddress.stateProvinceShort.toUpperCase()
    );

  const menuGuideQueryOpts = {
    ...useMenuQueryOpts(),
    comboId: combo?.id,
    enabled: Boolean(combo?.id),
  };

  // get menu guide
  const { data: comboGuideData } = useMenuGuideQuery(menuGuideQueryOpts);

  return (
    <ProductDetailLayout
      nameAndImageContent={
        <ProductNameAndImage
          displayName={menuPicker.displayName}
          description={menuPicker.query.data?.description}
          variant={menuPicker.selectedPickerAspectOption?.displayName}
          cals={baseCaloriesDisplay}
          asHero={asHero}
          imageUrl={menuPicker.query.data?.image.asset.uri}
          hasCustomizationsContent={hasCustomizationsContent}
          showSodiumDisclaimer={showSodiumDisclaimer}
          heroNutritionalInformationTile={
            !hasCustomizationsContent && (
              <NutritionalInformationTile
                setIsShowingNutrition={props.setIsShowingNutrition}
                isShowingNutrition={props.isShowingNutrition}
                showSodiumDisclaimer={showSodiumDisclaimer}
              />
            )
          }
        />
      }
      customizationContent={
        hasCustomizationsContent && (
          <YStack style={mqStyles.customizationContent}>
            {requiresPickAspectOptionSelection && (
              <ListItemGroupSection heading={menuPicker.query.data?.pickerAspect?.displayName}>
                <PickerAspectOptionSelector
                  value={menuPicker.selectedPickerAspectOptionId}
                  onChange={selection => {
                    menuPicker.setSelectedPickerAspectOptionId(selection);
                    menuPicker.setDraftCustomizationSelections({});
                  }}
                  options={menuPicker.query.data?.pickerAspect?.options.map(opt => ({
                    value: opt.id,
                    title: opt.displayName,
                    subtitle: opt.description,
                    image: opt.image,
                    disabled: !opt.item.isAvailable,
                  }))}
                />
              </ListItemGroupSection>
            )}
            {hasCustomizations &&
              topLevelCustomizationGroups.map((customizationGroup, index) => {
                const isFirstCustomizationGroup = index === 0;

                return (
                  <CustomizationGroup
                    customizationSelections={menuPicker.customizationSelections}
                    setCustomizationSelections={newSelections => {
                      menuPicker.setCustomizationSelections(newSelections);
                      menuPicker.setDraftCustomizationSelections(newSelections);
                    }}
                    key={customizationGroup.displayGroup.id ?? index}
                    customizationGroup={customizationGroup}
                    footer={
                      isFirstCustomizationGroup &&
                      topLevelCustomizationGroups.length < customizationGroups.length && (
                        <Link
                          href={{
                            pathname: '/v2/menu/[slug]/customize',
                            params: {
                              slug: params.slug,
                            },
                          }}
                          asChild
                        >
                          <Button type="outline" size="md">
                            <Button.Text>Customize Ingredients</Button.Text>
                          </Button>
                        </Link>
                      )
                    }
                  />
                );
              })}

            {combo && comboGuideData?.steps && (
              <MakeComboSection
                combo={combo}
                enabled={isComboEnabled}
                onToggle={() => {
                  if (isComboEnabled) {
                    setIsComboEnabled(false);
                    return;
                  }
                  if (!Object.keys(selectedPickers).length) {
                    setIsComboGuideOpen(true);
                  }
                  setIsComboEnabled(true);
                }}
                onEdit={(stepIndex: number) => {
                  setComboGuideInitialStepIndex(stepIndex);
                  setIsComboGuideOpen(true);
                }}
              />
            )}
            {isComboGuideOpen && (
              <ComboGuide
                steps={comboGuideData?.steps || []}
                onClose={onComboGuideClose}
                initialStepIndex={comboGuideInitialStepIndex}
              />
            )}

            {extraInstructionsEnabled && (
              <ExtraInstructions
                orderInstructions={menuPicker.instructions}
                onInstructionsChange={menuPicker.setInstructions}
              />
            )}

            <View style={mqStyles.divider} />

            <NutritionalInformationTile
              setIsShowingNutrition={props.setIsShowingNutrition}
              isShowingNutrition={props.isShowingNutrition}
              showSodiumDisclaimer={showSodiumDisclaimer}
            />
          </YStack>
        )
      }
    />
  );
}

const useMqStyles = createMqStyles({
  customizationContent: {
    $base: {
      paddingHorizontal: 16,
      gap: 24,
    },
  },
  divider: {
    $base: {
      height: 1,
      backgroundColor: tokens.colors.$blackOpacity04,
    },
  },
});
