import { router, useLocalSearchParams } from 'expo-router';
import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { Schema } from '@fhs/client';
import { isWeb } from '@fhs/utils';
import { create, devtools } from '@fhs/zustand';
import { IIncentiveSelection } from '@fhs-legacy/frontend/src/generated/graphql-gateway';
import { parseEntry } from '@fhs-legacy/frontend/src/state/global-state/models/loyalty/offers/offers.utils';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';
import { ICartEntry } from '@rbi-ctg/menu';

import { useAddIncentiveToCart } from '../hooks/use-add-incentive-to-cart';
import { useOrderLegacyStates } from '../hooks/use-legacy-order-states';
import { useOfferDetailUi } from '../queries/loyalty.queries';

type OfferGuideCartState = {
  onBackAttempt?: () => boolean;
  offerInfo?: Omit<SimplyOfferType, 'steps'>;
  steps: Step[];
  currentStepIndex: number;
  isGoingForward: boolean;
  showSummary: boolean;
  selectedEligibleItem?: Schema['MenuSectionItem']['type'];
  selectedTierId?: string;
};

type OfferGuideCartActions = {
  modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) => void;
  goToStep: (index: number, isEdit?: boolean) => void;
  goToPrevStep: () => void;
  goToNextStep: () => void;
  goToNextNotDoneStep: () => void;
  goFromScratch: () => void;
  applyPreSelectedItems: (cartEntries: ICartEntry[]) => void;
  setOffer: (offer: SimplyOfferType) => void;
  setSelectedEligibleItem: (eligibleItem?: Schema['MenuSectionItem']['type']) => void;
  setSelectedTierId: (tierId?: string) => void;
  reset: () => void;
  setOnBackAttempt: (callback: (() => boolean) | undefined) => void;
};

const initialState: OfferGuideCartState = {
  onBackAttempt: undefined,
  offerInfo: undefined,
  steps: [],
  currentStepIndex: 0,
  isGoingForward: true,
  showSummary: false,
  selectedEligibleItem: undefined,
  selectedTierId: undefined,
};

export type Step = Schema['Step']['type'] & {
  selectedEntry?: ICartEntry;
  selectedEntryImage?: string;
};

export type SimplyOfferType = Schema['OfferDetail']['type'] & {
  steps: Step[];
};

const _isValidIndex = (newIndex: number, stepsLength: number) => {
  if (newIndex < 0 || newIndex >= stepsLength) {
    return false;
  }

  return true;
};

const _getNextNotDoneStep = (steps: Step[]) => {
  const lastIndexWithoutProduct = steps.findIndex(step => !step.selectedEntry);

  if (lastIndexWithoutProduct === -1) {
    return steps.length;
  }
  return lastIndexWithoutProduct;
};

const addCartEntryUrl = (
  cartEntry: ICartEntry,
  eligibleItem?: Schema['MenuSectionItem']['type']
) => {
  return {
    ...cartEntry,
    url: eligibleItem?.url ?? cartEntry.url,
  } as ICartEntry;
};

export const useOfferCart = create<OfferGuideCartState & OfferGuideCartActions>()(
  devtools((set, get) => {
    const goToStep = (index: number) => {
      const state = get();
      const stepsLength = state.steps.length;
      if (index === stepsLength) {
        setTimeout(() => {
          set({ currentStepIndex: index, showSummary: true });
        }, 100);
        set({ isGoingForward: true });
        return;
      }

      if (_isValidIndex(index, stepsLength)) {
        setTimeout(() => {
          set({ currentStepIndex: index, showSummary: false });
        }, 100);
        if (index < state.currentStepIndex) {
          set({ isGoingForward: false });
          return;
        }
        set({ isGoingForward: true });
      }
    };
    return {
      ...initialState,
      reset: () => set(initialState),
      setSelectedEligibleItem: (eligibleItem?: Schema['MenuSectionItem']['type']) =>
        set({ selectedEligibleItem: eligibleItem }),
      setOnBackAttempt: (callback: (() => boolean) | undefined) => set({ onBackAttempt: callback }),
      goToNextStep: () => goToStep(get().currentStepIndex + 1),
      goToNextNotDoneStep: () => goToStep(_getNextNotDoneStep(get().steps)),
      goToPrevStep: () => goToStep(get().currentStepIndex - 1),
      goToStep,
      setSelectedTierId: (tierId?: string) =>
        set(state => ({
          selectedTierId: tierId,
          steps: state?.offerInfo?.tiers?.find(tier => tier.id === tierId)?.steps ?? [],
        })),
      applyPreSelectedItems: (cartEntries: ICartEntry[]) =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            draftSteps.forEach(draftStep => {
              const selectedEntry = cartEntries.find(
                cartEntry => cartEntry.cartId === draftStep.preSelectedItem
              );
              draftStep.preSelectedItem = undefined;

              if (selectedEntry) {
                const eligibleItem = draftStep.eligibleItems.find(
                  item => item.id === selectedEntry?._id
                );

                draftStep.selectedEntry = addCartEntryUrl(selectedEntry, eligibleItem);
                draftStep.selectedEntryImage = eligibleItem?.image?.asset?.uri;
              }
            });
          });

          const nextStepIndex = _getNextNotDoneStep(newSteps);
          goToStep(nextStepIndex);

          return { steps: newSteps };
        }),
      goFromScratch: () =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            draftSteps.forEach(draftStep => {
              draftStep.preSelectedItem = undefined;
            });
          });

          return { steps: newSteps };
        }),
      setOffer: (offer: SimplyOfferType) => {
        const { steps, ...offerInfo } = offer;
        set({ steps, offerInfo });
      },
      modifyItemSelectionInStep: (step: number, cartEntry: ICartEntry) =>
        set(state => {
          const newSteps = produce(state.steps, draftSteps => {
            const draftStep = draftSteps[step];
            const eligibleItem = draftStep.eligibleItems?.find(item => item.id === cartEntry._id);

            draftStep.selectedEntry = addCartEntryUrl(cartEntry, eligibleItem);
            draftStep.selectedEntryImage = eligibleItem?.image?.asset?.uri;
          });

          const nextStepIndex = _getNextNotDoneStep(newSteps);
          goToStep(nextStepIndex);

          return { steps: newSteps };
        }),
    };
  })
);

export const useIsOfferCartDirty = () => {
  const steps = useOfferCart(state => state.steps);
  const isDirty = useMemo(() => steps.some(step => !!step.selectedEntry), [steps]);

  return isDirty;
};

export const useIsItemInCart = () => {
  const steps = useOfferCart(state => state.steps);
  const isItemInCart = useMemo(() => steps.some(step => !!step.preSelectedItem), [steps]);

  return isItemInCart;
};

export const useSelectedStep = () => {
  const steps = useOfferCart(state => state.steps);
  const currentStepIndex = useOfferCart(state => state.currentStepIndex);
  const selectedStep = useMemo(() => steps[currentStepIndex], [steps, currentStepIndex]);

  return selectedStep;
};

export const useShouldRenderTierSelector = () => {
  const offerInfo = useOfferCart(state => state.offerInfo);
  const selectedTierId = useOfferCart(state => state.selectedTierId);
  return Boolean(offerInfo?.tiers && offerInfo.tiers?.length > 1 && !selectedTierId);
};

export const useEditStepSelectedEntry = () => {
  const setSelectedEligibleItem = useOfferCart(state => state.setSelectedEligibleItem);
  const goToStep = useOfferCart(state => state.goToStep);

  const editStepSelectedEntry = useCallback(
    (step: Step, stepIndex: number) => {
      const selectedEligibleItem = step.eligibleItems.find(
        eligibleItem => step?.selectedEntry?._id === eligibleItem.id
      );
      if (selectedEligibleItem) {
        router.setParams({
          isEdit: 'true',
        });

        setSelectedEligibleItem(selectedEligibleItem);
        goToStep(stepIndex);
        setTimeout(() => {
          router.setParams({
            isEdit: undefined,
          });
        }, 100);
      }
    },
    [setSelectedEligibleItem, goToStep]
  );

  return { editStepSelectedEntry };
};

export const useAddGuideOfferToCart = () => {
  const { offerInfo, steps: offerSteps, reset, selectedTierId } = useOfferCart();
  const { cartEntries, addItemToCart, removeFromCart, updateQuantity } = useOrderLegacyStates();
  const { addIncentiveToCart } = useAddIncentiveToCart();

  const addGuideOfferToCart = useCallback(
    (dryRun = false) => {
      if (offerInfo) {
        const selections: IIncentiveSelection[] = [];
        const cartEntriesClone = cloneDeep(cartEntries);
        const offerCartEntries: ICartEntry[] = [];
        const cartChangesBatch: (() => void)[] = [];
        offerSteps.forEach(step => {
          const itemInCart = cartEntriesClone.find(
            cartEntry => cartEntry.cartId === step.selectedEntry?.cartId
          );

          if (itemInCart && itemInCart.price) {
            const itemClone = cloneDeep(itemInCart);
            const individualPrice = itemClone.price
              ? Math.round(itemClone.price / itemClone.quantity)
              : 0;
            itemClone.quantity = 1;
            itemClone.price = individualPrice;
            itemClone.cartId = uuidv4();
            cartChangesBatch.push(() => addItemToCart(itemClone, undefined, false));
            offerCartEntries.push(itemClone);

            selections.push({
              incentiveId: offerInfo.id,
              key: step.key,
              lineId: itemClone?.cartId ?? '',
              quantity: 1,
              rank: step.rank,
              tierKey: selectedTierId,
            });

            itemInCart.quantity--;
            itemInCart.price = itemInCart.quantity * individualPrice;

            if (itemInCart.quantity <= 0) {
              cartChangesBatch.push(() => removeFromCart({ cartId: itemInCart.cartId }));
            } else {
              cartChangesBatch.push(() => updateQuantity(itemInCart.cartId, itemInCart.quantity));
            }

            return;
          }

          if (step.selectedEntry) {
            selections.push({
              incentiveId: offerInfo.id,
              key: step.key,
              lineId: step.selectedEntry?.cartId,
              quantity: 1,
              rank: step.rank,
              tierKey: selectedTierId,
            });
            cartChangesBatch.push(() => addItemToCart(step.selectedEntry, undefined, false));
            offerCartEntries.push(step.selectedEntry);
          }
        });

        if (!dryRun) {
          addIncentiveToCart({
            offer: offerInfo,
            selections,
            showMessage: true,
            callbacks: {
              onSuccess: async () => {
                reset();
                router.dismiss();
                cartChangesBatch.forEach(change => change());
                setTimeout(() => {
                  if (isWeb) {
                    router.replace(routes.menu);
                  } else {
                    router.navigate(routes.menu);
                  }
                }, 100);
              },
            },
          });
        }

        return {
          selections,
          backendCartEntries: offerCartEntries?.reduce(parseEntry(), []),
        };
      }
      return null;
    },
    [
      reset,
      offerInfo,
      offerSteps,
      addItemToCart,
      addIncentiveToCart,
      cartEntries,
      removeFromCart,
      updateQuantity,
      selectedTierId,
    ]
  );

  return { addGuideOfferToCart };
};

export const useIsLoadingTierSelector = () => {
  const { offerId, tierSelectorRequired } = useLocalSearchParams<{
    offerId: string;
    tierSelectorRequired: string;
  }>();
  const { isLoading } = useOfferDetailUi(offerId);

  return tierSelectorRequired === 'true' && isLoading;
};
