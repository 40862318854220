import { compact, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { IItem } from '@rbi-ctg/menu';
import { ActionsheetContent, ActionsheetWrapper } from 'components/action-sheet';
import { Calories } from 'components/price-and-calories';
import { ItemAvailabilityStatus } from 'enums/menu';
import { ILoyaltyBenefitSwap, LoyaltyBenefitType } from 'generated/graphql-gateway';
import { IAllItemsQuery, IItemFragment, useAllItemsQuery } from 'generated/sanity-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import { useFormatCalories } from 'hooks/use-format-calories';
import useIsComponentMounted from 'hooks/use-is-component-mounted';
import { useCartContext } from 'state/cart';
import { ISwappableItem } from 'state/cart/types';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLoyaltyContext } from 'state/loyalty';
import { useMenuContext } from 'state/menu';
import { useStoreContext } from 'state/store';

import {
  ItemImage,
  SingleItemContent,
  SingleItemHeader,
  SingleItemParagraph,
  SingleItemWrapper,
} from './modal-upsize.styled';
import { UpsizeContent } from './upsize-content';

const ModalUpsize = () => {
  const [availableItems, setAvailableItems] = useState<IItemFragment[]>();
  const [showUpsize, setShowUpsize] = useState(false);
  const { loyaltySurpriseOffersEnabled } = useLoyaltyContext();
  const upsizeAvailable = useAppSelector(selectors.loyalty.selectUpsizeAvailable);
  const userOffers = useAppSelector(selectors.loyalty.selectUserOffers);
  const dispatch = useAppDispatch();
  const { swapItems } = useCartContext();
  const isMounted = useIsComponentMounted();
  const { checkItemAvailability } = useMenuContext();
  const { vendor } = usePosVendor();
  const { store } = useStoreContext();
  const { logRBIEvent } = useCRMEventsContext();
  const formatCalories = useFormatCalories();

  const resetUpsizeAvailability = () => dispatch(actions.loyalty.resetUpsizeAvailability());

  const swappableItemsMap = userOffers.reduce<{ [key: string]: ISwappableItem }>((acc, offer) => {
    if (offer.benefits?.length) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(benefit: IBenefitSwap) => void'... Remove this comment to see the full error message
      offer.benefits.forEach((benefit: ILoyaltyBenefitSwap) => {
        if (benefit.type === LoyaltyBenefitType.SWAP) {
          acc[benefit.value.to] = {
            ...benefit.value,
            offerId: offer.id ?? null,
            offerType: offer.type,
            cmsId: offer.sanityId ?? null,
          };
        }
      });
    }

    return acc;
  }, {});

  const { loading, data } = useAllItemsQuery({
    variables: {
      where: {
        _id_in: Object.keys(swappableItemsMap),
      },
    },
    skip: isEmpty(swappableItemsMap),
  });

  const [swapTo, setSelectedSwapTo] = useState<IItemFragment>();
  const addr = store.physicalAddress;
  const restaurantAddress = useMemo(
    () => ({
      restaurantId: store._id || '',
      restaurantAddress: compact([addr?.address1, addr?.address2]).join(', ') || '',
      restaurantZip: addr?.postalCode || '',
      restaurantCity: addr?.city || '',
      restaurantState: addr?.stateProvince || '',
      restaurantCountry: addr?.country || '',
    }),
    [addr, store._id]
  );

  useEffect(() => {
    if (availableItems?.length) {
      setSelectedSwapTo(availableItems[0]);
    }
  }, [availableItems, data, setSelectedSwapTo]);

  const logUpsizeDisplayed = useCallback(
    (itemNames: string) => {
      logRBIEvent({
        name: CustomEventNames.UPSIZE_DISPLAYED,
        type: EventTypes.Other,
        attributes: {
          eligibleProductsName: itemNames,
          ...restaurantAddress,
        },
      });
    },
    [restaurantAddress, logRBIEvent]
  );

  // fire upsize displayed event when we have available items
  useEffect(() => {
    if (!availableItems || availableItems.length === 0) {
      return;
    }
    const itemNames = availableItems
      .map(item => item.name?.locale || '')
      .filter(name => name !== '');
    logUpsizeDisplayed(itemNames.join(', '));
  }, [availableItems, logUpsizeDisplayed]);

  useEffect(() => {
    (async () => {
      type Writeable<T> = { -readonly [P in keyof T]: T[P] };
      const filteredAvailableItems: Writeable<IAllItemsQuery['allItems']> = [];

      if (data && data.allItems) {
        setShowUpsize(false);
        for (const itemToCheck of data.allItems) {
          const { availabilityStatus } = await checkItemAvailability({
            vendor,
            restaurantPosDataId: store.restaurantPosData?._id ?? '',
            itemId: `${itemToCheck._type}-${itemToCheck._id}`,
          });

          if (availabilityStatus === ItemAvailabilityStatus.AVAILABLE) {
            filteredAvailableItems.push(itemToCheck);
          }
        }
        setAvailableItems(filteredAvailableItems);
        setShowUpsize(true);
      }
    })();
  }, [checkItemAvailability, data, store.number, store.restaurantPosData, vendor]);

  const logUpsizeSelection = (itemName: string) => {
    logRBIEvent({
      name: CustomEventNames.UPSIZE_SELECTION,
      type: EventTypes.Other,
      attributes: {
        selectedProductName: itemName,
        ...restaurantAddress,
      },
    });
  };
  const onUpsizePressHandler = () => {
    const swappableItem = swappableItemsMap[swapTo?._id || ''];
    if (swapTo) {
      // using `as` to avoid type error when using the IItemFragment type
      swapItems(swapTo as IItem, swappableItem);
      resetUpsizeAvailability();
      logUpsizeSelection(swapTo?.name?.locale || '');
    }
  };

  // unused index argument is required for ResponsiveCarousel

  const renderUpsizeItem = ({ item, index }: { item: IItemFragment; index: number }) => {
    const { _id, image, name, nutrition } = item;
    const caloriesRounded = formatCalories(nutrition?.calories || null);
    return (
      <SingleItemWrapper
        key={_id}
        onPress={() => setSelectedSwapTo(item)}
        $selected={swapTo?._id === _id}
        $isFirst={index === 0}
      >
        <SingleItemContent>
          {!!image && <ItemImage alt={name?.locale || ''} image={image} />}
          <SingleItemHeader>{name?.locale}</SingleItemHeader>
          {!!caloriesRounded && (
            <Calories>
              <SingleItemParagraph>{caloriesRounded}</SingleItemParagraph>
            </Calories>
          )}
        </SingleItemContent>
      </SingleItemWrapper>
    );
  };

  if (
    !loyaltySurpriseOffersEnabled ||
    !upsizeAvailable ||
    loading ||
    !availableItems?.length ||
    !isMounted
  ) {
    return null;
  }

  return (
    <ActionsheetWrapper
      onOpenEventMessage="Upsize for free"
      isOpen={showUpsize}
      onClose={resetUpsizeAvailability}
    >
      <ActionsheetContent>
        <UpsizeContent
          availableItems={availableItems}
          swapTo={swapTo}
          renderUpsizeItem={renderUpsizeItem}
          onUpsizePressed={onUpsizePressHandler}
          onNoThanksPressed={resetUpsizeAvailability}
          isDisabledUpsizeButton={loading || !data}
        />
      </ActionsheetContent>
    </ActionsheetWrapper>
  );
};

export default ModalUpsize;
