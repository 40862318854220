import { Link } from 'expo-router';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionSheet, Button, tokens, useScrollViewOverflowStatus } from '@fhs/ui';
import { isWeb } from '@fhs/utils';

import { NutritionDisclaimerText } from './nutrition-disclaimer';
import { NutritionFacts, NutritionFactsProps } from './nutrition-facts/nutrition-facts';

export type NutritionActionBottomSheetProps = {
  isVisible: boolean;
  onClose: () => void;
  headerTitle: string;
  nutritionFacts: NutritionFactsProps;
};

export const NutritionalBottomSheet = ({
  isVisible,
  onClose,
  headerTitle,
  nutritionFacts,
}: NutritionActionBottomSheetProps) => {
  const { handleLayout, handleContentSizeChange, overflowStatus } = useScrollViewOverflowStatus();

  return (
    <ActionSheet
      isVisible={isVisible}
      onClose={onClose}
      headerTitle={headerTitle}
      isScrollableDrawer
      drawerProps={{
        onLayout: handleLayout,
        onContentSizeChange: handleContentSizeChange,
        style: [styles.container],
        contentContainerStyle: [
          styles.scrollViewContent,
          overflowStatus.height && styles.scrollViewLongContent,
        ],
      }}
      footer={
        <View
          style={[
            styles.buttonContainer,
            overflowStatus.height && styles.buttonContainerLongContent,
          ]}
        >
          <Button
            style={styles.closeButton}
            type="solid"
            size="xl"
            testID="homepage-pickup-order"
            onPress={onClose}
          >
            <Button.Text>Close</Button.Text>
          </Button>
        </View>
      }
    >
      <NutritionFacts
        key={nutritionFacts.title}
        title={nutritionFacts.title}
        image={nutritionFacts.image}
        facts={nutritionFacts.facts}
      />

      <NutritionDisclaimerText size="sm">
        Calories will vary based on modifications made to item. Product availability, prices, offers
        and discounts may vary from in-restaurant.
      </NutritionDisclaimerText>

      <NutritionDisclaimerText size="sm">
        2,000 calories a day is used for general nutrition advice, but calorie needs vary. For
        additional nutrition information click here.
      </NutritionDisclaimerText>

      <NutritionDisclaimerText size="sm">
        <Link
          target="_blank"
          href="https://www.nutritionix.com/firehouse-subs/portal"
          style={{ textDecorationLine: 'underline' }}
        >
          For additional nutrition information click here.
        </Link>
      </NutritionDisclaimerText>
    </ActionSheet>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
  scrollViewContent: {
    paddingHorizontal: isWeb ? 28 : 16,
  },
  scrollViewLongContent: {
    paddingBottom: 16,
  },
  closeButton: {
    alignSelf: 'center',
    width: '100%',
  },
  buttonContainer: {
    padding: isWeb ? 28 : 16,
    shadowColor: tokens.colors.$black10,
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 12,
    elevation: 50,
  },
  buttonContainerLongContent: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$black10,
  },
});
