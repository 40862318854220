import { Image } from 'expo-image';
import React from 'react';
import { View } from 'react-native';

import { type RadioCircleIndicatorProps, RadioGroup, Text, XStack, useRadioItem } from '@fhs/ui';

import { optimizeSanityImageUri } from '../../utils';

import { BORDER_RADIUS, IMAGE_HEIGHT } from './constants';
import { ListItemUnavailableBadge } from './list-item-unavailable-badge';
import { listStyles } from './shared-styles';
import type { ListItemType } from './types';

export type ListItemRadioProps = ListItemType & {
  disabled?: boolean;
  value: string;
  variation?: 'actionsheetItem' | 'listItem';
  indicatorSize?: RadioCircleIndicatorProps['size'];
};

const IndicatorText = ({ indicatorText }: { indicatorText: string }) => {
  const { selected } = useRadioItem();
  return (
    <Text.Ui
      size="sm"
      numberOfLines={1}
      style={selected ? listStyles.indicatorTextSelected : listStyles.indicatorText}
    >
      {indicatorText}
    </Text.Ui>
  );
};

export function ListItemRadio({
  indicatorSize = 'sm',
  minHeightStyle = 'static',
  variation = 'listItem',
  ...props
}: ListItemRadioProps) {
  const isList = variation === 'listItem';

  const borderStyles = isList
    ? {
        borderTopLeftRadius: props.isFirstItem ? BORDER_RADIUS : 0,
        borderTopRightRadius: props.isFirstItem ? BORDER_RADIUS : 0,
        borderBottomLeftRadius: props.isLastItem ? BORDER_RADIUS : 0,
        borderBottomRightRadius: props.isLastItem ? BORDER_RADIUS : 0,
      }
    : {
        borderRadius: BORDER_RADIUS,
      };

  return (
    <RadioGroup.Item
      value={props.value}
      label={[props.title, props.subtitle].filter(Boolean).join(' - ')}
      disabled={props.disabled}
    >
      <RadioGroup.Pressable
        style={[
          isList ? listStyles.item : listStyles.actionsheetOption,
          minHeightStyle === 'static' && listStyles.minHeightStyleStatic,
          props.isLastItem && listStyles.lastItem,
        ]}
        hoveredStyle={listStyles.itemHover}
        {...borderStyles}
      >
        {props.image?.asset?.uri && (
          <Image
            contentFit="contain"
            style={[listStyles.image, props.disabled && listStyles.imageDisabled]}
            alt={props.image.altText}
            source={{ uri: optimizeSanityImageUri(props.image.asset.uri, IMAGE_HEIGHT) }}
            placeholder={{ blurhash: props.image.asset.blurHash }}
          />
        )}
        <View style={listStyles.titleTextContainer}>
          <View>
            <Text.Ui
              weight={props.titleFontWeight ?? 'semibold'}
              size="md"
              numberOfLines={1}
              style={props.disabled && listStyles.textDisabled}
            >
              {props.title}
            </Text.Ui>
          </View>
          {Boolean(props.subtitle) && (
            <View>
              <Text.Ui size="sm" numberOfLines={1} style={listStyles.subtitle}>
                {props.subtitle}
              </Text.Ui>
            </View>
          )}
        </View>
        {props.disabled ? (
          <ListItemUnavailableBadge />
        ) : (
          <XStack style={listStyles.indicatorWithTextWrapper}>
            {Boolean(props.indicatorText) && <IndicatorText indicatorText={props.indicatorText} />}
            <RadioGroup.CircleIndicator size={indicatorSize} />
          </XStack>
        )}
      </RadioGroup.Pressable>
    </RadioGroup.Item>
  );
}
