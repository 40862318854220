import { Image } from 'expo-image';
import { View } from 'react-native';

import { ActionSheet, Button, Text } from '@fhs/ui';

import { useComboSelectionContext } from '../../state/combo-selection';
import { useMenuPicker } from '../../state/menu-picker';
import { formatCentsToDollars } from '../../utils';

export const MakeComboActionSheet = ({
  isOpen,
  onClose,
  menuPicker,
  onCancel,
}: {
  isOpen: boolean;
  onClose: () => void;
  menuPicker: ReturnType<typeof useMenuPicker>;
  onCancel: () => void;
}) => {
  const { setIsComboGuideOpen, setIsComboEnabled } = useComboSelectionContext();
  return (
    menuPicker.query.data?.combo && (
      <ActionSheet
        onRequestClose={() => onClose()}
        onClose={() => onClose()}
        isVisible={isOpen}
        noPanGestureDetection
      >
        <View style={{ alignItems: 'center', padding: 30 }}>
          <Image
            source={require('./combo.webp')}
            alt="Make it a Combo"
            placeholder={{ thumbhash: 'bykOJIL4lrhqmXV+hZiPdIJICQ' }}
            contentFit="contain"
            style={{ width: '70%', aspectRatio: 280 / 164 }}
          />
          <Text.Heading type="one" style={{ marginTop: 20 }}>
            Make it a Combo
          </Text.Heading>
          <Text.Paragraph
            size="md"
            style={{ width: '70%', textAlign: 'center', marginVertical: 20 }}
          >
            {menuPicker.query.data?.combo.description} for only{' '}
            <Text weight="semibold">
              {formatCentsToDollars(menuPicker.query.data?.combo.basePriceCents)}
            </Text>
          </Text.Paragraph>
          <Button
            style={{ width: '100%', marginBottom: 10 }}
            onPress={() => {
              onClose();
              setIsComboGuideOpen(true);
              setIsComboEnabled(true);
            }}
          >
            <Button.Text>Make it a Combo</Button.Text>
          </Button>
          <Button
            type="outline"
            style={{ width: '100%' }}
            onPress={() => {
              onClose();
              onCancel();
            }}
          >
            <Button.Text>No, Thanks</Button.Text>
          </Button>
        </View>
      </ActionSheet>
    )
  );
};
