import { router } from 'expo-router';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, IconStore, Text, tokens } from '@fhs/ui';

import { useLoyaltyLegacyStates } from '../../hooks/use-loyalty-legacy-states';

export function NotAvailable() {
  const { serviceMode } = useLoyaltyLegacyStates();
  const link = useMemo(() => {
    if (serviceMode === 'DELIVERY') {
      return '/store-locator/address';
    }
    return '/store-locator/service-mode';
  }, [serviceMode]);

  const onPressChangeRestaurant = () => {
    router.dismiss();
    router.navigate(link);
  };

  return (
    <View style={styles.container}>
      <IconStore size={80} color={tokens.colors.$blackOpacity10} />
      <Text.Heading style={styles.heading} type="two">
        Not Available at This Location
      </Text.Heading>
      <Text.Ui size="md" style={styles.description}>
        This offer is not available at the currently selected restaurant.
      </Text.Ui>
      <Button size="sm" type="outline" style={styles.button} onPress={onPressChangeRestaurant}>
        <Button.Text>Change Restaurant</Button.Text>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    textAlign: 'center',
    paddingTop: 16,
  },
  description: {
    textAlign: 'center',
    paddingTop: 8,
  },
  button: {
    alignSelf: 'center',
    marginTop: 16,
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});
