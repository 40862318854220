import { StyleSheet, View } from 'react-native';

import { PressableProps, Switch, Text, XStack, YStack, tokens } from '@fhs/ui';

import { SelectedPicker, useComboSelectionContext } from '../../state/combo-selection';
import { MenuPickerData } from '../../types';
import { formatCentsToDollars } from '../../utils';
import { ListItemLinkPressable } from '../list-item-group-section';

type MakeComboSectionProps = {
  enabled: boolean;
  combo: MenuPickerData['combo'];
  onToggle: () => void;
  onEdit: (stepIndex: number) => void;
};

type SlotListItemProps = {
  slot: MenuPickerData['combo']['slots'][number];
  isLastItem;
  selectedPicker?: SelectedPicker;
  onPress: PressableProps['onPress'];
  disabled: PressableProps['disabled'];
};

const SlotListItem = ({
  slot,
  disabled,
  isLastItem,
  onPress,
  selectedPicker,
}: SlotListItemProps) => {
  if (selectedPicker) {
    const baseCaloriesDisplay = selectedPicker.selectedPickerAspectOption?.item?.baseCalories
      ? `${selectedPicker.selectedPickerAspectOption?.item?.baseCalories} Cal`
      : null;

    return (
      <ListItemLinkPressable
        image={selectedPicker.image}
        title={selectedPicker.displayName}
        titleStyle={{ fontSize: 14 }}
        subtitle={baseCaloriesDisplay}
        subtitleStyle={{ fontSize: 12 }}
        isLastItem={isLastItem}
        onPress={onPress}
        disabled={disabled}
      />
    );
  }

  return (
    <ListItemLinkPressable
      image={slot.image}
      title={`Add ${slot.displayName}`}
      titleStyle={{ fontSize: 14 }}
      isLastItem={isLastItem}
      onPress={onPress}
      disabled={disabled}
    />
  );
};

export function MakeComboSection({ enabled, combo, onToggle, onEdit }: MakeComboSectionProps) {
  const { selectedPickers, setIsEditingPickers } = useComboSelectionContext();

  return (
    <YStack>
      <XStack style={styles.titleSection}>
        <YStack>
          <Text weight="semibold" style={styles.title}>
            {combo.displayName}
          </Text>
          <Text style={styles.subtitle}>{combo.description}</Text>
        </YStack>
        <XStack style={styles.buttonWrapper}>
          <Text style={styles.extraPrice}>+ {formatCentsToDollars(combo.basePriceCents)}</Text>
          <Switch value={enabled} onChange={onToggle}>
            <Switch.Control />
          </Switch>
        </XStack>
      </XStack>
      <YStack style={styles.itemsWrapper}>
        {combo.slots.map((slot, index) => (
          <View key={slot.id} style={{ opacity: enabled ? undefined : 0.5 }}>
            <SlotListItem
              slot={slot}
              selectedPicker={selectedPickers[slot.id]}
              isLastItem={index === combo.slots.length - 1}
              disabled={!enabled}
              onPress={() => {
                onEdit(index);
                setIsEditingPickers(true);
              }}
            />
          </View>
        ))}
      </YStack>
    </YStack>
  );
}

const styles = StyleSheet.create({
  image: {
    aspectRatio: 1,
    width: 40,
    overflow: 'visible',
  },
  titleSection: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginBottom: 4,
    lineHeight: 16,
    fontSize: 14,
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 20,
  },
  extraPrice: {
    color: tokens.colors.$disabledText,
    fontSize: 14,
    lineHeight: 24,
  },
  buttonWrapper: {
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemsWrapper: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    marginTop: 12,
  },
  item: {
    flexDirection: 'row',
    paddingVertical: 12,
    paddingHorizontal: 16,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemText: {
    fontSize: 14,
  },
  comboItemsDivisorLine: {
    height: 1,
    width: '100%',
    backgroundColor: tokens.colors.$blackOpacity10,
  },
});
