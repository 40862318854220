import { tokens } from '@fhs/ui';
import { Text as BaseText, Box } from '@fhs-legacy/universal-components';

export * from './base.styled';

export const DetailsBox = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderRadius: 10,
  paddingY: '$5',
  paddingX: '$4',
  _text: {
    variant: 'copyOne',
    fontSize: 'md',
  },
});

export const UberBox = Box.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderRadius: 10,
  borderWidth: 1,
  marginBottom: 2,
  borderColor: tokens.colors.$black10,
  flexDirection: 'row',
  justifyContent: 'center',
});

export const DetailsEtaText = BaseText.withConfig({
  fontSize: 'xs',
});

export const Text = BaseText.withConfig({
  fontSize: 'md',
  lineHeight: 'lg',
});

export const BoldText = BaseText.withConfig({
  fontWeight: 'bold',
});

export const FieldContainer = Box.withConfig({
  marginBottom: '$4',
});
