import { Image } from 'expo-image';
import { useState } from 'react';
import { Pressable, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import { Button, Text, tokens } from '@fhs/ui';
import { useComposeDescription } from '@fhs-legacy/frontend/src/hooks/use-compose-description';
import { ICartEntry } from '@rbi-ctg/menu';

const descriptionCollapsedLimit = 2;

const Description = ({ selectedEntry }: { selectedEntry: ICartEntry }) => {
  const [isModifierMapClosed, setIsModifierMapClosed] = useState(true);

  const description = useComposeDescription(selectedEntry, {
    includeQuantity: false,
  });

  const descriptionSized = _resizeArray(description, isModifierMapClosed);
  return (
    <>
      {description?.length > 0 && (
        <View>
          {descriptionSized.map(descriptionItem => (
            <Text.Ui size="sm">{descriptionItem}</Text.Ui>
          ))}
          {description.length > descriptionCollapsedLimit && (
            <Pressable onPress={() => setIsModifierMapClosed(s => !s)}>
              <Text.Ui weight="semibold" style={styles.seeMore} size="sm">
                See {isModifierMapClosed ? 'More' : 'Less'}
              </Text.Ui>
            </Pressable>
          )}
        </View>
      )}
    </>
  );
};

export interface SummaryStepItemProps {
  selectedEntry?: ICartEntry;
  selectedEntryImage?: string;
  onPressButton: () => void;
  style?: StyleProp<ViewStyle>;
  buttonLabel?: string;
}

export function SummaryStepItem({
  selectedEntry,
  selectedEntryImage,
  onPressButton: onPressEdit,
  style,
  buttonLabel = 'Edit',
}: SummaryStepItemProps) {
  const { name } = selectedEntry ?? {};

  return (
    <View style={[styles.container, style]}>
      <View style={styles.content}>
        <Image source={{ uri: selectedEntryImage }} contentFit="contain" style={styles.image} />
        <View style={styles.infoContainer}>
          <Text.Ui weight="semibold" size="lg">
            {name}
          </Text.Ui>
          {selectedEntry && <Description selectedEntry={selectedEntry} />}
        </View>
      </View>
      <Button onPress={onPressEdit} size="xs" type="outline" style={styles.button}>
        <Button.Text style={{ fontSize: 10 }}>{buttonLabel}</Button.Text>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    paddingVertical: 12,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    height: 56,
    width: 56,
  },
  infoContainer: {
    marginLeft: 12,
    justifyContent: 'center',
    flex: 1,
  },
  seeMore: {
    color: tokens.colors.$blackOpacity75,
    textDecorationLine: 'underline',
  },
  button: {
    height: 31,
    marginLeft: 4,
  },
});

function _resizeArray<T>(arry: Array<T>, shouldResize: boolean): Array<T> {
  if (!shouldResize) {
    return arry;
  }
  const copy = Array.from(arry);
  copy.length = descriptionCollapsedLimit;
  return copy;
}
