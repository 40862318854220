export enum Header {
  CART_ID = 'x-cart-id',
  LANGUAGE = 'x-ui-language',
  UI_PLATFORM = 'x-ui-platform',
  PLATFORM_FRAMEWORK = 'x-platform-framework',
  UI_VERSION = 'x-ui-version',
  UI_COMMIT_SHA = 'x-ui-sha',
  REGION = 'x-ui-region',
  SESSION_ID = 'x-session-id',
  DATETIME = 'x-user-datetime',
  AUTHORIZATION = 'authorization',
}
export type Headers = Record<Header, string>;
