import { useComboSelectionContext } from '../state/combo-selection';
import { useMenuPicker } from '../state/menu-picker';

import { formatCentsToDollars } from '.';

export const useMenuItemPricePreview = ({
  menuPicker,
  quantity,
}: {
  menuPicker: ReturnType<typeof useMenuPicker>;
  quantity: number;
}) => {
  const { isComboEnabled, selectedPickers } = useComboSelectionContext();

  if (!isComboEnabled) {
    const totalPriceCents = quantity * menuPicker.priceCents;
    return {
      totalPriceCents,
      formattedTotalPrice: formatCentsToDollars(totalPriceCents) ?? '',
    };
  }

  // Sum all extra prices related to combo items customization
  const comboPickerExtraPrice = Object.values(selectedPickers).reduce(
    (total, picker) => total + picker.priceCents,
    0
  );

  const comboBasePrice = menuPicker.query.data?.combo.basePriceCents ?? 0;
  const totalUnitPriceCents = menuPicker.priceCents + comboPickerExtraPrice + comboBasePrice;
  const totalPriceCents = quantity * totalUnitPriceCents;
  const formattedTotalPrice = formatCentsToDollars(totalPriceCents) ?? '';

  return {
    totalPriceCents,
    formattedTotalPrice,
  };
};
