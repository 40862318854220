import { Redirect, Stack, router, useLocalSearchParams } from 'expo-router';
import { ActivityIndicator, StyleSheet } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { getScreenTitleHeader } from '@fhs/routing/src/components/get-screen-title-header';
import { IconArrowBack, MqSwitch, Pressable, tokens } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';

import { ComboSelectionProvider } from '../state/combo-selection';
import { MenuPickerProvider } from '../state/menu-picker';

export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuItemLayout() {
  const { slug } = useLocalSearchParams<{ slug: string }>();

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableSimplifiedMenuBetaDetailView = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );

  // The flag will be `undefined` initially, so we need to return null to avoid rendering the `menu` page,
  // because that will cause a redirect to the store locator if the store is not set.
  const flagsAreUndefined =
    enableSimplifiedMenuBeta === undefined && enableSimplifiedMenuBetaDetailView === undefined;
  if (flagsAreUndefined && !isSSG) {
    return <ActivityIndicator />;
  }
  if (!enableSimplifiedMenuBeta && !isSSG) {
    return <Redirect href="/menu" />;
  }
  // END of TODO

  return (
    <MenuPickerProvider slug={slug}>
      <ComboSelectionProvider>
        <SafeAreaView edges={['bottom', 'left', 'right']} style={styles.safeArea}>
          <Stack>
            <Stack.Screen
              name="index"
              options={{
                header: props => (
                  <MqSwitch
                    $ltDesktop={() => getScreenTitleHeader()(props)}
                    $gteDesktop={() => <MenuItemDesktopHeader />}
                  />
                ),
                title: '',
              }}
            />
            <Stack.Screen
              name="customize"
              options={{
                header: props => getScreenTitleHeader()(props),
                title: 'Customize Item',
                headerTitleAlign: 'center',
                headerShadowVisible: false,
              }}
            />
          </Stack>
        </SafeAreaView>
      </ComboSelectionProvider>
    </MenuPickerProvider>
  );
}

const MenuItemDesktopHeader = () => {
  return (
    <Pressable
      borderRadius={100}
      borderWidth={1}
      style={styles.iconContainer}
      onPress={() => router.back()}
    >
      <IconArrowBack size={24} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    backgroundColor: tokens.colors.$white,
    flex: 1,
  },
  iconContainer: {
    backgroundColor: tokens.colors.$white,
    height: 48,
    width: 48,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderColor: tokens.colors.$black10,
    shadowColor: tokens.colors.$black,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.1,
    shadowRadius: 30,
    top: 19,
    left: 20,
  },
});
