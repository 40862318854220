import { StyleSheet } from 'react-native';

import { Text, TextProps } from '@fhs/ui';

type SubheadingProps = {
  text: string;
  style?: TextProps['style'];
};

export function GuideSubheading(props: SubheadingProps) {
  return (
    <Text weight="semibold" style={[styles.subheading, props.style]}>
      {props.text}
    </Text>
  );
}

const styles = StyleSheet.create({
  subheading: {
    fontSize: 14,
    lineHeight: 19.6,
  },
});
