import { PropsWithChildren } from 'react';
import { Modal, StyleSheet } from 'react-native';

import { IconClose, Pressable, Text, XStack, YStack } from '@fhs/ui';
import { useBoxRef } from '@fhs/utils';

import { tokens } from '../../tokens';

import { ActionSheetScrollView } from './action-sheet-scroll-view';
import { ActionSheetProps } from './types';

const NoScrollableDrawer = ({ children, ...props }: PropsWithChildren) => (
  <YStack style={[styles.content]} {...props}>
    {children}
  </YStack>
);

export const DesktopOptionSelectModal = ({
  headerTitle = '',
  onClose,
  isVisible,
  children,
  onRequestClose,
  drawerProps,
  footer,
  isScrollableDrawer,
}: ActionSheetProps) => {
  const handleRequestCloseRef = useBoxRef(() => {
    onClose();
  });

  const Drawer = isScrollableDrawer ? ActionSheetScrollView : NoScrollableDrawer;

  return (
    <Modal
      visible={isVisible}
      transparent
      animationType="fade"
      onRequestClose={onRequestClose ?? handleRequestCloseRef.current}
    >
      <YStack style={styles.container}>
        <Pressable
          aria-label="Close dropdown"
          accessible={false}
          focusable={false}
          onPress={handleRequestCloseRef.current}
          style={styles.overlay}
        />
        <YStack style={[styles.modalWrapper]}>
          {!!headerTitle && (
            <XStack style={styles.header}>
              <Text style={styles.headerTitle} weight="semibold">
                {headerTitle}
              </Text>
              <Pressable onPress={onClose}>
                <IconClose size={32} />
              </Pressable>
            </XStack>
          )}
          <Drawer {...drawerProps}>{children}</Drawer>
          {footer}
        </YStack>
      </YStack>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: tokens.colors.$blackOpacity75,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 28,
    paddingTop: 28,
  },
  headerTitle: {
    fontSize: 24,
    lineHeight: 33.6,
  },
  modalWrapper: {
    display: 'flex',
    width: 520,
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: tokens.colors.$white,
    gap: 28,
    borderRadius: 12,
  },
  content: {
    flex: 1,
  },
});
