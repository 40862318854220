import { StyleSheet, View } from 'react-native';

import { Text, tokens } from '@fhs/ui';

export const ListItemUnavailableBadge = () => {
  return (
    <View style={styles.container}>
      <Text.Ui weight={'bold'} size={'sm'} style={styles.text}>
        Unavailable
      </Text.Ui>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 89,
    height: 24,
    borderRadius: 5,
    backgroundColor: tokens.colors.$houseLight,
    justifyContent: 'center',
  },
  text: {
    alignSelf: 'center',
    fontSize: 12,
  },
});
