import { router, useGlobalSearchParams, usePathname } from 'expo-router';
import Drawer from 'react-modern-drawer';
import { StyleSheet } from 'react-native';

import { CartScreen } from '@fhs/cart';
import { GenericErrorScreen } from '@fhs/cart';
import { tokens } from '@fhs/ui';

import { CartHeader } from '../cart-header';

import 'react-modern-drawer/dist/index.css';

export function DesktopCartDrawer() {
  const showCartDrawer = useGlobalSearchParams().drawer === 'cart';
  const pathname = usePathname();

  return (
    <Drawer
      open={showCartDrawer}
      onClose={() => router.setParams({ drawer: undefined })}
      overlayColor={tokens.colors.$blackOpacity75}
      size={450}
      style={styles.drawer}
      direction="right"
    >
      {
        // Only render the cart if its visible. The cart will spawn queries/subscriptions
        // that we want to only happen when the component is displayed.
        showCartDrawer && (
          <>
            <CartHeader href={{ pathname, params: { drawer: undefined } }} title="Cart" />
            <GenericErrorScreen>
              <CartScreen />
            </GenericErrorScreen>
          </>
        )
      }
    </Drawer>
  );
}

const styles = StyleSheet.create({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
  },
});
