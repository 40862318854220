import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Button, tokens } from '@fhs/ui';

export const ItemDetailsFooter = ({ onSaveItem }: { onSaveItem: () => void }) => {
  return (
    <View style={styles.footer}>
      <Button onPress={onSaveItem} style={styles.addItemCTAButton}>
        <Button.Text>Add Item</Button.Text>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    backgroundColor: tokens.colors.$white,
    padding: 16,
    flex: 1,
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 52,
  },
  addItemCTAButton: {
    flex: 1,
    fontSize: 18,
    height: 48,
  },
});
