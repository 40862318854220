export const frenchDipUSStartDate = new Date(2024, 11, 1); // December 1, 2024
export const frenchDipUSEndDate = new Date(2025, 3, 9, 23, 59, 59); // March 09, 2025
export const frenchDipUSUrl = '/menu/section-39f0c606-790a-4edd-a322-74cded7c2f51';

export const frenchDipCAStartDate = new Date(2024, 11, 1); // December 1, 2024
export const frenchDipCAEndDate = new Date(2025, 2, 2, 23, 59, 59); // March 02, 2025
export const frenchDipCAUrl = '/menu/section-b95bc971-3838-495c-9c8f-93183e68a539';

export const hotSauceBarCanadaStartDate = new Date(2024, 11, 1); // December 1, 2024
export const hotSauceBarCanadaEndDate = new Date(2025, 0, 27, 23, 59, 59); // January 27, 2025

export const hotOnesStartDate = new Date(2025, 2, 10); // March 10, 2025
export const hotOnesEndDate = new Date(2025, 5, 8, 23, 59, 59); // June 8, 2025
