import { type StyleProp, StyleSheet, View, type ViewStyle } from 'react-native';

import { Text, YStack, tokens } from '@fhs/ui';

type LabeledDividerProps = {
  labelText: string;
  style?: StyleProp<ViewStyle>;
};

export function GuideLabeledDivider(props: LabeledDividerProps) {
  return (
    <YStack style={[styles.dividerContainer, props.style]}>
      <View style={styles.divider} />
      <View style={styles.textLayer}>
        <Text style={styles.text}>{props.labelText}</Text>
      </View>
    </YStack>
  );
}

const styles = StyleSheet.create({
  dividerContainer: {
    minHeight: 32,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  divider: {
    width: '100%',
    height: StyleSheet.hairlineWidth,
    backgroundColor: tokens.colors.$blackOpacity10,
  },
  textLayer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    backgroundColor: 'white',
    paddingInline: 12,
    color: tokens.colors.$disabledText,
    fontSize: 16,
    lineHeight: 22.4,
  },
});
