import { Stack, usePathname } from 'expo-router';

import { MqSwitch, useMqSelect } from '@fhs/ui';

import { AppHeader } from '../../components/app-header';
import { getScreenTitleHeader } from '../../components/get-screen-title-header';

const appHeaderDesktopOnly = () => (
  <MqSwitch $ltDesktop={() => null} $gteDesktop={() => <AppHeader />} />
);

export default function V2StackLayout() {
  const pathName = usePathname();
  const isDesktop = useMqSelect(
    {
      $gteDesktop: true,
    },
    false
  );
  return (
    <>
      <Stack screenOptions={{ headerShown: false }}>
        <Stack.Screen
          name="signin"
          options={{
            header: appHeaderDesktopOnly,
            presentation: 'fullScreenModal',
          }}
        />
        <Stack.Screen
          name="[staticPage]"
          options={{
            header: appHeaderDesktopOnly,
            presentation: 'fullScreenModal',
          }}
        />
        <Stack.Screen
          name="menu"
          options={{
            header: appHeaderDesktopOnly,
            headerShown: isDesktop && !pathName.includes('/customize'),
          }}
        />
        <Stack.Screen
          name="cart"
          options={{
            title: 'Cart',
            header: getScreenTitleHeader({
              backStyle: 'back',
            }),
          }}
        />
        <Stack.Screen
          name="order"
          options={{
            title: 'Order',
            header: getScreenTitleHeader({
              backStyle: 'back',
            }),
          }}
        />
        <Stack.Screen
          name="index"
          options={{
            title: 'Simply Better',
            header: getScreenTitleHeader({ backStyle: 'back' }),
          }}
        />
        <Stack.Screen
          name="offers"
          options={{
            title: 'Simply Better',
            header: appHeaderDesktopOnly,
            headerShown: isDesktop,
          }}
        />
      </Stack>
    </>
  );
}
