import { router, useLocalSearchParams } from 'expo-router';
import { jwtDecode } from 'jwt-decode';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { useToast } from '@fhs-legacy/frontend/src/hooks/use-toast';

import { stripPhoneNumber } from '../utils';

import { cognitoSignIn } from './cognito';
import {
  AuthIdentifiers,
  AuthOptInType,
  AuthV2ChallengeType,
  AuthV2ErrorState,
  IAuthV2Context,
  SignUpInput,
  ValidatedIdentifiers,
  useCreateOtpv2Mutation,
  useSignUpV2Mutation,
  useValidateOtpV2Mutation,
} from './types';
import { CreateOtpInput, IdentifyAuthJwt, LoginJwt } from './types';

export const AuthV2Context = createContext<IAuthV2Context>({} as IAuthV2Context);

export const useAuthV2Context = () => useContext<IAuthV2Context>(AuthV2Context);

export const AuthProvider = ({ children }) => {
  const [showIdentifyPopup, setShowIdentifyPopup] = useState<boolean>(false);
  const [identifyAuthJwts, setIdentifyAuthJwts] = useState<IdentifyAuthJwt[]>([]);
  const [error, setError] = useState<AuthV2ErrorState>(null);
  const { loginJwt } = useLocalSearchParams<{ loginJwt?: string }>();

  const { show } = useToast();

  /**
   * Get consistent login attempt parameters
   * Stub implementation for device ID for now
   */
  const persistentParameters = useMemo(
    () => ({
      deviceId: 'some-device-id-here',
      loginJwt: loginJwt,
    }),
    [loginJwt]
  );

  /**
   * GraphQL Function Callbacks
   */
  const onGraphqlError = useCallback(
    err => {
      if (err?.graphQLErrors && err.graphQLErrors.length > 0) {
        //Take the highest level error code
        const code = err.graphQLErrors[0].extensions.code;
        switch (code) {
          case 'INVALID_OTP':
            setError("The code you've entered is incorrect");
            break;
          default:
            show({ text: 'Oops, something went wrong. Please try again', variant: 'negative' });
        }
      } else {
        show({ text: 'Oops, something went wrong. Please try again', variant: 'negative' });
      }
    },
    [setError, show]
  );

  const onValidateOtp = useCallback((data, _clientOptions) => {
    router.setParams({ loginJwt: data.validateOtpV2.loginJwt });
    return data;
  }, []);

  const onCreateOtp = useCallback((data, _clientOptions) => {
    //Push user to verify page & persist login JWT
    router.push({
      pathname: '/v2/signin/(verify)',
      params: { loginJwt: data.createOtpV2.loginJwt },
    });
    return data;
  }, []);
  const onSignUp = useCallback((data, _clientOptions) => {
    return data.signUpV2.loginJwt;
  }, []);

  const [internalCreateOtp] = useCreateOtpv2Mutation({ onCompleted: onCreateOtp });
  const [internalValidateOtp] = useValidateOtpV2Mutation({
    onCompleted: onValidateOtp,
    onError: onGraphqlError,
  });
  const [internalSignUp] = useSignUpV2Mutation({ onCompleted: onSignUp });
  const createOtp = useCallback(
    async (partialInput: CreateOtpInput) => {
      await internalCreateOtp({
        variables: {
          input: {
            ...persistentParameters,
            ...partialInput,
          },
        },
      });
    },
    [internalCreateOtp, persistentParameters]
  );

  ////////////////////////////////////////////////////////////
  // Confirm Login
  ////////////////////////////////////////////////////////////
  const validateOtp = useCallback(
    async (otpInput: string) => {
      if (loginJwt) {
        const response = await internalValidateOtp({
          variables: { input: { otpInput, loginJwt } },
        });
        const validateResult = response.data?.validateOtpV2;
        if (validateResult) {
          switch (validateResult.challengeType) {
            case AuthV2ChallengeType.SIGN_IN_CHALLENGE: {
              const { challengeCode, cognitoId, sessionId } = validateResult.challenge;
              //Authenticate with Cognito.
              const session = await cognitoSignIn(cognitoId, sessionId, challengeCode);
              //Validate the user is authenticated here
              if (session) {
                //Route to home page & clear login state
                router.navigate({ pathname: '/', params: { loginJwt: undefined } });
              }
              break;
            }
            case AuthV2ChallengeType.SIGN_UP_CHALLENGE: {
              router.push({
                pathname: '/v2/signin/(signup)',
                params: { loginJwt: validateResult.loginJwt },
              });
              break;
            }
            case AuthV2ChallengeType.IDENTIFY_CHALLENGE: {
              //Render select account popup
              const { authJwts } = validateResult.challenge;
              setIdentifyAuthJwts(
                authJwts?.map(jwt => {
                  return {
                    ...jwtDecode<IdentifyAuthJwt>(jwt),
                    jwt,
                  };
                }) ?? []
              );
              setShowIdentifyPopup(true);
              break;
            }
          }
        }
      }
    },
    [internalValidateOtp, loginJwt]
  );
  ////////////////////////////////////////////////////////////
  // Sign Up
  ////////////////////////////////////////////////////////////
  const signUp = useCallback(
    async ({ name, email, phoneNumber, dob, wantsPromotionalEmail }: SignUpInput) => {
      const response = await internalSignUp({
        variables: {
          input: {
            name,
            loginJwt,
            email,
            phoneNumber: stripPhoneNumber(phoneNumber),
            dob,
            optIns: [
              {
                identifier: 'EMAIL_MARKETING' as unknown as AuthOptInType,
                optInStatus: wantsPromotionalEmail,
              },
            ],
          },
        },
      });
      const signUpResult = response.data.signUpV2;
      if (signUpResult) {
        switch (signUpResult.challengeType) {
          case AuthV2ChallengeType.SIGN_IN_CHALLENGE: {
            const { challengeCode, cognitoId, sessionId } = signUpResult.challenge;
            //Authenticate with Cognito.
            const session = await cognitoSignIn(cognitoId, sessionId, challengeCode);
            //Validate the user is authenticated here
            if (session) {
              //Push to home page and clear login state
              router.push({ pathname: '/', params: { loginJwt: undefined } });
            }
            break;
          }
          case AuthV2ChallengeType.SIGN_UP_CHALLENGE:
          case AuthV2ChallengeType.IDENTIFY_CHALLENGE: {
            throw Error("Something went wrong, these aren't valid states");
          }
        }
      }
    },
    [internalSignUp, loginJwt]
  );

  const getJwtIdentifier = useCallback(() => {
    if (loginJwt) {
      return jwtDecode<LoginJwt>(loginJwt);
    }
    //Return a dummy JWT.
    return {
      loginIdentifier: '',
      identifierType: AuthIdentifiers.EMAIL,
      deviceId: '',
      validatedIdentifiers: {} as ValidatedIdentifiers,
    };
  }, [loginJwt]);

  const routeWithJWTContext = useCallback(
    (route: string) => {
      router.navigate({ pathname: route, params: { loginJwt } });
    },
    [loginJwt]
  );

  const value = {
    //Mutations
    createOtp,
    validateOtp,
    signUp,
    //Auth State
    error,
    setError,
    identifyAuthJwts,
    setIdentifyAuthJwts,
    showIdentifyPopup,
    setShowIdentifyPopup,
    //Utility Functions
    getJwtIdentifier,
    routeWithJWTContext,
  };

  return <AuthV2Context.Provider value={value}>{children}</AuthV2Context.Provider>;
};

export * from './types';
