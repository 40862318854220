import { ElementType, PropsWithChildren, createElement, useMemo } from 'react';

import { ClientProvider } from '@fhs/client';
import { LoyaltyModalsProvider } from '@fhs/loyalty/src/components';
import { UclProvider, UclTheme } from '@fhs-legacy/universal-components';
import { AmplitudeProvider } from 'state/amplitude';
import { AuthProvider } from 'state/auth';
import { BranchProvider } from 'state/branch';
import { CRMEventsProvider } from 'state/crm-events';
import { DayPartProvider } from 'state/day-part';
import { DeliveryConfirmationProvider } from 'state/delivery-confirmation';
import { ErrorsProvider } from 'state/errors';
import { FavoritesProvider } from 'state/favorites';
import { GeolocationProvider } from 'state/geolocation';
import { GlobalStateProvider } from 'state/global-state/provider';
import { GraphQLProvider } from 'state/graphql';
import { IntlProvider } from 'state/intl';
import { LDProvider } from 'state/launchdarkly';
import { LoyaltyProvider } from 'state/loyalty';
import { MenuProvider } from 'state/menu';
import { NetworkProvider } from 'state/network';
import { OffersProvider } from 'state/offers';
import { OrderProvider } from 'state/order';
import { PaymentProvider } from 'state/payment';
import { PypestreamProvider } from 'state/pypestream';
import { ScrollProvider } from 'state/scroll';
import { ServiceModeProvider } from 'state/service-mode';
import { PageManagerProvider } from 'state/static-page-manager';
import { StoreProvider } from 'state/store';
import { UIProvider } from 'state/ui';
import { isSSG } from 'utils/environment';

const useProviders = ({
  uclTheme,
  onErrorBoundaryRequestReload,
}: {
  uclTheme: UclTheme;
  onErrorBoundaryRequestReload: () => void;
}) => {
  const UclProviderWithTheme = useMemo(
    () =>
      ({ children }: PropsWithChildren<{}>) =>
        createElement(UclProvider, { theme: uclTheme }, children),
    [uclTheme]
  );

  const ErrorsProviderWithHandler = useMemo(
    () =>
      ({ children }: PropsWithChildren<{}>) =>
        createElement(ErrorsProvider, { onRequestReload: onErrorBoundaryRequestReload }, children),
    [onErrorBoundaryRequestReload]
  );

  return [
    ClientProvider,
    UclProviderWithTheme,
    GlobalStateProvider,
    LDProvider,
    IntlProvider,
    AmplitudeProvider,
    BranchProvider,
    GraphQLProvider,
    CRMEventsProvider,
    ErrorsProviderWithHandler,
    NetworkProvider,
    AuthProvider,
    UIProvider,
    ServiceModeProvider,
    StoreProvider,
    DayPartProvider,
    OffersProvider,
    LoyaltyProvider,
    MenuProvider,
    isSSG ? null : PaymentProvider,
    PageManagerProvider,
    OrderProvider,
    FavoritesProvider,
    isSSG ? null : GeolocationProvider,
    DeliveryConfirmationProvider,
    ScrollProvider,
    isSSG ? null : PypestreamProvider,
    LoyaltyModalsProvider,
  ].filter(Boolean) as ElementType[];
};

export default useProviders;
