import { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

import { Skeleton } from './skeleton';

export type SkeletonTextBlockProps = {
  lines: number;
  lineStyle?: StyleProp<ViewStyle>;
} & ViewProps;

export const SkeletonTextBlock = ({ lines, lineStyle, ...props }: SkeletonTextBlockProps) => {
  const skeletonTextLines = useMemo(
    () =>
      Array(lines)
        .fill(null)
        .map((_, i, linesArr) => {
          const isFinalLine = i === linesArr.length - 1;
          return (
            <Skeleton key={i} style={[styles.line, isFinalLine && styles.finalLine, lineStyle]} />
          );
        }),
    [lines, lineStyle]
  );
  return <View {...props}>{skeletonTextLines}</View>;
};

const styles = StyleSheet.create({
  line: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  finalLine: {
    width: '75%',
    marginBottom: 0,
  },
});
