import { useReactQueryDevTools } from '@dev-plugins/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';

export { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useReactNativeIntegration } from './use-react-native-integration';

export const queryClient = new QueryClient();

export const ClientProvider = (props: React.PropsWithChildren) => {
  useReactNativeIntegration();
  useReactQueryDevTools(queryClient);
  return <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>;
};
