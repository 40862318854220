import { Image } from 'expo-image';
import { StyleSheet } from 'react-native';

import {
  type ImageAssetWithAltText,
  RadioGroup,
  Text,
  YStack,
  tokens,
  usePressableState,
  useRadioItem,
} from '@fhs/ui';

import type { PickerAspectOptionSelectorProps } from './types';

export function HorizontalPickerAspectOptionSelector(props: PickerAspectOptionSelectorProps) {
  return (
    <RadioGroup style={styles.radioGroup} value={props.value} onChange={props.onChange}>
      {props.options.map(option => (
        <RadioGroup.Item
          key={option.value}
          value={option.value}
          label={option.title}
          disabled={option.disabled}
        >
          <PickerAspectCard title={option.title} subtitle={option.subtitle} image={option.image} />
        </RadioGroup.Item>
      ))}
    </RadioGroup>
  );
}

const styles = StyleSheet.create({
  radioGroup: {
    flexDirection: 'row',
    gap: 16,
  },
});

type PickerAspectCardProps = {
  title: string;
  subtitle: string;
  image?: ImageAssetWithAltText;
};

function PickerAspectCard(props: PickerAspectCardProps) {
  const radioItem = useRadioItem();

  const imageUrl = props.image?.asset?.uri && new URL(props.image.asset.uri);
  if (imageUrl && radioItem.disabled) {
    // Desaturate image if radioItem disabled
    imageUrl.searchParams.set('sat', '-100');
  }

  return (
    <RadioGroup.Pressable
      pressedStyle={cardStyles.selectedContainer}
      style={[
        cardStyles.container,
        !radioItem.disabled && radioItem.selected && cardStyles.selectedContainer,
      ]}
      borderRadius={8}
    >
      {imageUrl ? (
        <Image
          style={[
            cardStyles.image,
            !radioItem.selected && cardStyles.unselectedImage,
            radioItem.disabled && cardStyles.unavailableImage,
          ]}
          contentFit="contain"
          alt={props.image.altText}
          source={imageUrl.toString()}
          placeholder={
            props.image.asset.blurHash ? { blurhash: props.image.asset.blurHash } : undefined
          }
        />
      ) : null}
      <PickerAspectCardTextContainer
        selected={radioItem.selected}
        title={props.title}
        subtitle={radioItem.disabled ? 'Unavailable' : props.subtitle}
      />
    </RadioGroup.Pressable>
  );
}

function PickerAspectCardTextContainer({
  selected,
  title,
  subtitle,
}: {
  selected: boolean;
  title: string;
  subtitle?: string;
}) {
  const pressableState = usePressableState();
  const shouldTextBeMuted = (!pressableState.pressed && !selected) || pressableState.disabled;

  return (
    <YStack style={cardStyles.textContainer}>
      <Text.Ui
        size="md"
        weight="semibold"
        style={[cardStyles.text, shouldTextBeMuted && cardStyles.textMuted]}
      >
        {title}
      </Text.Ui>
      {subtitle ? (
        <Text.Ui
          size="sm"
          numberOfLines={1}
          style={[cardStyles.text, shouldTextBeMuted && cardStyles.textMuted]}
        >
          {subtitle}
        </Text.Ui>
      ) : null}
    </YStack>
  );
}

const cardStyles = StyleSheet.create({
  container: {
    flexBasis: 100,
    flexShrink: 0,
    flexGrow: 1,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    paddingTop: 20,
    paddingRight: 12,
    paddingBottom: 16,
    paddingLeft: 12,
    gap: 8,
  },
  selectedContainer: {
    borderColor: tokens.colors.$houseYellow,
  },
  image: {
    height: 40,
    width: '100%',
  },
  unselectedImage: {
    opacity: 0.5,
  },
  unavailableImage: {
    opacity: 0.3,
  },
  textContainer: {
    alignItems: 'center',
    maxWidth: '100%',
    gap: 2,
  },
  text: {
    textAlign: 'center',
  },
  textMuted: {
    color: tokens.colors.$disabledText,
  },
});
