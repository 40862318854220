import { Href, Link } from 'expo-router';
import { Pressable, StyleSheet, View } from 'react-native';

import {
  Button,
  HeaderTitleText,
  IconArrowBack,
  IconChevronLeft,
  IconStarRateFill,
  LogoSvg,
  Text,
  tokens,
  useMqSelect,
} from '@fhs/ui';
import { HeaderFrame } from '@fhs/ui';
import { useLoyaltyUser } from '@fhs-legacy/frontend/src/state/loyalty/hooks/use-loyalty-user';

type Props = { title: string; href: Href };

export function CartHeader(props: Props) {
  const { loyaltyUser } = useLoyaltyUser();

  return (
    <HeaderFrame>
      <HeaderFrame.Left>
        <Link href={props.href} asChild>
          <Pressable>
            <IconChevronLeft size={24} style={styles.desktopIconButton} />
          </Pressable>
        </Link>
      </HeaderFrame.Left>
      <HeaderFrame.Middle>
        <HeaderTitleText text={props.title} />
      </HeaderFrame.Middle>
      {!!loyaltyUser?.points && (
        <HeaderFrame.Right style={styles.loyaltyPoints}>
          <IconStarRateFill size={24} color={tokens.colors.$houseYellow} />
          <Text.Heading type="five">
            {loyaltyUser?.points ? loyaltyUser.points.toLocaleString() : ''}
          </Text.Heading>
        </HeaderFrame.Right>
      )}
    </HeaderFrame>
  );
}

export function CheckoutHeader(props: Props) {
  const showCheckoutHeader = useMqSelect({ $gteDesktop: true }, false);

  if (!showCheckoutHeader) {
    return <CartHeader {...props} />;
  }

  return (
    <HeaderFrame style={styles.container}>
      <View style={styles.inner}>
        <HeaderFrame.Middle>
          <Link href={props.href} asChild>
            <Button type="ghost" style={styles.button}>
              <Button.Icon>
                <IconArrowBack />
              </Button.Icon>
              <Button.Text>Back to Cart</Button.Text>
            </Button>
          </Link>
          <LogoSvg height={36} />
        </HeaderFrame.Middle>
      </View>
    </HeaderFrame>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 80,
  },
  inner: {
    maxWidth: 908,
    marginHorizontal: 'auto',
    flexDirection: 'row',
  },
  button: {
    borderColor: tokens.colors.$blackOpacity10,
    width: 179,
    position: 'absolute',
    right: '100%',
    transform: 'translateX(-100%)',
  },
  desktopIconButton: {
    borderRadius: 80,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    padding: 11,
  },

  loyaltyPoints: {
    alignItems: 'center',
    gap: 4,
  },
});
