import { Image } from 'expo-image';
import { Link, router } from 'expo-router';
import { useMemo, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import type { Schema } from '@fhs/client';
import { useMutation } from '@fhs/client';
import { IconAdd, IconDelete, IconRemove, Text, XStack, tokens } from '@fhs/ui';

import { updateItemInCart } from '../../api';

import { PointsBar } from './points-bar';

type Props = {
  item: Schema['CartItemEntry']['type'] & { quantity: number };
  canUsePoints: boolean;

  // This will be when an appliedIncentive is correalted
  // to this lineId
  appliedSavings?: number | null;
  showQuantitySelectors?: boolean;
};

export function CartItem({
  canUsePoints,
  item,
  appliedSavings = 0,
  showQuantitySelectors = true,
}: Props) {
  const { mutate } = useMutation({
    ...updateItemInCart,
  });
  const itemPrice = item.price - (appliedSavings ?? 0);

  return (
    <View>
      <View style={styles.top}>
        <XStack style={{ gap: 16 }}>
          <Link
            href={{
              pathname: '/v2/menu/[slug]',
              params: {
                slug: item.slug,
                lineId: item.lineId,
                redirect: '/cart',
              },
            }}
          >
            <View style={styles.imageContainer}>
              {item.image?.asset && (
                <Image source={item.image.asset} alt={item.image.altText} style={styles.image} />
              )}
            </View>
          </Link>

          <View style={{ flex: 1 }}>
            <Link
              href={{
                pathname: '/v2/menu/[slug]',
                params: {
                  slug: item.slug,
                  lineId: item.lineId,
                  redirect: '/cart',
                },
              }}
            >
              <Text.Ui size="md" weight="semibold" numberOfLines={1}>
                {item.displayName}
              </Text.Ui>
            </Link>
            <CartItemModifierList modifiers={item.modifiers} instructions={item.instructions} />
          </View>
          <View style={{ gap: 12 }}>
            <View style={styles.prices}>
              <Text.Ui size="md" style={itemPrice === 0 && styles.crossedOutPrices}>
                ${((item.price * item.quantity) / 100).toFixed(2)}
              </Text.Ui>
              {itemPrice === 0 && (
                <Text.Ui size="lg" weight="bold" style={styles.freePrice}>
                  {itemPrice === 0 ? 'Free' : itemPrice / 100}
                </Text.Ui>
              )}
            </View>
            {showQuantitySelectors && (
              <View style={styles.quantityContainer}>
                <Pressable
                  style={styles.quantityButton}
                  onPress={() => {
                    if (item.quantity === 1) {
                      router.setParams({ action: 'remove-item', id: item.lineId });
                      return;
                    }

                    mutate({
                      lineId: item.lineId,
                      quantity: item.quantity - 1,
                    });
                  }}
                >
                  {item.quantity === 1 ? <IconDelete /> : <IconRemove />}
                </Pressable>
                <Text.Ui weight="bold" size="sm" style={{ width: 20, textAlign: 'center' }}>
                  {item.quantity}
                </Text.Ui>
                <Pressable
                  style={styles.quantityButton}
                  onPress={() =>
                    mutate({
                      lineId: item.lineId,
                      quantity: item.quantity + 1,
                    })
                  }
                >
                  <IconAdd />
                </Pressable>
              </View>
            )}
          </View>
        </XStack>
      </View>
      {canUsePoints && <PointsBar lineId={item.lineId} points={item.pointCost} />}
    </View>
  );
}

function CartItemModifierList({
  modifiers,
  instructions,
}: {
  modifiers: { id: string; displayName: string; price: number }[] | null | undefined;
  instructions?: string | null;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  // Instructions are to be added at the end of the modifier list
  // so we need to insert the instructions at the end.
  const modifiersWithInstructions = useMemo(() => {
    const mods = [...(modifiers ?? [])];
    if (instructions?.length) {
      mods.push({ id: 'instructions', displayName: instructions, price: 0 });
    }
    return mods;
  }, [modifiers, instructions]);
  const initialDisplayModifiers = modifiersWithInstructions?.slice(0, 2) ?? [];
  return (
    <View style={styles.modifierContainer}>
      {initialDisplayModifiers.map(modifier => {
        return (
          <Text.Ui size="md" key={modifier.id}>
            {modifier.displayName}
            {modifier.price > 0 ? `(+ $${(modifier.price / 100).toFixed(2)})` : ''}
          </Text.Ui>
        );
      })}
      {isExpanded &&
        (modifiers ?? []).slice(2).map(modifier => {
          return (
            <Text.Ui size="md" key={modifier.id}>
              {modifier.displayName}
            </Text.Ui>
          );
        })}
      {(modifiers?.length ?? 0) > 2 && (
        <Pressable onPress={() => setIsExpanded(s => !s)}>
          <Text.Ui size="sm" weight="bold" style={styles.seeMoreButton}>
            See {isExpanded ? 'Less' : 'More'}
          </Text.Ui>
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  seeMoreButton: {
    paddingTop: 8,
    textDecorationLine: 'underline',
  },
  modifierContainer: {
    paddingVertical: 8,
  },
  redeemContainer: {
    paddingHorizontal: 16,
    margin: 16,
    height: 52,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: tokens.colors.$blackOpacity04,
  },
  image: {
    width: 64,
    height: 56,
  },
  imageContainer: {
    borderRadius: 4,
    backgroundColor: tokens.colors.$blackOpacity04,
    width: 64,
    height: 56,
  },
  freePrice: {
    color: tokens.colors.$success,
  },
  crossedOutPrices: {
    color: tokens.colors.$disabledText,
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  pointsButton: {
    borderColor: tokens.colors.$black10,
    padding: 8,
  },
  pointsButtonText: {
    color: tokens.colors.$black,
  },
  quantityContainer: {
    backgroundColor: tokens.colors.$blackOpacity04,
    borderRadius: 18,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    height: 25,
    width: 84,
  },
  top: {
    padding: 16,
  },
  quantityButton: {
    width: 16,
    height: 16,
  },
  prices: {
    alignItems: 'flex-end',
  },
});
