import * as cognito from '@aws-amplify/auth/cognito';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/data';

import { Header } from '@fhs/backend';
import { type Schema } from '@fhs/backend/amplify/data/resource';
import { outputs } from '@fhs/backend/amplify-outputs';
import NativeAuthStorage from '@fhs-legacy/frontend/src/utils/cognito/storage';
import { getConfigValue } from '@fhs-legacy/frontend/src/utils/environment';
import WebLocalStorage from '@fhs-legacy/frontend/src/utils/local-storage';
import { StorageKeys } from '@fhs-legacy/frontend/src/utils/local-storage';

import { headers } from './headers';

const { userPoolClientId, userPoolId, region } = getConfigValue({
  key: 'aws',
  defaultValue: {},
});
const AUTH_FLOW = 'CUSTOM_AUTH';

const Auth = {
  Cognito: {
    userPoolId: userPoolId,
    userPoolClientId: userPoolClientId,
  },
};
cognito.cognitoUserPoolsTokenProvider.setKeyValueStorage(NativeAuthStorage as any);
cognito.cognitoUserPoolsTokenProvider.setAuthConfig(Auth);

Amplify.configure(
  {
    auth: {
      aws_region: region,
      authentication_flow_type: AUTH_FLOW,
      user_pool_id: userPoolId,
      user_pool_client_id: userPoolClientId,
    },
    ...outputs,
  },
  {
    Auth: {
      credentialsProvider: cognito.cognitoCredentialsProvider,
      tokenProvider: cognito.cognitoUserPoolsTokenProvider,
    },
    API: {
      GraphQL: {
        headers() {
          return Promise.resolve({
            ...headers,
            [Header.CART_ID]: WebLocalStorage.getItem(StorageKeys.CART_ID),
            [Header.DATETIME]: new Date().toISOString(),
          });
        },
      },
    },
  }
);
export const client = generateClient<Schema>();

export type { Schema };
