import { useIntl } from 'react-intl';
import { View } from 'react-native';

import {
  Button,
  IconArrowBack,
  IconArrowForward,
  MqSwitch,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

interface Props {
  onPressSeeAll: () => void;
  nextCarouselPage: () => void;
  prevCarouselPage: () => void;
  isNextDisabled: boolean;
  isPrevDisabled: boolean;
  hideCarouselButtons: boolean;
}

export function OffersSectionHeader({
  onPressSeeAll,
  nextCarouselPage,
  prevCarouselPage,
  isNextDisabled,
  isPrevDisabled,
  hideCarouselButtons,
}: Props) {
  const mqStyles = useMqStyles();
  const { formatMessage } = useIntl();

  return (
    <View style={mqStyles.container}>
      <Text.Heading type={useMqSelect({ $gteDesktop: 'one' }, 'three')}>
        {formatMessage({ id: 'offers' })}
      </Text.Heading>
      <MqSwitch
        $base={() => (
          <Button type="ghost" size="sm" onPress={onPressSeeAll}>
            <Button.Text>{formatMessage({ id: 'seeAll' })}</Button.Text>
            <Button.Icon>
              <IconArrowForward />
            </Button.Icon>
          </Button>
        )}
        $gteDesktop={() =>
          !hideCarouselButtons && (
            <View style={mqStyles.carouselButtonsContainer}>
              <Button
                style={mqStyles.carouselButton}
                type="ghost"
                size="sm"
                onPress={prevCarouselPage}
                disabled={isPrevDisabled}
                disabledStyle={mqStyles.carouselButtonDisabled}
              >
                <Button.Icon color={isPrevDisabled ? tokens.colors.$blackOpacity10 : undefined}>
                  <IconArrowBack />
                </Button.Icon>
              </Button>

              <Button
                style={mqStyles.carouselButton}
                type="ghost"
                size="sm"
                onPress={nextCarouselPage}
                disabled={isNextDisabled}
                disabledStyle={mqStyles.carouselButtonDisabled}
              >
                <Button.Icon color={isNextDisabled ? tokens.colors.$blackOpacity10 : undefined}>
                  <IconArrowForward />
                </Button.Icon>
              </Button>
            </View>
          )
        }
      />
    </View>
  );
}

const useMqStyles = createMqStyles({
  container: {
    $base: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingTop: 16,
    },
    $gteDesktop: {
      paddingTop: 32,
    },
  },
  carouselButtonsContainer: {
    $base: {
      flexDirection: 'row',
      gap: 16,
    },
  },
  carouselButton: {
    $base: {
      borderColor: tokens.colors.$blackOpacity10,
      justifyContent: 'space-around',
    },
  },
  carouselButtonDisabled: {
    $base: {
      borderColor: tokens.colors.$blackOpacity04,
    },
  },
});
