import { type ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { ImageAssetWithAltText } from '@fhs/ui';

import { ComboItemModifierDetails, MenuPickerData } from '../types';

export type SelectedPicker = {
  id: string;
  slug: string;
  // Derived
  displayName: string;
  priceCents: number;

  // Instructions
  instructions: string;

  // Picker
  pickerSelections: Record<string, string>; // Record<PickerAspectId, PickerAspectOptionId>

  // PickerAspectOption
  selectedPickerAspectOptionId: string;
  selectedPickerAspectOption: null | NonNullable<MenuPickerData['pickerAspect']>['options'][number];

  // Customizations
  customizationSelections: Record<string, string | Record<string, number>>;
  defaultCustomizationSelections: Record<string, string | Record<string, number>>;
  draftCustomizationSelections: Record<string, string | Record<string, number>>;
  requiresCustomization: boolean;
  customizationDetailedSelections: Record<string, ComboItemModifierDetails>;

  // UI
  image: ImageAssetWithAltText;
};

export type ComboSelectionContext = {
  isComboEnabled: boolean;
  selectedPickers: Record<string, SelectedPicker>;
  isEditingPickers: boolean;
  setIsComboEnabled: (enabled: boolean) => void;
  setSelectedPickers: (pickers: Record<string, SelectedPicker>) => void;
  addOrUpdateSelectedPicker: (step: string, picker: SelectedPicker) => void;
  setIsEditingPickers: (editing: boolean) => void;
  isComboGuideOpen: boolean;
  setIsComboGuideOpen: (value: boolean) => void;
};

type ComboSelectionProviderProps = {
  children?: ReactNode;
};

const comboSelectionContext = createContext<ComboSelectionContext | null>(null);

export const ComboSelectionProvider = ({ children }: ComboSelectionProviderProps) => {
  const [isComboEnabled, setIsComboEnabled] = useState(false);
  const [selectedPickers, setSelectedPickers] = useState<Record<string, SelectedPicker>>({});
  const [isEditingPickers, setIsEditingPickers] = useState(false);
  const [isComboGuideOpen, setIsComboGuideOpen] = useState(false);

  const addOrUpdateSelectedPicker = useCallback(
    (step: string, picker: SelectedPicker) => {
      setSelectedPickers(prevPickers => ({
        ...prevPickers,
        [step]: picker, // Add or replace the picker for the given step
      }));
    },
    [setSelectedPickers]
  );

  const value: ComboSelectionContext = {
    selectedPickers,
    setSelectedPickers,
    addOrUpdateSelectedPicker,
    isEditingPickers,
    setIsEditingPickers,
    isComboEnabled,
    setIsComboEnabled,
    isComboGuideOpen,
    setIsComboGuideOpen,
  };

  return <comboSelectionContext.Provider value={value}>{children}</comboSelectionContext.Provider>;
};

export const useComboSelectionContext = (): ComboSelectionContext => {
  const context = useContext(comboSelectionContext);

  if (!context) {
    throw new Error(
      'useComboSelectionContext can only be called from within a descendant of ComboSelectionProvider'
    );
  }

  return context;
};
