import { useIntl } from 'react-intl';

import { IRestaurantNode } from 'generated/rbi-graphql';
import { useMemoWithCompare } from 'hooks/use-memo-with-compare';
import { useServiceModeStatus } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { ServiceMode, ServiceModeStatus } from 'state/service-mode/types';

export const serviceModeToIntlId: Record<ServiceMode, string> = {
  [ServiceMode.CATERING_DELIVERY]: 'cateringDelivery',
  [ServiceMode.CATERING_PICKUP]: 'cateringPickUp',
  [ServiceMode.CURBSIDE]: 'curbside',
  [ServiceMode.DELIVERY]: 'delivery',
  [ServiceMode.DRIVE_THRU]: 'driveThru',
  [ServiceMode.EAT_IN]: 'eatIn',
  [ServiceMode.TAKEOUT]: 'pickUp',
  [ServiceMode.PICKUP_WINDOW]: 'pickupWindow',
};

// Store the order and which service modes will display in the text.
const serviceModeOrder: ServiceMode[] = [
  ServiceMode.DRIVE_THRU,
  ServiceMode.TAKEOUT,
  ServiceMode.EAT_IN,
  ServiceMode.CURBSIDE,
  ServiceMode.CATERING_PICKUP,
  ServiceMode.PICKUP_WINDOW,
];

interface UseServiceModeTextOptions {
  restaurant: IRestaurantNode;
}

const isAvailable =
  (serviceModeStatus: ServiceModeStatus, isFutureOrderingEnabled = false) =>
  (serviceMode: ServiceMode): boolean => {
    const status = serviceModeStatus[serviceMode];

    if (!status) {
      return false;
    }

    if (isFutureOrderingEnabled) {
      return status.capable && !status.disabled;
    }

    return status.available && !status.disabled;
  };

export const useServiceModeText = ({
  restaurant,
}: UseServiceModeTextOptions): string | undefined => {
  const { formatMessage } = useIntl();
  const { serviceModeStatus } = useServiceModeStatus(restaurant);
  const enableFutureOrdering = useFlag(LaunchDarklyFlag.ENABLE_FUTURE_ORDERING);

  const serviceModes = serviceModeOrder.filter(
    isAvailable(serviceModeStatus, enableFutureOrdering)
  );

  return useMemoWithCompare(() => {
    if (!serviceModes.length) {
      return;
    }

    return serviceModes
      .map(serviceMode => formatMessage({ id: serviceModeToIntlId[serviceMode] }))
      .join(', ');
  }, [serviceModes]);
};
