import type { ReactNode } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import {
  IconChevronLeft,
  IconClose,
  MqSwitch,
  Text,
  XStack,
  type XStackProps,
  YStack,
  tokens,
} from '@fhs/ui';
import { withStaticProperties } from '@fhs/utils';

type GuideHeadingProps = Omit<XStackProps, 'children' | 'style'> & {
  text: string;
  badge: ReactNode;
  style?: StyleProp<ViewStyle>;
  onPressPrev: () => void;
  onClose: () => void;
  disableBackButton: boolean;
};
function Heading({
  style,
  text,
  badge,
  onPressPrev,
  onClose,
  disableBackButton,
  ...props
}: GuideHeadingProps) {
  return (
    <MqSwitch
      $base={() => (
        <YStack>
          <View style={styles.topContainerBase}>
            <TouchableOpacity
              style={{ justifyContent: 'center' }}
              onPress={onPressPrev}
              disabled={disableBackButton}
            >
              <IconChevronLeft
                size={24}
                color={disableBackButton ? tokens.colors.$blackOpacity10 : undefined}
              />
            </TouchableOpacity>
            <XStack style={styles.baseHeader}>
              <XStack {...props} style={[styles.baseHeadingContainer, style]}>
                <Text.Heading type="four">Combo Selections</Text.Heading>
                {badge}
              </XStack>
            </XStack>
            <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onClose}>
              <IconClose size={24} />
            </TouchableOpacity>
          </View>
          <XStack style={styles.baseSubtitleContainer}>
            <Text weight="semibold" style={styles.baseSubtitle}>
              Choose Your {text}
            </Text>
          </XStack>
        </YStack>
      )}
      $gteDesktop={() => (
        <XStack {...props} style={[styles.desktopHeadingContainer, style]}>
          <XStack style={styles.topContainerDesktop}>
            <TouchableOpacity
              style={{ justifyContent: 'center' }}
              onPress={onPressPrev}
              disabled={disableBackButton}
            >
              <IconChevronLeft
                size={24}
                color={disableBackButton ? tokens.colors.$blackOpacity10 : undefined}
              />
            </TouchableOpacity>
            <XStack style={styles.desktopHeader}>
              <XStack {...props} style={[styles.desktopSubtitleContainer, style]}>
                <Text.Heading type="two" style={{ marginRight: 12 }}>
                  Choose Your {text}
                </Text.Heading>
                {badge}
              </XStack>
            </XStack>
            <TouchableOpacity style={{ justifyContent: 'center' }} onPress={onClose}>
              <IconClose size={24} />
            </TouchableOpacity>
          </XStack>
        </XStack>
      )}
    />
  );
}

type GuideHeadingStepBadgeProps = {
  currentStep: number;
  totalSteps: number;
  style?: StyleProp<ViewStyle>;
};
function HeadingStepBadge({ style, currentStep, totalSteps }: GuideHeadingStepBadgeProps) {
  return (
    <View style={[styles.headingStepBadge, style]}>
      <Text.Paragraph size="sm">
        <Text.Paragraph weight="semibold" size="sm">
          {currentStep}
        </Text.Paragraph>{' '}
        / {totalSteps}
      </Text.Paragraph>
    </View>
  );
}

export const GuideHeading = withStaticProperties(Heading, {
  StepBadge: HeadingStepBadge,
});

const styles = StyleSheet.create({
  headingStepBadge: {
    paddingHorizontal: 8,
    backgroundColor: tokens.colors.$houseLight,
    borderRadius: 4,
    minHeight: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  baseHeadingContainer: {
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  desktopHeadingContainer: {
    gap: 12,
    textAlign: 'left',
    alignItems: 'center',
  },
  desktopSubtitleContainer: {
    flex: 1,
    marginLeft: 10,
  },
  baseSubtitleContainer: {
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: tokens.colors.$blackOpacity04,
    flex: 1,
  },
  baseSubtitle: {
    fontSize: 18,
  },
  itemStepsContainer: {
    backgroundColor: tokens.colors.$houseLight,
    paddingHorizontal: 8,
    height: 24,
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    marginLeft: 8,
  },
  baseHeader: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  desktopHeader: {
    justifyContent: 'flex-start',
    flex: 1,
  },
  topContainerBase: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topContainerDesktop: {
    flexDirection: 'row',
    flex: 1,
    padding: 28,
    borderBottomWidth: 1,
    borderColor: tokens.colors.$black10,
  },
});
