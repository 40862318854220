import { ScrollView, StyleProp, ViewStyle } from 'react-native';

import { MqSwitch, createMqStyles } from '@fhs/ui';

import { OfferCardSkeleton } from '../../components';

interface Props {
  style?: StyleProp<ViewStyle>;
}

export function OffersSkeleton({ style }: Props) {
  const mqStyles = useMqStyles();

  return (
    <ScrollView
      horizontal
      style={[mqStyles.emptyList, style]}
      contentContainerStyle={mqStyles.emptyListContent}
      showsHorizontalScrollIndicator={false}
    >
      <OfferCardSkeleton />
      <OfferCardSkeleton />
      <OfferCardSkeleton />
      <OfferCardSkeleton />
      <MqSwitch $ltDesktop={() => <OfferCardSkeleton />} />
    </ScrollView>
  );
}

const useMqStyles = createMqStyles({
  emptyList: {
    $base: {
      flexGrow: 0,
    },
  },
  emptyListContent: {
    $base: {
      gap: 12,
    },
    $gteDesktop: {
      width: 908,
    },
  },
});
