import delv from 'dlv';
import { router } from 'expo-router';
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { ScrollView, Text, VStack } from '@fhs-legacy/universal-components';
import ErrorBoundary from 'components/error-boundary';
import LegalDisclaimers from 'components/legal-disclaimers';
import { Hero } from 'components/menu-order-wizard/hero';
import { WizardCtaWrapper } from 'components/menu-order-wizard/wizard-cta-wrapper';
import NutritionModal from 'components/nutrition';
import { AllergensDisclaimer } from 'components/nutrition/allergens-disclaimer';
import { useNutritionFacts } from 'components/nutrition/use-nutrition-facts';
import PriceAndCalories from 'components/price-and-calories';
import { ReadMoreAccordion } from 'components/read-more-accordion';
import BlockRenderer from 'components/sanity-block-renderer';
import SelectionContainer from 'components/selection-container';
import { TextWithArrowButton } from 'components/text-with-arrow-button';
import { NumberInput } from 'components/ucl/number-input';
import { VisuallyHidden } from 'components/ucl/visually-hidden';
import { useIsDesktopBp } from 'hooks/breakpoints';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnce from 'hooks/use-effect-once';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';
import { useFormatCalories } from 'hooks/use-format-calories';
import { useFormatJoules } from 'hooks/use-format-joules';
import { useIsEditCartEnabled } from 'hooks/use-is-edit-cart-enabled';
import { usePathname } from 'hooks/use-pathname';
import { useSwitchCartMethodDialog } from 'hooks/use-switch-cart-method-dialog';
import { useDayPartContext } from 'state/day-part';
import { useErrorContext } from 'state/errors';
import { useFavoritesContext } from 'state/favorites';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useMenuContext } from 'state/menu';
import { useMenuUIContext } from 'state/menu-ui';
import { useOrderContext } from 'state/order';
import useProductWizardFlow from 'state/product-wizard/hooks/use-product-wizard-flow';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { MIN_CART_QUANTITY, getMenuObjectCartQuantity } from 'utils/cart';
import { HapticsImpactStyle, hapticImpact } from 'utils/haptic';
import {
  buildCalorieMapForPickerPossibilities,
  computeCurrentCalories,
  computeCurrentPrice,
  computeSelectedOption,
} from 'utils/menu';
import { useIsBrowseMenu } from 'utils/menu/hooks/use-is-browse-menu';
import { ModifierComponentStyle } from 'utils/menu/modifiers';
import { routes } from 'utils/routing';
import {
  calculateTotalSteps,
  getSelectionsFromMenuData,
  transformMenuObjectToCartItem,
} from 'utils/wizard';
import { getRequirementsErrors } from 'utils/wizard/requirements';

import { CtaButton } from './cta-button';
import { useMenuItemStatus } from './hooks/use-menu-item-status';
import { ItemUI } from './item-ui';
import { ParallelUI } from './parallel-ui';
import { modifierSelectionsReducer } from './reducers/modifier-selection';
import { useWizard } from './wizard-provider';
import {
  getChosenPickerAspectOptions,
  getDescriptionText,
  getTitleText,
} from './wizard-ui-helpers';
import {
  CustomizationContainer,
  Description,
  DescriptionTitle,
  Guide,
  ImageDisclaimer,
  Information,
  InformationContainer,
  OptionTile,
  Title,
  fixedButtonCSS,
  regularButtonCSS,
} from './wizard.styled';

const Wizard = ({
  upsertCart,
  currentCartEntryId,
  getCurrentCartEntry,
  data,
  loading,
  priceForItemOptionModifier,
  pricingFunction,
}) => {
  const { id: currentMenuItemId } = useProductWizardFlow();
  const { pathname } = usePathname();
  const { setSanityDataRef } = useErrorContext();
  const isDesktop = useIsDesktopBp();
  const { formatCurrencyForLocale } = useUIContext();
  const { setActiveCartEntries } = useMenuUIContext();
  const { activeDayParts, dayParts } = useDayPartContext();
  const { showStaticMenu, setSelectedStaticMenuItem } = useMenuContext();
  const {
    selectFavorite,
    currentCartEntry: currentFavoriteEntry,
    tempFavorite: currentFavorite,
  } = useFavoritesContext();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { images: imagesDisclaimer } = featureDisclaimers || {};

  const displayNutritionWithModifiersFromSanity = useFlag(
    LaunchDarklyFlag.DISPLAY_NUTRITION_WITH_MODIFIERS_FROM_SANITY
  );
  const enableAllergensDisclaimer = useFlag(LaunchDarklyFlag.ENABLE_ALLERGENS_DISCLAIMER);
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  // TODO: do we need this for TH?
  const enableEUNutritionDisplay = useFlag(LaunchDarklyFlag.ENABLE_EU_NUTRITION_DISPLAY);
  const enableCaloriesCalculator = useFlag(LaunchDarklyFlag.ENABLE_CALORIES_CALCULATOR);
  // TODO: do we need this for TH?
  const enableFixedAddToOrderAndQuantityButton = useFlag(
    LaunchDarklyFlag.ENABLE_FIXED_ADD_TO_ORDER_AND_QUANTITY_BUTTON
  );

  const { formatMessage } = useIntl();
  const formatCalories = useFormatCalories();
  // TODO: remove this if removing EU flag
  const formatJoules = useFormatJoules();
  const [pickerSelections, setPickerSelections] = useState({});
  const [modifierSelections, modifierDispatch] = useReducer(modifierSelectionsReducer, []);
  const [modifierFifoCounter, setModifierFifoCounter] = useState(0);

  // Track the modification of item, combo slot and picker aspect.
  // These values are initially set to false and is set to true if the user
  // changes these values away from the initial state.
  const addToCartEventAttrs = useRef({
    itemModified: false,
    // TODO: RN - can we get rid of this for TH?
    comboSlotSelection: false,
    pickerAspectSelection: false,
  });

  const [quantity, setQuantity] = useState(1);

  const [cta, setCta] = useState({
    children: [],
    onClick: () => {},
  });
  const [shouldConfirmChange, setShouldConfirmChange] = useState(false);

  const [EditingPickerAspectChangeModal, openEditingPickerAspectChangeModal] = useDialogModal({
    onConfirm: ({ pickerAspectKey, aspectIdentifier }) => {
      handlePickerSelectionNoConfirmation(pickerAspectKey, aspectIdentifier);
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Remove customizations',
  });

  // TODO: remove this bc TH always uses ParallelUI
  const {
    state: { currentStep, isFinalStep },
    actions: { setTotalSteps },
  } = useWizard();

  const { isEdit, isFavorite, isFromCart } = useProductWizardFlow();
  // NOTE: cartHasIncentive will always be false for TH
  const enableEditCart = useIsEditCartEnabled({ cartHasIncentive: false });
  const enableFavorites = useFlag(LaunchDarklyFlag.ENABLE_FAVORITES);
  const isEditing = useMemo(() => {
    // We are only editing favorites if isFavorite flag and currently editing a favorite
    // If its a favorite only return true if favorites is enabled
    if (isFavorite && currentFavorite) {
      return enableFavorites;
    }

    return enableEditCart ? isEdit && currentCartEntryId : false;
  }, [isFavorite, currentFavorite, enableEditCart, isEdit, currentCartEntryId, enableFavorites]);
  const selectionsEntry = useMemo(() => {
    if (!isEditing) {
      return undefined;
    }

    // NOTE: getCurrentCartEntry is a shared util which expects an arg for `isOffer`
    // TH does not have offers as menu items so this is always `false` in TH menu.
    return currentFavorite ? currentFavoriteEntry : getCurrentCartEntry(false);
  }, [currentFavoriteEntry, getCurrentCartEntry, isEditing, currentFavorite]);
  const { cartId: cartIdEditing = '' } = selectionsEntry ?? {};

  useEffect(() => {
    setSanityDataRef({ data });
  }, [data, setSanityDataRef]);

  const setModifiers = useCallback((selections, reset = false) => {
    if (reset) {
      // Anytime you input derived selections using `getSelectionsFromMenuData`
      //  it will make sure every selections is a valid option for the current item
      // We can just clear out the current state and use the derived state instead
      modifierDispatch({
        type: 'CLEAR_ALL_SELECTIONS',
      });
    }
    (selections ?? []).forEach(selection => {
      setModifierFifoCounter(prevCount => {
        modifierDispatch({
          ...selection,
          fifoCounter: prevCount,
          type: 'DEFAULT_SELECTION',
        });

        return prevCount + selection.option.maxAmount;
      });
    });
  }, []);

  const setDefaultSelections = useCallback(
    ({ useUserSelections = false, pickerSelectionOverrides }) => {
      const derivedState = getSelectionsFromMenuData({
        data,
        ...(useUserSelections && selectionsEntry && { selectionsEntry }),
        ...(pickerSelectionOverrides && { pickerSelectionOverrides }),
      });
      // Always reset these if the getSelectionsFromMenuData returns undefined
      if (useUserSelections) {
        setQuantity(derivedState.quantity ?? 1);
      }
      setPickerSelections(derivedState.pickerSelections ?? {});
      setModifiers(derivedState.modifierSelections ?? [], true);
    },
    [data, selectionsEntry, setModifiers]
  );

  // Anytime data changes or the cartEntry we are editing swaps out we need to reset everything
  // NOTE: for cart preview on desktop you can change the item you are editing while you have wizard open
  useEffect(() => {
    // reset confirmation anytime the thing we are customizing changes
    setShouldConfirmChange(true);
    // Get the derived state using the users selections if any
    setDefaultSelections({ useUserSelections: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMenuItemId, currentCartEntryId]);

  // Recalculate the total number of steps any time the pickerSelection changes
  useEffect(() => {
    if (!loading && data) {
      setTotalSteps(calculateTotalSteps(data, pickerSelections));
    }
  }, [data, loading, pickerSelections, setTotalSteps]);

  const handlePickerSelectionNoConfirmation = useCallback(
    (pickerAspectKey, aspectIdentifier) => {
      setPickerSelections(currentPickerSelections => {
        const newPickerSelections = {
          ...currentPickerSelections,
          [pickerAspectKey]: aspectIdentifier,
        };
        setDefaultSelections({ pickerSelectionOverrides: newPickerSelections });
        return newPickerSelections;
      });
      // Make sure we don't show the confirmation again until something resets our components state
      setShouldConfirmChange(false);
    },
    [setDefaultSelections]
  );

  const handlePickerSelection = useCallback(
    (pickerAspectKey, aspectIdentifier) => {
      if (pickerSelections[pickerAspectKey] !== aspectIdentifier) {
        addToCartEventAttrs.current.pickerAspectSelection = true;
      }

      // if we are editing and we haven't reset the selections yet show confirmation modal
      if (selectionsEntry && shouldConfirmChange) {
        openEditingPickerAspectChangeModal({ pickerAspectKey, aspectIdentifier });
      } else {
        handlePickerSelectionNoConfirmation(pickerAspectKey, aspectIdentifier);
      }
    },
    [
      handlePickerSelectionNoConfirmation,
      openEditingPickerAspectChangeModal,
      pickerSelections,
      selectionsEntry,
      shouldConfirmChange,
    ]
  );

  const handleModifierSelection = useCallback(
    (itemId, modifier, itemOption) => {
      addToCartEventAttrs.current.itemModified = true;
      // boolean componentStyles let you maintain an on/off selection of an itemOption.options.
      // there is an expected heuristic that option[0] === off and option[1] === on.
      // TODO: RN - does TH menu need to support Boolean?
      if (itemOption.componentStyle === ModifierComponentStyle.Boolean) {
        modifierDispatch({
          itemId,
          option: itemOption,
          type: 'TOGGLE_BOOLEAN_SELECTION',
        });
      }
      // stepper componentStyles let you maintain one selection of many options by moving along
      // the index of the options.
      else if (itemOption.componentStyle === ModifierComponentStyle.Stepper) {
        modifierDispatch({
          itemId,
          modifier,
          option: itemOption,
          type: 'MOVE_STEPPER_SELECTION',
        });
      }
      // selector componentStyles let you add and remove child options based on min/maxAmount
      // logic, and fifo (first in, first out) counters. 'selector' is the default componentStyle
      // when it is left empty in sanity.
      else if (
        !itemOption.componentStyle ||
        itemOption.componentStyle === ModifierComponentStyle.Selector
      ) {
        const isModifierSelected = !!modifierSelections.find(
          selection =>
            selection.itemId === itemId &&
            selection._key === itemOption._key &&
            selection.modifier._key === modifier._key
        );

        modifierDispatch({
          fifoCounter: !isModifierSelected && modifierFifoCounter,
          itemId,
          modifier,
          option: itemOption,
          type: isModifierSelected ? 'REMOVE_SELECTION' : 'ADD_SELECTION',
        });

        if (!isModifierSelected) {
          setModifierFifoCounter(prevState => prevState + 1);
        }
      }
    },
    [modifierSelections, modifierFifoCounter]
  );

  const handleModifierQuantityUpdate = useCallback(
    (newQuantity, itemOptionKey, itemId, key, oldQuantity, maxAmount) => {
      const isIncrease = newQuantity > oldQuantity;
      addToCartEventAttrs.current.itemModified = true;
      modifierDispatch({
        fifoCounter: modifierFifoCounter,
        itemId,
        modifier: { _key: key },
        option: { _key: itemOptionKey, maxAmount },
        quantity: newQuantity,
        type: isIncrease ? 'ADD_QUANTITY' : 'REMOVE_QUANTITY',
      });
      // only increment on adds, we don't care when they remove
      if (isIncrease) {
        setModifierFifoCounter(prevState => prevState + 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modifierSelections, modifierFifoCounter]
  );

  // This keeps activeCartEntries up to date with the latest pending changes.
  // activeCartEntries is a pending state to know what the current selections will output
  useEffect(() => {
    try {
      if (!loading && data) {
        const selectedOption = computeSelectedOption(pickerSelections, data);
        // We were unable to calculate the selectedOption, most likely because there are no options available to order
        if (!selectedOption) {
          return;
        }

        const { mainEntry, upsellEntries } = transformMenuObjectToCartItem(
          selectedOption,
          priceForItemOptionModifier,
          pricingFunction,
          // NOTE: expects priceForComboSlotSelection, but is never needed for TH
          undefined,
          {
            quantity,
            modifierSelections,
            pickerSelections,
            pathname,
            quickConfigs: data?.quickConfigs,
            existingCartId: selectionsEntry?.cartId,
          }
        );

        setActiveCartEntries([mainEntry, ...upsellEntries]);
      }
    } catch (error) {
      // No need to handle this error
    }
  }, [
    setActiveCartEntries,
    data,
    loading,
    pickerSelections,
    modifierSelections,
    selectionsEntry,
    priceForItemOptionModifier,
    pricingFunction,
    quantity,
    pathname,
  ]);

  const addToOrder = useCallback(() => {
    const { mainEntry, upsellEntries } = transformMenuObjectToCartItem(
      computeSelectedOption(pickerSelections, data),
      priceForItemOptionModifier,
      pricingFunction,
      // NOTE: expects priceForComboSlotSelection, but is never needed for TH
      undefined,
      {
        quantity,
        modifierSelections,
        pickerSelections,
        pathname,
        quickConfigs: data?.quickConfigs,
        existingCartId: selectionsEntry?.cartId,
      }
    );
    // Don't include the main entry if its an offer that gets set separately in offerCtx
    const newCartEntries = [mainEntry, ...upsellEntries];

    // If we are currently editing a favorite
    if (currentFavorite) {
      return selectFavorite({ cartEntries: newCartEntries });
    }

    // Only update the cart if we actually have anything to add.
    if (newCartEntries.length) {
      upsertCart(newCartEntries, addToCartEventAttrs.current);
    }

    hapticImpact({ style: HapticsImpactStyle.Heavy });

    // if editing and user coming from the cart
    if (selectionsEntry && isFromCart) {
      return router.navigate(routes.cart);
    }

    router.navigate(routes.menu);
  }, [
    pickerSelections,
    data,
    priceForItemOptionModifier,
    pricingFunction,
    quantity,
    modifierSelections,
    pathname,
    selectionsEntry,
    currentFavorite,
    isFromCart,
    selectFavorite,
    upsertCart,
  ]);

  const setModalCta = useCallback(
    ({ children, onClick }) =>
      setCta({
        children,
        onClick,
      }),
    []
  );

  // While editing in the cart preview while wizard is open we end up in a single render where there are pickerSelections but no pickerAspects in the data
  // Avoid reducing over the pickerSelections if the data has no pickerAspects
  const excludesForPickerAspects = data?.pickerAspects
    ? Object.keys(pickerSelections).reduce((acc, selection) => {
        const { pickerAspectItemOptionMappings, pickerAspects } = data;
        const pickerAspect = pickerAspects.find(({ _id }) => _id === selection);

        if (!pickerAspect) {
          return acc;
        }

        const itemOptionMappings = (pickerAspectItemOptionMappings || []).find(
          ({ pickerAspect: { _id } }) => _id === pickerAspect._id
        );

        if (!itemOptionMappings) {
          return acc;
        }

        return acc.concat(itemOptionMappings.options || []);
      }, [])
    : [];

  const calorieMap = useMemo(
    () => buildCalorieMapForPickerPossibilities(data, displayNutritionWithModifiersFromSanity),
    [data, displayNutritionWithModifiersFromSanity]
  );

  const selectedPicker = computeSelectedOption(pickerSelections, data);

  const renderDisplay = useCallback(
    (info, presentPrice) => {
      const addToOrderText = formatMessage({
        id: selectionsEntry ? 'updateCart' : 'addToCart',
      });

      if (info._type === 'picker') {
        return (
          <ErrorBoundary>
            <ParallelUI
              addToOrder={addToOrder}
              addToOrderText={addToOrderText}
              calorieMap={calorieMap}
              currentPrice={presentPrice}
              excludesForPickerAspects={excludesForPickerAspects}
              handleModifierQuantityUpdate={handleModifierQuantityUpdate}
              handleModifierSelection={handleModifierSelection}
              handlePickerSelection={handlePickerSelection}
              modifierSelections={modifierSelections}
              pickerAspects={info.pickerAspects || []}
              pickerOptions={info.options}
              pickerSelection={selectedPicker}
              quickConfigs={info.quickConfigs || []}
              selectedPickerIdentifiers={pickerSelections}
              setModalCta={setModalCta}
            />
          </ErrorBoundary>
        );
      } else if (info._type === 'item') {
        return (
          <ErrorBoundary>
            {!showStaticMenu && (
              <ItemUI
                addToOrder={addToOrder}
                addToOrderText={addToOrderText}
                currentPrice={presentPrice}
                excludesForPickerAspects={excludesForPickerAspects}
                handleModifierQuantityUpdate={handleModifierQuantityUpdate}
                handleModifierSelection={handleModifierSelection}
                item={info}
                modifierSelections={modifierSelections}
                setModalCta={setModalCta}
              />
            )}
          </ErrorBoundary>
        );
      }
      return null;
    },
    [
      formatMessage,
      selectionsEntry,
      calorieMap,
      addToOrder,
      excludesForPickerAspects,
      handleModifierQuantityUpdate,
      handleModifierSelection,
      handlePickerSelection,
      modifierSelections,
      selectedPicker,
      pickerSelections,
      setModalCta,
      showStaticMenu,
    ]
  );

  const [baseTotal, extraTotal] = computeCurrentPrice({
    item: data,
    modifierSelections,
    pickerSelections,
    pricingFunction,
    // NOTE: TH does not use offers in menu
    isOffer: false,
    selectedOffer: null,
    getOfferPrice: () => {},
    quantity,
  });

  const totalPrice = baseTotal + extraTotal;
  const currentPrice = formatCurrencyForLocale(totalPrice);
  // If price is not set correctly, don't allow adding such an item to cart
  const isCurrentPriceValid = totalPrice > 0;

  const chosenPickerAspectOptions = getChosenPickerAspectOptions({
    pickerAspects: data.pickerAspects,
    pickerSelections,
    step: currentStep,
    uiPattern: data.uiPattern,
  });
  const titleText = getTitleText(data?.name?.locale, chosenPickerAspectOptions);

  const itemDayParts =
    data?.operationalItem?.daypart || // dayparts on an individual item
    data?.options?.find(pickable => pickable.option?.operationalItem?.daypart)?.option
      ?.operationalItem?.daypart || // dayparts on an item picker
    data?.options?.find(pickable => pickable.option?.mainItem)?.option?.mainItem?.operationalItem
      ?.daypart || // dayparts on the main item of a combo
    [];

  const isItemAvailableForDaypart =
    !itemDayParts.length || // item not associated with any daypart
    !dayParts.length || // brand has no dayparts
    activeDayParts.some(({ key: dayPart }) => itemDayParts.includes(dayPart));

  const { isBrowseMenu } = useIsBrowseMenu(pathname);
  const { isStoreOpenAndAvailable } = useStoreContext();

  const handleFindRestaurantPress = () => {
    const { _type, _id, name } = data || {};
    setSelectedStaticMenuItem({
      itemId: `${_type}-${_id}`,
      itemName: name?.locale,
      // TODO: expo-router; how to get full search query a
      // search: location.search,
    });
    const redirectRoute =
      isBrowseMenu && isStoreOpenAndAvailable ? routes.menu : routes.storeLocator;
    router.navigate(redirectRoute);
  };

  const descriptionText = getDescriptionText(chosenPickerAspectOptions);

  const pickerSelection = computeSelectedOption(pickerSelections, data);

  const nutritionFacts = useNutritionFacts({
    pickerSelection,
    itemName: titleText,
    itemImage: data?.image,
    displayNutritionWithModifiersFromSanity,
  });

  const nutritionWithModifiers =
    pickerSelection?.mainItem?.nutritionWithModifiers ||
    delv(pickerSelection || data, 'nutritionWithModifiers') ||
    {};

  const currentCalories = displayNutritionWithModifiersFromSanity
    ? nutritionWithModifiers.calories || 0
    : computeCurrentCalories({
        item: data,
        modifierSelections,
        pickerSelections,
        quantity,
      });

  const formattedCalories = formatCalories(currentCalories);
  // TODO: decide if we need EU nutrition support
  const formattedJoules = formatJoules(currentCalories);
  const euFormattedNutritionText = formatMessage(
    { id: 'euNutritionValues' },
    {
      calories: formattedCalories,
      joules: formattedJoules,
    }
  );
  const calories = enableEUNutritionDisplay ? euFormattedNutritionText : formattedCalories;

  useEffect(() => {
    if (!calories) {
      return;
    }
  }, [calories]);

  const hideCalories = !!data.hideCalories;

  const { cartEntries } = useOrderContext() ?? {};
  const { availableCartQuantity, maxCartQuantityMet: isLimitReached } = getMenuObjectCartQuantity({
    menuObject: pickerSelection || data,
    quantityToAdd: quantity,
    cartEntries,
    cartIdEditing,
  });

  const maxCartQuantityMet = isLimitReached && !isEditing;

  // When changing pickerSelection we need to cap the quantity to the current pick max quantity
  if (!maxCartQuantityMet && availableCartQuantity < quantity) {
    setQuantity(availableCartQuantity);
  }

  const itemStatus = useMenuItemStatus({
    showStaticMenu,
    enableOrdering,
    isItemAvailableForDaypart,
    maxCartQuantityMet,
  });

  const showActionOrMessageInBottom = !isDesktop && enableFixedAddToOrderAndQuantityButton;
  const buttonCSS = showActionOrMessageInBottom ? fixedButtonCSS : regularButtonCSS;

  const showIncrementor = !showStaticMenu && !maxCartQuantityMet && isItemAvailableForDaypart;
  const incrementor = (
    <>
      {!showActionOrMessageInBottom && <Text>{currentPrice}</Text>}
      <NumberInput
        displayedValue={quantity}
        value={quantity}
        onChange={setQuantity}
        max={availableCartQuantity}
        min={MIN_CART_QUANTITY}
        accessibilityLabel={`Quantity ${quantity} of ${titleText}`}
        decrementAriaLabel={`decrement-${titleText}`}
        incrementAriaLabel={`increment-${titleText}`}
        disableIncrement={maxCartQuantityMet}
        disabled={maxCartQuantityMet}
      />
      <VisuallyHidden
        role="alert"
        accessibilityLabel={formatMessage(
          { id: 'quantityUpdatedAlert' },
          {
            quantity: quantity.toString(),
            itemName: data?.name?.locale ?? '',
          }
        )}
      />
    </>
  );

  const getStickyFooterElements = () => {
    const elements = [];
    if (showIncrementor) {
      elements.push({
        element: incrementor,
      });
    }
    elements.push({
      element: (
        <CtaButton
          buttonCSS={buttonCSS}
          cta={cta}
          currentPrice={currentPrice}
          dayParts={dayParts}
          handleFindRestaurantClick={handleFindRestaurantPress}
          isCurrentPriceValid={isCurrentPriceValid}
          isEditing={isEditing}
          isFinalStep={isFinalStep}
          itemDayParts={itemDayParts}
          itemStatus={itemStatus}
          quantity={quantity}
          requirementsErrors={getRequirementsErrors(selectedPicker, modifierSelections)}
          showActionOrMessageInBottom={showActionOrMessageInBottom}
          titleText={titleText}
        />
      ),
      spaces: showIncrementor ? 2 : 1,
    });
    return elements;
  };

  return (
    <VStack height="full">
      <ScrollView>
        <Hero image={data.image} alt={data.imageDescription?.locale} />
        <Guide>
          <CustomizationContainer>
            <DescriptionTitle tabIndex="0">
              <Title testID="wizard-name" id="modal-heading">
                {titleText}
              </Title>
              <PriceAndCalories
                currentPrice={currentPrice}
                formattedCalories={calories}
                shouldHideCalories={!enableCaloriesCalculator || hideCalories}
                shouldHidePrice={!!showStaticMenu}
              />
            </DescriptionTitle>

            <ReadMoreAccordion>
              <Description testID="wizard-description">
                {data?.description?.localeRaw && (
                  <BlockRenderer content={data?.description?.localeRaw} />
                )}
                {descriptionText ? <Text>{descriptionText}</Text> : null}
              </Description>
            </ReadMoreAccordion>
          </CustomizationContainer>

          <CustomizationContainer>
            <EditingPickerAspectChangeModal
              testID="picker-aspect-confirmation"
              heading={formatMessage({ id: 'makeThisChange' })}
              buttonLabel={formatMessage({ id: 'continue' })}
              body={formatMessage({ id: 'removeCustomizations' }, { itemType: 'meal' })}
            />

            {renderDisplay(data, currentPrice)}

            <VisuallyHidden
              accessibilityLabel={`The next step will be to customize your ${titleText}`}
            />

            {showIncrementor && !showActionOrMessageInBottom && (
              <SelectionContainer required={false} title={formatMessage({ id: 'selectQuantity' })}>
                <OptionTile>{incrementor}</OptionTile>
              </SelectionContainer>
            )}

            {!showActionOrMessageInBottom && (
              <CtaButton
                buttonCSS={buttonCSS}
                cta={cta}
                currentPrice={currentPrice}
                dayParts={dayParts}
                handleFindRestaurantClick={handleFindRestaurantPress}
                isCurrentPriceValid={isCurrentPriceValid}
                isEditing={isEditing}
                isFinalStep={isFinalStep}
                itemDayParts={itemDayParts}
                itemStatus={itemStatus}
                quantity={quantity}
                requirementsErrors={getRequirementsErrors(selectedPicker, modifierSelections)}
                showActionOrMessageInBottom={showActionOrMessageInBottom}
                titleText={titleText}
              />
            )}

            <InformationContainer>
              {!data.hideNutritionLegalDisclaimer ? (
                <Information>{formatMessage({ id: 'information' })}</Information>
              ) : null}

              {imagesDisclaimer ? (
                <ImageDisclaimer>{imagesDisclaimer?.locale ?? ''}</ImageDisclaimer>
              ) : null}

              {enableAllergensDisclaimer ? (
                <AllergensDisclaimer allergenGroups={nutritionFacts?.[0]?.allergenGroups} />
              ) : null}

              <LegalDisclaimers hideNutritionLegalDisclaimer={data.hideNutritionLegalDisclaimer} />

              {!hideCalories ? (
                <NutritionModal
                  nutritionFacts={nutritionFacts}
                  activatingElement={<TextWithArrowButton messageId="nutritionalInformation" />}
                />
              ) : null}
            </InformationContainer>
          </CustomizationContainer>
        </Guide>
      </ScrollView>

      {showActionOrMessageInBottom && <WizardCtaWrapper elements={getStickyFooterElements()} />}
    </VStack>
  );
};

export default props => {
  const { upsertCart, getCurrentCartEntry, cartIdEditing, removeFromCart } = useOrderContext();
  const { priceForItemOptionModifier, pricingFunction } = useMenuContext();
  const { SwitchCartMethodDialog, shouldShowSwitchToMobileDialog, openSwitchCartMethod } =
    useSwitchCartMethodDialog();

  useEffectOnce(() => {
    if (shouldShowSwitchToMobileDialog) {
      openSwitchCartMethod({ onCancel: () => router.navigate(routes.menu) });
    }
  });

  return (
    <>
      <Wizard
        priceForItemOptionModifier={priceForItemOptionModifier}
        pricingFunction={pricingFunction}
        currentCartEntryId={cartIdEditing}
        getCurrentCartEntry={getCurrentCartEntry}
        upsertCart={upsertCart}
        removeFromCart={removeFromCart}
        {...props}
      />
      <SwitchCartMethodDialog />
    </>
  );
};
