import { Link } from 'expo-router';
import { ElementType } from 'react';
import { StyleSheet, View, type ViewProps } from 'react-native';

import {
  IconChevronRight,
  IconRewards,
  IconRewardsAndOffers,
  Pressable,
  Text,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

import { useMenuClickEvents } from '../analytics';

type MenuOffersLinkProps = {
  style?: ViewProps['style'];
};

export function MenuOffersLink(props: MenuOffersLinkProps) {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.menuOfferContainer}>
      <LinkContent
        link="/rewards/offers"
        text="Offers"
        style={props.style}
        Icon={IconRewardsAndOffers}
      />
      <LinkContent link="/rewards/details" text="Rewards" style={props.style} Icon={IconRewards} />
    </View>
  );
}

const LinkContent = ({
  link,
  text,
  style,
  Icon,
}: {
  link: string;
  text: string;
  style: ViewProps['style'];
  Icon: ElementType;
}) => {
  const mqStyles = useMqStyles();
  const { logMenuClickEvent } = useMenuClickEvents();
  const isMobile = useMqSelect({ $ltDesktop: true }, false);
  return (
    <Link href={link} asChild>
      <Pressable
        borderRadius={8}
        hoveredStyle={styles.isHoveredPressable}
        style={StyleSheet.flatten([mqStyles.container, style])}
        onPress={() => {
          logMenuClickEvent({ text });
        }}
      >
        {pressableState => (
          <>
            <View style={mqStyles.leftContainer}>
              <Icon size={isMobile ? 30 : 40} />
              <Text.Heading
                type="three"
                style={[
                  mqStyles.offersAndRewardsText,
                  pressableState.hovered && styles.isHoveredText,
                ]}
              >
                {text}
              </Text.Heading>
            </View>

            <IconChevronRight
              size={isMobile ? 20 : 30}
              color={pressableState.hovered ? tokens.colors.$houseRedDarken : tokens.colors.$black}
            />
          </>
        )}
      </Pressable>
    </Link>
  );
};

const styles = StyleSheet.create({
  isHoveredPressable: {
    borderColor: tokens.colors.$houseRedOpacity30,
  },
  isHoveredText: {
    color: tokens.colors.$houseRedDarken,
  },
});

const useMqStyles = createMqStyles({
  menuOfferContainer: {
    $base: {
      flexDirection: 'row',
      flexGrow: 1,
      flexBasis: 0,
      gap: 24,
    },
    $ltDesktop: {
      gap: 12,
    },
  },
  container: {
    $base: {
      alignItems: 'center',
      height: 80,
      flexDirection: 'row',
      borderRadius: 8,
      borderColor: tokens.colors.$black10,
      borderWidth: 1,
      padding: 24,
      flex: 1,
    },
    $ltDesktop: {
      paddingVertical: 12,
      paddingHorizontal: 10,
    },
  },
  offersAndRewardsText: {
    $base: {
      paddingHorizontal: 20,
    },
    $ltDesktop: {
      paddingHorizontal: 8,
    },
  },
  iconContainerPadding: {
    $base: {
      paddingLeft: 22,
    },
    $ltDesktop: {
      paddingLeft: 16,
    },
  },
  leftContainer: {
    $base: {
      flexDirection: 'row',
      flexGrow: 1,
      flexBasis: 0,
      gap: 8,
      alignItems: 'center',
    },
    $ltDesktop: {
      gap: 4,
    },
  },
});
