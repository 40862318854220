import { isToday } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { InlineAlert, InlineAlertProps, Skeleton } from '@fhs-legacy/universal-components';
import { PickFutureTimeModal } from 'components/pick-future-time-modal';
import { calculateFireOrderInSeconds } from 'components/pick-future-time-modal/utils';
import { PromotionType } from 'generated/graphql-gateway';
import { useDatePickerOptions } from 'hooks/use-date-picker-options';
import { IDatePickerData, ISelectedDate } from 'hooks/use-date-picker-options/types';
import { FutureOrderTimeCard } from 'pages/cart/future-order-time-card';
import { FutureOrderWarning } from 'pages/cart/future-order-warning';
import { useOpenClosedText } from 'pages/store-locator/new-ui/store-card/hooks/use-open-closed-text';
import { useCartContext } from 'state/cart';
import { selectors, useAppSelector } from 'state/global-state';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { DateAndTimeErrors, IncentiveEvaluationErrorCodes } from 'state/loyalty/hooks/types';
import { getEvaluationResultMessage } from 'state/loyalty/hooks/utils/helpers';
import { useOrderContext } from 'state/order';
import { usePaymentContext } from 'state/payment';
import { useStoreContext } from 'state/store';
import { mapPaymentMethodToLoyaltyPaymentMethods } from 'utils/payment';
import { isCatering } from 'utils/service-mode';

import { SectionContainer, SectionTitle } from './styed';

export const DayAndTimeSelector = () => {
  const { serviceMode, pricedAndAvailable, cartEntries, calculateCartTotalWithDiscount } =
    useCartContext();
  const { checkoutPaymentMethod } = usePaymentContext();
  const { store } = useStoreContext();
  const { isOpen: isStoreOpen } = useOpenClosedText(store);
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const appliedRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const loyaltyId = useAppSelector(selectors.loyalty.selectLoyaltyUserId);
  const { evaluateLoyaltyUserIncentives, isOfferValidationError } = useLoyaltyContext();
  const offersFeedbackMap = useAppSelector(selectors.loyalty.selectOfferFeedbackMap);
  const selectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);
  const incentiveSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);
  const offerFeedback = offersFeedbackMap[selectedOffer?.loyaltyEngineId || ''];
  const { datePickerData, isLoadingData, firstOptionFormatted, firstAvailableDate, hasError } =
    useDatePickerOptions();
  const orderCtx = useOrderContext();

  const [showFutureTimeModal, setShowFutureTimeModal] = useState(false);
  const [showNextDayWarning, setShowNextDayWarning] = useState(false);
  const [selectedDate, setSelectedDate] = useState<ISelectedDate>();
  const [updatedPickerData, setUpdatedPickerData] = useState<IDatePickerData[] | undefined>();

  const formatters = useIntl();
  const { formatMessage } = formatters;

  const sectionTitle = formatMessage({ id: 'pickUpTimeAlt' });

  const onOptionSelected = (date: ISelectedDate | undefined) => {
    setSelectedDate(date);

    if (date) {
      const pickUpTime = new Date(
        Date.UTC(date.year, date.month, date.day, date.hour, date.minutes)
      ).toISOString();
      // Sets the updated fireOrderIn value based on the selected day and time
      orderCtx.setFireOrderIn(calculateFireOrderInSeconds(date));
      const { cartTotal } = calculateCartTotalWithDiscount();
      const paymentMethod = mapPaymentMethodToLoyaltyPaymentMethods(checkoutPaymentMethod);
      if (appliedLoyaltyOffers.length > 0) {
        evaluateLoyaltyUserIncentives({
          loyaltyId,
          appliedLoyaltyOffers,
          cartEntries,
          appliedRewards,
          subtotalAmount: cartTotal,
          paymentMethod,
          skipTimeValidation: false,
          pickUpTime,
          incentiveSelections,
        });
      }
    }
  };

  const enableCatering = useFlag(LaunchDarklyFlag.ENABLE_CATERING);
  const isCateringOrder = isCatering(serviceMode);
  const showCateringPickUpAlert = enableCatering && isCateringOrder;
  const showFutureOrderingWarning = !isStoreOpen && !isCateringOrder;

  const getTopInlineAlertProps = useCallback((): InlineAlertProps | undefined => {
    const defaultProps = {
      borderRadius: 8,
      marginBottom: '$2',
      iconCentered: true,
    };
    if (showCateringPickUpAlert) {
      return {
        ...defaultProps,
        status: 'warning',
        message: formatMessage({ id: 'cateringPickUpDate' }),
      };
    }
    const { cartTotal } = calculateCartTotalWithDiscount();

    if (cartTotal > 5000) {
      return {
        ...defaultProps,
        status: 'info',
        message:
          'Due to the size of your order, available pick-up times may be a bit later than usual.',
        analyticsHeader: 'Large Order Pickup Time',
      };
    }

    return undefined;
  }, [calculateCartTotalWithDiscount, formatMessage, showCateringPickUpAlert]);

  const getBottomInlineAlertProps = (): InlineAlertProps | undefined => {
    const defaultProps = {
      borderRadius: 8,
      marginTop: '$2',
      iconCentered: true,
    };
    if (isOfferValidationError) {
      return {
        ...defaultProps,
        status: 'error',
        message: DateAndTimeErrors.has(offerFeedback[0]?.code as IncentiveEvaluationErrorCodes)
          ? formatMessage({ id: 'futureOrderingOfferValidationError' })
          : getEvaluationResultMessage({
              evaluationResult: offerFeedback[0],
              formatters,
              incentiveType: PromotionType.OFFER,
            }),
      };
    }
    if (showFutureOrderingWarning) {
      return {
        ...defaultProps,
        status: 'warning',
        message:
          'Planning to place an order for later? Make sure this date and time works for you!',
        analyticsHeader: 'Check Pickup Time',
      };
    }

    return undefined;
  };
  const topInlineAlertProps = useMemo(() => getTopInlineAlertProps(), [getTopInlineAlertProps]);
  const bottomInlineAlertProps = getBottomInlineAlertProps();

  useEffect(() => {
    if (datePickerData) {
      onOptionSelected(firstOptionFormatted);
      setUpdatedPickerData(datePickerData);
    }
  }, [datePickerData, firstOptionFormatted]);

  useEffect(() => {
    if (isStoreOpen && firstAvailableDate && !isToday(firstAvailableDate)) {
      setShowNextDayWarning(true);
    }
  }, [firstAvailableDate, isStoreOpen]);

  const shouldShowLoadingAnimation = isLoadingData || !datePickerData || !updatedPickerData;

  if (!isLoadingData && !pricedAndAvailable) {
    return null;
  }

  return (
    <SectionContainer>
      <SectionTitle>{sectionTitle}</SectionTitle>
      {shouldShowLoadingAnimation ? (
        <Skeleton h="$16" borderRadius={10} />
      ) : (
        <>
          <PickFutureTimeModal
            isOpen={showFutureTimeModal}
            onClose={() => setShowFutureTimeModal(false)}
            onPickupTimeCallback={onOptionSelected}
            availableDates={updatedPickerData}
          />
          {topInlineAlertProps && <InlineAlert {...topInlineAlertProps} />}
          <FutureOrderTimeCard
            date={selectedDate}
            onPress={() => setShowFutureTimeModal(true)}
            readOnly={false}
            hasError={hasError}
            hasOfferValidationError={isOfferValidationError}
          />
          {bottomInlineAlertProps && <InlineAlert {...bottomInlineAlertProps} />}
          {firstAvailableDate && (
            <FutureOrderWarning
              isVisible={showNextDayWarning}
              onPressOk={() => setShowNextDayWarning(false)}
              onClose={() => setShowNextDayWarning(false)}
              futureDate={firstAvailableDate}
            />
          )}
        </>
      )}
    </SectionContainer>
  );
};
