import React from 'react';

import { RemoveIncentiveModal, ReplaceIncentiveModal } from '../../modals';

export const LoyaltyModalsProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <ReplaceIncentiveModal />
      <RemoveIncentiveModal />
    </>
  );
};
