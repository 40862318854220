import React from 'react';

import { SodiumFlag } from '@fhs-legacy/universal-components/themes/icons';

import { Text } from '../text';

export const SodiumDisclaimer = () => {
  return (
    <Text style={{ fontSize: 12, marginTop: 24 }}>
      Warning: <SodiumFlag size={3} mt={-0.5} /> indicates that sodium (salt) content of this item
      is higher than the total daily recommended limit (2,300mg). High Sodium intake can increase
      blood pressure and risk of heart disease and stroke.
    </Text>
  );
};
