import { Image, ImageBackground } from 'expo-image';

import { Text, createMqStyles, tokens, useMqSelect } from '@fhs/ui';

import { CtaButton } from '../shared/cta-button';

export function HotOnesCard({ url }: { url: string }) {
  const mqStyles = useMqStyles();
  const backgroundSource = useMqSelect(
    {
      $gteDesktopLg: require('./hot-ones-card-background-desktop.webp'),
    },
    require('./hot-ones-card-background-tablet.webp')
  );
  return (
    <ImageBackground
      alt="Hot Ones."
      source={backgroundSource}
      placeholder={{ thumbhash: '0CgKI4aZWIiAd2h2h4CGCWg' }}
      style={mqStyles.background}
    >
      <Image
        source={require('./zesty-garlic.webp')}
        alt="Zestic Garlic. Hook & Ladder."
        placeholder={{ thumbhash: '8yqGKYIVl5eAiHtof4T2R0UGgYZ7Bxg' }}
        contentFit="contain"
        style={mqStyles.zestyGarlic}
      />
      <Image
        source={require('./spicy-dill.webp')}
        alt="Spicy Dill. Hook & Ladder."
        placeholder={{ thumbhash: 'LomGEYgUp4eAiXtYf4T1RzY2gIWLODg' }}
        contentFit="contain"
        style={mqStyles.spicyDill}
      />
      <Image
        source={require('./logo.webp')}
        alt="Hot Ones."
        placeholder={{ thumbhash: 'K3yCCoATCpemlnOwparP0qcpdWmAeSU' }}
        contentFit="contain"
        style={mqStyles.logo}
      />
      <Text style={mqStyles.disclaimer}>
        Individuals sensitive to spice or the Carolina Reaper pepper{'\n'}should not consume the
        Spicy Dill Hook & Ladder®.
      </Text>
      <CtaButton href={url} style={mqStyles.button} />
    </ImageBackground>
  );
}

const useMqStyles = createMqStyles({
  background: {
    $base: {
      aspectRatio: 1024 / 505,
    },
    $gteDesktopLg: {
      aspectRatio: 1920 / 757,
    },
  },
  zestyGarlic: {
    $base: {
      position: 'absolute',
      aspectRatio: 480 / 100,
      width: '25%',
      left: '8%',
      top: '37%',
    },
    $gteDesktopLg: {
      left: '4%',
      top: '15%',
    },
  },
  spicyDill: {
    $base: {
      position: 'absolute',
      aspectRatio: 480 / 100,
      width: '25%',
      right: '11%',
      top: '43%',
    },
    $gteDesktopLg: {
      right: '4%',
      top: '15%',
    },
  },
  logo: {
    $base: {
      position: 'absolute',
      aspectRatio: 460 / 200,
      width: '30%',
      left: '34%',
      top: '10%',
    },
    $gteDesktopLg: {
      width: '25%',
      left: '38%',
      top: '10%',
    },
  },
  button: {
    $base: {
      position: 'absolute',
      top: '48%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    $gteDesktopLg: {
      top: '45%',
    },
  },
  disclaimer: {
    $base: {
      position: 'absolute',
      left: '16%',
      bottom: '4%',
      color: tokens.colors.$white,
      fontSize: 12,
    },
    $gteDesktopLg: {
      bottom: '3%',
      left: '19%',
      fontSize: 14,
    },
  },
});
