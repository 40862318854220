import { OfferCardProps } from '../components';
import { LoyaltyIncentiveOffer } from '../queries/loyalty.queries';

export function formatOfferCards(offers?: LoyaltyIncentiveOffer[], cardsToRender?: number) {
  return (
    offers?.slice(0, cardsToRender)?.map<OfferCardProps>(offer => ({
      id: offer.id,
      imageUrl: offer.image,
      title: offer.name,
      description: offer.description,
      onPressButton: offer.isApplied ? offer.removeOffer : offer.applyOffer,
      onPressImage: offer.seeDetails,
      tag: offer.tag,
      buttonVariant: offer.isApplied ? 'applied' : 'default',
      buttonLabel: offer.isApplied
        ? 'Offer Applied'
        : offer.isAvailable
        ? 'Apply Offer'
        : 'See Details',
    })) ?? []
  );
}
