import { ImageBackground } from 'expo-image';
import { View } from 'react-native';

import {
  Background,
  Button,
  MqSwitch,
  QuantityCounter,
  ScrollView,
  XStack,
  YStack,
  createMqStyles,
  tokens,
  useMqSelect,
} from '@fhs/ui';

interface Props {
  hideFooter?: boolean;
}

export const MenuItemSkeletonLoading = ({ hideFooter = false }: Props) => {
  return (
    <MqSwitch
      $ltDesktop={() => <MenuItemSkeletonLoadingMobile hideFooter={hideFooter} />}
      $gteDesktop={() => <MenuItemSkeletonLoadingDesktop hideFooter={hideFooter} />}
    />
  );
};

const Footer = () => {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.footer}>
      <QuantityCounter value={1} maxValue={2} minValue={1} onChangeValue={() => {}}>
        <QuantityCounter.Stepper />
      </QuantityCounter>
      <Button style={mqStyles.button} disabled>
        <Button.Text>{useMqSelect({ $ltDesktop: 'Add Item' }, 'Add to Order')}</Button.Text>
      </Button>
    </View>
  );
};

const MenuItemSkeletonLoadingMobile = ({ hideFooter }: Props) => {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={mqStyles.content}>
          <View style={mqStyles.itemTitle} />
          <View style={mqStyles.itemSubtitle} />
          <ImageBackground
            contentFit={useMqSelect({ $ltDesktop: 'contain' }, 'none')}
            source={require('../../assets/img/menu_item_detail_bg_2477_1058.webp')}
            style={mqStyles.containerImageBackground}
          >
            <View style={mqStyles.imageAnatomy} />
          </ImageBackground>
          <View style={mqStyles.description}>
            <View style={[mqStyles.itemDescriptionLine, { width: 335 }]} />
            <View style={[mqStyles.itemDescriptionLine, { width: 351 }]} />
            <View style={[mqStyles.itemDescriptionLine, { width: 297 }]} />
            <View style={[mqStyles.itemDescriptionLine, { width: 169 }]} />
          </View>
          <View style={mqStyles.sectionTitle} />
          <XStack style={mqStyles.sizeTile}>
            <View style={mqStyles.sizeBox} />
            <View style={mqStyles.sizeBox} />
            <View style={mqStyles.sizeBox} />
          </XStack>
          <View style={mqStyles.sectionTitle} />
          <View style={mqStyles.customizeBox} />

          <XStack style={mqStyles.customizeHeader}>
            <View style={mqStyles.customizeSection}>
              <View style={[mqStyles.itemDescriptionLine, { width: 131 }]} />
              <View style={[mqStyles.itemDescriptionLine, { width: 211 }]} />
            </View>
            <XStack style={mqStyles.allowCombo}>
              <View style={[mqStyles.itemDescriptionLine, { width: 43, height: 12 }]} />
              <View style={[mqStyles.itemDescriptionLine, { width: 48, height: 24 }]} />
            </XStack>
          </XStack>
          <View style={mqStyles.makeItACombo} />
        </View>
      </ScrollView>
      {!hideFooter && <Footer />}
    </View>
  );
};

const MenuItemSkeletonLoadingDesktop = ({ hideFooter }: Props) => {
  const mqStyles = useMqStyles();

  return (
    <View style={mqStyles.container}>
      <XStack style={mqStyles.flex1}>
        <YStack style={mqStyles.productContainer}>
          <View style={mqStyles.productDetails}>
            <View style={[mqStyles.itemDescriptionLine, { width: 400, height: 53 }]} />
            <View style={[mqStyles.itemDescriptionLine, { width: 209, height: 22 }]} />
            <View style={mqStyles.productHeader}>
              <View style={[mqStyles.itemDescriptionLine, { width: 508, height: 22 }]} />
              <View style={[mqStyles.itemDescriptionLine, { width: 370, height: 22 }]} />
              <View style={[mqStyles.itemDescriptionLine, { width: 248, height: 22 }]} />
            </View>
            <View style={mqStyles.backgroundContainer}>
              <Background />
              <View style={mqStyles.imageAnatomy} />
            </View>
          </View>
        </YStack>

        <YStack style={mqStyles.customizeContainer}>
          <View style={mqStyles.sectionTitle} />
          <View style={mqStyles.sizeBoxContainer}>
            <XStack style={mqStyles.sizeTile}>
              <View style={mqStyles.sizeBox} />
              <View style={mqStyles.sizeBox} />
              <View style={mqStyles.sizeBox} />
            </XStack>
            <View style={mqStyles.sectionTitle} />

            <View style={mqStyles.customizeBox}>
              <XStack style={mqStyles.customizeBoxDesktopRow}>
                <XStack>
                  <View style={mqStyles.itemImage} />
                  <View style={mqStyles.itemDetails}>
                    <View style={[mqStyles.itemDescriptionLine, { width: 80, height: 18 }]} />
                    <View style={[mqStyles.itemDescriptionLine, { width: 153, height: 12 }]} />
                  </View>
                </XStack>
                <View style={mqStyles.selectBox} />
              </XStack>
              <View style={mqStyles.divider} />
              <XStack style={mqStyles.customizeBoxDesktopRow}>
                <XStack>
                  <View style={mqStyles.itemImage} />
                  <View style={mqStyles.itemDetails}>
                    <View style={[mqStyles.itemDescriptionLine, { width: 80, height: 18 }]} />
                    <View style={[mqStyles.itemDescriptionLine, { width: 153, height: 12 }]} />
                  </View>
                </XStack>
                <View style={mqStyles.selectBox} />
              </XStack>
              <View style={mqStyles.divider} />
              <XStack style={mqStyles.customizeBoxDesktopRow}>
                <XStack>
                  <View style={mqStyles.itemImage} />
                  <View style={mqStyles.itemDetails}>
                    <View style={[mqStyles.itemDescriptionLine, { width: 80, height: 18 }]} />
                    <View style={[mqStyles.itemDescriptionLine, { width: 153, height: 12 }]} />
                  </View>
                </XStack>
                <View style={mqStyles.selectBox} />
              </XStack>
              <View style={mqStyles.divider} />
              <XStack style={mqStyles.customizeBoxDesktopRow}>
                <XStack>
                  <View style={mqStyles.itemImage} />
                  <View style={mqStyles.itemDetails}>
                    <View style={[mqStyles.itemDescriptionLine, { width: 80, height: 18 }]} />
                    <View style={[mqStyles.itemDescriptionLine, { width: 153, height: 12 }]} />
                  </View>
                </XStack>
                <View style={mqStyles.selectBox} />
              </XStack>
            </View>
          </View>
        </YStack>
      </XStack>
      {!hideFooter && <Footer />}
    </View>
  );
};

const useMqStyles = createMqStyles({
  itemDetails: {
    $base: {
      gap: 8,
      paddingLeft: 12,
    },
  },
  sizeBoxContainer: {
    $base: {
      flex: 1,
      width: '100%',
    },
  },
  flex1: {
    $base: { flex: 1 },
  },
  productContainer: {
    $gteDesktop: {
      flex: 1,
      paddingTop: 80,
    },
  },
  productDetails: {
    $gteDesktop: {
      gap: 8,
      alignItems: 'center',
      flex: 1,
    },
  },
  productHeader: {
    $gteDesktop: {
      gap: 4,
      alignItems: 'center',
      paddingTop: 28,
    },
  },
  container: {
    $ltDesktop: {
      alignSelf: 'center',
      maxWidth: 540,
      width: '100%',
      height: '100%',
    },
    $gteDesktop: {
      flex: 1,
      justifyContent: 'space-around',
      height: '100%',
    },
  },
  content: {
    $ltDesktop: {
      gap: 8,
      paddingTop: 0,
      paddingHorizontal: 16,
      paddingBottom: 32,
      alignItems: 'center',
    },
  },
  customizeContainer: {
    $gteDesktop: {
      flex: 1,
      maxWidth: 617,
      minWidth: 410,
      width: '100%',
      borderWidth: 1,
      borderColor: tokens.colors.$blackOpacity10,
      borderTopLeftRadius: 20,
      padding: 52,
      backgroundColor: tokens.colors.$white,
      borderBottomWidth: 0,
    },
  },
  description: {
    $base: {
      paddingTop: 16,
      gap: 8,
      alignSelf: 'flex-start',
    },
  },
  itemTitle: {
    $base: {
      width: 222,
      height: 22,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  itemSubtitle: {
    $base: {
      width: 154,
      height: 16,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  itemDescription: {
    $base: {
      flex: 1,
      width: 154,
      height: 16,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      gap: 8,
    },
  },
  itemDescriptionLine: {
    $base: {
      width: 154,
      height: 16,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  sizeContainer: {
    $base: {
      width: '100%',
    },
  },
  sectionTitle: {
    $base: {
      width: 61,
      height: 12,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 29,
      alignSelf: 'flex-start',
    },
    $gteDesktop: {
      height: 18,
    },
  },
  sizeBox: {
    $base: {
      flex: 1,
      height: 121,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  sizeTile: {
    $base: {
      width: '100%',
      paddingTop: 16,
      gap: 8,
    },
  },
  customizeHeader: {
    $base: {
      paddingTop: 28,
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  allowCombo: {
    $base: {
      alignItems: 'center',
      gap: 8,
    },
  },
  customizeSection: {
    $base: {
      gap: 8,
    },
  },
  customizeBox: {
    $ltDesktop: {
      width: '100%',
      height: 381,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 20,
      borderColor: tokens.colors.$houseLight,
      borderWidth: 1,
    },
    $gteDesktop: {
      width: '100%',
      top: 14,
      borderColor: tokens.colors.$blackOpacity10,
      borderWidth: 1,
      borderRadius: 12,
    },
  },
  customizeBoxDesktopRow: {
    $gteDesktop: {
      padding: 12,
      gap: 12,
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 66,
    },
  },
  containerImageBackground: {
    $ltDesktop: {
      flex: 1,
      minHeight: 200,
      width: '106%',
      paddingTop: 30,
      overflow: 'hidden',
    },
  },
  backgroundContainer: {
    $gteDesktop: {
      flex: 1,
      justifyContent: 'flex-end',
      width: '100%',
    },
  },
  imageAnatomy: {
    $base: {
      height: 121,
      width: 222,
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 12,
      alignSelf: 'center',
      bottom: 0,
    },
    $gteDesktop: {
      marginTop: 100,
      top: 30,
      maxHeight: 299,
      maxWidth: 469,
      width: '100%',
      height: '100%',
      position: 'absolute',
      flex: 1,
    },
  },
  makeItACombo: {
    $base: {
      height: 128,
      width: '100%',
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 12,
      paddingVertical: 40,
    },
  },
  button: {
    $base: {
      flex: 1,
      maxWidth: 340,
    },
  },
  footer: {
    $ltDesktop: {
      backgroundColor: tokens.colors.$white,
      padding: 12,
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '100%',
      gap: 52,
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity10,
      maxWidth: 508,
    },
    $gteDesktop: {
      borderTopWidth: 1,
      borderTopColor: tokens.colors.$blackOpacity10,
      justifyContent: 'flex-end',
      backgroundColor: tokens.colors.$white,
      flexDirection: 'row',
      alignSelf: 'center',
      width: '100%',
      gap: 52,
      height: 70,
      paddingVertical: 12,
      paddingRight: 12,
    },
  },
  itemImage: {
    $gteDesktop: {
      width: 40,
      height: 40,
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 4,
    },
  },
  selectBox: {
    $gteDesktop: {
      width: 24,
      height: 24,
      backgroundColor: tokens.colors.$houseLight,
      borderRadius: 4,
      padding: 12,
    },
  },
  divider: {
    $base: {
      height: 1,
      width: '100%',
      backgroundColor: tokens.colors.$houseLight,
    },
  },
});
