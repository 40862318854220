import React, { useCallback } from 'react';
import { StyleSheet, View } from 'react-native';

import { IconOfferOutline, Text, tokens } from '@fhs/ui';
import { create } from '@fhs/zustand';
import useDialogModal from '@fhs-legacy/frontend/src/hooks/use-dialog-modal';

import { IDialogCallbacks } from '../types';

interface IRemoveIncentiveStore {
  offerName: string;
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  show: (props: { offerName: string; callbacks: IDialogCallbacks }) => void;
  close: () => void;
}

export const useRemoveIncentiveStore = create<IRemoveIncentiveStore>(set => ({
  offerName: '',
  isOpen: false,
  onConfirm: () => {},
  onDismiss: () => {},
  show: ({ offerName, callbacks }: { offerName: string; callbacks: IDialogCallbacks }) =>
    set({
      offerName,
      isOpen: true,
      onConfirm: callbacks.onConfirm,
      onDismiss: callbacks.onDismiss,
    }),
  close: () => set({ isOpen: false, onConfirm: () => {}, onDismiss: () => {} }),
}));

interface IRemoveIncentiveModalProps {
  onDismiss: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  offerName: string;
}

const Modal = ({ onDismiss, onConfirm, isOpen, offerName }: IRemoveIncentiveModalProps) => {
  const [RemoveIncentiveDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Confirm Remove Incentive',
    showCancel: true,
    init: true,
    onDismiss: onDismiss,
    onConfirm: onConfirm,
    onCancel: onDismiss,
  });

  return (
    <RemoveIncentiveDialog
      showDialog={isOpen}
      headingComponent={<Text.Heading type="one">Remove Offer</Text.Heading>}
      bodyComponent={
        <View style={styles.content}>
          <Text.Ui size="md">Are you sure you want to remove this offer from your cart?</Text.Ui>
          <View style={styles.nameContainer}>
            <View style={styles.iconContainer}>
              <IconOfferOutline size={24} />
            </View>
            <View style={{ flex: 1 }}>
              <Text.Heading type="four" numberOfLines={1}>
                {offerName}
              </Text.Heading>
            </View>
          </View>
        </View>
      }
      buttonLabel="Remove"
      showCloseButton
    />
  );
};

export const RemoveIncentiveModal = () => {
  const { offerName, isOpen, close, onConfirm, onDismiss } = useRemoveIncentiveStore();

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    close();
  }, [close, onConfirm]);

  const handleDismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
    close();
  }, [close, onDismiss]);

  return (
    <>
      {isOpen && (
        <Modal
          offerName={offerName}
          onConfirm={handleConfirm}
          onDismiss={handleDismiss}
          isOpen={isOpen}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  content: {
    marginTop: 12,
    marginBottom: 20,
  },
  nameContainer: {
    flexDirection: 'row',
    gap: 12,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    height: 24,
    width: 24,
  },
});
