import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import { ScrollView, addWithConfig } from '@fhs-legacy/universal-components';
import { Box } from '@fhs-legacy/universal-components';
import { ICartEntry, IHistoricalOrder } from '@rbi-ctg/menu';
import ActionButton from 'components/action-button';
import ModalOrderUnavailable from 'components/modal-order-unavailable';
import { ProductCardListSkeleton } from 'components/product/product.skeleton';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import useAddToCartRecentItem from 'hooks/use-add-to-cart-recent-item';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { UserOrdersDefaultLimit, useUserOrders } from 'hooks/use-user-orders';
import { useAuthContext } from 'state/auth';
import { useMenuContext } from 'state/menu';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { isCartEntryInMenu } from 'utils/cart';
import { maybeMapCartEntryToMenuObjectIdentifier } from 'utils/menu';

import { ErrorState, LoggedOutEmptyState, NoRecentOrdersEmptyState } from './empty-state';
import RecentOrderCardGroup from './recent-order-card-group';

const PageContainer = ScrollView;

const LoadMoreButton = addWithConfig(ActionButton).withConfig({
  style: {
    marginLeft: '30%',
    width: '40%',
    marginBottom: '10%',
  },
});

const RecentOrderCardList: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { formatMessage } = useIntl();
  const [selectionUnavailable, setSelectionUnavailable] = useState<ICartEntry | null>(null);
  const { vendor } = usePosVendor();
  const { checkItemAvailability } = useMenuContext();
  const { isAuthenticated } = useAuthContext();
  const [fetchingMore, setFetchingMore] = useState(false);
  const { isStoreOpenAndAvailable, prices, store } = useStoreContext();
  const {
    serverOrder,
    recent: { pendingRecentItem, setPendingRecentItem },
  } = useOrderContext();
  const { addToCart } = useAddToCartRecentItem({ cartEntry: pendingRecentItem });

  const { loading, orders, error, count, resumeAfter, refetch, fetchMore } = useUserOrders({
    limit: UserOrdersDefaultLimit,
  });

  const handleLoadMorePress = async () => {
    setFetchingMore(true);
    try {
      await fetchMore({
        variables: {
          resumeAfter,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return prev;
          }
          return {
            ...fetchMoreResult,
            userOrders: {
              ...fetchMoreResult.userOrders,
              count: prev.userOrders.count + fetchMoreResult.userOrders.count,
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              orders: prev.userOrders.orders?.concat(fetchMoreResult.userOrders.orders),
            },
          };
        },
      });
      setFetchingMore(false);
    } catch {
      setFetchingMore(false);
    }
  };

  const addToCartIfAvailable = useCallback(
    async (cartEntry: ICartEntry) => {
      const itemId = maybeMapCartEntryToMenuObjectIdentifier(cartEntry);

      if (!itemId) {
        setSelectionUnavailable(cartEntry);
        return;
      }

      const { availabilityStatus } = await checkItemAvailability({
        vendor,
        restaurantPosDataId: store.restaurantPosData?._id ?? '',
        itemId,
      });

      if (availabilityStatus !== 'AVAILABLE') {
        setSelectionUnavailable(cartEntry);
      } else {
        addToCart();
      }
    },
    [addToCart, checkItemAvailability, store.number, store.restaurantPosData, vendor]
  );

  useEffect(() => {
    if (isStoreOpenAndAvailable && pendingRecentItem && prices) {
      setPendingRecentItem(null);
      addToCartIfAvailable(pendingRecentItem);
    }
  }, [
    addToCartIfAvailable,
    isStoreOpenAndAvailable,
    pendingRecentItem,
    prices,
    setPendingRecentItem,
  ]);

  const reorderableOrders = useMemo(
    () =>
      orders.reduce((ordersAccum: IHistoricalOrder[], order) => {
        const filteredCartEntries = order.cart.reorderCartEntries?.filter(
          cartEntry => isCartEntryInMenu(cartEntry) && !cartEntry.isDonation
        );

        if (filteredCartEntries?.length) {
          ordersAccum.push({
            ...order,
            cart: {
              ...order.cart,
              reorderCartEntries: filteredCartEntries,
            },
          });
        }

        return ordersAccum;
      }, []),
    [orders]
  );

  useEffectOnUpdates(() => {
    refetch();
  }, [refetch, serverOrder._id]);

  if (loading && !fetchingMore) {
    return (
      <Box flex={1}>
        <ProductCardListSkeleton />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <PageContainer>
        <LoggedOutEmptyState />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <ErrorState onRefresh={() => refetch()} />
      </PageContainer>
    );
  }

  if (!reorderableOrders.length && !resumeAfter) {
    return (
      <PageContainer>
        <NoRecentOrdersEmptyState />
      </PageContainer>
    );
  }

  return (
    <PageContainer
      showsVerticalScrollIndicator={false}
      accessibilityLabel="recent-order-card-list-container"
      keyboardShouldPersistTaps="handled"
    >
      {reorderableOrders.map(order => (
        <RecentOrderCardGroup key={order?.rbiOrderId} order={order} />
      ))}
      {count > 0 && resumeAfter && (
        <LoadMoreButton
          testID="load-more-button"
          onPress={handleLoadMorePress}
          isLoading={fetchingMore}
        >
          {formatMessage({ id: 'loadMore' })}
        </LoadMoreButton>
      )}
      {selectionUnavailable && (
        <ModalOrderUnavailable
          items={[selectionUnavailable]}
          onDismiss={() => setSelectionUnavailable(null)}
        />
      )}
    </PageContainer>
  );
};

export default RecentOrderCardList;
