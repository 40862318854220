import { PixelRatio } from 'react-native';

import { SelectedPicker } from '../state/combo-selection';

const BASE_MENU_URL = '/v2/menu';

export const getUrlForItemDetailPageFromSlug = (slug: string) => {
  return `${BASE_MENU_URL}/${slug}`;
};

export const formatCentsToDollars = (cents: number) => {
  // Always include decimal places
  return cents > 0 ? `$${(cents / 100).toFixed(2)}` : null;
};

export const formatCentsToDollarsWithPlusSign = (cents: number) => {
  return cents > 0 ? `+ ${formatCentsToDollars(cents)}` : null;
};

export const formatCaloriesOffsetText = (caloriesOffset?: number): string => {
  // We still want to display this information when calories are 0.
  return caloriesOffset !== null || caloriesOffset !== undefined ? `${caloriesOffset} Cal` : '';
};

const dpr = Math.max(PixelRatio.get(), 3).toString();

export function optimizeSanityImageUri(uri: string = '', imageHeight: number): string {
  const imageUri = new URL(uri);
  imageUri.searchParams.set('h', imageHeight.toString());
  imageUri.searchParams.set('dpr', dpr);
  imageUri.searchParams.set('fm', 'webp');

  return imageUri.toString();
}

export function mapPickerSelections(pickerSelections: Record<string, SelectedPicker>) {
  return Object.entries(pickerSelections).map(([slotId, selection]) => {
    const itemId = selection.id;
    const price = selection.priceCents;
    const pickerCustomizationSelections = selection.customizationDetailedSelections;

    const modifiersJson = Object.entries(selection.customizationSelections || {}).map(([id]) => {
      return {
        id,
        name: pickerCustomizationSelections[id]?.name,
        price: pickerCustomizationSelections[id]?.upChargeCents,
      };
    });

    return {
      slotId,
      itemId,
      price,
      modifiersJson,
    };
  });
}
