import { Image, ImageProps } from 'expo-image';
import { StyleSheet } from 'react-native';

import { LtoContentUS } from '../lto-content/lto-content-us';

export type TopMarketingImageProps = Omit<ImageProps, 'children' | 'source'>;

export function TopMarketingImage(props: TopMarketingImageProps) {
  return (
    <LtoContentUS
      ltoContent={
        <Image
          source={require('./hot-ones.webp')}
          placeholder={{ thumbhash: '11mKDQI4h5iGiGT3OIiIoKkKaWdwhnyHSA' }}
          alt="Hot Ones"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
      frenchDip={
        <Image
          source={require('./french-dip.webp')}
          placeholder={{ thumbhash: '33mGDQI3i4RAmbS51AlmkXBWJ4eAiIl6iA' }}
          alt="French Dip"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
      default={
        <Image
          source={require('./hook-and-ladder.webp')}
          placeholder={{ thumbhash: '2lmKFYInl3hziXDat5hzYEsGJ0eEh4D4Zw' }}
          alt="Hook & Ladder"
          contentFit="contain"
          {...props}
          style={[styles.marketingImage, props.style]}
        />
      }
    />
  );
}

const styles = StyleSheet.create({
  marketingImage: {
    aspectRatio: 1600 / 1622,
    width: '100%',
    height: '100%',
    minHeight: 200,
    alignSelf: 'center',
  },
});
