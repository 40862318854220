import { stripIgnoredCharacters } from 'graphql';

import { compose } from 'utils';

// Chrome starts truncating urls at around 7500 so
// we put a limit on it. Sanity supports up to 11KB
const MAX_QUERY_LENGTH = 7500;

function compressQuery(parsedBody: Record<string, string>): Record<string, string> {
  const query = stripIgnoredCharacters(parsedBody.query || '');

  return { ...parsedBody, query };
}

function stringifyVariables(parsedBody: Record<string, string | object>): Record<string, string> {
  const variables = JSON.stringify(parsedBody.variables);

  return { ...parsedBody, variables };
}

const transformBody = compose(compressQuery, stringifyVariables, JSON.parse);

export function sanityFetch(input: string | URL | Request, init?: RequestInit): Promise<Response> {
  const { body } = init ?? {};

  const parsedURL = input instanceof Request ? input.url : input;

  const requestUrl = new URL(parsedURL);

  if (!body) {
    return fetch(requestUrl.href);
  }

  const compressedBody = transformBody(body as string);

  const compressedBodyString = JSON.stringify(compressedBody);

  const useGet = encodeURIComponent(compressedBodyString).length <= MAX_QUERY_LENGTH;

  const newRequestConfig: RequestInit = {
    ...init,
    method: useGet ? 'GET' : 'POST',
  };

  delete newRequestConfig.body;

  if (useGet) {
    Object.entries(compressedBody).forEach(([key, value]: [string, string]) => {
      requestUrl.searchParams.append(
        key,
        typeof value !== 'string' ? JSON.stringify(value) : value
      );
    });
  } else {
    newRequestConfig.body = compressedBodyString;
  }

  return fetch(requestUrl.href, newRequestConfig);
}
