import { useLocalSearchParams } from 'expo-router';
import { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Button,
  IconChevronLeft,
  IconClose,
  ItemStepper,
  Skeleton,
  StepProps,
  Text,
  tokens,
} from '@fhs/ui';
import { isWeb } from '@fhs/utils';

import { useOfferDetailUi } from '../../queries/loyalty.queries';
import {
  useIsItemInCart,
  useIsLoadingTierSelector,
  useOfferCart,
  useShouldRenderTierSelector,
} from '../../state/offer-guide-cart-state';

interface NavigationProps {
  onPressPrev: () => void;
  onPressStep: (index: number) => void;
  onClose: () => void;
}

const CenterContent = ({
  onPressStep,
  isItemInCart,
  shouldRenderTierSelector,
}: {
  onPressStep: (index: number) => void;
  isItemInCart: boolean;
  shouldRenderTierSelector: boolean;
}) => {
  const { offerId } = useLocalSearchParams<{ offerId: string }>();
  const { isLoading } = useOfferDetailUi(offerId);
  const { steps: offerSteps, currentStepIndex, offerInfo } = useOfferCart();

  const steps = useMemo(
    () =>
      offerSteps.map<StepProps>((offerStep, index) => ({
        image: offerStep?.selectedEntryImage,
        isActive: currentStepIndex === index,
      })),
    [offerSteps, currentStepIndex]
  );
  const hasOneStep = steps.length === 1;

  if (isLoading) {
    return <Skeleton style={styles.itemStepperSkeleton} />;
  }
  if (isItemInCart || !offerInfo?.isAvailable) {
    return null;
  }

  if (shouldRenderTierSelector) {
    return (
      <Text.Ui size="md" weight="semibold" style={{ alignSelf: 'center' }}>
        Select Your Discount Tier
      </Text.Ui>
    );
  }

  if (hasOneStep) {
    return (
      <Text.Ui size="md" weight="semibold" style={{ alignSelf: 'center' }}>
        Item Selection
      </Text.Ui>
    );
  }

  return <ItemStepper steps={steps} onStepClick={onPressStep} />;
};

export function Navigation({ onPressPrev, onPressStep, onClose }: NavigationProps) {
  const { currentStepIndex, selectedEligibleItem, offerInfo } = useOfferCart();
  const isItemInCart = useIsItemInCart();
  const shouldRenderTierSelector = useShouldRenderTierSelector();
  const isLoadingTierSelector = useIsLoadingTierSelector();
  const hideBackButton = isLoadingTierSelector || shouldRenderTierSelector;

  const isBackButtonDisabled =
    (currentStepIndex === 0 && !selectedEligibleItem && offerInfo?.tiers == null) ||
    shouldRenderTierSelector;

  if (isItemInCart && !isWeb) {
    return <View style={{ paddingTop: 20 }} />;
  }

  return (
    <View style={styles.navigation}>
      <Button
        key={`${shouldRenderTierSelector}-${isBackButtonDisabled}`}
        disabled={isBackButtonDisabled}
        type="unstyled"
        size="xl"
        onPress={onPressPrev}
        style={{ alignSelf: 'center', opacity: hideBackButton ? 0 : 1 }}
      >
        <Button.Icon>
          <IconChevronLeft size={24} />
        </Button.Icon>
      </Button>
      <View style={styles.itemStepperContainer}>
        <CenterContent
          onPressStep={onPressStep}
          isItemInCart={isItemInCart}
          shouldRenderTierSelector={shouldRenderTierSelector}
        />
      </View>
      <Button type="ghost" size="xl" onPress={onClose} style={{ alignSelf: 'center' }}>
        <Button.Icon>
          <IconClose size={24} />
        </Button.Icon>
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  itemStepperContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    flexGrow: 1,
    flex: 1,
    overflow: 'hidden',
  },
  itemStepperSkeleton: {
    width: 75,
    height: 14,
    backgroundColor: tokens.colors.$houseLight,
    alignSelf: 'center',
  },
  navigation: {
    flexDirection: 'row',
    paddingHorizontal: 4,
    height: 66,
  },
});
