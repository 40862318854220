import { Image } from 'expo-image';
import { View } from 'react-native';

import { IconChevronRight, Pressable, type PressableProps, Text, XStack } from '@fhs/ui';

import { optimizeSanityImageUri } from '../../utils';

import { BORDER_RADIUS, IMAGE_HEIGHT } from './constants';
import { listStyles } from './shared-styles';
import type { ListItemType } from './types';

type ListItemLinkPressableProps = ListItemType &
  Omit<
    PressableProps,
    | 'borderRadius'
    | 'borderTopLeftRadius'
    | 'borderTopRightRadius'
    | 'borderBottomLeftRadius'
    | 'borderBottomRightRadius'
    | 'children'
  >;

export function ListItemLinkPressable({
  isFirstItem,
  isLastItem,
  title,
  titleFontWeight,
  titleStyle,
  subtitle,
  subtitleStyle,
  indicatorText,
  image,
  minHeightStyle = 'static',
  ...pressableProps
}: ListItemLinkPressableProps) {
  return (
    <Pressable
      {...pressableProps}
      style={[
        listStyles.item,
        minHeightStyle === 'static' && listStyles.minHeightStyleStatic,
        isLastItem && listStyles.lastItem,
        pressableProps.style,
      ]}
      hoveredStyle={listStyles.itemHover}
      borderTopLeftRadius={isFirstItem ? BORDER_RADIUS : 0}
      borderTopRightRadius={isFirstItem ? BORDER_RADIUS : 0}
      borderBottomLeftRadius={isLastItem ? BORDER_RADIUS : 0}
      borderBottomRightRadius={isLastItem ? BORDER_RADIUS : 0}
    >
      {image?.asset?.uri && (
        <Image
          contentFit="contain"
          style={listStyles.image}
          alt={image.altText}
          source={{ uri: optimizeSanityImageUri(image.asset.uri, IMAGE_HEIGHT) }}
          placeholder={{ blurhash: image.asset.blurHash }}
        />
      )}
      <View style={listStyles.titleTextContainer}>
        <View>
          <Text.Ui
            weight={titleFontWeight ?? 'semibold'}
            size="md"
            numberOfLines={1}
            style={titleStyle}
          >
            {title}
          </Text.Ui>
        </View>
        {Boolean(subtitle) && (
          <View>
            <Text.Ui size="sm" numberOfLines={1} style={[listStyles.subtitle, subtitleStyle]}>
              {subtitle}
            </Text.Ui>
          </View>
        )}
      </View>
      <XStack style={listStyles.indicatorWithTextWrapper}>
        {Boolean(indicatorText) && (
          <Text.Ui size="sm" numberOfLines={1} style={listStyles.indicatorText}>
            {indicatorText}
          </Text.Ui>
        )}
        <IconChevronRight size={24} />
      </XStack>
    </Pressable>
  );
}
