import React, { FC } from 'react';

import { Box } from '@fhs-legacy/universal-components';
import { STORE_LOCATOR_CONTAINER } from 'utils/test-ids';

import { ItemsUnavailableDialog } from './items-unavailable-dialog';
import { StoreLocatorProvider } from './store-locator-provider';
import { StoreLocatorView } from './store-locator.view';

/**
 *
 * StoreLocator purpose
 *
 */
const StoreLocator: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <StoreLocatorProvider>
      <Box flex={1} testID={STORE_LOCATOR_CONTAINER}>
        <StoreLocatorView />
        <ItemsUnavailableDialog />
      </Box>
    </StoreLocatorProvider>
  );
};

export default StoreLocator;
