import { View } from 'react-native';

import { Skeleton, createMqStyles, tokens } from '@fhs/ui';

export const MenuListItemSkeleton = () => {
  const mqStyles = useMqStyles();
  return (
    <View style={mqStyles.itemBox}>
      <View style={mqStyles.descriptionBox}>
        <Skeleton style={mqStyles.itemImage} />
        <Skeleton style={mqStyles.itemName} />
        <Skeleton style={mqStyles.itemDescriptionLine1} />
        <Skeleton style={mqStyles.itemDescriptionLine2} />
      </View>
    </View>
  );
};

const useMqStyles = createMqStyles({
  itemImage: {
    $base: {
      width: 110,
      height: 86,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      position: 'absolute',
      top: -50,
    },
  },
  itemBox: {
    $base: {
      flexGrow: 1,
      flexShrink: 1,

      height: 140,
      maxHeight: 205,
      marginTop: 62,
    },
    $gteDesktop: {
      flex: 1,

      height: 140,
      marginTop: 80,
    },
  },
  descriptionBox: {
    $base: {
      borderRadius: 8,
      borderWidth: 1,
      borderColor: tokens.colors.$blackOpacity10,
      width: '100%',

      height: '100%',
      alignItems: 'center',
      paddingTop: 52,
    },
    $gteDesktop: {
      height: 148,
    },
  },
  itemName: {
    $base: {
      height: 20,
      width: 78,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
    },
  },
  itemDescriptionLine1: {
    $base: {
      height: 12,
      width: 124,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 19,
    },
  },
  itemDescriptionLine2: {
    $base: {
      height: 12,
      width: 86,
      borderRadius: 12,
      backgroundColor: tokens.colors.$houseLight,
      marginTop: 5,
    },
  },
});
