import { memo } from 'react';

import { ActionSheet, RadioGroup, YStack, useMqSelect } from '@fhs/ui';

import { MenuItemCustomizationOptionValue } from '../types';
import { formatCentsToDollars } from '../utils';

import { ListItemRadio } from './list-item-group-section';

type PickerActionSheetProps = {
  isVisible: boolean;
  onClose: () => void;
  selectedOptionKey: string | null;
  headerTitle?: string;
  onSelect: (option: MenuItemCustomizationOptionValue) => void;
  options: MenuItemCustomizationOptionValue[];
};

function _PickerActionSheet({
  isVisible = false,
  onClose,
  selectedOptionKey,
  headerTitle,
  options,
  onSelect,
}: PickerActionSheetProps) {
  // If the selected option key is not null, then we are selecting an option from the action sheet
  const defaultOption = options.find(opt => opt.isDefault);
  const selectedOption =
    options.find(opt => opt.key === selectedOptionKey) ?? defaultOption ?? options[0];

  const isDesktop = useMqSelect({ $gteDesktop: true }, false);
  const listVariation = isDesktop ? 'actionsheetItem' : 'listItem';

  return (
    <ActionSheet isVisible={isVisible} onClose={onClose} headerTitle={headerTitle}>
      <YStack style={{ paddingBottom: 28 }}>
        <RadioGroup
          value={selectedOption?.key}
          onChange={nextKey => onSelect(options.find(opt => opt.key === nextKey))}
        >
          {options.map(opt => {
            return (
              <ListItemRadio
                key={opt.key}
                indicatorSize="md"
                value={opt.key}
                disabled={!opt.isAvailable}
                variation={listVariation}
                isFirstItem={false}
                isLastItem={false}
                image={opt.image}
                title={opt.displayName}
                //   {/* TODO(simplified-menu): For Gluten Free, we will need to add Only Available For Medium Sub +price */}
                subtitle={
                  opt.caloriesOffset !== undefined ? `${opt.caloriesOffset} cal` : undefined
                }
                indicatorText={formatCentsToDollars(opt.upChargeCents)}
              />
            );
          })}
        </RadioGroup>
      </YStack>
    </ActionSheet>
  );
}

export const PickerActionSheet = memo(_PickerActionSheet);
